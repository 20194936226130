import AuthLayout from '@/views/auth/AuthLayout.vue';

export default {
  path: '',
  name: 'AuthLayout',
  component: AuthLayout,
  children: [
    {
      path: 'auth-login',
      name: 'AuthLogin',
      component: () => import(
        /* webpackChunkName: "login" */
        '../views/auth/login/Login.vue'
      ),
    },
    // {
    //   path: "registration",
    //   name: "Registration",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "registration" */
    //       "../views/auth/registration/Registration.vue"
    //     ),
    // },
    // {
    //   path: "reset-password",
    //   name: "ResetPassword",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "reset-password" */
    //       "../views/auth/reset-password/ResetPassword.vue"
    //     ),
    // },
    {
      path: 'login',
      name: 'Login',
      component: () => import('../views/auth/login/LoginBridge.vue'),
      props: (route) => ({ redirectFrom: route.query.redirectFrom }),
    },
    {
      path: 'logout',
      name: 'Logout',
      component: () => import('../views/auth/login/Logout.vue'),
    },
    {
      path: 'oauth',
      name: 'OAuth',
      component: () => import('../views/auth/login/OAuth.vue'),
    },
  ],
};
