import LookupApi from '@/api/lookup.api';
import { deserializer } from '@/helpers/api';

class BrokenParametr extends Error {
  constructor(message, status) {
    super(message);
    this.name = 'ReadError';
    this.status = status || 404;
  }
}

const mainState = {
  mainClassifications: [],
  firstSubClassifications: [],
  secondSubClassifications: [],
  activities: [],
  goals: [],
};

const mainGetters = {
  mainClassifications: (store) => store.mainClassifications.map((cl) => ({
    label: cl.arTitle,
    key: Number(cl.id),
  })),
  firstSubClassifications: (store) => store.firstSubClassifications.map((cl) => ({
    label: cl.arTitle,
    key: Number(cl.id),
  })),
  secondSubClassifications: (store) => store.secondSubClassifications.map((cl) => ({
    label: cl.arTitle,
    key: Number(cl.id),
  })),
  activitiesOptions: (store) => store.activities.map((cl) => ({
    label: cl.arTitle,
    key: cl.id,
  })),
  goalsOptions: (store) => store.goals.map((g) => ({
    label: g.arTitle,
    key: g.id,
  })),
};

const mutations = {
  setMainClassification: (store, list) => {
    store.mainClassifications = list;
  },
  setFirstSubClassification: (store, list) => {
    store.firstSubClassifications = list;
  },
  setSecondSubClassification: (store, list) => {
    store.secondSubClassifications = list;
  },
  setActivities: (store, list) => {
    store.activities = list;
  },
  setGoals: (store, list) => {
    store.goals = list;
  },
};

const actions = {
  fetchClassifications: async ({ commit }, { type, id }) => {
    let classifications = null;
    try {
      const res = await LookupApi.getClassifications(type, id);
      const result = await deserializer(res.data);
      classifications = Object.freeze(result);
      commit(`set${type}`, Object.freeze(result));
    } catch (e) {
      console.warn(e);
      classifications = {
        error: e,
      };
    }
    return classifications;
  },
  fetchActivities: async ({ commit }, { classificationId }) => {
    let activities = null;
    try {
      const res = await LookupApi.getActivities(classificationId);
      const result = await deserializer(res.data);
      activities = Object.freeze(result);
      commit('setActivities', activities);
    } catch (e) {
      console.warn(e);
      activities = {
        error: e,
      };
    }
    return activities;
  },
  fetchGoals: async ({ commit }, { classificationId, ids, type }) => {
    let goals = null;
    if (!classificationId) {
      const errMsg = 'ClassificationId not defined';
      console.warn(errMsg);
      return {
        error: {
          response: new BrokenParametr(errMsg, 404),
        },
      };
    }
    try {
      const res = await LookupApi.getGoals(classificationId, ids, type);
      const result = await deserializer(res.data);
      goals = Object.freeze(result);
      commit('setGoals', goals);
    } catch (e) {
      console.warn(e);
      goals = {
        error: e,
      };
    }
    return goals;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
