import {
  CREATE_MEMBERSHIP_REQUEST,
  GET_CURRENT_DELEGATE,
  GET_DELEGATE_DETAILS,
  GET_MEMBERSHIP_DETAILS,
  GET_REQUESTS,
  LIST_ENTITY_MEMBERS,
  REJECT_MEMBERSHIP_REQUEST,
  ACCEPT_MEMBERSHIP_REQUEST,
} from '@/api/graphql/delegate-management-gql';
import { GraphQLRequest } from '@/setup/axios';

class DelegateManagementApi {
  listEntityMembers = (unitId, page, size, role, active) => GraphQLRequest({
    query: LIST_ENTITY_MEMBERS,
    variables: {
      unitId: Number(unitId),
      page: Number(page),
      size: Number(size),
      role,
      active,
    },
  })

  getDelegateDetails = (membershipRequestId) => GraphQLRequest({
    query: GET_DELEGATE_DETAILS,
    variables: {
      id: Number(membershipRequestId),
    },
  })

  getMembershipDetails = (id) => GraphQLRequest({
    query: GET_MEMBERSHIP_DETAILS,
    variables: {
      id: Number(id),
    },
  })

  getPotentialDelegate = (unitId, page, size, status, unitType) => GraphQLRequest({
    query: GET_REQUESTS,
    variables: {
      unitId: Number(unitId),
      page: Number(page),
      size: Number(size),
      status,
      unitType,
      asChairman: true,
    },
  })

  getCurrentDelegate = (id, role, active) => GraphQLRequest({
    query: GET_CURRENT_DELEGATE,
    variables: {
      id,
      role,
      active,
    },
  })

  createMembershipRequest = (id, type, mode, nationalId, birtday, email, phone) => GraphQLRequest({
    query: CREATE_MEMBERSHIP_REQUEST,
    variables: {
      membershipRequestCreateAttributes: {
        unitId: Number(id),
        unitType: type,
        memberMode: mode,
        nationalId,
        birthDateHijri: birtday,
        email,
        absherPhone: phone,
      },
    },
  })

  rejectDelegateRequest = (id, rejectReason) => GraphQLRequest({
    query: REJECT_MEMBERSHIP_REQUEST,
    variables: {
      membershipRequestRejectAttributes: {
        id: Number(id),
        rejectReason,
      },
    },
  })

  acceptDelegateRequest = (id) => GraphQLRequest({
    query: ACCEPT_MEMBERSHIP_REQUEST,
    variables: {
      membershipRequestAcceptAttributes: {
        id: Number(id),
      },
    },
  })

  listEntityRequests = ({
    id, page, size, unitType, asChairman, status,
  }) => GraphQLRequest({
    query: GET_REQUESTS,
    variables: {
      unitId: id ? Number(id) : undefined,
      page: Number(page),
      size: Number(size),
      unitType,
      asChairman,
      status,
    },
  })
}

export default new DelegateManagementApi();
