import { getField, updateField } from 'vuex-map-fields';
import ErrorHandler from '@/helpers/errors';
import unifiedNationalNumberApi from '@/api/unified-national-number.api';
import { mediaAttachableTypes, mediaDocTypes } from '@/constants/enums';
import { getDocumentsList, uploadDocuments } from '@/services/media.service';
import i18n from '@/i18n';

const normalizeUnifiedNationalNumber = (data = {}) => ({
  unifiedNumbers: data.unifiedNumbers || [],
  entityId: data.entityId || '',
});

const formatDate = (date) => {
  if (!date) {
    return undefined;
  }
  if (typeof date !== 'string') {
    return undefined;
  }
  const datePart = date.split('T')[0];
  return datePart ? datePart.replace(/-/g, '/') : undefined;
};

const normalizeLicensingInfo = (data = {}) => ({
  id: data.id || '',
  status: data.status || '',
  unifiedNumbers: data.unifiedNumbers || [],
  unifiedNumber700: data.unifiedNumber700 || '',
  licensingNumber: data.entityLicenseNumber || '',
  licenseInitiatedAt: formatDate(data.licenseInitiatedAt) || '',
  entityId: data.entityId || '',
  licenseExpiredAt: formatDate(data.licenseExpiredAt) || '',
  entityTelephoneNumber: data.entityTelephoneNumber ? data.entityTelephoneNumber.split('00966')[1] : '',
  entityPhoneNumber: data.entityPhoneNumber ? data.entityPhoneNumber.split('00966')[1] : '',
  entityEmail: data.entityEmail || '',
  chairmanBoardFirstName: data.chairmanBoardFirstName || '',
  chairmanBoardFatherName: data.chairmanBoardFatherName || '',
  chairmanBoardGrandfatherName: data.chairmanBoardGrandfatherName || '',
  chairmanBoardLastName: data.chairmanBoardLastName || '',
  chairmanBoardBirthday: formatDate(data.chairmanBoardBirthday) || '',
  chairmanBoardNationalId: data.chairmanBoardNationalId || '',
  chairmanBoardPhoneNumber: data.chairmanBoardPhoneNumber ? data.chairmanBoardPhoneNumber.split('00966')[1] : '',
  chairmanBoardEmail: data.chairmanBoardEmail || '',
  ownerFirstName: data.ownerFirstName || '',
  ownerFatherName: data.ownerFatherName || '',
  ownerGrandfatherName: data.ownerGrandfatherName || '',
  ownerLastName: data.ownerLastName || '',
  ownerNationalId: data.ownerNationalId || '',
  ownerPhoneNumber: data.ownerPhoneNumber ? data.ownerPhoneNumber.split('00966')[1] : '',
  ownerEmail: data.ownerEmail || '',
  ownerBirthday: formatDate(data.ownerBirthday) || '',
  cityAr: data.cityAr || '',
  cityEn: data.cityEn || '',
  createdAt: formatDate(data.createdAt) || '',
  nameAr: data.nameAr || '',
  nameEn: data.nameEn || '',
  type: data.type || '',
});

const mainState = {
  newUnifiedNationalNumber: normalizeUnifiedNationalNumber(),
  form: normalizeLicensingInfo(),
  isLoading: false,
  errors: [],
  fileUploading: false,
  attachments: [],
  undefindNumber700: '',

};

const mainGetters = {
  getField,
  getUnifiedNationalNumberData: (store) => store.newUnifiedNationalNumber,
  getDataInfo: (store) => store.form,
  getErrors: (store) => store.errors,
  fileUploading: (store) => store.fileUploading,
  isLoading: (store) => store.isLoading,
  getAttachments: (store) => store.attachments,
  getUndefindNumber700: (store) => store.undefindNumber700,
};

const mutations = {
  updateField,
  setUnifiedNationalNumberData: (store, form) => {
    store.newUnifiedNationalNumber = normalizeUnifiedNationalNumber(form);
  },
  resetNewUnifiedNationalNumberForm: (store) => {
    store.newUnifiedNationalNumber = normalizeUnifiedNationalNumber();
  },
  setDataInfo: (store, data) => {
    store.form = { ...store.form, ...data };
  },
  setAttachments: (store, value) => {
    store.attachments = value;
  },
  setLoading: (store, value) => {
    store.isLoading = value;
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  resetErrors: (store) => {
    store.errors = [];
  },
  setFileUploading: (store, value) => {
    store.fileUploading = value;
  },
  setUndefindNumber700: (store, value) => {
    store.undefindNumber700 = value;
  },
  resetSetUndefindNumber700: (store) => {
    store.undefindNumber700 = undefined;
  },
  resetDataInfo: (store) => {
    store.form = {
      unifiedNumbers: [],
    };
  },
  clearLocalStorage: () => {
    localStorage.removeItem('delegateInfo');
    localStorage.removeItem('ChairmanBoardInfo');
    localStorage.removeItem('licensingInfo');
    localStorage.removeItem('contactInfo');
  },
};

const actions = {
  createUnifiesNumberForAdminRequest: async ({ commit, dispatch, state }) => {
    commit('setLoading', true);
    commit('setErrors', []);
    try {
      const data = state.newUnifiedNationalNumber;
      const res = await unifiedNationalNumberApi.createUnifiedNumberAdminRequest(data);
      const unifiedNumberData = res?.data?.createUnifiedNumberAdminRequest;
      const normalizedData = normalizeLicensingInfo(unifiedNumberData);
      commit('setDataInfo', normalizedData);
      dispatch('toast/showNotification', {
        message: i18n.t('general.success.addUnifiedNumbers'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return unifiedNumberData;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },
  fetchUnifiedNumbersList: async ({ commit, rootGetters, dispatch }, { page, size, entityId }) => {
    commit('setLoading', true);
    commit('setDataInfo', []);
    try {
      const payload = {
        page: Number(rootGetters['unifiedNationalNumberPagination/selectedPage']) || page,
        size: Number(rootGetters['unifiedNationalNumberPagination/selectedSize']) || size,
        entityId,
      };
      const res = await unifiedNationalNumberApi.getUnifiedNumberAdminRequest(payload);
      const listUnifiedRequest = res?.data?.listUnifiedNumberAdminRequest?.adminRequests[0];
      commit('setDataInfo', listUnifiedRequest);
      const adminRequestId = listUnifiedRequest?.id;
      if (adminRequestId) {
        const lazyCalls = ['fetchUnifiedNumAttachments'];
        lazyCalls.forEach((name) => {
          dispatch(name, { page: 1, size: 15, adminRequestId });
        });
      }
      return listUnifiedRequest;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  deleteUnifiedNumber: async ({ dispatch }, { requestId, unifiedNumbersList, entityId }) => {
    const payload = {
      id: requestId,
      unifiedNumbers: unifiedNumbersList,
    };
    try {
      const data = await unifiedNationalNumberApi.deleteUnifiedNumberAdminRequest(payload);
      if (data.data.error) {
        return { error: data.data.error };
      }
      await dispatch('fetchUnifiedNumbersList', { entityId });
      return data;
    } catch (e) {
      dispatch(
        'toast/showNotification',
        {
          message: ErrorHandler.parseGlobalErrors(e),
          duration: 3000,
          type: 'error',
        },
        { root: true },
      );
      return { error: e.message };
    }
  },
  unifiedNationalDocUpload: async ({ commit }, { files, id }) => {
    commit('setFileUploading', true);
    const res = await uploadDocuments(
      files,
      id,
      mediaAttachableTypes.UnifiedNumber,
      mediaDocTypes.unifiedNationalNumber700,
    );
    commit('setFileUploading', false);
    return res;
  },
  getUnifiedNationalDoc: async (_, id) => getDocumentsList(
    id,
    mediaAttachableTypes.UnifiedNumber,
    mediaDocTypes.unifiedNationalNumber700,
    null,
  ),

  createUnifiedNationalAttachment: async ({ commit }, {
    selectedUnifiedNumber, id, fileMetadataUId, docTypeData,
  }) => {
    commit('setLoading', true);
    try {
      const payload = {
        unifiedNumber: selectedUnifiedNumber,
        adminRequestId: id,
        fileMetadataUid: fileMetadataUId,
        docType: docTypeData,
      };
      const data = await unifiedNationalNumberApi.createUnifiedNumberAttachment(payload);
      return data;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  fetchUnifiedNumAttachments: async ({ commit, rootGetters }, { page, size, adminRequestId }) => {
    commit('setLoading', true);
    try {
      const payload = {
        page: Number(rootGetters['unifiedNationalNumberPagination/selectedPage']) || page,
        size: Number(rootGetters['unifiedNationalNumberPagination/selectedSize']) || size,
        adminRequestId,
      };
      const data = await unifiedNationalNumberApi.getUnifiedNumberAttachments(payload);
      const attachments = data?.data?.listUnifiedNumberAttachments?.attachments;
      commit('setAttachments', attachments);
      return attachments;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  fetchUnifiedNumberDataById: async ({ commit }, { id }) => {
    commit('setLoading', true);
    commit('setDataInfo', []);
    try {
      const payload = { id };
      const data = await unifiedNationalNumberApi.getUnifiedNumberAdminRequestById(payload);
      const unifiedRequestDataById = data?.data?.findUnifiedNumberAdminRequest;
      const unifiedRequestData = normalizeLicensingInfo(unifiedRequestDataById);
      commit('setDataInfo', unifiedRequestData);
      return unifiedRequestData;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  updateUnifiedNumberForAdminRequest: async ({ commit, dispatch }, { payload }) => {
    commit('setLoading', true);
    commit('setErrors', []);
    try {
      const data = payload;
      const res = await unifiedNationalNumberApi.updateUnifiedNumberAdminRequest(data);
      const unifiedNumberUpdated = res?.data?.updateUnifiedNumberAdminRequest;
      const unifiedRequestDataUpdated = normalizeLicensingInfo(unifiedNumberUpdated);
      commit('setDataInfo', unifiedRequestDataUpdated);
      const id = data?.id;
      if (id) {
        const lazyCalls = [
          'fetchUnifiedNumberDataById',
        ];
        lazyCalls.forEach((name) => {
          dispatch(name, { id });
        });
      }
      return unifiedRequestDataUpdated;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  submitUnifiesNumberForAdminRequest: async ({ commit, dispatch }, { payload }) => {
    commit('setLoading', true);
    commit('setErrors', []);
    try {
      const data = payload;
      const res = await unifiedNationalNumberApi.submitUnifiedNumberAdminRequest(data);
      const unifiesNumberRequest = res?.data?.submitUnifiedNumberAdminRequest;
      const unifiedRequestData = normalizeLicensingInfo(unifiesNumberRequest);
      commit('setDataInfo', unifiedRequestData);
      dispatch('toast/showNotification', {
        message: i18n.t('general.success.ProcessUnifiedNumber'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return unifiesNumberRequest;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
