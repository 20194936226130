<template>
  <div class="about-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AboutLayout',
};
</script>

<style src="./styles.scss" lang="scss"/>
