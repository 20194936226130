import { getField, updateField } from 'vuex-map-fields';
import {
  JoinSteps,
  mediaAttachableTypes,
  mediaDocTypes,
  MembershipEntityTypes,
  MemberTypes,
  nonGovernmentalTypes,
  UserRequestStatuses,
} from '@/constants/enums';
import {
  getDateOfBirthAsString,
  makeDateFromString,
} from '@/helpers/general';
import ErrorHandler from '@/helpers/errors';
import MembershipApi from '@/api/membership.api';
import i18n from '@/i18n';
import {
  getMemberships,
  normalizeMembership,
  updateMembership,
  serializeMembership,
  serializeParentMembership,
  normalizeDelegateMembership,
  fetchMember,
  getFullName,
  getUserRequestPayload,
} from '@/services/membership.service';
import { getDocumentsList, uploadDocuments } from '@/services/media.service';
import EmployeeApi from '@/api/employee.api';
import UserRequestsApi from '@/api/user-requests.api';
import { prepareDisclosureForm } from '@/services/user-requests.service';
import { PhoneFormatterAdapter } from '@takamol/nawa-library/src/helpers/phone-formatter';

const mainState = {
  form: normalizeMembership(),
  delegateForm: normalizeDelegateMembership(),
  memberships: [],
  parentMemberships: [],
  parentEmployeeList: [],
  saveLoading: false,
  membershipMode: MemberTypes.Individual,
  errors: [],
  fileUploading: false,
  activeMember: null,
  parentEmployeeListMeta: null,
};

const mainGetters = {
  getField,
  getErrors: (store) => store.errors,
  fileUploading: (store) => store.fileUploading,
  parentMemberships: (store) => store.parentMemberships,
  parentEmployeeList: (store) => store.parentEmployeeList,
  getMemberships: (store) => store.memberships,
  isSaveLoading: (store) => store.saveLoading,
  membershipMode: (store) => store.membershipMode,
  membershipCount: (store) => store.memberships?.length || 0,
  memberships: (store) => store.memberships,
  hasInnerSupporters: (store) => store.memberships.some((m) => m.financeAmount > 0),
  contributorsTotal: (store, _, rootState) => {
    const { innerSupporters, outsideSupporters, contributionSourcesList } = rootState.entity;
    const inner = store.memberships.reduce((total = 0, m) => {
      total += innerSupporters ? +m.financeAmount : 0;
      return total;
    }, 0);
    const outer = contributionSourcesList.reduce((total = 0, s) => {
      total += outsideSupporters ? +s.amount : 0;
      return total;
    }, 0);
    return inner + outer;
  },
  isEditMode: (store) => store.form.editMode,
  isActiveDOB: (store) => store.form.nationalId?.startsWith('1'),
  remainMemberships: (store, getters, _, rootGetters) => (
    store.parentMemberships
      .filter((member) => !rootGetters['userRequests/summaryMembers'].some((m) => (
        member.memberId === m.memberId && m.status !== UserRequestStatuses.RejectedByUser
      )))
      .filter((member) => !getters.getMemberships.some((m) => member.memberId === m.memberId))
      .map((member) => ({ ...member, label: member.fullName || '' }))
  ),
  remainEmployees: (store, getters, _, rootGetters) => (
    store.parentEmployeeList
      .filter((e) => !rootGetters['userRequests/summaryMembers'].some((m) => (
        Number(e.id) === Number(m.memberId) && m.status !== UserRequestStatuses.RejectedByUser
      )))
      .filter((e) => !getters.getMemberships.some((m) => Number(e.id) === Number(m.memberId)))
      .map((e) => {
        e.label = e.fullName || '';
        return e;
      })
  ),
  activeMemberDetails: (store) => store.activeMember,
  getParentEmployeeListMeta: (store) => store.parentEmployeeListMeta,
  getActiveProofStore: (store) => {
    if (store.delegateForm.proofType === nonGovernmentalTypes.License) return 'nonProfitOrganizationLicenseMedia';
    if (store.delegateForm.proofType === nonGovernmentalTypes.Commercial) return 'commercialRegistrationMedia';
    if (store.delegateForm.proofType === nonGovernmentalTypes.PrivateAssociation || store.delegateForm.proofType === nonGovernmentalTypes.PrivateCorporation) return 'generalAssociationMinutesMedia';
    return 'endowmentDeedNumberMedia';
  },
  getActiveProofDocType: (store) => {
    if (store.delegateForm.proofType
      === nonGovernmentalTypes.License) return mediaDocTypes.LicenseProof;
    if (store.delegateForm.proofType
      === nonGovernmentalTypes.Commercial) return mediaDocTypes.CommercialProof;
    if (store.delegateForm.proofType
      === nonGovernmentalTypes.PrivateAssociation || store.delegateForm.proofType
      === nonGovernmentalTypes.PrivateCorporation) return mediaDocTypes.AssociationMinutesProof;
    return mediaDocTypes.EndowmentProof;
  },
};

const mutations = {
  updateField,
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setFileUploading: (store, value) => {
    store.fileUploading = value;
  },
  resetErrors: (store) => {
    store.errors = [];
  },
  setSaveLoading: (store, value) => {
    store.saveLoading = value;
  },
  setMemberLoadingState: (store, { memberId, value }) => {
    const member = store.memberships.find((m) => m.id === memberId) || {};
    member.loading = value;
  },
  setFinanceContributor: (store, membership) => {
    store.memberships = store.memberships.map((m) => (m.id === membership.id ? membership : m));
  },
  setMembershipMode: (store, mode) => {
    store.membershipMode = mode;
  },
  setMemberships: (store, members) => {
    store.memberships = members;
  },
  resetMemberships: (store) => {
    store.memberships = [];
  },
  resetForm: (store) => {
    store.form = normalizeMembership();
  },
  resetDelegateForm: (store) => {
    store.delegateForm = normalizeDelegateMembership();
  },
  setDelegateForm: (store, member) => {
    store.delegateForm = normalizeDelegateMembership(member);
  },
  setDelegateFormId: (store, member) => {
    store.delegateForm = { ...store.delegateForm, delegateId: member.id };
  },
  setForm: (store, membership) => {
    store.form = { ...store.form, ...membership, disclosureFormId: membership?.disclosureForm?.id };
  },
  setExistingForm: (store, membership) => {
    store.form = { ...store.form, ...membership, position: '' };
  },
  resetDOB: (store) => {
    store.form.birthDateHijri = { day: '', month: '', year: '' };
  },
  resetCustomPosition: (store) => {
    store.form.customPosition = undefined;
  },
  setParentMemberships: (store, members) => {
    store.parentMemberships = members.map((m) => serializeParentMembership(m));
  },
  setParentEmployeeList: (store, data) => {
    store.parentEmployeeList = [...store.parentEmployeeList, ...data];
  },
  setActiveMember: (store, member) => {
    const phone = member.phone || member.absherPhone;
    const { nationalAddress } = member;
    store.activeMember = {
      fullName: member.fullName || '—',
      birthDate: getDateOfBirthAsString(member.birthDateHijri) || makeDateFromString(member.birthDateHijri) || '—',
      email: member.email || '—',
      phone: phone ? PhoneFormatterAdapter.fromServerToUI(phone) : '—',

      nationalAddressAr: {
        streetName: nationalAddress.streetNameAr,
        districtArea: nationalAddress.districtAreaAr,
        city: nationalAddress.cityAr,
        buildingNo: nationalAddress.buildingNo,
        unitNo: nationalAddress.unitNo,
        zipCode: nationalAddress.zipCode,
        additionalNo: nationalAddress.additionalNo,
      },
      nationalAddressEn: {
        streetName: nationalAddress.streetNameEn,
        districtArea: nationalAddress.districtAreaEn,
        city: nationalAddress.cityEn,
        buildingNo: nationalAddress.buildingNo,
        unitNo: nationalAddress.unitNo,
        zipCode: nationalAddress.zipCode,
        additionalNo: nationalAddress.additionalNo,
      },
      memberRole: member.role || '—',
    };
  },
  resetActiveMember: (store) => {
    store.activeMember = null;
  },
  setParentEmployeeListMeta: (store, obj) => {
    store.parentEmployeeListMeta = obj;
  },
};

const actions = {
  editFinanceContributor: async ({ state, commit, dispatch }, { contributor }) => {
    const submitPayload = serializeMembership(
      contributor,
      state.form.id,
    );
    const res = await updateMembership(submitPayload, contributor.id);
    if (res?.error) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(res.error),
        duration: 3000,
        type: 'error',
      }, { root: true });
    } else {
      commit('setFinanceContributor', contributor);
    }
    return res;
  },
  getParentMembers: async ({ commit }, parentId) => {
    if (!parentId) return false;
    const res = await getMemberships(parentId);
    commit('setParentMemberships', res?.error ? [] : res);
    return res;
  },
  getParentEmployeeList: async ({ commit }, { entityId, page, size }) => {
    if (!entityId) return { error: `EntityId is broken: ${entityId}` };
    entityId = Number(entityId);
    page = Number(page) || 1;
    size = Number(size) || 10;
    try {
      const res = await EmployeeApi.getEntityEmployeesGql(
        entityId,
        page,
        size,
      );
      const employeeRequests = res?.data?.listEmployeeRequestsByMember;
      let list = employeeRequests?.list || [];
      list = list.map((obj) => ({
        ...obj.employee,
        fullName: getFullName(obj.employee),
      }));
      const meta = employeeRequests?.meta || { pageCount: 1 };
      commit('setParentEmployeeListMeta', meta);
      commit('setParentEmployeeList', list);
      return res;
    } catch (e) {
      return { error: e.message };
    }
  },
  removeMembership: async ({ commit, dispatch }, { memberId, entityId, entityType }) => {
    commit('setMemberLoadingState', { value: true, memberId });
    try {
      await MembershipApi.deleteMembership(memberId);
      await dispatch('fetchMemberships', { entityId, type: entityType });
    } catch (e) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    } finally {
      commit('setMemberLoadingState', { value: false, memberId });
    }
  },
  updateUserRequest: async ({ commit, dispatch, state },
    { unitId, payloadType, memberDetails = null }) => {
    commit('setSaveLoading', true);
    commit('setErrors', []);
    const data = {
      form: memberDetails || state.form,
      unitId,
      memberMode: state.membershipMode,
    };
    let submitPayload = getUserRequestPayload(payloadType, data);

    const { role, governmental } = submitPayload;
    if (role === MemberTypes.Delegate && governmental) {
      const attachments = await dispatch('approvalDocumentMedia/serializeFiles', mediaDocTypes.EntityApprovalProof, { root: true });
      submitPayload = {
        ...submitPayload,
        attachments,
      };
    }

    const memberId = memberDetails?.id || state.form.id;
    const res = await updateMembership(submitPayload, memberId);
    if (res?.error) {
      commit('setErrors', res.error);
    } else {
      await dispatch('fetchMemberships', { entityId: unitId });
    }
    commit('setSaveLoading', false);
    return res;
  },
  acceptUserRequest: async (_, id) => {
    try {
      return await UserRequestsApi.acceptUserRequest(id);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  submitDisclosureForm: async ({ commit, state }, data) => {
    try {
      const { updateDisclosureFormGql: update, createDisclosureFormGql: create } = UserRequestsApi;
      const { disclosureFormId, id } = state.form;
      const method = disclosureFormId ? update : create;
      const res = await method({ ...prepareDisclosureForm(data), id: disclosureFormId || id });
      if (!disclosureFormId && res?.data?.createDisclosureForm?.id) {
        commit('updateField', {
          path: 'form.disclosureFormId',
          value: res.data.createDisclosureForm.id,
        });
      }
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  createUserRequest: async (
    { commit, dispatch, state },
    {
      unitId, payloadType, unitType, memberMode, getList = true, memberDetails = null,
    },
  ) => {
    commit('setSaveLoading', true);
    commit('setErrors', []);
    try {
      const data = {
        form: memberDetails || state.form,
        unitId,
        unitType,
        memberMode,
      };
      let submitPayload = getUserRequestPayload(payloadType, data);

      const { role, governmental } = submitPayload;
      if (role === MemberTypes.Delegate && governmental) {
        const attachments = await dispatch('approvalDocumentMedia/serializeFiles', mediaDocTypes.EntityApprovalProof, { root: true });
        submitPayload = {
          ...submitPayload,
          attachments,
        };
      }

      const res = await MembershipApi.saveMembership(submitPayload);
      if (res?.error) {
        commit('setErrors', res.error);
      } else if (getList) {
        await dispatch('fetchMemberships', {
          entityId: unitId,
          type: unitType || MembershipEntityTypes.Entity,
        });
      }
      dispatch('toast/showNotification', {
        message: i18n.t('entity.addMemberSuccess'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return res;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setSaveLoading', false);
    }
  },
  fetchMemberships: async ({ commit }, { entityId, type }) => {
    commit('setErrors', []);
    const res = await getMemberships(entityId, type);
    if (res?.error) {
      commit('setErrors', res.error);
    } else {
      commit('setMemberships', res);
    }
    return res;
  },
  delegateProofUpload: async ({ commit }, { files, id }) => {
    commit('setFileUploading', true);
    const res = await uploadDocuments(
      files,
      id,
      mediaAttachableTypes.DelegatorProof,
      mediaDocTypes.DelegatorProofLetter,
    );
    commit('setFileUploading', false);
    return res;
  },
  getDelegateProof: async (_, id) => getDocumentsList(
    id,
    mediaAttachableTypes.DelegatorProof,
    mediaDocTypes.DelegatorProofLetter,
    null,
  ),
  getInviteeInfo: async ({ commit }, { id, unitType }) => {
    const member = await fetchMember(id);
    const keyByRole = member?.role === MemberTypes.Delegate ? [] : [JoinSteps.Delegate];
    const keys = unitType === MembershipEntityTypes.Entity
      ? keyByRole
      : [JoinSteps.Security, JoinSteps.Delegate];
    commit('joinStepper/filterStepsByKeys', keys, { root: true });
    if (!member?.error) {
      commit('setDelegateForm', member?.delegatorProof);
      commit('setForm', member);
    }
    return member;
  },
  submitDelegateProof: async ({
    commit, state, dispatch, getters,
  }, { id, governmental }) => {
    try {
      const submitPayload = {
        entityName: state.delegateForm.entityName,
        userRequestId: Number(id),
        organizationName: state.delegateForm.organizationName,
        organizationNumber: state.delegateForm.organizationNumber,
      };
      if (!governmental) {
        const number700 = await dispatch('number700DocumentMedia/serializeFiles', mediaDocTypes.Number700, { root: true });
        const gosi = await dispatch('gosiDocumentMedia/serializeFiles', mediaDocTypes.GosiProof, { root: true });
        const certificateNumber = await dispatch('zakatDocumentMedia/serializeFiles', mediaDocTypes.ZakatProof, { root: true });
        const proofValue = await dispatch(`${getters.getActiveProofStore}/serializeFiles`, getters.getActiveProofDocType, { root: true });
        submitPayload.nonGovernmentalProof = {
          proofType: state.delegateForm.proofType,
          attachments: [
            ...proofValue,
            ...number700,
            ...gosi,
            ...certificateNumber,
          ],
        };
      }
      const delegateDocument = await dispatch('delegateDocumentMedia/serializeFiles', mediaDocTypes.DelegatorProofLetter, { root: true });
      submitPayload.attachments = [
        ...delegateDocument,
      ];
      let delegatorProof;
      if (state.delegateForm.delegateId) {
        const res = await MembershipApi.updateDelegateProof(
          state.delegateForm.delegateId, submitPayload,
        );
        delegatorProof = res?.data?.updateDelegatorProof;
      } else {
        const res = await MembershipApi.createDelegateProof(id, submitPayload);
        delegatorProof = res?.data?.createDelegatorProof;
      }
      commit('setDelegateForm', delegatorProof);
      return delegatorProof;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
