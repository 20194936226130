<template>
  <div id="app">
    <router-view />
    <toast-container />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ToastContainer from '@/containers/toast/ToastContainer.vue';
import LookupService from '@/services/lookup.service';

export default {
  name: 'App',
  components: {
    ToastContainer,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler({ query, params }) {
        if (query.requestToken && !params.nid) {
          this.getInvitationsInfo(query.requestToken);
        }
      },
    },
  },
  async created() {
    LookupService.fetchLookup();
    await this.getUserProfile({ useLoading: true });
    // if (this.isAuthenticated && ['AuthLogin', 'Registration', 'ResetPassword'].includes(this.$route.name)) {
    //   this.logoutIndividual(false);
    // }
  },
  methods: {
    ...mapActions({
      getUserProfile: 'user/getUserProfile',
      logoutIndividual: 'auth/logoutIndividual',
      getInvitationsInfo: 'auth/getInvitationsInfo',
    }),
  },
};

</script>
