import { getField, updateField } from 'vuex-map-fields';
import EmployeeApi from '@/api/employee.api';
import {
  makeDateFromString,
} from '@/helpers/general';
import ErrorHandler from '@/helpers/errors';
import { getFullName } from '@/services/membership.service';
import { PhoneFormatterAdapter } from '@takamol/nawa-library/src/helpers/phone-formatter';

const handleEmployeePublicInfo = (res) => {
  const request = res?.data?.invitation?.request;
  const employee = request?.employee;
  if (!employee) return {};
  return {
    position: request.customPosition,
    totalExperienceYears: request.totalExperience,
    status: request.status,
    nationalId: employee.nationalId,
    birthDateHijri: makeDateFromString(employee.birthDateHijri),
    occupation: employee.occupation,
    employeeName: getFullName(employee),
    absherPhone: PhoneFormatterAdapter.fromServerToUI(employee.absherPhone),
    educationLevel: employee.educationalLevel,
    gender: employee.gender || '',
  };
};

const mainState = {
  errors: [],
  employeeInfo: null,
};

const mainGetters = {
  getField,
  getErrors: (store) => store.errors,
  getEmployeeInfo: (store) => store.employeeInfo,
};

const mutations = {
  updateField,
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setEmployeeInfo: (store, data) => {
    store.employeeInfo = data;
  },
};

const actions = {
  fetchEmployeeInfo: async ({ commit }, token) => {
    let info = null;
    try {
      const res = await EmployeeApi.getEmployeeInfoGql(token);
      info = handleEmployeePublicInfo(res);
      commit('setEmployeeInfo', info);
    } catch (e) {
      console.warn(e);
      info = {
        error: e,
      };
    }
    return info;
  },
  sendConfirmationEmployeeInfo: async ({ commit }, { value, token }) => {
    let confirmation;
    commit('setErrors', []);
    try {
      confirmation = await EmployeeApi.sendConfirmationEmployeeInfo(value, token);
    } catch (e) {
      confirmation = ErrorHandler.parseFormErrors(e);
      commit('setErrors', confirmation.error);
    }
    return confirmation;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
