import { GraphQLRequest } from '@/setup/axios';
import {
  ACCEPT_EDIT_REQUEST,
  LIST_EDIT_ITEMS,
  LIST_ENTITY_REQUESTS,
  REJECT_EDIT_REQUEST,
} from '@/api/graphql/edit-request-gql';

export function listEditRequests(type, unitId, status, unitType, page, size) {
  return GraphQLRequest({
    query: LIST_ENTITY_REQUESTS,
    variables: {
      unitId: Number(unitId),
      unitType,
      type,
      status,
      page,
      size,
    },
  });
}

export function listEditItems(editId, action, page, size) {
  return GraphQLRequest({
    query: LIST_EDIT_ITEMS,
    variables: {
      editRequestId: Number(editId),
      page: Number(page),
      size: Number(size),
      action,
    },
  });
}

export function acceptEditRequest(editId) {
  return GraphQLRequest({
    query: ACCEPT_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        id: Number(editId),
      },
    },
  });
}

export function rejectEditRequest(editId, reason) {
  return GraphQLRequest({
    query: REJECT_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        id: Number(editId),
        rejectReason: reason,
      },
    },
  });
}
