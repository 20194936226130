import isEmpty from 'lodash.isempty';
import uniqWith from 'lodash.uniqwith';
import isEqual from 'lodash.isequal';
import {
  EditRequestUnitActions,
  EditRequestUnitTypes,
} from '@/constants/enums';

export const deleteRepeatedObjects = (arr) => (
  uniqWith(arr, isEqual)
);

export const insertGoalsAndActivities = (dataArray, goalsAndActivitiesObj) => {
  if (isEmpty(dataArray)) return dataArray;
  const { generalEditRequestId } = dataArray[0];
  return dataArray
    .map((obj) => {
      let { oldValue, newValue } = obj;
      if (goalsAndActivitiesObj[obj.fieldName]) {
        oldValue = oldValue.map(
          (index) => goalsAndActivitiesObj[obj.fieldName].find(
            (c) => Number(c.key) === Number(index),
          ).label,
        );
        newValue = newValue.map(
          (index) => goalsAndActivitiesObj[obj.fieldName].find(
            (c) => Number(c.key) === Number(index),
          ).label,
        );
      }
      if (generalEditRequestId && obj.editRequestId !== generalEditRequestId) {
        console.warn("More than two id's of edit requests");
      }
      return {
        fieldName: obj.fieldName,
        oldValue,
        newValue,
        editRequestId: obj.editRequestId,
      };
    });
};

export const handleEditedLocations = (data) => {
  const editedRegions = {
    fieldName: EditRequestUnitTypes.EntityLocation,
    oldValue: [],
    newValue: [],
    editRequestId: null,
  };
  data.filter((editRequest) => (
    editRequest.unitType === EditRequestUnitTypes.EntityLocation
  ))
    .forEach((obj) => {
      const { currentPayload, newPayload, editRequestId } = obj;
      if (isEmpty(newPayload) && isEmpty(currentPayload)) {
        return;
      }
      if (!editedRegions.editRequestId) editedRegions.editRequestId = editRequestId;
      if (Object.keys(currentPayload).length) {
        editedRegions.oldValue = [...editedRegions.oldValue, ...[{
          cityAr: currentPayload.cityArTitle,
          cityEn: currentPayload.cityEnTitle,
          regionAr: currentPayload.regionArTitle,
          regionEn: currentPayload.regionEnTitle,
        }]];
      }
      if (Object.keys(newPayload).length) {
        editedRegions.newValue = [...editedRegions.newValue, ...[{
          cityAr: newPayload.cityArTitle,
          cityEn: newPayload.cityEnTitle,
          regionAr: newPayload.regionArTitle,
          regionEn: newPayload.regionEnTitle,
        }]];
      }
    });
  editedRegions.oldValue = deleteRepeatedObjects(editedRegions.oldValue);
  editedRegions.newValue = deleteRepeatedObjects(editedRegions.newValue);
  return editedRegions;
};

export const handleEditedFields = (data, goalsAndActivitiesObj) => {
  const editedItems = [];
  data.filter((editRequest) => (
    editRequest.action !== EditRequestUnitActions.Idle
      && editRequest.unitType !== EditRequestUnitTypes.EntityLocation
  ))
    .forEach((obj) => {
      const { currentPayload, newPayload, editRequestId } = obj;
      if (isEmpty(newPayload) && isEmpty(currentPayload)) {
        return;
      }
      Object.keys(newPayload).forEach((field) => {
        if (newPayload[field] !== currentPayload[field]) {
          const indexOfField = editedItems.findIndex((o) => o.fieldName === field);
          if (indexOfField >= 0) {
            editedItems[indexOfField].oldValue = currentPayload[field];
          } else {
            editedItems.push({
              fieldName: field,
              oldValue: currentPayload[field],
              newValue: newPayload[field],
              editRequestId,
            });
          }
        }
      });
    });
  return insertGoalsAndActivities(editedItems, goalsAndActivitiesObj);
};
