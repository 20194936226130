import Hijri from 'hijrah-date';
import BankAccountApi from '@/api/bank-account.api';
import { deserializer } from '@/helpers/api';
import ErrorHandler from '@/helpers/errors';
import { DatePattern } from '@/constants/enums';

export const normalizeBankPermit = (permit) => {
  if (permit?.createdAt) {
    const date = new Hijri(permit.createdAt);
    permit.createdHijri = date.format(DatePattern.Rtl);
    permit.endedHijri = date.plusDays(5).format(DatePattern.Rtl);
  }
  return permit;
};

export const checkBankPermitNumber = async (number) => {
  try {
    const res = await BankAccountApi.checkPermitNumber(number);
    const data = await deserializer(res.data);
    return normalizeBankPermit(data);
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const createBankReactivation = async (data) => {
  try {
    const res = await BankAccountApi.createBankReactivation(data);
    return res?.data?.createBankAccountReactivation;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};
