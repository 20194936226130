import LandingLayout from '@/views/landing/LandingLayout.vue';

export default {
  path: '',
  component: LandingLayout,
  children: [
    {
      path: '',
      name: 'Home',
      component: () => import(
        /* webpackChunkName: "home" */
        '../views/landing/home/Home.vue'
      ),
    },
    {
      path: 'entities-list',
      name: 'EntitiesList',
      component: () => import(
        /* webpackChunkName: "entities" */
        '../views/landing/entities/EntitiesList.vue'
      ),
    },
    {
      path: 'public-entity-profile/:id',
      // name: 'EntityProfile',
      component: () => import(
        /* webpackChunkName: "public-entity-profile" */
        '../views/landing/public-entity-profile/PublicEntityProfile.vue'
      ),
      children: [
        {
          path: '',
          redirect: { name: 'PublicEntityMain' },
        },
        {
          path: 'main',
          name: 'PublicEntityMain',
          component: () => import(
            /* webpackChunkName: "entity-main" */
            '../containers/entity-profile-tabs/main-tab/MainTab.vue'
          ),
        },
        {
          path: 'members',
          name: 'PublicEntityMembers',
          component: () => import(
            /* webpackChunkName: "entity-members" */
            '../containers/entity-profile-tabs/members-tab/MembersTab.vue'
          ),
        },
        {
          path: 'contacts',
          name: 'PublicEntityContact',
          component: () => import(
            /* webpackChunkName: "entity-contact" */
            '../containers/entity-profile-tabs/contact-tab/ContactTab.vue'
          ),
        },
        {
          path: 'documents',
          name: 'PublicEntityDocuments',
          component: () => import(
            /* webpackChunkName: "entity-documents" */
            '../containers/entity-profile-tabs/documents-tab/DocumentsTab.vue'
          ),
        },
      ],
    },
    {
      path: 'check-bank-account-permit',
      name: 'CheckBankPermit',
      component: () => import(
        /* webpackChunkName: "check-bank-account-permit" */
        '../views/landing/check-bank-permit/CheckBankPermit.vue'
      ),
    },
    {
      path: 'documents-authenticity/:id/:itemType/:docType',
      name: 'DocumentsAuthenticity',
      component: () => import(
        /* webpackChunkName: "documents-authenticity" */
        '../views/landing/documents-authenticity/DocumentsAuthenticity.vue'
      ),
    },
    {
      path: 'services',
      name: 'Services',
      component: () => import(
        /* webpackChunkName: "services" */
        '../views/landing/services/Services.vue'
      ),
      children: [
        {
          path: ':category',
          name: 'ServicesCategory',
          component: () => import(
            /* webpackChunkName: "services-category" */
            '../views/landing/services/Services.vue'
          ),
        },
      ],
    },
    {
      path: 'services-details',
      name: 'ServicesDetails',
      component: () => import(
        /* webpackChunkName: "services-details" */
        '../views/landing/services-details/ServicesDetails.vue'
      ),
      children: [
        {
          path: 'check-list2',
          name: 'CheckList2',
          component: () => import(
            /* webpackChunkName: "check-list2" */
            '../views/landing/services-details/service-details-tabs/CheckList2Tab.vue'
          ),
        },
        {
          path: 'documents-tab',
          name: 'DocumentsTab',
          component: () => import(
            /* webpackChunkName: "documents" */
            '../views/landing/services-details/service-details-tabs/DocumentsTab.vue'
          ),
        },
        {
          path: 'progress',
          name: 'Progress',
          component: () => import(
            /* webpackChunkName: "progress" */
            '../views/landing/services-details/service-details-tabs/ProgressTab.vue'
          ),
        },
        {
          path: 'check-list',
          name: 'CheckList',
          component: () => import(
            /* webpackChunkName: "check-list" */
            '../views/landing/services-details/service-details-tabs/CheckListTab.vue'
          ),
        },
      ],
    },
    {
      path: 'faq',
      name: 'FAQ',
      component: () => import(
        /* webpackChunkName: "faq" */
        '../views/landing/faq/FAQ.vue'
      ),
    },
    {
      path: 'privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import(
        /* webpackChunkName: "faq" */
        '../views/landing/privacy-policy/PrivacyPolicy.vue'
      ),
    },
    {
      path: 'verify-entity-license',
      name: 'VerifyEntityLicense',
      component: () => import(
        /* webpackChunkName: "verify-entity-license" */
        '../views/landing/verify-entity-license/VerifyEntityLicense.vue'
      ),
    },
    {
      path: 'verify-bank-delegations',
      name: 'VerifyBankDelegations',
      component: () => import(
        /* webpackChunkName: "verify-bank-delegations" */
        '../views/landing/verify-bank-delegations/VerifyBankDelegations.vue'
      ),
    },
    {
      path: 'verify-bank-delegations/:id',
      name: 'VerifyBankDelegation',
      component: () => import(
        /* webpackChunkName: "verify-bank-delegations" */
        '../views/landing/verify-bank-delegations/VerifyBankDelegations.vue'
      ),
    },
    {
      path: 'eshop-license-information/:uid',
      name: 'EshopLicenseInformation',
      component: () => import(
        /* webpackChunkName: "eshop-license-information" */
        '../views/landing/eshop-license-information/EshopLicenseInformation.vue'
      ),
    },
    {
      path: 'objection-form/:id',
      name: 'CreateObjection',
      component: () => import(
        /* webpackChunkName: "objection-form" */
        '../views/landing/create-objection/CreateObjection.vue'
      ),
    },
    {
      path: 'announcements',
      name: 'Announcements',
      component: () => import(
        /* webpackChunkName: "announcements" */
        '../views/landing/announcements/Announcements.vue'
      ),
    },
    {
      path: 'announcement/:id',
      name: 'Announcement',
      component: () => import(
        /* webpackChunkName: "announcement" */
        '../views/landing/announcement/Announcement.vue'
      ),
    },
  ],
};
