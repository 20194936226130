import { v4 as uuid } from 'uuid';

const mainState = {
  notifications: [],
};

const mainGetters = {
  toastList: (store) => store.notifications,
};

const mutations = {
  addNotification: (store, notification) => {
    store.notifications.push(notification);
  },
  removeNotification: (store, id) => {
    const index = store.notifications.findIndex((o) => o.id === id);
    store.notifications.splice(index, 1);
  },
  removeAllNotifications: (store) => {
    store.notifications = [];
  },
};

const actions = {
  showNotification: ({ commit }, notification) => {
    const message = {
      ...notification,
      id: uuid(),
    };
    commit('addNotification', message);
    setTimeout(() => {
      commit('removeNotification', message.id);
    }, notification.duration || 4000);
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
