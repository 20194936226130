import { Gateway } from '@/setup/axios';

class NotificationsAPI {
  getNotifications = () => (
    Gateway.get('notifications')
  )

  updateNotificationStatus = (id, data) => (
    Gateway.patch(`notifications/${id}`, data)
  )
}

export default new NotificationsAPI();
