export const setZELang = (lang) => {
  if (window.zE) {
    window.zE('webWidget', 'setLocale', lang);
  }
};

export const loadZEWidget = (i18n) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  setTimeout(() => {
    const scriptTag = document.createElement('script');
    scriptTag.id = 'ze-snippet';
    scriptTag.src = 'https://static.zdassets.com/ekr/snippet.js?key=6eff5fd7-a7c1-41c8-8551-bdc780ebc441';
    scriptTag.async = true;
    document.body.appendChild(scriptTag);
    scriptTag.onload = () => {
      setZELang(i18n.locale);
    };
  }, 500);
};
