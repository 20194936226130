import * as enums from '@/constants/enums';
import {
  FAQ_URL, IS_DEMO, IS_PROD, IS_STAGE,
} from '@/constants';

const constantsPlugin = {
  install(Vue) {
    Vue.prototype.$enums = enums;
    Vue.prototype.$isProd = IS_PROD;
    Vue.prototype.$isStage = IS_STAGE;
    Vue.prototype.$isDemo = IS_DEMO;
    Vue.prototype.$faqUrl = FAQ_URL;
  },
};

export default constantsPlugin;
