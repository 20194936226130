import isEmpty from 'lodash.isempty';

import { formatDate, getRemainingTime } from '@/helpers/date';
import EntityApi from '@/api/entity.api';
import { deserializer, serializer } from '@/helpers/api';
import { getHijriWeekDate, splitStringIntoArray } from '@/helpers/general';
import ErrorHandler from '@/helpers/errors';
import LookupApi from '@/api/lookup.api';
import { MemberTypes, TimePart, UnitTypes } from '@/constants/enums';
import { getFullName, normalizeDelegateMembership } from '@/services/membership.service';
import { normalizeDisclosureForm } from '@/services/user-requests.service';
import RequestApi from '@/api/request.api';

export const parseArrayToString = (arr) => {
  if (!isEmpty(arr)) {
    return arr.join(',');
  }
  return '';
};

export const parseDocuments = (docObj) => {
  try {
    if (docObj) {
      return Object.freeze([JSON.parse(docObj)]);
    }
    return undefined;
  } catch (e) {
    console.warn('Invalid JSON');
    return undefined;
  }
};

export const parseAreaOfActivity = (areaOfActivity) => {
  if (!areaOfActivity?.length) return [];
  return areaOfActivity?.map((area) => ({
    regionCode: area?.regionCode,
    regionName: {
      arTitle: area.region.arTitle,
      enTitle: area.region.enTitle,
    },
    selectedCities: area?.cityUids || [],
    allCitiesSelected: !area?.cityUids?.length,
  }));
};

export const handleContributionSources = (sources) => {
  if (sources && sources.length) {
    const source = sources[0];
    return {
      amount: source?.amount,
      kind: source?.kind,
      title: source?.title,
    };
  }
  return null;
};

export const normalizeOwnerMembership = (data) => ({
  governmental: data?.governmental,
  disclosureForm: normalizeDisclosureForm(data?.disclosureForm),
  disclosureFormId: data?.disclosureForm?.id,
  role: data?.role || MemberTypes.Individual,
  id: data?.id,
  customPosition: data?.customPosition,
  nationalId: data?.member?.nationalId,
  positionUid: data?.positionUid,
  delegatorProof: normalizeDelegateMembership(data?.delegatorProof),
  foundingMember: data?.foundingMember,
  email: data?.email,
  status: data?.status,
  numberOfShares: data?.numberOfShares,
  familyRelationId: data?.familyRelation?.uid,
  isSpecialized: data?.specialized,
  position: data?.position,
  financeContributionAmount: data?.financeContributionAmount,
});

export const normalizeEntity = (entity = {}) => ({
  avatar: entity?.entityProfile?.logo || null,
  type: entity.type,
  becomeMember: entity.autocreateMembershipForOwner || null,
  parentType: entity.parentType || entity.type,
  id: entity.id,
  status: entity.status,
  areasOfActivity: parseAreaOfActivity(entity?.areasOfActivity),
  financialCommitment: entity.financialCommitment || false,
  createdAt: formatDate(entity.createdAt),
  createdWeekdayHijri: getHijriWeekDate(entity.updatedAt),
  updatedAt: formatDate(entity.updatedAt),
  acceptedAt: entity.acceptedAt ? formatDate(entity.acceptedAt) : null,
  updatedAtHijri: entity.updatedAtHijri,
  registrationDateHijri: entity.registrationDateHijri,
  supervisoryRemainingAt: Math.round(
    getRemainingTime(entity.supervisoryRemainingAt, TimePart.Hours) / 24,
  ),
  specializedRemainingAt: Math.round(
    getRemainingTime(entity.specializedRemainingAt, TimePart.Hours) / 24,
  ),
  entityEstablisherName: entity.entityEstablisherName || getFullName(entity?.owner),
  entityEstablisherNid: entity.entityEstablisherNid,
  entityId: entity.entityId,
  foundFor: entity.foundFor,
  nameArabic: entity.nameArabic,
  nameEnglish: entity.nameEnglish,
  establishmentApprovalProofs: entity.establishmentApprovalProofs,
  copiedFrom: entity.copiedFrom,
  parentNameArabic: entity.parentNameArabic,
  parentNameEnglish: entity.parentNameEnglish,
  // typeOfBenefits: entity.typeOfBenefits,
  classification: entity.classification,
  firstClassification: entity.firstClassification,
  secondClassification: entity.secondSubClassificationId,
  membershipTypeAssociation: entity.membershipType,
  activities: splitStringIntoArray(entity.activityIds),
  goals: splitStringIntoArray(entity.goalIds),
  membershipTypes: splitStringIntoArray(entity.membershipTypes),
  ceoEducationalLevelCondition: entity.ceoEducationalLevelCondition,
  workerMembershipFee: entity.workerMembershipFee || entity.membershipFee,
  conditionsWorker: entity.workerConditions,
  membershipDuties: entity.membershipDuties,
  membershipRights: entity.membershipRights,
  associateMembershipFee: entity.associateMembershipFee,
  ceoDegreesCondition: entity.ceoDegreesCondition,
  ceoPreviousExperienceCondition: entity.ceoPreviousExperienceCondition,
  ceoCustomCondition: entity.ceoCustomCondition,
  capital: entity.capital,
  numberOfShares: entity.numberOfShares,
  minNumOfSharesPerMember: entity.minNumOfSharesPerMember,
  bank: entity.bank,
  bankId: entity.bank?.id,
  bankAccount: entity.bankAccount,
  department: entity.department,
  description: entity.description,
  bankBranchName: entity.bankBranchName,
  region: entity.regionCode,
  regionData: entity.region,
  cityData: entity.city,
  city: entity.cityUid,
  ownerId: Number(entity.owner?.id),
  entityUid: entity.entityUid,
  entityLicenseNumber: entity?.entityLicenseNumber,
  step: entity?.progressStep?.step || 0,
  numberOfBoardMembers: entity.numberOfBoardMembers,
  shareValue: entity.shareValue || null,
  membershipRequirements: parseArrayToString(entity.membershipRequirements),
  tempBankAccount: entity.tempBankAccount || {},
  ownerMembership: normalizeOwnerMembership(),
  contributionSources: handleContributionSources(entity.contributionSources),
  eligibilityDocument: entity?.eligibilityStudyFiles,
  bankCertificate: entity?.bankCertificates,
  isNama: entity?.isNama,
  membersCount: entity.membersCount || 0,
  unifiedNumber700: entity?.unifiedNumber700 || null,
  supportingDocuments: entity.supportingDocuments,
  entityLicenses: entity.entityLicenses || null,
  bankCertificates: entity.bankCertificates || null,
  eligibilityStudyFiles: entity.eligibilityStudyFiles || null,
  entityPolicies: entity.entityPolicies || null,
  establishmentDecisions: entity.establishmentDecisions || null,
  migrationDelegateLetters: entity.migrationDelegateLetters || null,
  boardFirstTermLength: entity.boardFirstTermLength || null,
  boardLaterTermsLength: entity.boardLaterTermsLength || null,
  families: entity.families || null,
  isDocsExposed: entity.isDocsExposed || null,
});

export const normalizeBranch = (branch = {}) => ({
  id: branch.id,
  entityId: branch.entityId,
  parentType: branch.type,
  goals: splitStringIntoArray(branch.goalIds) || [],
  bankCertificate: parseDocuments(branch.bankCertificate),
  nameArabic: branch.nameArabic,
  nameEnglish: branch.nameEnglish,
  bank: branch.bankIds,
  region: branch.regionCode,
  city: branch.cityUid,
  approvalType: branch.approvalType,
  acknowledgmentAgreed: branch.acknowledgmentAgreed || false,
  step: branch.step || 0,
});

export const getEntityClassifications = async (ids, config = {}) => {
  if (!ids) return [];
  try {
    const res = await LookupApi.getClassificationsDetails(ids, config);
    const data = (await deserializer(res.data)) || [];
    return data.map((item) => ({
      id: item.id,
      secondClassification: item.secondSubClassificationArTitle,
      firstClassification: item.firstSubClassificationArTitle,
      classification: item.mainClassificationArTitle,
      department: item.departmentArTitle,
      description: item.secondSubClassificationArDescription,
    }));
  } catch (e) {
    console.warn(e);
    return [];
  }
};

export const findEntity = async (entityId) => {
  try {
    const res = await EntityApi.findEntity(entityId);
    const data = res.data?.findEntityById;
    return {
      ...normalizeEntity(data),
      familyRelation: res.data.findOwnerAsAMemberUserRequestByEntityId?.familyRelation,
    };
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const createCopyEntity = async (unitId) => {
  try {
    const { data } = await EntityApi.createCopyEntity(
      serializer({ unitId: Number(unitId) }, 'entity'),
    );
    return data?.unit_id;
  } catch (e) {
    return {
      error: ErrorHandler.parseGlobalErrors(e),
    };
  }
};

export const checkEntityLicense = async (licenseNumber) => {
  try {
    const { data } = await EntityApi.checkEntityLicenseNumber(licenseNumber);
    return data?.unit_id;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const getTotalContributionEntity = async (entityId) => {
  try {
    const { data } = await EntityApi.getTotalContribution(entityId);
    return data?.totalContributions?.totalContributions;
  } catch (e) {
    console.warn(e);
    return {
      error: e,
    };
  }
};

export const fetchRequestTracker = async (id) => {
  try {
    const res = await RequestApi.fetchRequestTracker(id, UnitTypes.Entity);
    return res?.data?.findRequestTracker;
  } catch (e) {
    console.warn(e);
    return ErrorHandler.parseFormErrors(e);
  }
};

export const fetchSevenHundredErrors = async (unitId) => {
  try {
    const res = await RequestApi.getSevenHundredErrorsRequests(unitId);
    const list = res.data.listUnifiedNumberErrors.errors || [];
    return list;
  } catch (e) {
    console.warn(e);
    return ErrorHandler.parseFormErrors(e);
  }
};

export const listContributionSources = async (entityId) => {
  try {
    const res = await EntityApi.listContributionSources(entityId);
    return res?.data?.listContributionSources?.contributionsSources;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const createOrUpdateContributionSource = async (data) => {
  try {
    const res = await EntityApi.createOrUpdateContributionSource(data);
    return res?.data?.createOrUpdateContributionSource;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const deleteContributionSource = async (id) => {
  try {
    const res = await EntityApi.deleteContributionSource(id);
    return res?.data?.deleteContributionSource;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const findTempBankAccountByEntityId = async (entityId) => {
  try {
    const res = await EntityApi.findTempBankAccountByEntityId(entityId);
    return res?.data?.findTempBankAccountByEntityId;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};
