const findInput = (c) => [...c].reduce((res, item) => {
  if (!item) return res;
  const element = item.localName !== 'input' ? [...findInput(item.childNodes)] : [item];
  return [...res, ...element];
}, []);

export default {
  inserted(el) {
    if (el.localName === 'input') {
      el.focus();
    } else {
      const input = findInput(el.childNodes);
      if (input[0]) {
        input[0].focus();
      }
    }
  },
};
