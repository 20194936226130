import { getField, updateField } from 'vuex-map-fields';
import { fetchMember } from '@/services/membership.service';
import UserRequestsApi from '@/api/user-requests.api';
import { normalizeUserRequest, prepareDisclosureForm } from '@/services/user-requests.service';
import ErrorHandler from '@/helpers/errors';

const mainState = {
  familyInvitationRequest: normalizeUserRequest(),
};

const mainGetters = {
  getField,
  familyInvitationRequest: (store) => store.familyInvitationRequest,
};

const mutations = {
  updateField,
  setFamilyInvitationRequest: (store, request) => {
    store.familyInvitationRequest = normalizeUserRequest(request);
  },
  setDisclosureForm: (store, list) => {
    store.familyInvitationRequest.disclosureForm = list;
  },
};

const actions = {
  fetchFamilyInvitationRequest: async ({ commit }, id) => {
    const data = await fetchMember(id);
    if (!data?.error) {
      commit('setFamilyInvitationRequest', data);
    }
    return data;
  },
  submitFamilyInvitationRequest: async ({ dispatch }, { disclosureData, decisionData }) => {
    if (decisionData.decision) {
      const disclosureRes = await dispatch('createOrUpdateDisclosureForm', disclosureData);
      if (disclosureRes?.error) {
        return disclosureRes;
      }
    }
    return dispatch('acceptOrRejectFamilyInvitationRequest', decisionData);
  },
  acceptOrRejectFamilyInvitationRequest: async ({ getters }, { decision, rejectReason }) => {
    try {
      const { acceptUserRequest: accept, rejectUserRequest: reject } = UserRequestsApi;
      const res = decision
        ? await accept(getters.familyInvitationRequest?.id)
        : await reject(getters.familyInvitationRequest?.id, rejectReason);
      return res.data;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  createOrUpdateDisclosureForm: async ({ commit, getters }, disclosureData) => {
    try {
      commit('setDisclosureForm', disclosureData);
      const { updateDisclosureFormGql: update, createDisclosureFormGql: create } = UserRequestsApi;
      const { disclosureFormId, id } = getters.familyInvitationRequest;
      const method = disclosureFormId ? update : create;
      const res = await method({
        ...prepareDisclosureForm(disclosureData),
        id: disclosureFormId || id,
      });
      if (!disclosureFormId && res.data?.createDisclosureForm?.id) {
        commit('updateField', {
          path: 'familyInvitationRequest.disclosureFormId',
          value: res.data.createDisclosureForm.id,
        });
      }
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
