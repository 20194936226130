import { getField, updateField } from 'vuex-map-fields';
import DelegateManagementApi from '@/api/delegate-management.api';
import {
  DelegateRoles, MemberRole, MembershipRequestStatus, MembershipRequestUnitTypes,
} from '@/constants/enums';
import ErrorHandler from '@/helpers/errors';
import { findEntity } from '@/services/entity.service';
import { handleSummaryRequestList } from '@/services/user-requests.service';

const state = {
  form: {
    nationalId: '',
    mobileNumber: '',
    email: '',
    birthDateHijri: {
      day: '',
      month: '',
      year: '',
    },
  },
  entity: {},
  userData: null,
  currentDelegate: null,
  potentialDelegate: null,
  delegateType: '',
  selectedMember: null,
  membersLoading: false,
  isDataLoading: false,
  isChairman: false,
  isPendingRequests: false,
  errors: null,
};

const getters = {
  getField,
  delegateType: (store) => store.delegateType,
  userData: (store) => store.userData,
  errors: (store) => store.errors,
  isChairman: (store) => store.isChairman,
  isNewDelegate: (store) => store.delegateType === DelegateRoles.NewMember,
  isExistingMember: (store) => store.delegateType === DelegateRoles.ExistingMember,
  isPreviousDelegate: (store) => store.delegateType === DelegateRoles.PreviousDelegate,
  isActiveDOB: (store) => String(store.form.nationalId || '').startsWith('1'),
  membersLoading: (store) => store.membersLoading,
  getEntity: (store) => store.entity,
  getPotentialDelegate: (store) => store.potentialDelegate,
  getCurrentDelegate: (store) => store.currentDelegate,
  getIsPendingRequests: (store) => store.isPendingRequests,
  getSelectedMember: (store) => store.selectedMember,
};

const mutations = {
  updateField,
  resetDOB: (store) => {
    store.form.birthDateHijri = { day: '', month: '', year: '' };
  },
  resetForm: (store) => {
    store.selectedMember = null;
    store.userData = null;
    store.form = {
      nationalId: '',
      mobileNumber: '',
      email: '',
      birthDateHijri: {
        day: '',
        month: '',
        year: '',
      },
    };
  },
  resetUserData: (store) => {
    store.userData = null;
  },
  setUserData: (store, data) => {
    store.userData = data;
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setEntity: (store, data) => {
    store.entity = data;
  },
  setIsChairman: (store, data) => {
    store.isChairman = data;
  },
  setPotentialDelegate: (store, data) => {
    store.potentialDelegate = data;
  },
  setCurrentDelegate: (store, data) => {
    store.currentDelegate = data;
  },
  setIsPrendingRequests: (store, data) => {
    store.isPendingRequests = data;
  },
  resetDelegatesData: (store) => {
    store.isPendingRequests = false;
    store.currentDelegate = null;
    store.potentialDelegate = null;
  },
};

const actions = {
  checkThatIsChairman: async ({ commit, dispatch, rootGetters }, id) => {
    const list = await dispatch('fetchEntityMembers', { id });
    const result = list.some((m) => m.membershipPosition === 'chairman_board'
      && Number(m?.memberId) === Number(rootGetters['user/userId']));
    commit('setIsChairman', result);
  },
  fetchEntity: async ({ commit }, entityId) => {
    const entity = await findEntity(entityId);
    if (!entity?.error) {
      commit('setEntity', entity);
    }
    return entity;
  },
  fetchDelegateDetails: async (_, id) => {
    try {
      const res = await DelegateManagementApi.getDelegateDetails(id);
      const data = res?.data?.findMembershipRequest;
      return {
        ...data,
        ownerStartDate: data?.memberMembership?.ownerStartDate,
        ownerEndDate: data?.memberMembership?.ownerEndDate,
      };
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchMembershipDetails: async (_, id) => {
    try {
      const res = await DelegateManagementApi.getMembershipDetails(id);
      return res?.data?.findMembership;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchEntityMembers: async (_, { id, role }) => {
    try {
      const res = await DelegateManagementApi.listEntityMembers(
        Number(id),
        1,
        100,
        role,
      );
      const entityMembers = res?.data?.memberships?.memberships || [];
      return handleSummaryRequestList(entityMembers);
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchPreviousDelegates: async (_, { id, page = 1, size = 100 }) => {
    try {
      const res = await DelegateManagementApi.listEntityMembers(
        Number(id),
        page,
        size,
        [MemberRole.Owner],
        false,
      );
      const entityMembers = res?.data?.memberships?.memberships || [];
      return { list: handleSummaryRequestList(entityMembers), meta: res?.data?.memberships?.meta };
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchPreviousDelegatesList: async ({ dispatch, commit }, {
    id,
    page,
    size,
  }) => {
    const { list, meta } = await dispatch('fetchPreviousDelegates', { id, page, size });
    if (!list.error) {
      commit('delegateManagementPagination/setTotalPages', meta?.pageCount, { root: true });
      commit('delegateManagementPagination/setTotalRecords', meta?.recordsCount, { root: true });
    }
    return list;
  },
  fetchPotentialDelegate: async ({ commit }, { id, page = 1, size = 1 }) => {
    try {
      const res = await DelegateManagementApi.getPotentialDelegate(
        Number(id),
        page,
        size,
        [MembershipRequestStatus.Pending],
        MembershipRequestUnitTypes.Entity,
      );
      const potentialDelegates = res?.data?.listMembershipRequests?.membershipRequests || [];
      if (potentialDelegates.length) {
        commit('setPotentialDelegate', potentialDelegates[0]);
      }
      return { list: handleSummaryRequestList(potentialDelegates) };
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchCurrentDelegate: async ({ commit }, id) => {
    try {
      const res = await DelegateManagementApi.getCurrentDelegate(
        Number(id),
        MemberRole.Owner,
        true,
      );
      const currentDelegate = res?.data?.memberships?.memberships;
      if (currentDelegate.length) {
        commit('setCurrentDelegate', currentDelegate[0]);
      }
      return currentDelegate;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  createMembershipRequest: async (_, {
    unitId, memberMode, nationalId, birthDateHijri, email, absherPhone,
  }) => {
    try {
      const res = await DelegateManagementApi.createMembershipRequest(
        Number(unitId),
        MembershipRequestUnitTypes.Entity,
        memberMode,
        nationalId,
        birthDateHijri,
        email,
        absherPhone,
      );
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchEntityOwnerRequests: async ({ commit }, { unitId, page, size }) => {
    try {
      const res = await DelegateManagementApi.listEntityRequests({
        id: Number(unitId),
        page,
        size,
        unitType: MembershipRequestUnitTypes.Entity,
        asChairman: true,
      });
      const ownerRequests = res?.data?.listMembershipRequests?.membershipRequests || [];
      const pendingRequest = ownerRequests.find(
        (request) => request.status === MembershipRequestStatus.Pending,
      );
      commit('setIsPrendingRequests', !!pendingRequest);
      return {
        list: handleSummaryRequestList(ownerRequests),
        meta: res?.data?.listMembershipRequests?.meta,
      };
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchEntityOwnerRequestsList: async ({ dispatch, commit }, {
    unitId,
    page,
    size,
  }) => {
    const { list, meta } = await dispatch('fetchEntityOwnerRequests', { unitId, page, size });
    if (!list.error) {
      commit('delegateManagementPagination/setTotalPages', meta?.pageCount, { root: true });
      commit('delegateManagementPagination/setTotalRecords', meta?.recordsCount, { root: true });
    }
    return list;
  },
  fetchEntityInvitations: async (_, { unitId, page, size }) => {
    try {
      const res = await DelegateManagementApi.listEntityRequests({
        id: Number(unitId),
        page,
        size,
        unitType: MembershipRequestUnitTypes.Entity,
        asChairman: false,
        status: [MembershipRequestStatus.Pending],
      });
      const invitationRequests = res?.data?.listMembershipRequests?.membershipRequests || [];
      return {
        list: handleSummaryRequestList(invitationRequests),
        meta: res?.data?.listMembershipRequests?.meta,
      };
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchEntityInvitationsRequestsList: async ({ dispatch, commit }, {
    unitId,
    page,
    size,
  }) => {
    const { list, meta } = await dispatch('fetchEntityInvitations', { unitId, page, size });
    if (!list.error) {
      commit('delegateManagementPagination/setTotalPages', meta?.pageCount, { root: true });
      commit('delegateManagementPagination/setTotalRecords', meta?.recordsCount, { root: true });
    }
    return list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
