export const GET_USER_REQUESTS = `query GetUserRequests($page: Int!, $size: Int!, $unitId: Int, $unitType: UnitType, $status: [UserRequestStatus!]) {
  userRequests(page: $page, size: $size, unitId: $unitId, unitType: $unitType, status: $status) {
    userRequests {
      absherPhone
      createdAt
      id
      status
      unitId
      unitType
      memberId
      memberType
      customPosition
      email
      memberMode
      specialized
      foundingMember
      governmental
      financeContributionAmount
      numberOfShares
      familyRelation {
        uid
        arTitle
        enTitle
      }
      membershipId
      positionUid
      role
      attachments {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      delegatorProof {
        id
        userRequestId
        entityName
        nonGovernmentalProof {
          commercialRegistrationNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          facilityRecordNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          gosiNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          nonProfitOrganizationLicenseProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          zakatCertificateNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          endowmentDeedNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          delegatorProofId
          facilityRecordNumber
          gosiNumber
          proofType
          proofValue
          zakatCertificateNumber
        }
      }
      unit {
        ... on Entity {
          id
          nameArabic
          nameEnglish
          status
          entityMultiStatus {
            membersReplacementType
            secondApprovalType
            supervisoryChanges
          }
          owner {
            grandfatherName
            fatherName
            firstName
            lastName
          }
          type
        }
        ... on Branch {
          id
          nameArabic
          nameEnglish
          status
          type
          entity {
            owner {
              grandfatherName
              fatherName
              firstName
              lastName
            }
          }
        }
      }
      member {
        account {
          title
        }
        absherPhone
        birthDateHijri
        birthplace
        createdAt
        degrees
        educationSpecialization
        educationalLevel
        email
        employmentPlace
        fatherName
        firstName
        gender
        grandfatherName
        id
        lastName
        nationalId
        nidExpireDate
        occupation
        previousExperience
        updatedAt
        nationalAddress {
          additionalNo
          buildingNo
          cityAr
          cityEn
          createdAt
          districtAreaAr
          districtAreaEn
          id
          latitude
          longitude
          streetNameAr
          streetNameEn
          unitNo
          updatedAt
          zipCode
        }
        nationality {
          uid
          arTitle
          enTitle
        }
      }
      position {
        arTitle
        enTitle
        uid
      }
      rejectReason {
        createdAt
        id
        message
        updatedAt
        wrongFields
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const GET_USER_REQUEST_INFO = `query findUserRequest($id: Int!) {
  findUserRequest(id: $id) {
    customPosition
    delegatorProof {
      entityName
      id
      userRequestId
      nonGovernmentalProof {
        commercialRegistrationNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        facilityRecordNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        gosiNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        nonProfitOrganizationLicenseProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        zakatCertificateNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        endowmentDeedNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        createdAt
        delegatorProofId
        facilityRecordNumber
        gosiNumber
        id
        proofType
        proofValue
        updatedAt
        zakatCertificateNumber
      }
    }
    disclosureForm {
      acrm
      crmi
      crmo
      fffl
      id
      lknw
      naff
      nmbd
      oman
      resi
      rman
      userRequestId
    }
    financeContributionAmount
    foundingMember
    governmental
    id
    member {
      absherPhone
      birthDateHijri
      birthplace
      createdAt
      degrees
      deletedAt
      educationSpecialization
      educationalLevel
      email
      employmentPlace
      fatherName
      firstName
      gender
      grandfatherName
      id
      lastName
      nationalAddress {
        additionalNo
        buildingNo
        cityAr
        cityEn
        createdAt
        districtAreaAr
        districtAreaEn
        id
        latitude
        longitude
        streetNameAr
        streetNameEn
        unitNo
        updatedAt
        zipCode
      }
      nationalId
      nationality {
        arTitle
        enTitle
        uid
      }
      nidExpireDate
      occupation
      previousExperience
      updatedAt
    }
    memberId
    memberType
    numberOfShares
    position {
      arTitle
      enTitle
      uid
    }
    positionUid
    role
    specialized
    status
    unitId
    unitType
    type
  }
}`;

export const CREATE_DELEGATE_PROOF = `mutation createDelegatorProof($delegatorProofCreateAttributes: DelegatorProofCreateInput!) {
  createDelegatorProof(delegatorProofCreateAttributes: $delegatorProofCreateAttributes) {
    entityName
    id
    organizationName
    organizationNumber
    nonGovernmentalProof {
      associationMinutesProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      commercialRegistrationNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      facilityRecordNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      gosiNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      nonProfitOrganizationLicenseProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      zakatCertificateNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      endowmentDeedNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      createdAt
      delegatorProofId
      facilityRecordNumber
      gosiNumber
      id
      proofType
      proofValue
      updatedAt
      zakatCertificateNumber
    }
    userRequestId
  }
}`;

export const CREATE_DISCLOSURE_FORM = `mutation createDisclosureForm(
  $userRequestId: Int
  $employeeRequestId: Int
  $acrm: Boolean!
  $crmo: Boolean!
  $crmi: Boolean!
  $resi: Boolean!
  $oman: Boolean!
  $rman: Boolean!
  $lknw: Boolean!
  $naff: Boolean!
  $fffl: Boolean!
  $nmbd: Boolean!
) {
  createDisclosureForm (
      userRequestId: $userRequestId
      employeeRequestId: $employeeRequestId
      acrm: $acrm
      crmo: $crmo
      crmi: $crmi
      resi: $resi
      oman: $oman
      rman: $rman
      lknw: $lknw
      naff: $naff
      fffl: $fffl
      nmbd: $nmbd
  ) {
    userRequestId
    employeeRequestId
    acrm
    crmo
    crmi
    resi
    oman
    rman
    lknw
    naff
    fffl
    nmbd
    id
  }
}`;

export const UPDATE_DISCLOSURE_FORM = `mutation updateDisclosureForm(
  $id: Int!
  $acrm: Boolean!
  $crmo: Boolean!
  $crmi: Boolean!
  $resi: Boolean!
  $oman: Boolean!
  $rman: Boolean!
  $lknw: Boolean!
  $naff: Boolean!
  $fffl: Boolean!
  $nmbd: Boolean!
) {
  updateDisclosureForm (
    id: $id
    acrm: $acrm
    crmo: $crmo
    crmi: $crmi
    resi: $resi
    oman: $oman
    rman: $rman
    lknw: $lknw
    naff: $naff
    fffl: $fffl
    nmbd: $nmbd
  ) {
    id
    acrm
    crmo
    crmi
    resi
    oman
    rman
    lknw
    naff
    fffl
    nmbd
  }
}`;

export const UPDATE_DELEGATE_PROOF = `mutation updateDelegatorProof($delegatorProofUpdateAttributes: DelegatorProofUpdateInput!) {
  updateDelegatorProof(delegatorProofUpdateAttributes: $delegatorProofUpdateAttributes) {
    entityName
    id
    organizationName
    organizationNumber
    nonGovernmentalProof {
      associationMinutesProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      commercialRegistrationNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      facilityRecordNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      gosiNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      nonProfitOrganizationLicenseProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      zakatCertificateNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      endowmentDeedNumberProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      createdAt
      delegatorProofId
      facilityRecordNumber
      gosiNumber
      id
      proofType
      proofValue
      updatedAt
      zakatCertificateNumber
    }
    userRequestId
  }
}`;

export const GET_OWNER_AS_MEMBER = `query findOwnerAsAMemberUserRequestByEntityId($entityId: Int!) {
  findOwnerAsAMemberUserRequestByEntityId(entityId: $entityId) {
    createdAt
    customPosition
    financeContributionAmount
    governmental
    memberId
    familyRelation {
      uid
    }
    disclosureForm {
      acrm
      crmi
      crmo
      fffl
      id
      lknw
      naff
      nmbd
      oman
      resi
      rman
      userRequestId
    }
    memberType
    numberOfShares
    positionUid
    role
    specialized
    unitId
    unitType
    id
  }
}`;

export const CREATE_USER_REQUEST = `mutation CreateUserRequest($userRequestCreateAttributes: UserRequestCreateInput!) {
  createUserRequest(userRequestCreateAttributes: $userRequestCreateAttributes) {
    id
  }
}`;

export const UPDATE_USER_REQUEST = `mutation UpdateUserRequest(
  $userRequestUpdateAttributes: UserRequestUpdateInput!,
) {
  updateUserRequest(userRequestUpdateAttributes: $userRequestUpdateAttributes) {
    id
  }
}`;

export const DELETE_USER_REQUEST = `mutation DeleteUserRequest($id: Int!) {
  deleteUserRequest(id: $id) {
    id
  }
}`;

export const ACCEPT_USER_REQUEST = `mutation acceptByMemberUserRequest (
  $userRequestAcceptAttributes: UserRequestsAcceptByMemberInput!,
) {
  acceptByMemberUserRequest(userRequestAcceptAttributes: $userRequestAcceptAttributes) {
    id
  }
}`;

export const REJECT_USER_REQUEST = `mutation rejectRequest($userRequestRejectByMemberAttributes: UserRequestRejectByMemberInput!) {
  rejectByMemberUserRequest(userRequestRejectByMemberAttributes: $userRequestRejectByMemberAttributes) {
    id
    status
  }
}`;

export const CANCEL_USER_REQUEST = `mutation cancelUserRequest($id: Int!) {
  cancelUserRequest(id: $id) {
    id
  }
}`;

export const MEMBERS_COUNT = `query membersCount($units: [MembersCountInput!]!) {
  membersCount(units: $units) {
    numberOfUnitMembers {
      unitId,
      unitType,
      membersCount
    }
  }
}`;

export const FIND_DELEGATE_PROOF_LETTERS = `query findUserRequest($id: Int!) {
  findUserRequest(id: $id) {
    delegatorProof {
      delegatorProofLetters {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
    }
  }
}`;
