import PendingLayout from '@/views/pending/PendingLayout.vue';

export default {
  path: '',
  component: PendingLayout,
  children: [
    {
      path: 'invites',
      name: 'Invites',
      component: () => import(
        /* webpackChunkName: "invites" */
        '../views/pending/invites/Invites.vue'
      ),
      children: [
        {
          path: ':token',
          name: 'Token',
        },
      ],
    },
    {
      path: 'employee-confirmation',
      name: 'EmployeeConfirmation',
      component: () => import(
        /* webpackChunkName: "employee-confirmation" */
        '../views/pending/employee-confirmation/EmployeeConfirmation.vue'
      ),
    },
  ],
};
