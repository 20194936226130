export default {
  mb_xs: 0,
  mb: 576,
  tb: 768,
  lp: 1026,
  dp: 1366,
  mobile_xs: (screen) => screen.mb_xs && !screen.mb,
  'mobile-': (screen) => (screen.mb || screen.mb_xs) && !screen.tb,
  mobile: (screen) => screen.mb && !screen.tb,
  'tablet-': (screen) => (screen.tb || screen.mb || screen.mb_xs) && !screen.lp,
  tablet: (screen) => screen.tb && !screen.lp,
  'tablet+': (screen) => screen.tb,
  'laptop-': (screen) => (screen.lp || screen.tb || screen.mb || screen.mb_xs) && !screen.dp,
  laptop: (screen) => screen.lp && !screen.dp,
  'laptop+': (screen) => screen.lp,
  desktop: (screen) => screen.dp,
};
