export default {
  bind(el, binding, vNode) {
    el.clickHandler = (e) => {
      if (!(el.contains(e.target) || e.target === el) && binding.expression) {
        vNode.context[binding.expression](e);
      }
    };
    document.addEventListener('click', el.clickHandler);
  },
  unbind(el) {
    document.removeEventListener('click', el.clickHandler);
  },
};
