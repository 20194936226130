export const CREATE_UNIFIED_NUMBER_ADMIN_REQUEST = `mutation CreateUnifiedNumberAdminRequest($unifiedNumberAdminRequestCreateAttributes: CreateAdminRequestInput!) {
  createUnifiedNumberAdminRequest(unifiedNumberAdminRequestCreateAttributes: $unifiedNumberAdminRequestCreateAttributes) { 
      id
      unifiedNumbers
  }
}`;

export const FETCh_UNIFIED_NUMBER_ADMIN_REQUEST = `query ListUnifiedNumberAdminRequest($page: Int!, $size: Int!, $status: String, $entityId: Int, $type: String, $city: String, $name: String) {
    listUnifiedNumberAdminRequest(page: $page, size: $size, status: $status, entityId: $entityId, type: $type, city: $city, name: $name) {
           adminRequests { 
            id
            unifiedNumbers
            status
            entityId
            createdAt
            }
            meta {
             page
             pageCount
             recordsCount
             size
            }
       }
}`;

export const DELETE_UNIFIED_NUMBER_ADMIN_REQUEST = `mutation UpdateUnifiedNumberAdminRequest($unifiedNumberAdminRequestUpdateAttributes:UpdateAdminRequestInput!) {
    updateUnifiedNumberAdminRequest(unifiedNumberAdminRequestUpdateAttributes: $unifiedNumberAdminRequestUpdateAttributes){     
        id
        unifiedNumbers
    }
}`;

export const CREATE_UNIFIED_NUMBER_ATTACHMENT = `mutation CreateUnifiedNumberAttachment ($unifiedNumberAttachmentCreateAttributes:CreateAttachmentsInput!){
    createUnifiedNumberAttachment(unifiedNumberAttachmentCreateAttributes:$unifiedNumberAttachmentCreateAttributes) {
        unifiedNumber
        fileMetadataUid
        docType
        adminRequestId
    }
}`;

export const FETCh_UNIFIED_NUMBER_ADMIN_REQUEST_BY_ID = ` query FindUnifiedNumberAdminRequest($id: Int!) {
    findUnifiedNumberAdminRequest(id: $id) {
        chairmanBoardBirthday
        chairmanBoardEmail
        chairmanBoardFatherName
        chairmanBoardFirstName
        chairmanBoardGrandfatherName
        chairmanBoardLastName
        chairmanBoardNationalId
        chairmanBoardPhoneNumber
        createdAt
        entityEmail
        entityId
        entityPhoneNumber
        entityTelephoneNumber
        id
        licenseExpiredAt
        licenseInitiatedAt
        ownerBirthday
        ownerEmail
        ownerFatherName
        ownerFirstName
        ownerGrandfatherName
        ownerLastName
        ownerNationalId
        ownerPhoneNumber
        status
        unifiedNumbers
        updatedAt
        nameAr
        nameEn
    }
}`;

export const FETCH_UNIFIED_NUMBER_ATTACHMENTS = `query ListUnifiedNumberAttachments ($page:Int!,$size:Int!,$adminRequestId:Int!){
    listUnifiedNumberAttachments(page:$page,size:$size,adminRequestId:$adminRequestId){
        attachments {
            unifiedNumber
            adminRequestId
            fileMetadataUid
            docType
            attachments {
              filename
              path
              uid
            }
        }
    }
}`;

export const UPDATE_UNIFIED_NUMBER_ADMIN_REQUEST = `mutation UpdateUnifiedNumberAdminRequest($unifiedNumberAdminRequestUpdateAttributes: UpdateAdminRequestInput!) {
    updateUnifiedNumberAdminRequest(unifiedNumberAdminRequestUpdateAttributes: $unifiedNumberAdminRequestUpdateAttributes) { 
    id
    entityPhoneNumber
    entityTelephoneNumber
    entityEmail
    chairmanBoardFirstName
    chairmanBoardFatherName
    chairmanBoardGrandfatherName
    chairmanBoardLastName
    chairmanBoardNationalId
    chairmanBoardBirthday
    chairmanBoardPhoneNumber
    chairmanBoardEmail
    licenseExpiredAt
    licenseInitiatedAt
    unifiedNumbers
    }
}`;

export const SUBMIT_UNIFIED_NUMBER_ADMIN_REQUEST = `mutation SubmitUnifiedNumberAdminRequest($unifiedNumberAdminRequestSubmitAttributes: SubmitAdminRequestInput!) {
    submitUnifiedNumberAdminRequest(unifiedNumberAdminRequestSubmitAttributes: $unifiedNumberAdminRequestSubmitAttributes) { 
    id
    ownerFirstName
    ownerFatherName
    ownerGrandfatherName
    ownerLastName
    ownerNationalId
    ownerBirthday
    ownerPhoneNumber
    ownerEmail
    }
}`;
