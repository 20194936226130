import { Gateway } from '@/setup/axios';

class MediaApi {
  optionalParams = (params) => {
    const userId = params.userId ? `&user_id=${params.userId}` : '';
    const attachableId = params.attachableId ? `&attachable_id=${params.attachableId}` : '';
    const attachableType = params.attachableType ? `&attachable_type=${params.attachableType}` : '';
    const docType = params.docType ? `&doc_type=${params.docType}` : '';

    return `${userId}${attachableId}${attachableType}${docType}`;
  };

  fetchDocumentsList = ({
    userId, attachableId, attachableType, docType, page = 1, size = 10,
  }, config = {}) => Gateway.get(
    `media/search?page=${page}&size=${size}${this.optionalParams({
      userId,
      attachableId,
      attachableType,
      docType,
    })}`, config,
  )

  uploadMedia = (file) => {
    const formData = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('file', file);
    return Gateway.post('media/v2/upload', formData, config);
  }

  uploadDocuments = (file, onUploadProgress, attachableId, attachableType, docType) => {
    const formData = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    };
    formData.append('file', file);
    if (attachableId) formData.append('attachable_id', attachableId);
    if (attachableType) formData.append('attachable_type', attachableType);
    if (docType) formData.append('doc_type', docType);

    return Gateway.post('media/upload-document', formData, config);
  }

  fetchDocument = ({
    attachableId, attachableType, page = 1, size = 10,
  }) => Gateway.get(`media/search?attachable_id=${attachableId}&attachable_type=${attachableType}&page=${page}&size=${size}`)

  deleteFile = (fileId) => Gateway.delete(`/media/${fileId}`);
}

export default new MediaApi();
