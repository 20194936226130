import { OFFICES_LIST, SUPERVISORY_LIST } from '@/constants';
import AboutLayout from '@/views/about/AboutLayout.vue';

export default {
  path: '',
  name: 'AboutLayout',
  component: AboutLayout,
  children: [
    {
      path: 'offices',
      name: 'Offices',
      props: { offices: OFFICES_LIST },
      component: () => import(
        /* webpackChunkName: "offices" */
        '../views/about/offices/Offices.vue'
      ),
      children: [
        {
          path: ':slug',
          name: 'Office',
        },
      ],
    },
    {
      path: 'supervisors',
      name: 'Supervisors',
      props: { supervisors: SUPERVISORY_LIST },
      component: () => import(
        /* webpackChunkName: "supervisors" */
        '../views/about/supervisors/Supervisors.vue'
      ),
      children: [
        {
          path: ':slug',
          name: 'Supervisory',
        },
      ],
    },
  ],
};
