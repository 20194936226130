import {
  RequestTabs,
  UserRequestStatuses,
  RequestStatuses,
  EntityTypes,
} from '@/constants/enums';
import UserApi from '@/api/user.api';
import ErrorHandler from '@/helpers/errors';
// eslint-disable-next-line import/named
import { handleSummaryRequestList } from '@/services/user-requests.service';
import MembershipApi from '@/api/membership.api';

const mainState = {
  summaryMembers: [],
  userRequests: [],
  userBranchRequests: [],
  userEntityRequests: [],
  userRequestsCount: 0,
  entityPageCount: 0,
  branchPageCount: 0,
  loading: false,
  summaryLoading: false,
  loaded: false,
  resendLoading: false,
  userRequestsLoading: false,
  loadingDisclosure: false,
  userRequestType: RequestTabs.Entity,
  isBranchInSummary: false,
  errors: [],
};

const mainGetters = {
  getErrors: (store) => store.errors,
  userRequests: (store) => store.userRequests,
  isLoading: (store) => store.loading,
  isResendLoading: (store) => store.resendLoading,
  isSummaryLoading: (store) => store.summaryLoading,
  isBranchInSummary: (store) => store.isBranchInSummary,
  userRequestType: (store) => store.userRequestType,
  summaryMembers: (store) => store.summaryMembers,
  parentSummaryType: (store) => store.summaryMembers[0]?.entityType,
  countAcceptedMembers(store) {
    return store.summaryMembers.filter(
      (m) => [
        UserRequestStatuses.AcceptedByUser,
        UserRequestStatuses.AcceptedBySpecialized,
      ]
        .includes(m.status),
    ).length;
  },
  countMembers(store) {
    return store.summaryMembers.length;
  },
  checkReplacementPhase(store) {
    return store.summaryMembers.some(
      (m) => m.entityStatus === RequestStatuses.MemberAdjustments
        || (m?.entityType !== EntityTypes.FamilyFund && ![
          RequestStatuses.Rejected,
          RequestStatuses.Accepted,
        ].includes(m.entityStatus)
          && [
            UserRequestStatuses.RejectedByUser,
            UserRequestStatuses.RejectedBySpecialized,
          ].includes(m.status)),
    );
  },
};

const mutations = {
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setReason: (store, { id, message }) => {
    const request = store.userRequests.find((item) => item.id === id);
    if (request) {
      request.reason = message;
    }
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  setSummaryLoading: (store, value) => {
    store.summaryLoading = value;
  },
  setBranchInSummary: (store, value) => {
    store.isBranchInSummary = value;
  },
  setVisibleInfo: (store, id) => {
    store.userRequests = store.userRequests.map(
      (m) => ({ ...m, showInfo: m.id === id ? !m.showInfo : false }),
    );
  },
  setSummaryMemberLoadingState: (store, { memberId, value }) => {
    const member = store.summaryMembers.find((m) => m.id === memberId) || {};
    member.loading = value;
  },
  setSummaryMembers: (store, summaryMembers) => {
    store.summaryMembers = summaryMembers;
  },
  setResendLoading: (store, value) => {
    store.resendLoading = value;
  },
  setVisibleInfoMembers: (store, id) => {
    store.summaryMembers = store.summaryMembers.map(
      (m) => ({
        ...m,
        showInfo: m.id === id && m.status === UserRequestStatuses.RejectedByUser
          ? !m.showInfo
          : false,
      }),
    );
    store.errors = [];
  },
  setVisibleInfoMembersDetails: (store, id) => {
    store.summaryMembers = store.summaryMembers.map(
      (m) => ({
        ...m,
        showInfo: m.id === id ? !m.showInfo : false,
      }),
    );
    store.errors = [];
  },
  resetSummaryMembers: (store) => {
    store.summaryMembers = [];
  },
};

const actions = {
  updateUserRequest: async ({ commit, rootGetters }, data) => {
    commit('setErrors', []);
    commit('setLoading', true);
    try {
      const res = await UserApi.updateIndividual({
        id: rootGetters['user/userId'],
        ...data,
      });
      const user = res.data.updateUser;
      const combinedUserData = { ...rootGetters['user/userProfile'], ...user };
      commit('user/setProfile', combinedUserData, { root: true });
      commit('user/setSecurityForm', combinedUserData, { root: true });
      return user;
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return { error: e.message };
    } finally {
      commit('setLoading', false);
    }
  },
  removeSummaryMember: async ({ commit, dispatch, getters }, { memberId, entityId }) => {
    commit('setSummaryMemberLoadingState', { value: true, memberId });
    commit('setErrors', []);
    try {
      const res = await MembershipApi.deleteMembership(memberId);
      await dispatch('fetchSummaryMembersList', { entityId, isBranch: getters.isBranchInSummary });
      return res.data;
    } catch (e) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 2000,
        type: 'error',
      }, { root: true });
      return { error: e.message };
    } finally {
      commit('setSummaryMemberLoadingState', { value: false, memberId });
    }
  },
  resendRequest: async ({ commit, dispatch, getters }, data) => {
    commit('setResendLoading', true);
    commit('setErrors', []);
    try {
      const res = await MembershipApi.updateMembership(data, data.id);
      await dispatch('getSummaryMembersList', { entityId: data.entityId, isBranch: getters.isBranchInSummary });
      return res.data;
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return { error: e.message };
    } finally {
      commit('setResendLoading', false);
    }
  },
  fetchSummaryMembersList: async ({ commit, dispatch, getters }, payload) => {
    commit('setSummaryLoading', true);
    try {
      await dispatch('getSummaryMembersList', { ...payload, isBranch: getters.isBranchInSummary });
    } catch (e) {
      console.warn(e);
    } finally {
      commit('setSummaryLoading', false);
    }
  },
  getSummaryMembersList: async ({ commit }, payload) => {
    try {
      const res = await MembershipApi.getSummaryMembers(payload);
      const userRequests = res?.data?.userRequests?.userRequests || [];
      commit('setSummaryMembers', handleSummaryRequestList(userRequests));
    } catch (e) {
      console.warn(e);
      commit('setSummaryMembers', []);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
