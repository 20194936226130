import { getField, updateField } from 'vuex-map-fields';
import EntityApi from '@/api/entity.api';
import { deserializer } from '@/helpers/api';
import { normalizeEntity } from '@/services/entity.service';
import { bulkMembersCount } from '@/services/user-requests.service';
import { EntityCardsTypes, UnitTypes, EntityTypes } from '@/constants/enums';

const handlePublicEntities = (data) => data.map((item) => {
  const entity = normalizeEntity(item);
  entity.classification = item.classificationDetails?.mainClassificationArTitle;
  entity.firstClassification = item.classificationDetails?.firstSubClassificationArTitle;
  entity.secondClassification = item.classificationDetails?.secondSubClassificationArTitle;
  entity.supervisory = item.classificationDetails?.departmentArTitle;
  entity.goals = item.goals;
  entity.activities = item.activities;
  return entity;
});

const handleUserEntities = (data) => data.map((item) => {
  const classifications = item?.classificationDetails;
  return {
    ...normalizeEntity(item),
    unifiedNumber700: item?.unifiedNumber700 || null,
    avatar: item?.entityProfile?.logo || null,
    classificationData: classifications?.mainClassificationArTitle,
    firstClassificationData: classifications?.firstSubClassificationArTitle,
    secondClassificationData: classifications?.secondSubClassificationArTitle,
    departmentId: classifications?.departmentId,
    supervisoryData: classifications?.departmentArTitle,
    currentUserPosition: item?.currentPosition?.positionUid,
  };
});

function makeBulkMemberCountPayload(entities) {
  return entities.map((entity) => ({
    unitId: Number(entity.id),
    unitType: UnitTypes.Entity,
  }));
}

function findMembersCount(entities, searchUnit) {
  if (!entities) {
    return null;
  }
  const entityBySearchUnit = entities.find(({ unitId }) => unitId === Number(searchUnit.id));
  return entityBySearchUnit?.membersCount || null;
}

const mainState = {
  entities: [],
  branches: [],
  entitiesByMember: [],
  entitiesLoaded: false,
  landingEntities: [],
  entityDetails: null,
  entityFilters: {
    entityUid: '',
    region: '',
    city: [],
    name: '',
    entity_type: '',
    second_sub_classification_ids: '',
    goalId: '',
    activityId: '',
    hijriDateFrom: '',
    hijriDateTo: '',
    classification: '',
    firstClassification: '',
    unifiedNumber700: '',
  },
  loading: false,
  filterType: EntityCardsTypes.EntityOwner,
  activeEntityId: '',
  entitiesOwnerMeta: {},
  entitiesMemberMeta: {},
  entitiesBranchMeta: {},
  pages: {
    entitiesOwnerPage: 1,
    entitiesMemberPage: 1,
    entitiesBranchPage: 1,
  },
};

const mainGetters = {
  getField,
  isLoading: (store) => store.loading,
  getEntities: (store) => store.entities,
  getBranches: (store) => store.branches,
  getEntitiesByMember: (store) => store.entitiesByMember,
  filterType: (store) => store.filterType,
  getEntity: (store) => (entityId) => store.entities.find((e) => e.id === entityId),
  getLandingEntities: (store) => store.landingEntities,
  activeEntityId: (store) => store.activeEntityId,
  getList: (store) => {
    if (store.filterType === EntityCardsTypes.EntityMember) {
      return store.entitiesByMember;
    }
    if (store.filterType === EntityCardsTypes.BranchOwner) {
      return store.branches;
    }
    return store.entities.filter((entity) => !entity.entityId);
  },
  getActivePage: (store) => {
    if (store.filterType === EntityCardsTypes.EntityMember) {
      return store.pages.entitiesMemberPage;
    }
    if (store.filterType === EntityCardsTypes.BranchOwner) {
      return store.pages.entitiesBranchPage;
    }
    return store.pages.entitiesOwnerPage;
  },
  getMeta: (store) => {
    if (store.filterType === EntityCardsTypes.EntityMember) {
      return store.entitiesMemberMeta;
    }
    if (store.filterType === EntityCardsTypes.BranchOwner) {
      return store.entitiesBranchMeta;
    }
    return store.entitiesOwnerMeta;
  },
  getActiveEntity: (store, getters) => getters.getList.find(
    (entity) => +entity.id === +store.activeEntityId,
  ) || {},
  getEntityFilters: (store) => store.entityFilters,
};

const mutations = {
  updateField,
  setEntities: (store, entities) => {
    store.entities = entities;
  },
  setEntitiesOwnerMeta: (store, meta) => {
    store.entitiesOwnerMeta = meta;
  },
  setEntitiesMemberMeta: (store, meta) => {
    store.entitiesMemberMeta = meta;
  },
  setBranchesMeta: (store, meta) => {
    store.entitiesBranchMeta = meta;
  },
  addNewOwnerEntities: (store, entities) => {
    store.entities = [...store.entities, ...entities];
  },
  addNewMemberEntities: (store, entities) => {
    store.entitiesByMember = [...store.entitiesByMember, ...entities];
  },
  addNewBranches: (store, branches) => {
    store.branches = [...store.branches, ...branches];
  },
  setBranches: (store, branches) => {
    store.branches = branches;
  },
  setEntitiesByMember: (store, entities) => {
    store.entitiesByMember = entities;
  },
  setEntitiesLoaded: (store, value) => {
    store.entitiesLoaded = value;
  },
  setFilterType: (store, type) => {
    store.filterType = type;
  },
  setLandingEntities: (store, landingEntities) => {
    store.landingEntities = landingEntities;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  resetClassifications: (store) => {
    store.entityFilters.second_sub_classification_ids = '';
    store.entityFilters.firstClassification = '';
    store.entityFilters.classification = '';
  },
  resetEntityFilters: (store) => {
    store.entityFilters = {
      entityUid: '',
      region: '',
      city: [],
      name: '',
      entity_type: '',
      second_sub_classification_ids: '',
      goalId: '',
      activityId: '',
      hijriDateFrom: '',
      hijriDateTo: '',
      classification: '',
      firstClassification: '',
      unifiedNumber700: '',
    };
  },
  setActiveEntityId: (store, id) => {
    store.activeEntityId = id;
  },
  setActivePage: (store, page) => {
    if (store.filterType === EntityCardsTypes.EntityMember) {
      store.pages.entitiesMemberPage = page;
    }
    if (store.filterType === EntityCardsTypes.BranchOwner) {
      store.pages.entitiesBranchPage = page;
    }
    if (store.filterType === EntityCardsTypes.EntityOwner) {
      store.pages.entitiesOwnerPage = page;
    }
  },
  resetEntitiesState: (store) => {
    store.activeEntityId = '';
    store.filterType = EntityCardsTypes.EntityOwner;
    store.pages = {
      entitiesOwnerPage: 1,
      entitiesMemberPage: 1,
      entitiesBranchPage: 1,
    };
  },
};

const actions = {
  fetchCurrentEntities: async ({ state, dispatch }, data) => {
    switch (state.filterType) {
      case EntityCardsTypes.EntityMember:
        await dispatch('fetchMemberEntities', data);
        return;
      case EntityCardsTypes.BranchOwner:
        await dispatch('fetchBranches', data);
        return;
      default:
        await dispatch('fetchOwnerEntities', data);
    }
  },
  fetchOwnerEntities: async ({ commit }, { page, size }) => {
    try {
      const ownerEntities = await EntityApi.getAllEntities(page, size);
      const entities = ownerEntities?.data?.entities?.entities || [];
      commit('addNewOwnerEntities', handleUserEntities(entities));
    } catch (e) {
      console.log(e);
    }
  },
  fetchMemberEntities: async ({ commit }, { page, size }) => {
    try {
      const memberEntities = await EntityApi.getEntitiesByMemberGql(page, size);
      const entities = memberEntities?.data?.entities?.entities || [];
      commit('addNewMemberEntities', handleUserEntities(entities));
    } catch (e) {
      console.log(e);
    }
  },
  fetchBranches: async ({ commit }, { page, size }) => {
    try {
      const ownerBranches = await EntityApi.getAllBranches(page, size);
      const branches = await deserializer(ownerBranches?.data) || [];
      commit('addNewBranches', handleUserEntities(branches));
    } catch (e) {
      console.log(e);
    }
  },
  fetchAllEntities: async ({ commit, state }, force) => {
    if (state.entitiesLoaded && !force) {
      return;
    }
    commit('setLoading', true);
    try {
      const ownerEntitiesPromise = EntityApi.getAllEntities();
      const memberEntitiesPromise = EntityApi.getEntitiesByMemberGql();
      const [ownerEntities, memberEntities] = await Promise.allSettled(
        [ownerEntitiesPromise, memberEntitiesPromise],
      );
      const entities = ownerEntities?.value?.data?.entities?.entities || [];
      const entitiesOwnerMeta = ownerEntities?.value?.data?.entities?.meta || [];
      const entitiesByMember = memberEntities?.value?.data?.entities?.entities || [];
      const entitiesMemberMeta = memberEntities?.value?.data?.entities?.meta || [];
      commit('setEntitiesOwnerMeta', entitiesOwnerMeta);
      commit('setEntitiesMemberMeta', entitiesMemberMeta);
      commit('setEntities', handleUserEntities(entities));
      commit('setEntitiesByMember', handleUserEntities(entitiesByMember));
      commit('setEntitiesLoaded', true);
    } catch (e) {
      console.warn(e);
    } finally {
      commit('setLoading', false);
    }
  },
  fetchLandingEstablishedEntities: async ({ commit, state, rootGetters }) => {
    const { entityFilters } = state;

    const filtersBy = {
      name: String(entityFilters.name || ''),
      mainClassificationId: Number(entityFilters.classification),
      firstSubClassificationId: Number(entityFilters.firstClassification),
      secondSubClassificationId: Number(entityFilters.second_sub_classification_ids),
      hijriDateFrom: String(entityFilters.hijriDateFrom || ''),
      hijriDateTo: String(entityFilters.hijriDateTo || ''),
      goalId: Number(entityFilters.goalId),
      activityId: Number(entityFilters.activityId),
      type: String(entityFilters.entity_type || ''),
      regionCode: String(entityFilters.region || ''),
      cityUid: entityFilters.city?.length ? entityFilters.city : undefined,
      entityUid: String(entityFilters.entityUid || ''),
      unifiedNumber700: entityFilters.unifiedNumber700 || '',
    };
    const payload = {
      page: Number(rootGetters['landingEntitiesPagination/selectedPage']),
      size: Number(rootGetters['landingEntitiesPagination/selectedSize']),
      excludedType: [EntityTypes.CooperativeAssociation],
    };
    Object.keys(filtersBy).forEach((key) => {
      if (!filtersBy[key]) return;
      payload[key] = filtersBy[key];
    });
    commit('setLoading', true);
    try {
      const publicEntitiesResp = await EntityApi.fetchFilteredEntities(payload);
      const { entities } = publicEntitiesResp.data.publicListEntities;
      const bulkMembersCountResp = await bulkMembersCount(makeBulkMemberCountPayload(entities));
      let entitesWithMembersCount = null;
      if (!bulkMembersCountResp.error) {
        entitesWithMembersCount = bulkMembersCountResp;
      }
      const modifiedEntities = entities.map((entity) => ({
        ...entity,
        membersCount: findMembersCount(entitesWithMembersCount, entity),
      }));
      const { meta } = publicEntitiesResp.data.publicListEntities;
      commit('setLandingEntities', handlePublicEntities(modifiedEntities));
      commit('landingEntitiesPagination/setTotalPages', meta.pageCount, { root: true });
      commit('landingEntitiesPagination/setTotalRecords', meta.recordsCount, { root: true });
    } catch (e) {
      console.warn(e);
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
