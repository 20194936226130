import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';
import AuthApi from '@/api/auth.api';
import { deserializer, serializer } from '@/helpers/api';
import UserApi from '@/api/user.api';
import {
  mediaAttachableTypes,
  mediaDocTypes,
  UserRoles,
} from '@/constants/enums';
import {
  handleNationalAddress,
  randomEmail,
} from '@/helpers/general';
import ErrorHandler from '@/helpers/errors';
import {
  getDocumentsList,
  uploadDocuments,
} from '@/services/media.service';
import { getFullName } from '@/services/membership.service';
import i18n from '@/i18n';
import { PhoneFormatterAdapter } from '@takamol/nawa-library/src/helpers/phone-formatter';

const mainState = {
  profile: null,
  account: null,
  loading: false,
  sessionLoading: false,
  formLoading: false,
  errors: null,
  filesLoading: false,
  certificates: [],
  form: {
    email: null,
    phone: null,
  },
};

const mainGetters = {
  getField,
  userProfile: (store) => ({
    ...store.profile,
    fullName: getFullName(store.profile),
  }),
  userLoading: (store) => store.loading,
  isSessionLoading: (store) => store.sessionLoading,
  isFormLoading: (store) => store.formLoading,
  userId: (store) => store.profile?.id,
  getNationalId: (store) => store.profile?.nationalId,
  account: (store) => store.account,
  accountId: (store) => store.account?.id,
  nationalAddress: (store) => handleNationalAddress(store.profile?.nationalAddress),
  isAuthenticated: (store) => Boolean(store.profile),
  isActiveSocials: (store) => store.profile?.socials?.some((s) => s.accountName),
  getErrors: (store) => store.errors,
  filesLoading: (store) => store.filesLoading,
  certificates: (store) => store.certificates,
};

const mutations = {
  updateField,
  setCertificates: (store, value) => {
    store.certificates = value;
  },
  setSessionLoading: (store, value) => {
    store.sessionLoading = value;
  },
  setFilesLoading: (store, value) => {
    store.filesLoading = value;
  },
  setProfile: (store, data) => {
    store.profile = data;
  },
  setAccount: (store, data) => {
    store.account = data;
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  setFormLoading: (store, value) => {
    store.formLoading = value;
  },
  setSecurityForm: (store, data) => {
    let twitter = '';
    let facebook = '';
    store.profile.absherPhone = PhoneFormatterAdapter.fromServerToInput(data.absherPhone);
    Vue.set(store.profile, 'hasSocials', false);
    if (data && data.socialMedias && Array.isArray(data.socialMedias)) {
      store.profile.hasSocials = data.socialMedias.some((item) => item.accountName) || false;
      twitter = data.socialMedias.find((item) => item.type === 'twitter')?.accountName || '';
      facebook = data.socialMedias.find((item) => item.type === 'facebook')?.accountName || '';
    }
    Vue.set(store.profile, 'socials', [
      {
        type: 'twitter',
        accountName: twitter,
        userId: +store.profile.id,
      },
      {
        type: 'facebook',
        accountName: facebook,
        userId: +store.profile.id,
      },
    ]);
  },
};

const actions = {
  certificatesUpload: async ({ commit, rootGetters }, files) => {
    commit('setFilesLoading', true);
    const res = await uploadDocuments(
      files,
      rootGetters['user/userId'],
      mediaAttachableTypes.User,
      mediaDocTypes.Certificate,
    );
    commit('setFilesLoading', false);
    return res;
  },
  getCertificates: async ({ commit, rootGetters }) => {
    commit('setFilesLoading', true);
    const res = await getDocumentsList(
      rootGetters['user/userId'],
      mediaAttachableTypes.User,
      mediaDocTypes.Certificate,
      null,
    );
    if (!res?.error) {
      commit('setCertificates', res);
    }
    commit('setFilesLoading', false);
  },
  removeSocialMedia: ({ rootGetters }) => {
    try {
      UserApi.removeSocialMedia(
        rootGetters['user/userId'],
      );
    } catch (e) {
      console.warn(e);
    }
  },
  sendSecurityForms: async ({ commit, rootGetters }, data) => {
    commit('setErrors', null);
    commit('setFormLoading', true);
    try {
      const res = await UserApi.updateIndividual({ ...data, id: rootGetters['user/userId'] });
      return res.data.updateUser;
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return { error: e.message };
    } finally {
      commit('setFormLoading', false);
    }
  },
  getUserProfile: async ({ commit }, { useLoading }) => {
    if (useLoading) commit('setSessionLoading', true);
    try {
      const res = await UserApi.getUserProfile();
      const account = res?.data?.profile || {};
      const user = account?.user;
      if (user && account.role === UserRoles.Individual) {
        commit('setProfile', user);
        commit('setSecurityForm', user);
        commit('setAccount', {
          phone: account.phone,
          email: account.email,
          id: account.accountId,
          role: account.role,
        });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      if (useLoading) commit('setSessionLoading', false);
    }
  },
  getIndividualInfo: async (_, { nid, dob }) => {
    try {
      const res = await AuthApi.getIndividualInfo(nid, dob);
      return await deserializer(res.data);
    } catch (e) {
      console.warn(e);
      return { error: e.message };
    }
  },
  getIndividualInfoV2: async (_, { nid, dob }) => {
    try {
      const res = await UserApi.getInfo({ nationalId: nid, birthDateHijri: dob });
      return res.data?.findOrCreateUser;
    } catch (e) {
      console.warn(e);
      return { error: e.message };
    }
  },
  initOtp: async ({ commit, state }) => {
    commit('setErrors', null);
    try {
      const { data } = await UserApi.initOtp({
        phone: PhoneFormatterAdapter.fromUIToServer(state.form.phone),
      });
      return data?.initPhoneOtp?.otpToken;
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return { error: ErrorHandler.parseFormErrors(e) };
    }
  },
  updateAccount: async ({ commit, dispatch }, payload) => {
    commit('setLoading', true);
    commit('setErrors', null);
    try {
      await UserApi.sendUpdatedProfile(payload);
      await dispatch('user/getUserProfile', { useLoading: false }, { root: true });
      dispatch('toast/showNotification', {
        message: i18n.t('profileUpdate.notificationSuccess'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return true;
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },

  updateUser: async ({ state, dispatch }, { data, needFetch = false }) => {
    try {
      const response = await UserApi.updateUser({ ...data, id: state.profile?.id });
      const responseData = response.data?.updateUser?.updateUser;
      if (needFetch) {
        await dispatch('user/getUserProfile', { useLoading: false }, { root: true });
      }
      return responseData;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },

  updateIndividual: async ({ commit, state }, id) => {
    const {
      nid,
      password,
      passwordConfirm,
      birthday,
      phone,
      title,
    } = state.registerForm;
    const data = serializer({
      password,
      id,
      password_confirmation: passwordConfirm,
      first_name: 'John',
      last_name: 'Doe',
      email: randomEmail(),
      phone: PhoneFormatterAdapter.fromUIToServer(phone),
      title,
      national_id: nid,
      birth_date_hijri: `${birthday.year}${birthday.month}${birthday.day}`,
    }, 'registration');

    commit('auth/setLoading', true, { root: true });
    try {
      const response = await UserApi.updateIndividual(data);
      const deserializedData = await deserializer(response.data);
      commit('setProfile', deserializedData, { root: true });
      commit('setSecurityForm', deserializedData, { root: true });
      return deserializedData;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('auth/setLoading', false, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
