<template>
  <div class="landing-content">
    <LandingHeader v-if="!isHomePage" />
    <div class="web__main">
      <router-view />
    </div>
    <home-footer v-if="!isHomePage" />
  </div>
</template>

<script>
// import LandingFooter from '@/views/landing/landing-footer/LandingFooter.vue';
import HomeFooter from '@/views/landing/home/home-footer/HomeFooter.vue';
import LandingHeader from '@/views/landing/home/home-header/HomeHeader.vue';
import { isFeatureAvailable } from '@/helpers/features-config';

export default {
  name: 'LandingLayout',
  components: {
    LandingHeader,
    // LandingFooter,
    HomeFooter,
  },

  data() {
    return {
      isFixed: false,
    };
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'Home';
    },
  },
  beforeCreate() {
    document.querySelector('html').classList.add('landing-layout');
    if (
      this.$i18n.locale !== this.$enums.LocaleTypes.Ar
      && isFeatureAvailable(this.$enums.featureNames.landingArabicLocaleOnly)
    ) {
      this.$router.replace({
        params: {
          locale: this.$enums.LocaleTypes.Ar,
        },
      });
    }
  },
  destroyed() {
    document.querySelector('html').classList.remove('landing-layout');
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
