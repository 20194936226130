import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios';
import BankAccountApi from '@/api/bank-account.api';
import { deserializer, serializer } from '@/helpers/api';
import {
  EntityBankTypes,
  mediaDocTypes,
  MembershipEntityTypes, UnitTypes,
} from '@/constants/enums';
import {
  findEntity,
  findTempBankAccountByEntityId,
  getEntityClassifications,
  normalizeEntity,
} from '@/services/entity.service';
import EntityApi from '@/api/entity.api';
import { getMemberships, serializeMembership, updateMembership } from '@/services/membership.service';
import i18n from '@/i18n';
import ErrorHandler from '@/helpers/errors';
import { getDocumentsList, uploadDocuments } from '@/services/media.service';
import UserRequestsApi from '@/api/user-requests.api';
import { findBranch } from '@/api/branch.api';
import { formatDate } from '@/helpers/date';

let cancelSource = null;

const mainState = {
  bankType: EntityBankTypes.Main,
  banksList: [],
  banksLoading: false,
  entity: {},
  memberships: [],
  entityLoading: false,
};

const mainGetters = {
  getField,
  bankType: (store) => store.bankType,
  entity: (store) => store.entity,
  memberships: (store) => store.memberships,
  entityLoading: (store) => store.entityLoading,
  banksList: (store) => store.banksList,
  isBanksLoading: (store) => store.banksLoading,
  hasMainAccount: (store, getters) => (
    getters.banksList.length > 0 && getters.bankType === EntityBankTypes.Main
  ),
  unifiedNumber700: (store) => store.entity.unifiedNumber700,
};

const mutations = {
  updateField,
  setBankType: (store, type) => {
    store.bankType = type;
  },
  setBanksList: (store, list) => {
    store.banksList = list;
  },
  setBanksLoading: (store, value) => {
    store.banksLoading = value;
  },
  setVisibleMode: (store, { id, mode }) => {
    store.memberships = store.memberships.map((member) => {
      if (member.id === id) {
        member.visibleMode = mode;
      } else {
        member.visibleMode = 'view';
      }
      return member;
    });
  },
  setMemberLoading: (store, { id, value }) => {
    store.memberships = store.memberships.map((member) => {
      if (member.id === id) {
        member.loading = value;
      }
      return member;
    });
  },
  setEntity: (store, value) => {
    store.entity = value;
  },
  setMemberships: (store, value) => {
    store.memberships = value;
  },
  setEntityLoading: (store, value) => {
    store.entityLoading = value;
  },
  updateNumberOfShares: (store, { id, numberOfShares }) => {
    const currentMembership = store.memberships.find((m) => m.id === id);
    currentMembership.numberOfShares = numberOfShares;
  },
  setMemberLoadingState: (store, { memberId, value }) => {
    const member = store.memberships.find((m) => m.id === memberId) || {};
    member.loading = value;
  },
};

const actions = {
  checkEntityWithoutMembership: async (_, { entityId, membershipId }) => {
    try {
      const payload = serializer({ without_membership: membershipId }, 'validateEntityMemberships');
      await EntityApi.checkEntityWithoutMembership(entityId, payload);
      return true;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  closeTemporaryBankAccount: async ({ commit }, bankId) => {
    try {
      const res = await EntityApi.closeTemporaryBankAccount(bankId);
      const entity = await deserializer(res.data);
      commit('setEntity', normalizeEntity(entity));
      return entity;
    } catch (e) {
      console.warn(e);
      return ErrorHandler.parseFormErrors(e);
    }
  },
  createTemporaryBankAccount: async (_, data) => {
    try {
      const payload = serializer(data, 'tempBankAccount');
      const res = await EntityApi.createTemporaryBankAccount(payload);
      return await deserializer(res.data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  submitTemporaryBankAccount: async ({ commit }, entityId) => {
    try {
      const res = await EntityApi.submitTemporaryBankAccount(entityId);
      const entity = await deserializer(res.data);
      commit('setEntity', normalizeEntity(entity));
      return entity;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  updateCapital: async (_, { id, capital }) => {
    try {
      const payload = serializer({ capital }, 'entity');
      const res = await EntityApi.updateCapital(id, payload);
      return await deserializer(res.data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  getEntityClassifications: async ({ commit }, data) => {
    const entity = data;
    if (entity.secondClassification) {
      const classifications = await getEntityClassifications(entity.secondClassification);
      if (classifications?.length) {
        entity.firstClassificationTitle = classifications[0].firstClassification;
        entity.secondClassificationTitle = classifications[0].secondClassification;
        entity.classificationTitle = classifications[0].classification;
      }
    }
    commit('setEntity', entity);
  },
  getBranch: async ({ commit }, branchId) => {
    commit('setEntityLoading', true);
    const res = await findBranch(branchId);
    if (res?.error) {
      return res;
    }
    commit('setEntity', res);
    const entity = {
      ...res?.data?.findBranchById,
      createdAt: formatDate(res?.data?.findBranchById?.createdAt),
      updatedAt: formatDate(res?.data?.findBranchById?.updatedAt),
    };
    commit('setEntityLoading', false);
    return entity;
  },
  getEntityForBankVerification: async ({ commit }, entityId) => {
    commit('setEntityLoading', true);
    const entity = await findEntity(entityId);
    if (entity?.error) {
      commit('setEntityLoading', false);
      return entity;
    }
    commit('setEntity', entity);
    commit('setEntityLoading', false);
    return entity;
  },
  getEntity: async ({ commit, dispatch }, entityId) => {
    commit('setEntityLoading', true);
    const res = await findEntity(entityId);
    if (res?.error) {
      return res;
    }
    commit('setEntity', res);
    const tempBankAccount = await findTempBankAccountByEntityId(entityId);
    const entity = {
      ...res,
      tempBankAccount,
    };
    await dispatch('getEntityClassifications', entity);
    commit('setEntityLoading', false);
    return entity;
  },
  getMemberships: async ({ commit }, entityId) => {
    const res = await getMemberships(entityId, MembershipEntityTypes.Entity) || [];
    const memberships = res?.error ? [] : res.map(((member) => ({ ...member, visibleMode: 'view' })));
    commit('setMemberships', memberships);
    return memberships;
  },
  cancelRequest: async (_, requestId) => {
    try {
      return await UserRequestsApi.cancelRequest(requestId);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  updateMembership: async ({ dispatch }, { data }) => {
    const res = await updateMembership(serializeMembership(data), data.id);
    if (res?.error) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(res.error),
        duration: 3000,
        type: 'error',
      }, { root: true });
    } else {
      dispatch('toast/showNotification', {
        message: i18n.t('temporaryBankAccount.successUpdate'),
        duration: 2000,
        type: 'success',
      }, { root: true });
    }
    return res;
  },
  getBankStatement: async (_, { id }) => getDocumentsList(
    id,
    'Entity',
    mediaDocTypes.TempBankStatement,
    null,
  ),
  getBankCertificate: async (_, { id }) => getDocumentsList(
    id,
    'Entity',
    mediaDocTypes.TempBankCertificate,
    null,
  ),
  getCloseBankAccount: async (_, { id, type }) => getDocumentsList(
    id,
    type,
    mediaDocTypes.ConfirmationOfClose,
    null,
  ),
  bankCertificateUpload: (
    _,
    { files, id },
  ) => uploadDocuments(
    files,
    id,
    'Entity',
    mediaDocTypes.TempBankCertificate,
  ),
  bankStatementUpload: (
    _,
    { files, id },
  ) => uploadDocuments(
    files,
    id,
    'Entity',
    mediaDocTypes.TempBankStatement,
  ),
  closeBankAccountUpload: (
    _,
    { files, id, type },
  ) => uploadDocuments(
    files,
    id,
    type,
    mediaDocTypes.ConfirmationOfClose,
  ),
  getBanksList: async ({ getters, commit }, {
    id,
    unitType,
    page,
    size,
  }) => {
    commit('setBanksLoading', true);

    if (cancelSource) {
      cancelSource.cancel();
    }
    cancelSource = axios.CancelToken.source();
    try {
      const payload = {
        unitId: id,
        unitType: unitType || UnitTypes.Entity,
        type: getters.bankType,
        page,
        size,
      };
      const res = await BankAccountApi.getBanksList(payload, { cancelToken: cancelSource.token });
      const { bankAccounts, meta } = res.data.listBankAccounts;
      commit('setBanksList', bankAccounts);
      commit('banksAccountsPagination/setTotalPages', meta.pageCount, { root: true });
      commit('banksAccountsPagination/setTotalRecords', meta.recordsCount, { root: true });
      commit('setBanksLoading', false);
      return true;
    } catch (e) {
      if (!axios.isCancel(e)) {
        commit('setBanksLoading', false);
      }
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  verifyBankAccount: async (_, data) => {
    try {
      const jsonPayload = serializer(data, 'verifyBankAccount');
      const res = await BankAccountApi.sendBankData(jsonPayload);
      if (res.data.error) {
        return { error: res.data.error };
      }
      return deserializer(res.data);
    } catch (e) {
      return { error: e.response?.data?.error || e.message };
    }
  },
  createBankAccount: async (_, data) => {
    try {
      return await BankAccountApi.createBankAccount(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  updateBankAccount: async (_, { data, bankId }) => {
    try {
      const res = await BankAccountApi.updateBankAccount({
        bankId,
        iban: data.iban,
        number700: data.number700,
        attachments: data.attachments,
      });
      return res.data.updateBankAccount;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchBankById: async (_, bankId) => {
    try {
      const res = await BankAccountApi.fetchBankById(bankId);
      return res.data?.findBankAccount;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
