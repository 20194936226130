import { Gateway, GraphQLRequest } from '@/setup/axios';
import {
  CREATE_BANK_ACCOUNT,
  CREATE_BANK_REACTIVATION,
  FETCH_LIST_OF_BANKS_BY_ID,
  FIND_BANK_BY_ID,
  UPDATE_BANK_ACCOUNT,
} from '@/api/graphql/bank-account-gql';
import { UnitTypes } from '@/constants/enums';

class BankAccountApi {
  sendBankData = (data) => (
    Gateway.post('bank_accounts', data)
  );

  getBanksList = (data) => (
    GraphQLRequest({
      query: FETCH_LIST_OF_BANKS_BY_ID,
      variables: {
        accountType: data.type || undefined,
        page: +data.page || 1,
        size: +data.size || 10,
        unitId: +data.unitId || undefined,
        unitType: data.unitType || UnitTypes.Entity,
        status: data.status || undefined,
      },
    })
  );

  createBankAccount = (data) => (
    GraphQLRequest({
      query: CREATE_BANK_ACCOUNT,
      variables: {
        bankAccount: {
          attachments: data.attachments || undefined,
          accountType: data.accountType || undefined,
          unitId: +data.unitId,
          unitType: data.unitType || UnitTypes.Entity,
          iban: data.iban || undefined,
          number700: data.number700 || undefined,
        },
      },
    })
  );

  updateBankAccount = (data) => (
    GraphQLRequest({
      query: UPDATE_BANK_ACCOUNT,
      variables: {
        bankAccount: {
          attachments: data.attachments || undefined,
          id: Number(data.bankId) || undefined,
          iban: data.iban || undefined,
          number700: data.number700 || undefined,
        },
      },
    })
  )

  fetchBankById = (bankId) => (
    GraphQLRequest({
      query: FIND_BANK_BY_ID,
      variables: {
        id: Number(bankId) || undefined,
      },
    })
  )

  createBankReactivation = ({ bankAccountId, entityId, bankId }) => (
    GraphQLRequest({
      query: CREATE_BANK_REACTIVATION,
      variables: {
        bankAccountReactivation: {
          bankAccountId: Number(bankAccountId),
          entityId: Number(entityId),
          bankId: Number(bankId),
        },
      },
    })
  )

  createBankPermit = (data) => (
    Gateway.post('bank_account_permits', data)
  );

  getPermitDocumentsList = (entityType, accountType) => (
    Gateway.get(`bank_account_permits/documents_list?entity_type=${entityType}&bank_account_type=${accountType}`)
  );

  checkPermitNumber = (id) => (
    Gateway.get(`public/bank_account_permits/check?uid=${id}`)
  );
}

export default new BankAccountApi();
