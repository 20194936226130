import BankPermitModule from '@/modules/bank-permit/views/BankPermitModule.vue';

export default {
  parent: 'DashboardLayout',
  route: {
    path: '',
    name: 'BankPermitModule',
    component: BankPermitModule,
    children: [
      {
        path: 'create-permit/:entityId',
        name: 'CreatePermit',
        component: () => import(
          /* webpackChunkName: "create-permit" */
          '@/modules/bank-permit/views/create-permit/CreatePermit.vue'
        ),
      },
    ],
  },
};
