import ErrorHandler from '@/helpers/errors';
import FamilyFundApi from '@/api/family-fund.api';
import { parseAreaOfActivity } from './entity.service';

export const normalizeFamilyFund = (data) => {
  const entity = {
    becomeMember: data?.autocreateMembershipForOwner,
    name: data?.nameArabic,
    city: data?.city?.uid,
    foundFor: data?.foundFor,
    unitId: data?.id,
    step: data?.progressStep?.step,
    region: data?.region?.code,
    status: data?.status,
    banks: data?.banks,
    ownerId: Number(data?.owner?.id),
    goals: data?.goalIds,
    acknowledgment: !!data?.nameArabic,
    haveAddress: !!data?.entityNationalAddress,
    buildingNumber: data?.entityNationalAddress?.buildingNumber,
    streetName: data?.entityNationalAddress?.streetName,
    postCode: data?.entityNationalAddress?.postCode,
    additionalNumber: data?.entityNationalAddress?.additionalNumber,
    owner: data?.owner,
    nationalId: '',
    day: '',
    month: '',
    year: '',
    areasOfActivity: parseAreaOfActivity(data?.areasOfActivity),
    ceoEducationalLevelCondition: data?.ceoEducationalLevelCondition,
    ceoPreviousExperienceCondition: data?.ceoPreviousExperienceCondition,
    families: data?.families || null,
  };
  Object.entries(entity).forEach(([key, value]) => {
    entity[key] = value ?? null;
  });
  return entity;
};

export const createFamilyFund = async (data) => {
  try {
    const res = await FamilyFundApi.createFamilyFund(data);
    return res?.data?.createFamilyFundEntity;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const updateFamilyFund = async (data, stepData) => {
  try {
    const res = await FamilyFundApi.updateFamilyFund(data, stepData);
    return res?.data?.updateFamilyFundEntity;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};
export const findFamilyFundSubscription = async (id) => {
  try {
    const res = await FamilyFundApi.findFamilyFundSubscription(id);
    return res?.data?.findFamilyFundSubscriptionByEntityIdRequest;
  } catch (e) {
    return {
      error: ErrorHandler.parseGlobalErrors(e),
    };
  }
};

export const submitFamilyFundSubscription = async (data, stepData) => {
  try {
    const {
      createFamilyFundSubscription: create,
      updateFamilyFundSubscription: update,
    } = FamilyFundApi;
    const { id, entityId, amount } = data;
    const payload = { amount: Number(amount) };
    if (id) payload.id = Number(id);
    else payload.entityId = Number(entityId);
    const method = id ? update : create;
    const action = id ? 'update' : 'create';
    const res = await method(payload, stepData);
    const { [`${action}FamilyFundSubscription`]: responseData } = res?.data;
    return responseData;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};
