export const FETCH_LIST_OF_BANKS_BY_ID = `
  query FetchListOfBanksById(
    $status: String,
    $unitId: Int,
    $unitType: String,
    $page: Int,
    $size: Int,
    $accountType: String,
  ) {
    listBankAccounts(
      status: $status,
      unitId: $unitId,
      unitType: $unitType,
      accountType: $accountType,
      page: $page,
      size: $size,
    ) {
      bankAccounts {
        accountType
        createdAt
        iban
        id
        number700
        updatedAt
      }
      meta {
        page
        pageCount
        recordsCount
        size
      }
    }
  }
`;
export const CREATE_BANK_ACCOUNT = `mutation CreateBankAccount($bankAccount: CreateBankAccountInput!) {
  createBankAccount(bankAccount: $bankAccount) {
    accountType
    createdAt
    iban
    id
    number700
    updatedAt
  }
}
`;

export const UPDATE_BANK_ACCOUNT = `mutation UpdateBankAccount($bankAccount: UpdateBankAccountInput!) {
  updateBankAccount(bankAccount: $bankAccount) {
    accountType
    createdAt
    iban
    id
    number700
    updatedAt
  }
}
`;

export const FIND_BANK_BY_ID = `query FindBankById($id: Int!) {
  findBankAccount(id: $id) {
    accountType
    createdAt
    iban
    id
    number700
    bankVerificationFile {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    ibanVerificationFiles {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    unit
    unitId
    unitType
    updatedAt
  }
}`;

export const CREATE_BANK_REACTIVATION = `mutation createBankAccountReactivation($bankAccountReactivation: CreateBankAccountReactivationInput!) {
  createBankAccountReactivation(bankAccountReactivation: $bankAccountReactivation) {
    bankAccountId
    bankId
    createdAt
    entityId
    id
    updatedAt
  }
}`;
