import { getField, updateField } from 'vuex-map-fields';
import {
  FamilySteps, mediaDocTypes, MembershipEntityTypes, MemberTypes, UnitTypes, FamilyFundTypes,
} from '@/constants/enums';
import {
  createFamilyFund,
  findFamilyFundSubscription,
  normalizeFamilyFund,
  submitFamilyFundSubscription,
  updateFamilyFund,
} from '@/services/family-fund.service';
import router from '@/router';
import FamilyFundApi from '@/api/family-fund.api';
import { normalizeOwnerMembership } from '@/services/entity.service';
import ErrorHandler from '@/helpers/errors';
import UserRequestsApi from '@/api/user-requests.api';
import { prepareDisclosureForm } from '@/services/user-requests.service';
import EntityApi from '@/api/entity.api';
import UserApi from '@/api/user.api';
import { makeEntityLocationsPayload } from './entity';

const mainState = {
  form: normalizeFamilyFund(),
  ownerMembership: normalizeOwnerMembership(),
  subscription: {
    amount: '',
    id: '',
  },
  fundOwner: {
    fatherName: '',
    firstName: '',
    lastName: '',
    grandfatherName: '',
    userId: '',
  },
  loading: false,
  filesLoading: false,
  errors: [],
  locations: [],
  agreements: {
    disclosureAgreement: false,
    goalsAgreementTransfer: false,
    goalsAgreementName: false,
    agreementRules: false,
    // agreementToJoin: false,
    agreementToDelegate: false,
  },
};

const mainGetters = {
  getField,
  isLoading: (store) => store.loading,
  fundOwner: (store) => store.fundOwner,
  foundForUserId: (store) => store.fundOwner.userId,
  subscription: (store) => store.subscription,
  filesLoading: (store) => store.filesLoading,
  locations: (store) => store.locations,
  ownerMembership: (store) => store.ownerMembership,
  disclosureForm: (store) => store.ownerMembership.disclosureForm,
  form: (store) => store.form,
  areasOfActivity: (store) => store.form.areasOfActivity,
};

const mutations = {
  updateField,
  updateFamilyForm: (store, data) => {
    store.form.becomeMember = data.becomeMember;
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  setForm: (store, data) => {
    store.form = data;
  },
  setDisclosureForm: (store, list) => {
    store.ownerMembership.disclosureForm = list;
  },
  setOwnerMembership: (store, data) => {
    store.ownerMembership = normalizeOwnerMembership(data);
  },
  resetForm: (store) => {
    store.form = normalizeFamilyFund();
    store.ownerMembership = normalizeOwnerMembership();
  },
  setSubscription: (store, data) => {
    store.subscription = data;
  },
  setFundOwner: (store, data) => {
    store.fundOwner = {
      fatherName: data.fatherName,
      firstName: data.firstName,
      lastName: data.lastName,
      grandfatherName: data.grandfatherName,
      userId: data.userId || data.id,
    };
  },
  resetFundOwner: (store) => {
    store.fundOwner = {
      fatherName: '',
      firstName: '',
      lastName: '',
      grandfatherName: '',
      userId: '',
    };
  },
  resetSubscription: (store) => {
    store.subscription = {
      amount: '',
      id: '',
    };
  },
  resetAgreements: (store) => {
    store.agreements = {
      disclosureAgreement: false,
      goalsAgreementTransfer: false,
      goalsAgreementName: false,
      // agreementToJoin: false,
      agreementRules: false,
      agreementToDelegate: false,
    };
  },
  setAgreements: (store) => {
    store.agreements.disclosureAgreement = !!store.ownerMembership.disclosureFormId;
    store.agreements.goalsAgreementTransfer = !!store.form.goals?.length;
    store.agreements.goalsAgreementName = !!store.form.goals?.length;
    store.agreements.agreementToJoin = store.form.step === FamilySteps.Agreements;
    store.agreements.agreementRules = store.form.step === FamilySteps.Agreements;
    store.agreements.agreementToDelegate = store.form.step === FamilySteps.Agreements;
  },
};

const actions = {
  createOrUpdateEntityLocations: async ({ state, dispatch }, entityLocations) => {
    try {
      await EntityApi
        .createOrUpdateEntityLocations(
          state.form.unitId,
          makeEntityLocationsPayload(entityLocations),
        );
    } catch (e) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    }
  },
  resetFamilyFundState: ({ commit }) => {
    commit('resetForm');
    commit('resetFundOwner');
    commit('resetSubscription');
    commit('resetAgreements');
  },
  getInfo: async ({ commit }, data) => {
    try {
      const res = await UserApi.getInfo(data);
      const fundOwner = res.data?.findOrCreateUser;
      commit('setFundOwner', fundOwner);
      return fundOwner;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  getInfoById: async ({ commit }, data) => {
    try {
      const res = await UserApi.getInfoByNationalId(data);
      const fundOwner = res.data?.findByNationalId;
      commit('setFundOwner', fundOwner);
      return fundOwner;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  createFamilyFund: async ({ state, commit, dispatch }, { isRedirect = true }) => {
    const payload = {
      nameArabic: state.form.name,
      autocreateMembershipForOwner: state.form.becomeMember,
      foundFor: state.form.foundFor,
      families: state.form.families,
      membershipData: {
        role: MemberTypes.Individual,
      },
    };
    if (state.form.foundFor === FamilyFundTypes.Self) {
      payload.foundForUserId = Number(state.fundOwner?.userId);
    }
    const res = await createFamilyFund(payload);
    if (res?.id) {
      commit('updateField', {
        path: 'form.unitId',
        value: res.id,
      });
    }
    if (res?.foundFor === FamilyFundTypes.Self) {
      commit('setFundOwner', res.owner);
    }
    if (res?.autocreateMembershipForOwner) {
      const ownerData = await dispatch('entity/getOwnerUserRequest', res.id, { root: true });
      commit('setOwnerMembership', ownerData);
      dispatch('membership/fetchMemberships', {
        entityId: res?.id,
        type: MembershipEntityTypes.Entity,
      }, { root: true });
    }
    if (res?.owner?.educationalCertificates) {
      await dispatch('educationDocumentMedia/initFiles', res.owner.educationalCertificates, { root: true });
    }
    if (!res?.error && isRedirect && router.currentRoute.name !== 'EditFamilyFund') {
      router.replace({
        name: 'EditFamilyFund',
        params: { entityId: state.form.unitId, skipScroll: true },
      });
    }
    return res;
  },
  updateFamilyFund: async ({ commit, state }, { data, step }) => {
    commit('setLoading', true);
    const stepData = {
      unitId: +state.form.unitId,
      unitType: UnitTypes.Entity,
      step,
    };
    const res = await updateFamilyFund(data, stepData);
    commit('setLoading', false);
    return res;
  },
  submitFamilyFundSubscription: async ({ commit, getters, state }, stepData) => {
    commit('setLoading', true);
    const payload = { ...getters.subscription, entityId: +state.form.unitId };
    const res = await submitFamilyFundSubscription(payload, stepData);
    if (!res?.error) {
      commit('setSubscription', res);
    }
    commit('setLoading', false);
    return res;
  },
  deleteFamilyFundSubscription: async ({ commit, getters }, stepData) => {
    commit('setLoading', true);
    try {
      const res = await FamilyFundApi.deleteFamilyFundSubscription(
        +getters.subscription.id,
        stepData,
      );
      commit('resetSubscription');
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  findOwnerFamilyFundEntity: async ({ commit }, entityId) => {
    try {
      const res = await FamilyFundApi.findOwnerFamilyFundEntity(entityId);
      const data = res?.data?.findOwnerAsAMemberUserRequestByEntityId;
      commit('setOwnerMembership', data);
      return data;
    } catch (e) {
      return {
        error: e,
      };
    }
  },
  getFamilyFund: async ({ commit, dispatch, rootGetters }, entityId) => {
    try {
      const entityRes = await FamilyFundApi.findFamilyFundEntity(entityId);
      const data = entityRes?.data?.findEntityById;
      const ownerUserRequestData = entityRes?.data?.findOwnerAsAMemberUserRequestByEntityId;
      const subscriptionData = await findFamilyFundSubscription(entityId);
      const entity = normalizeFamilyFund(data);
      commit('setForm', entity);
      commit('updateField', {
        path: 'form.acknowledgment',
        value: true,
      });
      commit('setOwnerMembership', ownerUserRequestData);
      await dispatch('filterFamilySteps', {
        becomeMember: entity.becomeMember,
      });
      if (subscriptionData && !subscriptionData.error) {
        commit('setSubscription', subscriptionData);
      }
      if (entity.step) {
        const step = Object.values(rootGetters['familyFundStepper/allSteps']).findIndex((s) => entity.step === s.name);
        dispatch('familyFundStepper/goStep', { index: step < 0 ? 1 : step + 1 }, { root: true });
      }
      if (entity.owner?.educationalCertificates) {
        await dispatch('educationDocumentMedia/initFiles', entity.owner.educationalCertificates, { root: true });
      }
      commit('setAgreements');
      dispatch('membership/fetchMemberships', {
        entityId,
        type: MembershipEntityTypes.Entity,
      }, { root: true });
      return entity;
    } catch (e) {
      console.warn(e);
      return {
        error: e,
      };
    }
  },
  filterFamilySteps: ({ commit }, { becomeMember }) => {
    if (!becomeMember) {
      commit(
        'familyFundStepper/filterStepsByKeys',
        [
          FamilySteps.Disclosure,
          FamilySteps.Qualification,
        ],
        { root: true },
      );
    }
  },
  updateOwnerEducation: async ({
    commit, rootGetters, dispatch, state,
  }, { step }) => {
    commit('setLoading', true);
    const attachments = await dispatch(
      'educationDocumentMedia/serializeFiles',
      mediaDocTypes.EducationalCertificate,
      { root: true },
    );
    const userId = +rootGetters['user/userId'];
    const stepData = {
      unitId: +state.form.unitId,
      unitType: UnitTypes.Entity,
      step,
    };
    try {
      await FamilyFundApi.updateFamilyOwnerRequest(state.ownerMembership);
      dispatch('membership/fetchMemberships', {
        entityId: +state.form.unitId,
        type: MembershipEntityTypes.Entity,
      }, { root: true });
      const res = await FamilyFundApi.updateOwnerEducation(
        {
          attachments,
          id: userId,
        },
        stepData,
      );
      if (res?.data?.updateUser?.educationalCertificates) {
        await dispatch('educationDocumentMedia/initFiles', res?.data?.updateUser?.educationalCertificates, { root: true });
      }
      return res;
    } catch (e) {
      return {
        error: ErrorHandler.parseGlobalErrors(e),
      };
    } finally {
      commit('setLoading', false);
    }
  },
  submitFamilyStep: async ({ commit, state }, { step, useLoading = true }) => {
    if (useLoading) {
      commit('setLoading', true);
    }
    let res = null;
    const stepData = {
      unitId: +state.form.unitId,
      unitType: UnitTypes.Entity,
      step,
    };
    try {
      res = await FamilyFundApi.updateStepProgress(stepData);
    } catch (e) {
      res = ErrorHandler.parseFormErrors(e);
    }
    commit('setLoading', false);
    return res;
  },
  submitDisclosureForm: async ({ commit, dispatch, getters }, { step, data }) => {
    commit('setLoading', true);
    try {
      commit('setDisclosureForm', data);
      const { updateDisclosureFormGql: update, createDisclosureFormGql: create } = UserRequestsApi;
      const { disclosureFormId, id } = getters.ownerMembership;
      const method = disclosureFormId ? update : create;
      const res = await method({ ...prepareDisclosureForm(data), id: disclosureFormId || id });
      await dispatch('submitFamilyStep', { step: step.step, useLoading: false });
      if (!disclosureFormId && res?.data?.createDisclosureForm?.id) {
        commit('updateField', {
          path: 'ownerMembership.disclosureFormId',
          value: res.data.createDisclosureForm.id,
        });
      }
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  finalSubmitFamilyFund: async ({
    state, commit, getters, dispatch,
  }) => {
    commit('setLoading', true);
    try {
      const entityId = state.form.unitId;
      if (state.form.becomeMember) {
        await dispatch('findOwnerFamilyFundEntity', entityId);
        if (getters.ownerMembership.status === 'draft') {
          await UserRequestsApi.acceptUserRequest(getters.ownerMembership.id);
        }
      }
      const res = await FamilyFundApi.finalSubmitFamilyFund(entityId);
      return res?.data?.submitFamilyFundEntity;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  validateFamilyFundMembers: async ({ state, commit }) => {
    commit('setLoading', true);
    try {
      const entityId = state.form.unitId;
      const res = await EntityApi.validateMembers(entityId, UnitTypes.Entity);
      return res?.data?.validatePotentialMembersByUnit;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
