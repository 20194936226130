export const GET_ENTITY_EMPLOYEES = `query GetBranchEmployees($page: Int!, $size: Int!, $unitId: Int!) {
  listEmployeeRequestsByMember (page: $page, size: $size, unitId: $unitId, status: accepted, unitType: Entity) {
    list: employeeRequests {
      employee {
        id
        firstName
        fatherName
        grandfatherName
        lastName
        absherPhone
        birthDateHijri
        nationalId
        email
        nationalAddress {
          buildingNo
          streetNameAr
          streetNameEn
          districtAreaAr
          districtAreaEn
          cityAr
          cityEn
          zipCode
          additionalNo
          unitNo
        }
      }
    }
    meta {
      pageCount
      page
      recordsCount
    }
  }
}`;

export const GET_EMPLOYEE_INFO_PUBLIC = `query GetEmployeeInfoPublic($token: String!) {
  invitation(token: $token) {
    request {
      ... on EmployeeRequest {
        id
        positionExperienceYears
        previousExperience
        salary
        employeeType
        unitId
        status
        employeeType
        unitType
        position
        customPosition
        totalExperience
        employee {
          absherPhone
          birthDateHijri
          educationalLevel
          degrees
          employmentPlace
          firstName
          fatherName
          grandfatherName
          lastName
          nationalId
          previousExperience
          gender
          occupation
        }
      }
    }
  }
}`;

export const ACCEPT_EMPLOYEE_INFO_PUBLIC = `mutation AcceptEmployeeInfoPublic($token: String!) {
  acceptInvitation(token: $token)
  {
    unit {
      ... on Entity {
        id
      }
    }
  }
}`;
export const REJECT_EMPLOYEE_INFO_PUBLIC = `mutation RejectEmployeeInfoPublic($token: String!) {
  rejectInvitation(token: $token)
  {
    unit {
      ... on Entity {
        id
      }
    }
  }
}`;

export const ACCEPT_BY_MEMBER_EMPLOYEE_REQUEST = `mutation AcceptByMemberEmployeeRequest($employeeRequestAttributes: EmployeeRequestsAcceptByMemberInput!) {
 acceptByMemberEmployeeRequest(employeeRequestAttributes: $employeeRequestAttributes) {
   id
 }
}`;

export const REJECT_BY_MEMBER_EMPLOYEE_REQUEST = `mutation RejectByMemberEmployeeRequest($employeeRequestAttributes: EmployeeRequestRejectByMemberInput!) {
 rejectByMemberEmployeeRequest(employeeRequestAttributes: $employeeRequestAttributes) {
   id
 }
}`;

export const GET_EMPLOYEE_REQUESTS_COUNT = `query ListEmployeeRequests($page: Int!, $size: Int!, $unitId: Int, $unitType: UnitType, $status: [EmployeeRequestStatus!], $asOwner: Boolean) {
  listEmployeeRequests(page: $page, size: $size, unitId: $unitId, unitType: $unitType, status: $status, asOwner: $asOwner) {
    meta {
      recordsCount
    }
  }
}`;

export const LIST_EMPLOYEE_REQUESTS = `query ListEmployeeRequests($page: Int!, $size: Int!, $unitType: UnitType, $unitId: Int, $status: [EmployeeRequestStatus!]) {
  listEmployeeRequests(page: $page, size: $size, unitType: $unitType, unitId: $unitId, status: $status) {
    employeeRequests {
      autoAccept
      createdAt
      customPosition
      position
      employeeType
      id
      unitId
      unitType
      status
      updatedAt
      userId
      entity {
        type
        nameEnglish
        nameArabic
        owner {
          firstName
          fatherName
          grandfatherName
          lastName
        }
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const FIND_EMPLOYEE_REQUEST = `query FindEmployeeRequest($id: Int!) {
  findEmployeeRequest(id: $id) {
    id
    employee {
      nationalId
      birthDateHijri
      absherPhone
    }
    rejectReason {
      message
    }
    entity {
      nameArabic
      nameEnglish
    }
    status
    unitId
    salary
    previousExperience
    positionExperienceYears
    position
    totalExperience
    customPosition
  }
}`;
