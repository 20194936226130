import { getField, updateField } from 'vuex-map-fields';
import EntityProfileApi from '@/api/entity-profile.api';
import ErrorHandler from '@/helpers/errors';
import { DocumentType, EntityTypes, MemberRole } from '@/constants/enums';
import { findEntity, parseAreaOfActivity } from '@/services/entity.service';
import { PhoneFormatterAdapter } from '@takamol/nawa-library/src/helpers/phone-formatter';

const normalizeEntityContacts = (data = {}) => ({
  contactId: data.id,
  email: data.email || '',
  facebook: data.facebookAccount || '',
  instagram: data.instagramAccount || '',
  linkedin: data.linkedinAccount || '',
  telephone: data.phoneNumber ? data.phoneNumber.split('00966')[1] : data.phoneNumber || '',
  snapchat: data.snapchatAccount || '',
  phone: data.telephoneNumber ? data.telephoneNumber.split('00966')[1] : data.telephoneNumber || '' || '',
  twitter: data.twitterAccount || '',
  unifiedNumber: data.unifiedNumber || '',
  website: data.website || '',
});

const serializeEntityContacts = (data = {}) => ({
  email: data.email,
  facebookAccount: data.facebook,
  id: data.contactId ? Number(data.contactId) : undefined,
  instagramAccount: data.instagram,
  linkedinAccount: data.linkedin,
  phoneNumber: PhoneFormatterAdapter.fromUIToServer(data.phone),
  snapchatAccount: data.snapchat,
  telephoneNumber: PhoneFormatterAdapter.fromUIToServer(data.telephone),
  twitterAccount: data.twitter,
  unifiedNumber: data.unifiedNumber,
  website: data.website,
});

const normalizeEntityProfile = (data = {}) => ({
  avatar: data?.entityProfile?.logo || null,
  ...data,
  areasOfActivity: parseAreaOfActivity(data.areasOfActivity),
});

const mainState = {
  entityProfile: normalizeEntityProfile(),
  memberships: [],
  membershipConditions: [],
  entityContacts: normalizeEntityContacts(),
  entityEmployees: [],
  entityEmployeesLoading: false,
  citiesForIssuingUnifiedNumber700: [],
  selectedCityIdForIssuingUnifiedNumber700: '',
};

const mainGetters = {
  getField,
  getEntityProfile: (state) => state.entityProfile,
  getEntityAvatar: (state) => state.entityProfile.avatar,
  getProfileMemberships: (state) => state.memberships,
  getProfileMembershipConditions: (state) => state.membershipConditions,
  getEntityContacts: (state) => state.entityContacts,
  memberRole: (state) => state.entityProfile?.memberRole,
  entityType: (state) => state.entityProfile?.type,
  isPrivateCorporation: (state) => state.entityProfile?.type === EntityTypes.PrivateCorporation,
  isPrivateAssociation: (state) => state.entityProfile?.type === EntityTypes.PrivateAssociation,
  isGuest: (state) => state.entityProfile?.memberRole === MemberRole.Guest,
  isOwner: (state) => state.entityProfile?.memberRole === MemberRole.Owner,
  getEntityEmployees: (state) => state.entityEmployees,
  getEntityEmployeesLoading: (state) => state.entityEmployeesLoading,
  getCitiesForIssuingUnifiedNumber700: (state) => state.citiesForIssuingUnifiedNumber700
    .map((e) => ({
      label: e?.name,
      key: e?.cityCode,
    })),
  getSelectedCityIdForIssuingUnifiedNumber700: (state) => (
    state.selectedCityIdForIssuingUnifiedNumber700
  ),
};

const mutations = {
  updateField,
  setEntityProfile: (store, profile) => {
    store.entityProfile = normalizeEntityProfile(profile);
  },
  setEntityProfileAvatar: (store, file) => {
    store.entityProfile.avatar = file;
  },
  setEntityProfileMemberships: (store, data) => {
    store.memberships = data;
  },
  setEntityProfileMembershipConditions: (store, data) => {
    store.membershipConditions = data;
  },
  setEntityProfileMembersInfo: (store, id) => {
    store.memberships = store.memberships.map(
      (m) => ({
        ...m,
        showInfo: m.id === id ? !m.showInfo : false,
      }),
    );
    store.errors = [];
  },
  setEntityProfileEmployeesInfo: (store, id) => {
    store.entityEmployees = store.entityEmployees.map(
      (e) => ({
        ...e,
        showInfo: e.id === id ? !e.showInfo : false,
      }),
    );
    store.errors = [];
  },
  setEntityContacts: (state, data) => {
    state.entityContacts = data;
  },
  setEntityEmployees: (state, arr) => {
    state.entityEmployees = arr;
  },
  setEntityEmployeesLoading: (state, val) => {
    state.entityEmployeesLoading = val;
  },
  setCitiesForIssuingUnifiedNumber700: (state, val) => {
    if (!Array.isArray(val)) return;
    state.citiesForIssuingUnifiedNumber700 = val;
  },
  resetSelectedCityIdForIssuingUnifiedNumber700: (state) => {
    state.selectedCityIdForIssuingUnifiedNumber700 = '';
  },
};

const actions = {
  fetchEntityProfile: async ({ commit, dispatch }, entityId) => {
    const lazyCalls = [
      'fetchEntityContacts',
      'findEntityAdditionalInfo',
      'fetchEntityEmployees',
    ];
    lazyCalls.forEach((name) => dispatch(name, entityId));
    let result = null;
    try {
      const response = await EntityProfileApi.findEntityProfileInfoByEntityId(entityId);
      result = response?.data?.entityProfile || {};
      commit('setEntityProfile', result);
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    }
    return result;
  },

  findEntityAdditionalInfo: async ({ commit }, entityId) => {
    let result = null;
    try {
      const response = await EntityProfileApi.findEntityAdditionalInfo(entityId);
      result = response?.data || {};
      commit('setEntityProfileMemberships', result.publicMemberships.memberships);
      commit('setEntityProfileMembershipConditions', result.membershipConditions.membershipConditions);
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    }
    return result;
  },

  fetchEntityEmployees: async ({ commit }, entityId) => {
    if (!entityId) return null;
    commit('setEntityEmployeesLoading', true);
    let result = null;
    try {
      const res = await EntityProfileApi.fetchEntityEmployees({ entityId });
      result = res.data?.listProfileEmployeeRequests?.employeeRequests;
      commit('setEntityEmployees', result);
    } catch (e) {
      result = 1;
    } finally {
      commit('setEntityEmployeesLoading', false);
    }
    return result;
  },

  fetchEntityContacts: async ({ commit }, entityId) => {
    let result = null;
    try {
      const response = await EntityProfileApi.fetchEntityContacts(entityId);
      result = response?.data?.findEntityContactByEntityId || {};
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    } finally {
      commit('setEntityContacts', normalizeEntityContacts(result?.error ? {} : result));
    }
    return result;
  },

  createEntityContact: async ({ commit }, { entityId, payload }) => {
    let result = null;
    payload.entityId = Number(entityId); // To create contact we need to send entityId
    try {
      const response = await EntityProfileApi.createEntityContact(payload);
      result = response?.data?.createEntityContact || {};
      commit('setEntityContacts', normalizeEntityContacts(result));
    } catch (error) {
      result = ErrorHandler.parseFormErrors(error);
    }
    return result;
  },

  updateEntityContact: async ({ commit }, { payload }) => {
    let result = null;
    try {
      const response = await EntityProfileApi.updateEntityContact(payload);
      result = response?.data?.updateEntityContact || {};
      commit('setEntityContacts', normalizeEntityContacts(result));
    } catch (error) {
      result = ErrorHandler.parseFormErrors(error);
    }
    return result;
  },

  createOrUpdateContact: async ({ dispatch, getters }, { entityId, data }) => {
    let result;
    const payload = serializeEntityContacts(data);
    const isContactHasOwnId = Boolean(getters.getEntityContacts.contactId);
    if (isContactHasOwnId) {
      result = await dispatch('updateEntityContact', { payload });
      return result;
    }
    result = await dispatch('createEntityContact', { entityId, payload });
    return result;
  },
  updateEntityAvatar: async ({
    dispatch, getters, rootGetters, commit,
  }) => {
    let result = null;
    const { id } = getters.getEntityProfile;
    if (!id) return null;
    const serializedAvatar = await dispatch('mediaEntityAvatar/serializeFiles', DocumentType.EntityLogo, { root: true });
    const payload = {
      entityId: id,
      attachments: serializedAvatar,
    };
    try {
      result = await EntityProfileApi.updateEntityAvatar(payload);
      commit('setEntityProfileAvatar', rootGetters['mediaEntityAvatar/files'].find((e) => !e.destroy));
    } catch (error) {
      result = ErrorHandler.parseFormErrors(error);
    }
    return result;
  },
  prepareFormForUnifiedNumber700: async ({ rootGetters, dispatch }, entityId) => {
    const citiesForNumber700 = dispatch('fetchCitiesForIssuingUnifiedNumber700', {});
    const getCachedEntity = rootGetters['entities/getActiveEntity'];
    const currentEntityRes = getCachedEntity.id === entityId
      ? getCachedEntity
      : findEntity(entityId);
    const importantFetches = await Promise.all([
      citiesForNumber700,
      currentEntityRes,
    ]);
    const [, currentEntity] = importantFetches;
    const anyError = importantFetches.find((f) => f.error);
    if (anyError) return anyError;
    const unifiedNumber700 = currentEntity.unifiedNumber700 || null;
    if (unifiedNumber700) return { unifiedNumber700 };
    await dispatch('fetchEntityContacts', entityId);
    return {};
  },
  fetchCitiesForIssuingUnifiedNumber700: async ({ commit }, {
    name,
    page,
    size,
  }) => {
    try {
      const res = await EntityProfileApi.fetchCitiesForIssuingUnifiedNumber700({
        name,
        page,
        size,
      });
      const { cities } = res.data.listUnifiedCities;
      commit('setCitiesForIssuingUnifiedNumber700', cities);
      return res.data;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  issueUnifiedNumber700: async ({ getters, dispatch }, entityId) => {
    const { phone, email, telephone } = getters.getEntityContacts;
    const payload = {
      entityId,
      phoneNumber: PhoneFormatterAdapter.fromUIToServer(telephone),
      email,
      telephoneNumber: PhoneFormatterAdapter.fromUIToServer(phone),
      cityCode: getters.getSelectedCityIdForIssuingUnifiedNumber700,
    };
    try {
      const res = await EntityProfileApi.issueUnifiedNumber700(payload);
      dispatch('entities/fetchAllEntities', true, { root: true });
      return res.data?.issueUnifiedNumber700;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
