import DashboardLayout from '@/views/dashboard/DashboardLayout.vue';
import { DASHBOARD_URL } from '@/constants';

export default {
  path: '',
  name: 'DashboardLayout',
  component: DashboardLayout,
  children: [
    {
      path: 'user-space',
      name: 'UserSpace',
      beforeEnter: (to, from, next) => {
        if (DASHBOARD_URL) {
          window.location.href = DASHBOARD_URL;
        } else {
          next();
        }
      },

      component: () => import(
        /* webpackChunkName: "user-space" */
        '../views/dashboard/user-space-wrapper/UserSpaceWrapper.vue'
      ),
    },
    {
      path: 'create-unified-number',
      name: 'CreateUnifiedNumber',
      component: () => import(
        /* webpackChunkName: "user-space" */
        '../views/dashboard/create-unified-number/CreateUnifiedNumber.vue'
      ),
    },
    {
      path: 'create-unified-number-request/:id',
      name: 'CreateUnifiedNumberRequest',
      component: () => import(
        /* webpackChunkName: "user-space" */
        '../views/dashboard/create-unified-number/CreateUnifiedNumberRequest.vue'
      ),
    },
    {
      path: 'contact-information',
      name: 'ContactInformation',
      component: () => import(
        /* webpackChunkName: "user-space" */
        '../views/dashboard/process-unified-number/sections/contactInformationForm.vue'
      ),
    },
    {
      path: 'process-unified-number/:type/:requestId?/:flag?',
      name: 'ProcessUnifiedNumber',
      component: () => import(
        /* webpackChunkName: "user-space" */
        // '../views/dashboard/create-unified-number/CreateUnifiedNumber.vue'
        '../views/dashboard/process-unified-number/processUnifiedNumber.vue'
      ),
    },
    {
      path: 'upload-documents-unified-number/:unifiedNumber/:id',
      name: 'UploadDocuments',
      component: () => import(
        /* webpackChunkName: "unified-national-number-requests" */
        '../views/dashboard/process-unified-number/upload-documents/uploadDocuments.vue'
      ),
    },
    {
      path: 'unified-number-details/:requestId',
      name: 'UnifiedNumberDetails',
      component: () => import(
        /* webpackChunkName: "request-view-details" */
        '../views/dashboard/view-requests/unified-numbers-request-view-details/UnifiedNumbersRequestViewDetails.vue'
      ),
    },
    {
      path: 'user-profile',
      name: 'UserProfile',
      component: () => import(
        /* webpackChunkName: "profile-update" */
        '../views/profile/UserProfile.vue'
      ),
    },
    {
      path: 'entity-profile/:id',
      component: () => import(
        /* webpackChunkName: "entity-profile" */
        '../views/dashboard/entity-profile/EntityProfile.vue'
      ),
      children: [
        {
          path: '',
          redirect: { name: 'EntityMain' },
        },
        {
          path: 'main',
          name: 'EntityMain',
          component: () => import(
            /* webpackChunkName: "entity-main" */
            '../containers/entity-profile-tabs/main-tab/MainTab.vue'
          ),
          props: { theme: 'dashboard' },
        },
        {
          path: 'members',
          name: 'EntityMembers',
          component: () => import(
            /* webpackChunkName: "entity-members" */
            '../containers/entity-profile-tabs/members-tab/MembersTab.vue'
          ),
          props: { theme: 'dashboard' },
        },
        {
          path: 'employees',
          name: 'EntityEmployees',
          component: () => import(
            /* webpackChunkName: "entity-employees" */
            '../containers/entity-profile-tabs/employees-tab/EmployeesTab.vue'
          ),
          props: { theme: 'dashboard' },
        },
        {
          path: 'contacts',
          name: 'EntityContact',
          component: () => import(
            /* webpackChunkName: "entity-contact" */
            '../containers/entity-profile-tabs/contact-tab/ContactTab.vue'
          ),
          props: { theme: 'dashboard' },
        },
        {
          path: 'documents',
          name: 'EntityDocuments',
          component: () => import(
            /* webpackChunkName: "entity-documents" */
            '../containers/entity-profile-tabs/documents-tab/DocumentsTab.vue'
          ),
          props: { theme: 'dashboard' },
        },
      ],
    },
    {
      path: 'entity-establishment',
      name: 'EntityEstablishment',
      component: () => import(
        /* webpackChunkName: "entity-establishment" */
        '../views/dashboard/entity-establishment/EntityEstablishment.vue'
      ),
    },
    {
      path: 'create-family-fund',
      name: 'CrateFamilyFund',
      component: () => import(
        /* webpackChunkName: "create-family-fund" */
        '../views/dashboard/create-family-fund/CrateFamilyFund.vue'
      ),
    },
    {
      path: 'edit-family-fund/:entityId',
      name: 'EditFamilyFund',
      component: () => import(
        /* webpackChunkName: "create-family-fund" */
        '../views/dashboard/create-family-fund/CrateFamilyFund.vue'
      ),
    },
    {
      path: 'create-entity',
      name: 'CreateEntity',
      component: () => import(
        /* webpackChunkName: "create-entity" */
        '../views/dashboard/create-entity/CreateEntity.vue'
      ),
    },
    {
      path: 'edit-entity/:entityId',
      name: 'EditEntity',
      component: () => import(
        /* webpackChunkName: "create-entity" */
        '../views/dashboard/create-entity/CreateEntity.vue'
      ),
    },
    {
      path: 'members/:requestId',
      name: 'Members',
      component: () => import(
        /* webpackChunkName: "members-summary" */
        '../views/dashboard/memberships-summary/MembershipsSummary.vue'
      ),
    },
    {
      path: 'user-requests',
      name: 'UserRequests',
      redirect: { name: 'EntityUserRequests' },
      component: () => import(
        /* webpackChunkName: "user-requests" */
        '../views/dashboard/user-requests-new/UserRequests.vue'
      ),
      children: [
        {
          path: 'entity',
          name: 'EntityUserRequests',
          component: () => import(
            /* webpackChunkName: "entity-notifications" */
            '../views/dashboard/user-requests-new/user-requests-tables/EntityRequestsTable.vue'
          ),
        },
        {
          path: 'branch',
          name: 'BranchUserRequests',
          component: () => import(
            /* webpackChunkName: "branch-notifications" */
            '../views/dashboard/user-requests-new/user-requests-tables/BranchRequestsTable.vue'
          ),
        },
        {
          path: 'delegate',
          name: 'DelegateUserRequests',
          component: () => import(
            /* webpackChunkName: "delegate-notifications" */
            '../views/dashboard/user-requests-new/user-requests-tables/DelegateRequestsTable.vue'
          ),
        },
        {
          path: 'employee',
          name: 'EmployeeUserRequests',
          redirect: { name: 'ActualEmployeeUserRequests' },
          component: () => import(
            /* webpackChunkName: "employee-notifications" */
            '../views/dashboard/user-requests-new/user-requests-tables/EmployeeRequestsTable.vue'
          ),
          children: [
            {
              path: 'actual',
              name: 'ActualEmployeeUserRequests',
              component: () => import(
                /* webpackChunkName: "actual-employee-notifications" */
                '../views/dashboard/user-requests-new/user-requests-tables/EmployeeRequestsTable.vue'
              ),
            },
            {
              path: 'history',
              name: 'HistoryEmployeeUserRequests',
              component: () => import(
                /* webpackChunkName: "history-employee-notifications" */
                '../views/dashboard/user-requests-new/user-requests-tables/EmployeeRequestsTable.vue'
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'entity-request-details/:id',
      name: 'EntityRequestDetails',
      component: () => import(
        /* webpackChunkName: "entity-request-details" */
        '../views/dashboard/user-requests-details/entity-request-details/EntityRequestDetails.vue'
      ),
    },
    {
      path: 'delegate-request-details/:id',
      name: 'DelegateRequestDetails',
      component: () => import(
        /* webpackChunkName: "delegate-request-details" */
        '../views/dashboard/user-requests-details/delegate-request-details/DelegateRequestDetails.vue'
      ),
    },
    {
      path: 'employee-request-details/:id',
      name: 'EmployeeRequestDetails',
      component: () => import(
        /* webpackChunkName: "employee-request-details" */
        '../views/dashboard/user-requests-details/employee-request-details/EmployeeRequestDetails.vue'
      ),
    },
    {
      path: 'join-member/:unitType/:id/:entityId',
      name: 'JoinMember',
      component: () => import(
        /* webpackChunkName: "join-member" */
        '../views/dashboard/join-member/JoinMember.vue'
      ),
    },
    {
      path: 'join-employee/:requestId/:entityId',
      name: 'JoinEmployee',
      component: () => import(
        /* webpackChunkName: "join-employee" */
        '../views/dashboard/join-member/JoinEmployeeMember.vue'
      ),
    },
    {
      path: 'documents/:id',
      name: 'Documents',
      component: () => import(
        /* webpackChunkName: "documents" */
        '../views/dashboard/documents/documents/Documents.vue'
      ),
    },
    {
      path: 'branch-documents/:id',
      name: 'BranchDocuments',
      component: () => import(
        /* webpackChunkName: "branch-documents" */
        '../views/dashboard/documents/branch-documents/BranchDocuments.vue'
      ),
    },
    {
      path: 'activity-document/:id/:activityId',
      name: 'ApprovalActivityDocument',
      component: () => import(
        /* webpackChunkName: "activity-document" */
        '../views/dashboard/documents/activity-document/ApprovalDocument.vue'
      ),
    },
    {
      path: 'bank-accounts/:id/:type',
      name: 'ListBankAccounts',
      redirect: { name: 'MainBankAccounts' },
      component: () => import(
        /* webpackChunkName: "bank-accounts" */
        '../views/dashboard/bank-accounts/BankAccounts.vue'
      ),
      children: [
        {
          path: 'main',
          name: 'MainBankAccounts',
          component: () => import(
            /* webpackChunkName: "list-bank-accounts" */
            '../views/dashboard/bank-accounts/bank-table/BanksTable.vue'
          ),
        },
        {
          path: 'secondary',
          name: 'SecondaryBankAccounts',
          component: () => import(
            /* webpackChunkName: "list-bank-accounts" */
            '../views/dashboard/bank-accounts/bank-table/BanksTable.vue'
          ),
        },
        {
          path: 'expense',
          name: 'ExpenseBankAccounts',
          component: () => import(
            /* webpackChunkName: "list-bank-accounts" */
            '../views/dashboard/bank-accounts/bank-table/BanksTable.vue'
          ),
        },
      ],
    },
    {
      path: 'bank-accounts/:id/:type/:bankId/edit',
      name: 'EditBankAccountVerification',
      component: () => import(
        /* webpackChunkName: "bank-verification" */
        '../views/dashboard/bank-accounts/bank-verification/BankVerification.vue'
      ),
    },
    {
      path: 'bank-accounts/:id/:type/:bankType/:unifiedNumber?/create',
      name: 'BankAccountVerification',
      component: () => import(
        /* webpackChunkName: "bank-verification" */
        '../views/dashboard/bank-accounts/bank-verification/BankVerification.vue'
      ),
    },
    {
      path: 'bank-accounts/:id/:type/:bankId/reactivate',
      name: 'BankAccountReactivate',
      component: () => import(
        /* webpackChunkName: "bank-reactivate" */
        '../views/dashboard/bank-accounts/bank-reactivate/BankReactivate.vue'
      ),
    },
    {
      path: 'request-view-details/:requestId',
      name: 'RequestViewDetails',
      component: () => import(
        /* webpackChunkName: "request-view-details" */
        '../views/dashboard/view-requests/request-view-details/RequestViewDetails.vue'
      ),
    },
    {
      path: 'temporary-bank-account/:entityId',
      name: 'TemporaryBankAccount',
      component: () => import(
        /* webpackChunkName: "temporary-bank-account" */
        '../views/dashboard/temporary-bank-account/TemporaryBankAccount.vue'
      ),
    },
    {
      path: 'close-bank-account/:entityId',
      name: 'CloseBankAccount',
      component: () => import(
        /* webpackChunkName: "close-bank-account" */
        '../views/dashboard/close-bank-account/CloseBankAccount.vue'
      ),
    },
    {
      path: 'entity',
      component: () => import(
        /* webpackChunkName: "entity" */
        '../views/dashboard/entity-service/EntityService.vue'
      ),
      children: [
        {
          path: '',
          redirect: 'my-requests',
        },
        {
          path: 'my-requests',
          name: 'ServiceRequests',
          component: () => import(
            /* webpackChunkName: "my-requests" */
            '../views/dashboard/entity-service/service-requests/ServiceRequests.vue'
          ),
        },
        // hidden by PO request
        // {
        //   path: 'notifications',
        //   name: 'Notifications',
        //   component: () => import(
        //     /* webpackChunkName: "notifications" */
        //     '../views/dashboard/entity-service/service-notifications/ServiceNotifications.vue'
        //   ),
        // },
        {
          path: 'member-requests',
          name: 'MemberRequests',
          component: () => import(
            /* webpackChunkName: "member-requests" */
            '../views/dashboard/entity-service/service-member-requests/ServiceMemberRequests.vue'
          ),
        },
        {
          path: 'unified-national-number-requests',
          name: 'UnifiedNationalNumberRequests',
          component: () => import(
            /* webpackChunkName: "unified-national-number-requests" */
            '../views/dashboard/entity-service/service-unified-national-number-request/ServiceUnifiedNationalNumberRequests.vue'
          ),
        },
      ],
    },
    {
      path: 'edit-entity-policy-adjustments/:editRequestId',
      name: 'EditEntityPolicyAdjustments',
      component: () => import(
        /* webpackChunkName: "edit-entity-policy-adjustments" */
        '../views/dashboard/edit-entity-policy-adjustments/EditEntityPolicyAdjustments.vue'
      ),
    },
    {
      path: 'member-invitation/:userRequestId',
      name: 'MemberInvitation',
      component: () => import(
        /* webpackChunkName: "member-invitation" */
        '../views/dashboard/member-invitation/MemberInvitation.vue'
      ),
    },
    {
      path: 'questions/:entityId',
      name: 'Questions',
      component: () => import(
        /* webpackChunkName: "questions" */
        '../views/dashboard/questions/Questions.vue'
      ),
    },
    {
      path: 'edit-request/:entityId',
      name: 'EditRequest',
      component: () => import(
        /* webpackChunkName: "edit-request" */
        '../views/dashboard/edit-request/EditRequest.vue'
      ),
    },
    {
      path: 'delegate-management/:id',
      name: 'DelegateManagement',
      component: () => import(
        /* webpackChunkName: "delegate-management" */
        '../views/dashboard/delegate-management/DelegateManagement.vue'
      ),
      children: [
        {
          path: 'requests',
          name: 'DelegateRequests',
          component: () => import(
            /* webpackChunkName: "delegate-requests" */
            '../views/dashboard/delegate-management/delegate-tables/RequestsDelegateTable.vue'
          ),
        },
        {
          path: 'invitations',
          name: 'InvitationsDelegate',
          component: () => import(
            /* webpackChunkName: "delegate-invitations" */
            '../views/dashboard/delegate-management/delegate-tables/InvitationDelegateTable.vue'
          ),
        },
        {
          path: 'previous',
          name: 'PreviousDelegate',
          component: () => import(
            /* webpackChunkName: "delegate-previous" */
            '../views/dashboard/delegate-management/delegate-tables/PreviousDelegateTable.vue'
          ),
        },
      ],
    },
    {
      path: 'create-delegate/:entityId',
      name: 'CreateDelegate',
      component: () => import(
        /* webpackChunkName: "create-delegate" */
        '../views/dashboard/delegate-management/create-delegate-manager/CreateDelegateManager.vue'
      ),
    },
    {
      path: 'delegate-details-request/:entityId/:membershipRequestId',
      name: 'DelegateMembershipRequestDetails',
      component: () => import(
        /* webpackChunkName: "delegate-details" */
        '../views/dashboard/delegate-management/delegate-details/DelegateDetails.vue'
      ),
    },
    {
      path: 'delegate-details/:entityId/:membershipId',
      name: 'DelegateMembershipDetails',
      component: () => import(
        /* webpackChunkName: "delegate-details" */
        '../views/dashboard/delegate-management/delegate-details/DelegateDetails.vue'
      ),
    },
  ],
};
