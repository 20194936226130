import { GraphQLRequest } from '@/setup/axios';
import {
  FETCH_LIST_OF_BRANCHES_BY_ENTITY_ID,
  FIND_BRANCH_BY_BRANCH_ID,
} from '@/api/graphql/branch-gql';
import { RequestStatuses } from '@/constants/enums';

export const fetchListOfBranchesByEntityId = ({
  entityId,
  statuses,
  page,
  size,
}) => (
  GraphQLRequest({
    query: FETCH_LIST_OF_BRANCHES_BY_ENTITY_ID,
    variables: {
      page: Number(page || 1),
      size: Number(size || 100),
      entityId: Number(entityId),
      status: (
        Array.isArray(statuses)
          && statuses.every((s) => Object.values(RequestStatuses).includes(s))
      ) ? statuses : [],
    },
  })
);

export const findBranch = (branchId) => (
  GraphQLRequest({
    query: FIND_BRANCH_BY_BRANCH_ID,
    variables: {
      branchId: Number(branchId),
    },
  })
);
