import { Gateway } from '@/setup/axios';

class LookupApi {
  getClassifications = (type, id) => {
    const classificationIdParam = id ? `&classification_id=${id}` : '';
    return Gateway.get(`static_data/classifications?type=${type}${classificationIdParam}`);
  };

  getClassificationsDetails = (ids, config = {}) => (
    Gateway.get(`static_data/classifications/details?second_sub_classification_ids=${ids}`, config)
  );

  findClassification = (id) => (
    Gateway.get(`static_data/classifications/${id}`)
  );

  getActivities = (classificationId, ids, config = {}) => {
    const idParam = ids ? `&ids=${ids}` : '';
    return Gateway.get(`static_data/activities?classification_id=${classificationId}${idParam}`, config);
  };

  getGoals = (classificationId, ids, type, config = {}) => {
    const typeParam = type ? `&entity_type=${type}` : '';
    const idParam = ids ? `&ids=${ids}` : '';
    return Gateway.get(`static_data/goals?classification_id=${classificationId}${typeParam}${idParam}`, config);
  };
}

export default new LookupApi();
