import { Gateway } from '@/setup/axios';

class AuthApi {
  getIndividualInfo = (nid, dob) => {
    const dobParam = dob ? `&birth_date_hijri=${dob}` : '';
    return Gateway.get(`individual/info?national_id=${nid}${dobParam}`);
  }

  loginIndividual = (data) => (
    Gateway.post('individual/login', data, { clearCacheEntry: true })
  )

  loginIndividualByOAuthCode = (data) => (
    Gateway.post('individual/authorize', data, { clearCacheEntry: true })
  )

  checkLoginOtp = (data) => (
    Gateway.post('individual/login-otp', data)
  )

  logoutIndividual = (data) => (
    Gateway.post('individual/log-out', data, { clearCacheEntry: true })
  )

  registerIndividual = (data) => (
    Gateway.post('registration/individual', data)
  )

  checkRegistration = (data) => (
    Gateway.post('registration/check-nid', data)
  )

  checkRegistrationOtp = (data) => (
    Gateway.post('registration/check-nic-otp', data)
  )

  verifyPhone = (data) => (
    Gateway.post('registration/verify-phone', data)
  )

  verifyPhoneOtp = (data) => (
    Gateway.post('registration/phone-otp', data)
  )

  invitationsData = (token) => (
    Gateway.get(`invitations/${token}`)
  )

  checkPasswordLogin = (data) => (
    Gateway.post('reset-password/check-nid', data)
  )

  checkPasswordOtp = (data) => (
    Gateway.post('reset-password/check-nid-otp', data)
  )

  updatePassword = (data) => (
    Gateway.post('accounts/change-password', data)
  )
}

export default new AuthApi();
