import { getField, updateField } from 'vuex-map-fields';
import { v4 as uuid } from 'uuid';
import ErrorHandler from '@/helpers/errors';
import EntityRelationsApi from '@/api/entity-relations.api';
import { UnitTypes } from '@/constants/enums';

const mainState = {
  leaveBankStep: false,
  bankLoading: false,
  banks: [],
};

const mainGetters = {
  getField,
  bankLoading: (store) => store.bankLoading,
  leaveBankStep: (store) => store.leaveBankStep,
  banks: (store) => store.banks,
  hasBank: (store) => store.banks.some((b) => b.bankId),
};

const mutations = {
  updateField,
  resetBanks: (store) => {
    store.banks = [];
    store.leaveBankStep = false;
  },
  setLeaveBankStep: (store, value) => {
    store.leaveBankStep = value;
  },
  setBankLoading: (store, value) => {
    store.bankLoading = value;
  },
  addBankToList: (store, { id, bank }) => {
    const currentBank = store.banks.find((b) => b.id === id) || {};
    currentBank.id = bank.id;
    currentBank.bankId = bank.bankId;
    currentBank.branchName = bank.bankBranchName;
  },
  addEmptyBank: (store) => {
    store.banks.push({
      bankId: '',
      id: '',
      feId: uuid(),
      branchName: '',
    });
  },
  setBanks: (store, banksList = []) => {
    if (!Array.isArray(banksList)) {
      store.banks = [];
      return;
    }
    store.banks = banksList.map((item) => ({
      branchName: item.bankBranchName,
      bankId: item.bankId,
      id: item.id,
    }));
  },
  removeBankById: (store, storedBank) => {
    if (!storedBank.id) {
      // if ID not present that's mean that this bank was not stored in database
      // and was created just on the user side, but has not been saved in DB yet
      store.banks = store.banks.filter((b) => b.feId !== storedBank.feId);
      return;
    }
    store.banks = store.banks.filter((b) => b.id !== storedBank.id);
  },
};

const actions = {
  listEntityBanks: async ({ commit }, id) => {
    try {
      const res = await EntityRelationsApi.listEntityBanks(id, UnitTypes.Entity);
      commit('setBanks', res.data?.entitiesBanks);
      return res.data?.entitiesBanks;
    } catch (e) {
      return { error: e.message };
    }
  },
  createEntityBank: async ({ dispatch, commit }, { data, id }) => {
    try {
      commit('setBankLoading', true);
      const res = await EntityRelationsApi.createEntityBank(data);
      commit('addBankToList', { bank: res.data?.createEntitiesBank, id });
    } catch (e) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    } finally {
      commit('setBankLoading', false);
    }
  },
  updateEntityBank: async ({ dispatch, commit }, data) => {
    try {
      commit('setBankLoading', true);
      await EntityRelationsApi.updateEntityBank(data);
    } catch (e) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    } finally {
      commit('setBankLoading', false);
    }
  },
  deleteEntityBank: async ({ commit, dispatch }, bank) => {
    try {
      if (bank?.id) {
        await EntityRelationsApi.deleteEntityBank(bank.id);
      }
      commit('removeBankById', bank);
    } catch (e) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
