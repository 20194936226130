import UserRequestsApi from '@/api/user-requests.api';
import {
  MembersReplacementTypes,
  RequestStatuses,
  RequestTabs, RequestTypes,
  SecondApprovalTypes,
  UserRequestStatuses,
} from '@/constants/enums';
import { handleUserRequestList } from '@/services/user-requests.service';
import { deserializer } from '@/helpers/api';
import ErrorHandler from '@/helpers/errors';
import RequestApi from '@/api/request.api';
import { formatDate } from '@/helpers/date';

const getDeleteStatus = (requestStatus) => {
  switch (requestStatus) {
    case RequestStatuses.Draft:
      return true;
    case RequestStatuses.MemberApproval:
      return false;
    case RequestStatuses.MemberAdjustments:
      return false;
    case RequestStatuses.SupervisoryApproval:
      return false;
    case RequestStatuses.SupervisoryAdjustments:
      return false;
    case RequestStatuses.Accepted:
      return false;
    case RequestStatuses.Rejected:
      return false;
    default:
      return false;
  }
};

const outcomeArray = [RequestStatuses.Accepted, RequestStatuses.Rejected];

const setStatus = (sourceArray, requestStatus) => sourceArray.map((status) => {
  if ((outcomeArray.includes(requestStatus)
    && status.title === RequestStatuses.RequestResults)
    || status.title === requestStatus) {
    return {
      ...status,
      active: true,
    };
  }
  return {
    ...status,
    active: false,
  };
});

const EntityEstablishment = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.AdminsApproval,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const CooperativeAssociation = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.SupervisoryApproval,
    active: false,
    duration: '15',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const PrivateAssociation = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.SupervisoryApproval,
    active: false,
    duration: '15',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const PrivateCorporation = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.SupervisoryApproval,
    active: false,
    duration: '15',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const setTitle = (sourceArray, requestStatus) => sourceArray.map((status) => {
  if (status.title === RequestStatuses.RequestResults && outcomeArray.includes(requestStatus)) {
    return {
      ...status,
      title: requestStatus,
    };
  }
  return {
    ...status,
    title: status.title,
  };
});

const getStatuses = (requestType, requestStatus) => {
  if (requestType === RequestTypes.EntityEstablishment) {
    const status = setStatus(EntityEstablishment, requestStatus);
    return setTitle(status, requestStatus);
  }
  if (requestType === RequestTypes.BranchCooperativeAssociation) {
    const status = setStatus(CooperativeAssociation, requestStatus);
    return setTitle(status, requestStatus);
  }
  if (requestType === RequestTypes.BranchPrivateAssociation) {
    const status = setStatus(PrivateAssociation, requestStatus);
    return setTitle(status, requestStatus);
  }
  if (requestType === RequestTypes.BranchPrivateCorporation) {
    const status = setStatus(PrivateCorporation, requestStatus);
    return setTitle(status, requestStatus);
  }
  return [];
};

const handleEntityRequests = (data) => data.map((request) => ({
  entityType: request.type,
  requestType: request.requestType,
  parentId: request.entityId,
  actionExpectation: request.actionExpectation,
  remainingData: request.remainingData,
  nameArabic: request.nameArabic,
  nameEnglish: request.nameEnglish || request.nameArabic,
  parentNameArabic: request.parentNameArabic,
  parentNameEnglish: request.parentNameEnglish,
  createdAt: formatDate(request.createdAt),
  trackAction: true,
  unitId: request.id,
  isAdjustment: request.supervisoryChanges,
  loading: false,
  id: request.id,
  deleteAction: getDeleteStatus(request.status),
  viewAction: true,
  showInfo: false,
  supervisoryChanges: request?.entityMultiStatus?.supervisoryChanges,
  status: request.status || RequestStatuses.Draft,
  membersReplacementType: request?.entityMultiStatus?.membersReplacementType
    || MembersReplacementTypes.None,
  secondApprovalType: request?.entityMultiStatus?.secondApprovalType || SecondApprovalTypes.None,
  statuses: getStatuses(request.requestType, request.status),
}));

const mainState = {
  notifications: [],
  notificationsCount: null,
  notificationsErrors: null,
  notificationLoading: false,
  requests: [],
  requestsLoading: false,
  requestsError: [],
  memberRequests: [],
  memberRequestsLoading: false,
};

const mainGetters = {
  notifications: (store) => store.notifications,
  notificationsCount: (store) => store.notificationsCount,
  notificationsErrors: (store) => store.notificationsErrors,
  notificationLoading: (store) => store.notificationLoading,
  requests: (store) => store.requests,
  requestsLoading: (store) => store.requestsLoading,
  requestsError: (store) => store.requestsError,
  memberRequests: (store) => store.memberRequests,
  memberRequestsLoading: (store) => store.memberRequestsLoading,
};

const mutations = {
  setNotifications: (store, notifications) => {
    store.notifications = notifications;
  },
  setNotificationLoading: (store, value) => {
    store.notificationLoading = value;
  },
  setNotificationsCount: (store, value) => {
    store.notificationsCount = value;
  },
  setVisibleInfo: (store, id) => {
    store.notifications = store.notifications.map(
      (m) => ({ ...m, showInfo: m.id === id ? !m.showInfo : false }),
    );
  },
  setRequestsLoading: (store, value) => {
    store.requestsLoading = value;
  },
  setVisibleInfoRequests: (store, id) => {
    store.requests = store.requests.map(
      (m) => ({ ...m, showInfo: m.id === id ? !m.showInfo : false }),
    );
  },
  setCopyLoading: (store, { id, value }) => {
    store.requests = store.requests.map(
      (r) => ({ ...r, loading: r.id === id ? value : false }),
    );
  },
  setDeleteLoading: (store, { id, value }) => {
    store.requests = store.requests.map(
      (r) => ({ ...r, loading: r.id === id ? value : false }),
    );
  },
  hideAllRequestInfo: (store) => {
    store.requests = store.requests.map(
      (m) => ({ ...m, showInfo: false }),
    );
  },
  setRequests: (store, requests) => {
    store.requests = requests;
  },
  setRequestsError: (store, requestsError) => {
    store.requestsError = requestsError;
  },
  setMemberRequests: (store, memberRequests) => {
    store.memberRequests = memberRequests;
  },
  setVisibleMemberRequestsInfo: (store, id) => {
    store.memberRequests = store.memberRequests.map(
      (m) => ({ ...m, showInfo: m.id === id ? !m.showInfo : false }),
    );
  },
  setMemberRequestsLoading: (store, value) => {
    store.memberRequestsLoading = value;
  },
  hideAllMemberRequestsInfo: (store) => {
    store.memberRequests = store.memberRequests.map(
      (m) => ({ ...m, showInfo: false }),
    );
  },
};

const actions = {
  getNotificationsCount: async ({ commit }) => {
    const payload = {
      page: 1,
      size: 1,
      unitType: RequestTabs.Entity,
    };
    try {
      const res = await UserRequestsApi.getUserRequestsGql(payload);
      const { meta } = res?.data?.userRequests;
      commit('setNotificationsCount', meta.recordsCount);
    } catch (e) {
      console.warn(e);
    }
  },
  getNotificationsList: async ({ commit, rootGetters }) => {
    commit('setNotificationLoading', true);
    const payload = {
      page: rootGetters['notificationsPagination/selectedPage'] || 1,
      size: rootGetters['notificationsPagination/selectedSize'],
      unitType: RequestTabs.Entity,
    };
    try {
      const res = await UserRequestsApi.getUserRequestsGql(payload);
      const { meta, userRequests } = res?.data?.userRequests;
      commit('setNotifications', handleUserRequestList(userRequests));
      commit('setNotificationsCount', meta.recordsCount);
      commit('notificationsPagination/setTotal', { pages: meta.pageCount, records: meta.pageCount }, { root: true });
      return handleUserRequestList(userRequests);
    } catch (e) {
      console.warn(e);
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setNotificationLoading', false);
    }
  },
  deleteRequest: async ({ dispatch, commit }, { requestId, establishmentType }) => {
    commit('setDeleteLoading', { id: requestId, value: true });
    try {
      const res = await RequestApi.deleteRequest(requestId);
      if (res.data.error) {
        return { error: res.data.error };
      }
      await dispatch('getEntityRequests', {
        useLoading: false,
        establishmentType,
      });
      return res;
    } catch (e) {
      console.warn(e);
      return { error: e.message };
    } finally {
      commit('setDeleteLoading', { id: requestId, value: false });
    }
  },
  checkRejectMsg: async (_, { unitId, unitType }) => {
    try {
      const res = await RequestApi.getRejectReason(unitId, unitType);
      const data = await deserializer(res.data) || [];
      const rejectMsg = data.map((item) => item.message);
      const rejectedBy = data[0]?.adminRole;
      return { rejectMsg, rejectedBy };
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  getEntityRequests: async ({ commit, rootGetters }, useLoading = true) => {
    commit('setRequestsError', null);
    if (useLoading) commit('setRequestsLoading', true);
    try {
      const res = await RequestApi.getEntityRequests({
        page: rootGetters['requestsPagination/selectedPage'],
        size: rootGetters['requestsPagination/selectedSize'],
        withAcceptedNamaEntities: true,
        config: { clearCacheEntry: true },
      });
      if (res.data.error) {
        commit('setRequests', []);
        return { error: res.data.error };
      }
      const entities = res?.data?.entities;
      const data = entities?.entities || [];
      commit('requestsPagination/setTotal', {
        records: entities?.meta?.recordsCount,
        pages: entities?.meta?.pageCount,
      }, { root: true });
      commit('setRequests', handleEntityRequests(data));
      return handleEntityRequests(data);
    } catch (e) {
      console.warn(e);
      commit('setRequestsError', e.response.status);
      return { error: e.message };
    } finally {
      commit('setRequestsLoading', false);
    }
  },
  getMemberRequestsList: async ({ commit, rootGetters }) => {
    commit('setMemberRequestsLoading', true);
    const payload = {
      page: rootGetters['memberRequestsPagination/selectedPage'] || 1,
      size: rootGetters['memberRequestsPagination/selectedSize'],
      unitType: RequestTabs.Entity,
      status: [
        UserRequestStatuses.AcceptedByUser,
        UserRequestStatuses.AcceptedBySpecialized,
        UserRequestStatuses.RejectedBySpecialized,
        UserRequestStatuses.FinalRejected,
        UserRequestStatuses.Canceled,
      ],
    };
    try {
      const res = await UserRequestsApi.getUserRequestsGql(payload);
      const { meta, userRequests } = res?.data?.userRequests;
      commit('setMemberRequests', handleUserRequestList(userRequests));
      commit('memberRequestsPagination/setTotal', { pages: meta.pageCount, records: meta.pageCount }, { root: true });
      return handleUserRequestList(userRequests);
    } catch (e) {
      console.warn(e);
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setMemberRequestsLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
