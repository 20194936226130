export const INIT_OTP = `mutation InitPhoneOtp($initPhoneOtpAttributes: AuthInitPhoneOtpInput!) {
  initPhoneOtp(initPhoneOtpAttributes: $initPhoneOtpAttributes) {
    otpToken
  }
}`;

export const UPDATE_PHONE_EMAIL = `mutation UpdatePhoneAndEmail($accountEmailPhoneAttributes: AccountEmailPhoneInput!) {
  updatePhoneAndEmail(accountEmailPhoneAttributes: $accountEmailPhoneAttributes) {
    id
    phone
    email
  }
}`;

export const UPDATE_USER = `mutation updateUser($userUpdateAttributes: UserUpdateInput!){
  updateUser(userUpdateAttributes: $userUpdateAttributes) {
    id
  }
}`;
