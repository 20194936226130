<template>
  <div
    class="menu-wrap"
    :class="{open: isMenuOpen}"
  >
    <button
      type="button"
      class="close-menu"
      @click="closeMenu"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="37.5" height="37.5" viewBox="0 0 37.5 37.5" v-bind:svg-inline="''" v-bind:width="'38'" v-bind:height="'38'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="Group 742"><g data-name="Group 741" fill="none" stroke="#161616" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="translate(-3046.772 -4390.223)"><circle data-name="Ellipse 96" cx="18" cy="18" r="18" transform="translate(3047.522 4390.973)"/><path data-name="Line 380" d="M3058.815 4402.265l13.415 13.415"/></g></g><g data-name="close"><g data-name="Group 743"><path data-name="Line 381" fill="none" stroke="#161616" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.043 25.457l13.415-13.415"/></g></g></svg>
    </button>
    <div
      class="menu-nav"
    >
      <nav-list
        class="menu-nav-list"
        item-class-name="menu-nav-list-item"
        @itemClick="closeMenu"
      />
    </div>
  </div>
</template>

<script>
import NavList from '@/views/landing/nav-list/NavList.vue';

export default {
  name: 'LandingMenu',
  components: { NavList },
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeMenu() {
      this.$emit('closeMenu', true);
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
