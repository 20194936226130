import { handleDOB } from '@/helpers/general';
// eslint-disable-next-line import/no-cycle
import { normalizeDisclosureForm } from '@/services/user-requests.service';
import ErrorHandler from '@/helpers/errors';
import MembershipApi from '@/api/membership.api';
import { MembershipEntityTypes, MemberTypes, UserRequestPayloadType } from '@/constants/enums';
import UserRequestsApi from '@/api/user-requests.api';
import { PhoneFormatterAdapter } from '@takamol/nawa-library/src/helpers/phone-formatter';

export const handlePositionTranslations = (translation = {}) => ({
  ar: translation?.arTitle,
  en: translation?.enTitle,
});

export const getFullName = (member) => {
  const firstName = member?.firstName ? `${member?.firstName} ` : member?.firstName;
  const fatherName = member?.fatherName ? `${member?.fatherName} ` : member?.fatherName;
  const grandfatherName = member?.grandfatherName ? `${member?.grandfatherName} ` : member?.grandfatherName;
  const lastName = member?.lastName;
  return `${firstName || ''}${fatherName || ''}${grandfatherName || ''}${lastName || ''}`;
};

export const normalizeMembership = (membership = {}) => ({
  id: membership.id,
  userId: membership.userId,
  memberId: membership.memberId,
  memberType: membership.memberType,
  customPosition: membership.customPosition,
  isSpecialized: membership.specialized,
  title: membership.member?.account?.title,
  numberOfShares: membership.numberOfShares,
  role: membership.role || MemberTypes.Individual,
  position: membership.positionUid,
  financeContributor: Boolean(membership.financeContributionAmount),
  financeAmount: membership.financeContributionAmount,
  positionTranslations: handlePositionTranslations(membership.position),
  birthDateHijri: handleDOB(membership.member?.birthDateHijri),
  phone: membership?.absherPhone
    || membership.member?.absherPhone
    || membership.member?.phone,
  nationalId: membership.member?.nationalId,
  fullName: getFullName(membership.member),
  firstName: membership.member?.firstName || '',
  lastName: membership.member?.lastName || '',
  fatherName: membership.member?.fatherName || '',
  grandfatherName: membership.member?.grandfatherName || '',
  email: membership?.email,
  nationalAddress: membership.member?.nationalAddress,
  loading: false,
  governmental: membership.governmental,
  delegatorProof: membership.delegatorProof,
  foundingMember: membership.foundingMember,
  status: membership.status,
  familyRelationUid: membership?.familyRelation?.uid,
  familyRelation: membership?.familyRelation,
  disclosureForm: normalizeDisclosureForm(membership?.disclosureForm),
  disclosureFormId: membership?.disclosureForm?.id,
  attachments: membership?.attachments || [],
});

export const normalizeDelegateMembership = (delegatorProof = {}) => ({
  delegateId: delegatorProof?.id,
  entityName: delegatorProof?.entityName,
  proofType: delegatorProof?.nonGovernmentalProof?.proofType
    || delegatorProof?.proofType,
  proofValue: delegatorProof?.nonGovernmentalProof?.proofValue
    || delegatorProof?.proofValue,
  commercialRegistrationNumber: delegatorProof?.nonGovernmentalProof
    ?.commercialRegistrationNumberProofs || [],
  nonProfitOrganizationLicense: delegatorProof?.nonGovernmentalProof
    ?.nonProfitOrganizationLicenseProofs || [],
  endowmentDeedNumber: delegatorProof?.nonGovernmentalProof
    ?.endowmentDeedNumberProofs || [],
  gosi: delegatorProof?.nonGovernmentalProof?.gosiNumberProofs || [],
  number700: delegatorProof?.nonGovernmentalProof?.facilityRecordNumberProofs || [],
  certificateNumber: delegatorProof?.nonGovernmentalProof?.zakatCertificateNumberProofs || [],
  organizationName: delegatorProof?.organizationName,
  organizationNumber: delegatorProof?.organizationNumber,
  generalAssociationMinutes: delegatorProof?.nonGovernmentalProof
    ?.associationMinutesProofs || [],
});

export const serializeMembership = (membership, unitId, unitType, memberMode) => {
  const {
    nationalId,
    position,
    birthDateHijri,
    phone,
    isSpecialized,
    numberOfShares,
    customPosition,
    financeContributor,
    financeAmount,
    role,
    positionUid,
    governmental,
    foundingMember,
  } = membership;
  const year = birthDateHijri?.year || '';
  const month = birthDateHijri?.month || '';
  const day = birthDateHijri?.day || '';
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    nationalId,
    customPosition,
    foundingMember: foundingMember || false,
    governmental: governmental || false,
    specialized: isSpecialized || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
    absherPhone: phone ? PhoneFormatterAdapter.fromUIToServer(phone) : '',
    birthDateHijri: `${year}${month}${day}`,
    numberOfShares: Number(numberOfShares) || 0,
    contributeFinances: financeContributor,
    financeContributionAmount: financeAmount || 0,
  };
};

export const getBranchPayload = ({
  form, unitId, unitType, memberMode,
}) => {
  const {
    nationalId,
    position,
    birthDateHijri,
    phone,
    isSpecialized,
    customPosition,
    role,
    positionUid,
    foundingMember,
  } = form;
  const year = birthDateHijri?.year || '';
  const month = birthDateHijri?.month || '';
  const day = birthDateHijri?.day || '';
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    nationalId,
    customPosition,
    foundingMember: foundingMember || false,
    specialized: isSpecialized || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
    absherPhone: phone ? PhoneFormatterAdapter.fromUIToServer(phone) : '',
    birthDateHijri: `${year}${month}${day}`,
  };
};

export const getEntityCooperativeAssociationUpdatePayload = ({
  form, unitId, unitType, memberMode,
}) => {
  const {
    position,
    positionUid,
    numberOfShares,
    email,
    role,
    governmental,
    isSpecialized,
  } = form;
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    email,
    governmental: governmental || false,
    specialized: isSpecialized || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
    numberOfShares: Number(numberOfShares) || 0,
  };
};

export const getEntityCooperativeAssociationCreatePayload = ({
  form, unitId, unitType, memberMode,
}) => {
  const {
    nationalId,
    birthDateHijri,
    phone,
    position,
    positionUid,
    numberOfShares,
    email,
    role,
    governmental,
    isSpecialized,
  } = form;
  const year = birthDateHijri?.year || '';
  const month = birthDateHijri?.month || '';
  const day = birthDateHijri?.day || '';
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    email,
    nationalId,
    absherPhone: phone ? PhoneFormatterAdapter.fromUIToServer(phone) : '',
    birthDateHijri: `${year}${month}${day}`,
    governmental: governmental || false,
    specialized: isSpecialized || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
    numberOfShares: Number(numberOfShares) || 0,
  };
};

export const getEntityPrivateAssociationUpdatePayload = ({
  form, unitId, unitType, memberMode,
}) => {
  const {
    position,
    positionUid,
    email,
    role,
    governmental,
    isSpecialized,
    financeAmount,
  } = form;
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    email,
    governmental: governmental || false,
    specialized: isSpecialized || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
    contributeFinances: false,
    financeContributionAmount: 0 || financeAmount,
  };
};

export const getEntityPrivateAssociationCreatePayload = ({
  form, unitId, unitType, memberMode,
}) => {
  const {
    nationalId,
    birthDateHijri,
    phone,
    position,
    positionUid,
    email,
    role,
    governmental,
    isSpecialized,
    financeAmount,
  } = form;
  const year = birthDateHijri?.year || '';
  const month = birthDateHijri?.month || '';
  const day = birthDateHijri?.day || '';
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    email,
    nationalId,
    absherPhone: phone ? PhoneFormatterAdapter.fromUIToServer(phone) : '',
    birthDateHijri: `${year}${month}${day}`,
    governmental: governmental || false,
    specialized: isSpecialized || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
    contributeFinances: false,
    financeContributionAmount: 0 || financeAmount,
  };
};

export const getEntityPrivateCorporationUpdatePayload = ({
  form, unitId, unitType, memberMode,
}) => {
  const {
    position,
    positionUid,
    email,
    role,
    governmental,
    foundingMember,
  } = form;
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    email,
    governmental: governmental || false,
    foundingMember: foundingMember || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
  };
};

export const getEntityPrivateCorporationCreatePayload = ({
  form, unitId, unitType, memberMode,
}) => {
  const {
    nationalId,
    birthDateHijri,
    phone,
    position,
    positionUid,
    email,
    role,
    governmental,
    foundingMember,
  } = form;
  const year = birthDateHijri?.year || '';
  const month = birthDateHijri?.month || '';
  const day = birthDateHijri?.day || '';
  return {
    unitId: Number(unitId),
    unitType,
    memberMode,
    email,
    nationalId,
    absherPhone: phone ? PhoneFormatterAdapter.fromUIToServer(phone) : '',
    birthDateHijri: `${year}${month}${day}`,
    governmental: governmental || false,
    foundingMember: foundingMember || false,
    role: role || MemberTypes.Individual,
    memberType: 'User',
    positionUid: positionUid || position,
  };
};

export const getFamilyFundCreateRequestPayload = ({ form, unitId }) => {
  const {
    nationalId,
    position,
    birthDateHijri,
    phone,
    email,
    positionUid,
    foundingMember,
    familyRelationUid,
  } = form;
  const year = birthDateHijri?.year || '';
  const month = birthDateHijri?.month || '';
  const day = birthDateHijri?.day || '';

  const birthDate = typeof birthDateHijri === 'string'
    ? birthDateHijri
    : `${year}${month}${day}`;
  return {
    nationalId,
    email,
    familyRelationUid,
    unitId: Number(unitId),
    memberType: 'User',
    role: 'Individual',
    foundingMember: foundingMember || false,
    unitType: MembershipEntityTypes.Entity,
    positionUid: positionUid || position,
    absherPhone: PhoneFormatterAdapter.fromUIToServer(phone),
    birthDateHijri: birthDate,
  };
};

export const getFamilyFundUpdateRequestPayload = ({ form, unitId }) => {
  const {
    position,
    email,
    positionUid,
    foundingMember,
    familyRelationUid,
  } = form;
  return {
    email,
    familyRelationUid,
    unitId: Number(unitId),
    foundingMember: foundingMember || false,
    positionUid: positionUid || position,
  };
};

export const serializeParentMembership = (membership) => {
  const member = { ...membership };
  delete member.numberOfShares;
  return member;
};

export const fetchMember = async (membershipId) => {
  try {
    const res = await MembershipApi.getMembershipInfo({ id: membershipId });
    return res?.data?.findUserRequest || [];
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const getMemberships = async (
  unitId,
  unitType = MembershipEntityTypes.Entity,
) => {
  try {
    const payload = {
      unitId,
      unitType,
      size: 100,
      status: [],
    };
    const res = await UserRequestsApi.getUserRequestsGql(payload);
    const userRequests = res?.data?.userRequests?.userRequests || [];
    return userRequests.map((request) => normalizeMembership(request));
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const updateMembership = async (payload, id) => {
  try {
    const res = await MembershipApi.updateMembership(payload, id);
    return res?.data;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const getUserRequestPayload = (type, data) => {
  if (!Object.keys(UserRequestPayloadType).includes(type)) {
    console.warn('Wrong Payload Type');
    return {};
  }
  const methods = {
    FamilyFundCreate: getFamilyFundCreateRequestPayload,
    FamilyFundUpdate: getFamilyFundUpdateRequestPayload,
    EntityCooperativeAssociationCreate: getEntityCooperativeAssociationCreatePayload,
    EntityCooperativeAssociationUpdate: getEntityCooperativeAssociationUpdatePayload,
    EntityPrivateAssociationCreate: getEntityPrivateAssociationCreatePayload,
    EntityPrivateAssociationUpdate: getEntityPrivateAssociationUpdatePayload,
    EntityPrivateCorporationCreate: getEntityPrivateCorporationCreatePayload,
    EntityPrivateCorporationUpdate: getEntityPrivateCorporationUpdatePayload,
    BranchCreate: getBranchPayload,
  };
  return methods[type](data);
};
