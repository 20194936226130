import cloneDeep from 'lodash.clonedeep';
import { formatDate } from '@/helpers/date';
import { MembershipSteps, MemberTypes, UnitTypes } from '@/constants/enums';

// eslint-disable-next-line import/no-cycle
import { getFullName, normalizeDelegateMembership } from '@/services/membership.service';
import { DISCLOSURE_LIST } from '@/constants';
import ErrorHandler from '@/helpers/errors';
import UserRequestsApi from '@/api/user-requests.api';

export const normalizeDisclosureForm = (list = {}) => cloneDeep(DISCLOSURE_LIST).map((item) => (
  {
    ...item,
    list: item.list.map((l) => ({
      ...l,
      value: list ? list[l.shortName] : '' ?? '',
    })),
  }
));

export const normalizeUserRequest = (request = {}) => ({
  createdAt: formatDate(request.createdAt),
  entity: request?.unit ? {
    ...request?.unit,
    membersReplacementType: request.unit?.entityMultiStatus?.membersReplacementType,
    secondApprovalType: request.unit?.entityMultiStatus?.secondApprovalType,
    supervisoryChanges: request.unit?.entityMultiStatus?.supervisoryChanges,
  } : {},
  entityId: request.unit?.id,
  entityType: request?.unit?.type,
  entityStatus: request?.unit?.status,
  entityEstablisherName: request.unitType === UnitTypes.Entity
    ? getFullName(request.unit?.owner)
    : getFullName(request.unit?.entity.owner),
  showInfo: false,
  id: request.id,
  reason: '',
  mode: MembershipSteps.Info,
  membershipId: request.membershipId,
  financeContributionAmount: request.financeContributionAmount,
  numberOfShares: request.numberOfShares,
  rejectReason: request.rejectReason,
  unitType: request.unitType,
  status: request.status,
  userId: request.userId,
  member: request.member,
  position: request.position,
  governmental: request?.governmental,
  disclosureForm: normalizeDisclosureForm(request?.disclosureForm),
  disclosureFormId: request?.disclosureForm?.id,
  role: request?.role || MemberTypes.Individual,
  customPosition: request?.customPosition,
  nationalId: request?.member?.nationalId,
  positionUid: request?.positionUid,
  delegatorProof: request?.delegatorProof,
  foundingMember: request?.foundingMember,
  familyRelationId: request?.familyRelation?.uid,
  isSpecialized: request?.specialized,
});

export const handleUserRequestList = (list) => list.map(
  (member) => normalizeUserRequest(member),
);

export const normalizeSummaryRequest = (request = {}) => ({
  showInfo: false,
  numberOfShares: request?.numberOfShares,
  financeContributionAmount: request?.financeContributionAmount,
  contributeFinances: Boolean(request.financeContributionAmount),
  isSpecialized: request?.specialized,
  role: request?.role,
  unitType: request.unitType,
  customPosition: request?.customPosition,
  createdAt: formatDate(request.createdAt),
  nameEnglish: request.unit?.nameEnglish,
  nameArabic: request.unit?.nameArabic,
  entityType: request.unit?.type,
  entityStatus: request.unit?.status,
  parentNameEnglish: request.unit?.parentNameEnglish,
  parentNameArabic: request.unit?.parentNameArabic,
  membershipFee: request.unit?.membershipFee,
  memberId: request.memberId,
  membershipPosition: request.positionUid,
  memberMembership: request.memberMembership,
  position: request.position,
  status: request.status,
  id: request.id,
  loading: false,
  ownerEndDate: formatDate(request?.ownerEndDate) || '',
  ownerStartDate: formatDate(request?.ownerStartDate) || '',
  entityId: request.unitId,
  membershipId: request.membershipId,
  rejectReason: request.rejectReason,
  absherPhone: request?.member?.absherPhone,
  birthDateHijri: request?.member?.birthDateHijri,
  fullName: getFullName(request?.member),
  firstName: request?.member?.firstName,
  lastName: request?.member?.lastName,
  nationalId: request?.member?.nationalId,
  delegateProof: normalizeDelegateMembership(request?.delegatorProof),
  governmental: request?.governmental,
  email: request?.member?.email,
});

export const handleSummaryRequestList = (list) => list.map(
  (member) => normalizeSummaryRequest(member),
);

export const prepareDisclosureForm = (arr) => {
  let values = {};
  arr.forEach((item) => {
    item.list.forEach((v) => {
      values = { ...values, [v.shortName]: v.value || false };
    });
  });
  return values;
};

export const bulkMembersCount = async (entityUnits) => {
  try {
    const res = await UserRequestsApi.bulkMembersCount(entityUnits);
    return res?.data.membersCount?.numberOfUnitMembers;
  } catch (err) {
    return ErrorHandler.parseFetchErrors(err);
  }
};

export const findDelegateProofLetter = async (userRequestId) => {
  try {
    const res = await UserRequestsApi.findDelegateProofLetter(userRequestId);
    return res?.data.findUserRequest?.delegatorProof?.delegatorProofLetters;
  } catch (err) {
    return ErrorHandler.parseFetchErrors(err);
  }
};

export const rejectRequest = async (data) => {
  try {
    const res = await UserRequestsApi.rejectUserRequest(data.id, data.message);
    return res.data;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};
