import { Gateway, GraphQLRequest } from '@/setup/axios';
import {
  CREATE_ENTITY_PA,
  UPDATE_ENTITY_PA,
  FIND_ENTITY_BY_ID,
  GET_ENTITIES_BY_MEMBER,
  TOTAL_CONTRIBUTION,
  UPDATE_ENTITY_PC,
  UPDATE_ENTITY_CA,
  CREATE_ENTITY_PC,
  CREATE_ENTITY_CA,
  FINAL_SUBMIT_ENTITY_PA,
  FINAL_SUBMIT_ENTITY_PC,
  FINAL_SUBMIT_ENTITY_CA,
  GET_ENTITIES_BY_OWNER,
  FETCH_FILTERED_ENTITIES,
  LIST_CONTRIBUTION_SOURCES,
  FIND_TEMP_BANK_ACCOUNT_BY_ENTITY_ID,
  VALIDATE_MEMBERS,
  CREATE_OR_UPDATE_CONTRIBUTION_SOURCE,
  DELETE_CONTRIBUTION_SOURCE,
} from '@/api/graphql/entities-gql';
import { EntityTypes } from '@/constants/enums';
import { UPDATE_PROGRESS_STEP } from '@/api/graphql/family-fund-gql';
import {
  CREATE_OR_UPDATE_ENTITY_LOCATIONS,
} from '@/api/graphql/entity-locations-gql';
import { LIST_MEMBERSHIP_CONDITIONS } from '@/api/graphql/membership-conditions-gql';

const getEntityCreateQuery = (type) => {
  switch (type) {
    case EntityTypes.CooperativeAssociation:
      return CREATE_ENTITY_CA;
    case EntityTypes.PrivateCorporation:
      return CREATE_ENTITY_PC;
    case EntityTypes.PrivateAssociation:
      return CREATE_ENTITY_PA;
    default:
      return '';
  }
};
const getEntityUpdateQuery = (type) => {
  switch (type) {
    case EntityTypes.CooperativeAssociation:
      return UPDATE_ENTITY_CA;
    case EntityTypes.PrivateCorporation:
      return UPDATE_ENTITY_PC;
    case EntityTypes.PrivateAssociation:
      return UPDATE_ENTITY_PA;
    default:
      return '';
  }
};

const getEntityFinalSubmitQuery = (type) => {
  switch (type) {
    case EntityTypes.CooperativeAssociation:
      return FINAL_SUBMIT_ENTITY_CA;
    case EntityTypes.PrivateCorporation:
      return FINAL_SUBMIT_ENTITY_PC;
    case EntityTypes.PrivateAssociation:
      return FINAL_SUBMIT_ENTITY_PA;
    default:
      return '';
  }
};

class EntityApi {
  getAllEntities = (page = 1, size = 15) => (
    GraphQLRequest({
      query: GET_ENTITIES_BY_OWNER,
      variables: {
        page: Number(page),
        size: Number(size),
        status: 'accepted',
      },
    })
  )

  validateMembers = (unitId, unitType) => (
    GraphQLRequest({
      query: VALIDATE_MEMBERS,
      variables: {
        unitId: Number(unitId),
        unitType,
      },
    })
  )

  getAllBranches = (page = 1, size = 15, config = {}) => (
    Gateway.get(`entities/search?establishment_type=branch&status=accepted&page=${page}&size=${size}`, config)
  )

  getEntitiesByMemberGql = (page = 1, size = 15) => (
    GraphQLRequest({
      query: GET_ENTITIES_BY_MEMBER,
      variables: {
        page: Number(page),
        size: Number(size),
        status: 'accepted',
        memberRoleType: 'Member',
      },
    })
  )

  fetchFilteredEntities = (payload) => (
    GraphQLRequest({
      query: FETCH_FILTERED_ENTITIES,
      variables: {
        ...payload,
      },
    })
  )

  getTotalContribution = (entityId) => (
    GraphQLRequest({
      query: TOTAL_CONTRIBUTION,
      variables: {
        entityId: Number(entityId),
      },
    })
  )

  createEntity = (type, data) => (
    GraphQLRequest({
      query: getEntityCreateQuery(type),
      variables: {
        [`${type}EntityCreateAttributes`]: data,
      },
    })
  )

  updateEntity = (type, data, stepData) => (
    GraphQLRequest({
      query: getEntityUpdateQuery(type),
      variables: {
        [`${type}EntityUpdateAttributes`]: data,
        progressStepUpdateAttributes: stepData,
      },
    })
  )

  finalSubmitEntity = (type, id, innerSupporters, outsideSupporters) => {
    const payload = { id: +id };
    if (type === EntityTypes.PrivateAssociation) {
      payload.includeInternalFinance = innerSupporters;
      payload.includeExternalFinance = outsideSupporters;
    }
    return GraphQLRequest({
      query: getEntityFinalSubmitQuery(type),
      variables: {
        [`${type}EntitySubmitAttributes`]: { ...payload },
      },
    });
  };

  updateStepProgress = (data) => (
    GraphQLRequest({
      query: UPDATE_PROGRESS_STEP,
      variables: {
        progressStepUpdateAttributes: {
          step: data.step,
          unitId: +data.unitId,
          unitType: data.unitType,
        },
      },
    })
  )

  getEntity = (data) => (
    Gateway.post('entities/find', data)
  )

  findEntity = (id) => (
    GraphQLRequest({
      query: FIND_ENTITY_BY_ID,
      variables: {
        id: Number(id),
      },
    })
  )

  createOrUpdateEntityLocations = (entityId, entityLocations) => GraphQLRequest({
    query: CREATE_OR_UPDATE_ENTITY_LOCATIONS,
    variables: {
      entityLocationsCreateOrUpdateAttributes: {
        entityId: Number(entityId),
        entityLocations,
      },
    },
  })

  getMembershipConditionsList = (id) => (
    GraphQLRequest({
      query: LIST_MEMBERSHIP_CONDITIONS,
      variables: {
        entityId: Number(id),
      },
    })
  )

  updateMembershipConditions = (id, data) => (
    Gateway.patch(`membership_conditions/${id}`, data)
  )

  deleteMembershipConditions = (id, data) => (
    Gateway.delete(`membership_conditions/${id}`, data)
  )

  createMembershipConditions = (data) => (
    Gateway.post('membership_conditions', data)
  )

  updateCapital = (id, data) => (
    Gateway.patch(`entities/${id}/capital`, data)
  )

  createTemporaryBankAccount = (data) => (
    Gateway.post('temp_bank_accounts', data)
  )

  submitTemporaryBankAccount = (id) => (
    Gateway.post(`entities/${id}/submit_financial_info`, {})
  )

  closeTemporaryBankAccount = (id) => (
    Gateway.post(`temp_bank_accounts/${id}/close`, {})
  )

  checkEntityWithoutMembership = (id, data) => (
    Gateway.post(`entities/${id}/validate_membership`, data)
  )

  createCopyEntity = (data) => Gateway.post('entities/copy', data)

  checkEntityLicenseNumber = (licenseNumber) => Gateway.get(`public/entity_license/check?entity_uid=${licenseNumber}`)

  findTempBankAccountByEntityId = (entityId) => GraphQLRequest({
    query: FIND_TEMP_BANK_ACCOUNT_BY_ENTITY_ID,
    variables: {
      entityId: Number(entityId),
    },
  })

  listContributionSources = (entityId, page = 1, size = 10) => GraphQLRequest({
    query: LIST_CONTRIBUTION_SOURCES,
    variables: {
      entityId: Number(entityId),
      page,
      size,
    },
  })

  createOrUpdateContributionSource = (data) => GraphQLRequest({
    query: CREATE_OR_UPDATE_CONTRIBUTION_SOURCE,
    variables: {
      contributionSourcesCreateOrUpdateAttributes: {
        entityId: Number(data.entityId),
        id: data.id ? Number(data.id) : undefined,
        kind: data.kind,
        title: data.title,
        amount: data.amount,
        attachments: data.attachments,
      },
    },
  })

  deleteContributionSource = (id) => GraphQLRequest({
    query: DELETE_CONTRIBUTION_SOURCE,
    variables: {
      id: Number(id),
    },
  })
}

export default new EntityApi();
