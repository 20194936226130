import axios from 'axios';
import { Deserializer, Serializer } from 'jsonapi-serializer';

export const sleep = ({ isError, data }, delay) => new Promise((resolve, reject) => {
  setTimeout(() => {
    if (isError) {
      reject(data);
    }
    resolve(data);
  }, delay || 1000);
});

export const serializer = (
  data,
  type = 'request',
  options = {
    keyForAttribute: 'snake_case',
    pluralizeType: false,
  },
) => {
  const attributes = Array.isArray(data) ? Object.keys(data[0]) : Object.keys(data);
  const opts = {
    attributes,
    ...options,
  };
  return new Serializer(type, opts).serialize(data);
};

export const deserializer = (
  payload,
  cb = null,
  options = {
    keyForAttribute: 'camelCase',
  },
) => new Deserializer(options).deserialize(payload, (err, newData) => {
  if (err) throw err;
  if (cb) cb(newData);
  return newData;
});

export const abortController = () => {
  let sources = [];

  const register = () => {
    const ct = axios.CancelToken.source();
    sources.push(ct);
    return ct.token;
  };

  const hasItems = () => !!sources.length;

  const reset = () => {
    sources = [];
  };

  const abort = (message) => {
    sources.forEach((s) => {
      s.cancel(message || 'abort request');
    });
    reset();
  };

  return {
    register,
    hasItems,
    abort,
  };
};
