<template>
  <header class="header main-container">
    <!-- <logo :route-name="routeName" /> -->
    <router-link
      id="menu-user-space"
      :to="{name: 'UserSpace'}"
      tag="a"
      class="user-menu-label"
    >
      <img
        src="../../assets/images/White-Logo-NAWA (1).svg"
        alt="logo"
        width="150px"
      >
    </router-link>
    <div class="header-left">
      <div class="header-right">
        <lang-switcher />
      </div>
      <slot name="header-left" />
    </div>
  </header>
</template>

<script>
import LangSwitcher from '@/containers/lang-switcher/LangSwitcher.vue';

export default {
  name: 'Header',
  components: {
    LangSwitcher,
  },
  computed: {
    routeName() {
      const checkDashboardLayout = this.$route.matched.some(
        (record) => record.name === 'DashboardLayout',
      );
      return checkDashboardLayout ? 'UserSpace' : 'Home';
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
