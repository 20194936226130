import axios from 'axios';
import { formatDate } from '@/helpers/date';
import {
  EmployeeRequestStatuses,
  EmployeeRequestTracker,
  RequestStatuses,
  RequestTypes,
  PolicyRequestApprovalStatuses,
  PolicyRequestApprovalSteps,
  EditRequestTypes,
  MembersReplacementTypes,
  SecondApprovalTypes,
} from '@/constants/enums';
import RequestApi from '@/api/request.api';
import { deserializer } from '@/helpers/api';
import ErrorHandler from '@/helpers/errors';

const getDeleteStatus = (requestStatus) => {
  switch (requestStatus) {
    case RequestStatuses.Draft:
      return true;
    case RequestStatuses.MemberApproval:
      return false;
    case RequestStatuses.MemberAdjustments:
      return false;
    case RequestStatuses.SupervisoryApproval:
      return false;
    case RequestStatuses.SupervisoryAdjustments:
      return false;
    case RequestStatuses.Accepted:
      return false;
    case RequestStatuses.Rejected:
      return false;
    default:
      return false;
  }
};

const EntityEstablishment = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.AdminsApproval,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const CooperativeAssociation = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.SupervisoryApproval,
    active: false,
    duration: '15',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const PrivateAssociation = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.SupervisoryApproval,
    active: false,
    duration: '15',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const PrivateCorporation = [
  {
    title: RequestStatuses.Draft,
    active: false,
    duration: '',
  },
  {
    title: RequestStatuses.MemberApproval,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.MemberAdjustments,
    active: false,
    duration: '5',
  },
  {
    title: RequestStatuses.SupervisoryApproval,
    active: false,
    duration: '15',
  },
  {
    title: RequestStatuses.RequestResults,
    active: false,
    duration: '',
  },
];

const outcomeArray = [RequestStatuses.Accepted, RequestStatuses.Rejected];

const setStatus = (sourceArray, requestStatus) => sourceArray.map((status) => {
  if ((outcomeArray.includes(requestStatus)
      && status.title === RequestStatuses.RequestResults)
      || status.title === requestStatus) {
    return {
      ...status,
      active: true,
    };
  }
  return {
    ...status,
    active: false,
  };
});

const setTitle = (sourceArray, requestStatus) => sourceArray.map((status) => {
  if (status.title === RequestStatuses.RequestResults && outcomeArray.includes(requestStatus)) {
    return {
      ...status,
      title: requestStatus,
    };
  }
  return {
    ...status,
    title: status.title,
  };
});

const getStatuses = (requestType, requestStatus) => {
  if (requestType === RequestTypes.EntityEstablishment) {
    const status = setStatus(EntityEstablishment, requestStatus);
    return setTitle(status, requestStatus);
  }
  if (requestType === RequestTypes.BranchCooperativeAssociation) {
    const status = setStatus(CooperativeAssociation, requestStatus);
    return setTitle(status, requestStatus);
  }
  if (requestType === RequestTypes.BranchPrivateAssociation) {
    const status = setStatus(PrivateAssociation, requestStatus);
    return setTitle(status, requestStatus);
  }
  if (requestType === RequestTypes.BranchPrivateCorporation) {
    const status = setStatus(PrivateCorporation, requestStatus);
    return setTitle(status, requestStatus);
  }
  return [];
};

const employeeRequestStatuses = [
  {
    title: EmployeeRequestTracker.EmployeeApproval,
    active: false,
    duration: '',
  },
  {
    title: EmployeeRequestTracker.AdminsApproval,
    active: false,
    duration: '',
  },
  {
    title: EmployeeRequestTracker.RequestResults,
    active: false,
    duration: '',
  },
];

const getEmployeeStatuses = (requestStatus) => employeeRequestStatuses.map((item) => {
  let active = false;
  let { title } = item;
  switch (item.title) {
    case EmployeeRequestTracker.EmployeeApproval:
      active = [
        EmployeeRequestStatuses.Pending,
      ].includes(requestStatus);
      break;
    case EmployeeRequestTracker.AdminsApproval:
      active = [
        EmployeeRequestStatuses.AcceptedByUser,
        EmployeeRequestStatuses.AcceptedByHrsdEmployee,
        EmployeeRequestStatuses.AcceptedBySpecializedAdmin,
        EmployeeRequestStatuses.AcceptedByRegistrationAndPermitAdmin,
      ].includes(requestStatus);
      break;
    case EmployeeRequestTracker.RequestResults:
      active = [
        EmployeeRequestStatuses.Accepted,
        EmployeeRequestStatuses.RejectedByUser,
        EmployeeRequestStatuses.RejectedBySpecializedAdmin,
        EmployeeRequestStatuses.RejectedByRegistrationAndPermitAdmin,
        EmployeeRequestStatuses.RejectedByHrsdEmployee,
      ].includes(requestStatus);
      break;
    default:
      break;
  }
  if ([
    EmployeeRequestStatuses.RejectedByUser,
    EmployeeRequestStatuses.RejectedBySpecializedAdmin,
    EmployeeRequestStatuses.RejectedByRegistrationAndPermitAdmin,
    EmployeeRequestStatuses.RejectedByHrsdEmployee,
  ].includes(requestStatus) && item.title === EmployeeRequestTracker.RequestResults) {
    title = EmployeeRequestStatuses.Rejected;
  }
  if ([
    EmployeeRequestStatuses.AcceptedBySpecializedAdmin,
    EmployeeRequestStatuses.AcceptedByRegistrationAndPermitAdmin,
    EmployeeRequestStatuses.AcceptedByHrsdEmployee,
    EmployeeRequestStatuses.Accepted,
  ].includes(requestStatus) && item.title === EmployeeRequestTracker.RequestResults) {
    title = EmployeeRequestStatuses.Accepted;
  }
  return {
    duration: item.duration,
    title,
    active,
  };
});

const policyRequestStatuses = [
  {
    title: PolicyRequestApprovalSteps.Draft,
    active: true,
    duration: '',
  },
  {
    title: PolicyRequestApprovalSteps.AdminsApproval,
    active: false,
    duration: '',
  },
  {
    title: PolicyRequestApprovalSteps.RequestResults,
    active: false,
    duration: '',
  },
];

const getPolicyStatus = (status, requestType) => policyRequestStatuses.map((item) => {
  let active = false;
  let { title } = item;
  const { duration } = item;
  switch (title) {
    case PolicyRequestApprovalSteps.Draft:
      active = [
        PolicyRequestApprovalStatuses.Draft,
      ].includes(status);
      break;
    case PolicyRequestApprovalSteps.AdminsApproval:
      active = [
        PolicyRequestApprovalStatuses.Pending,
      ].includes(status);
      break;
    case PolicyRequestApprovalSteps.RequestResults:
      active = [
        PolicyRequestApprovalStatuses.Accepted,
        PolicyRequestApprovalStatuses.Rejected,
      ].includes(status);
      break;
    default:
      break;
  }

  if (item.title === PolicyRequestApprovalSteps.AdminsApproval
    && requestType === EditRequestTypes.GoalsAndActivities) {
    title = PolicyRequestApprovalStatuses.SupervisoryApproval;
  }

  if (item.title === PolicyRequestApprovalSteps.AdminsApproval
    && requestType === EditRequestTypes.RegulationItemsAndGeographicInformation) {
    title = PolicyRequestApprovalStatuses.RegistrationAndPermitApproval;
  }

  if (PolicyRequestApprovalStatuses.Accepted === status
    && item.title === PolicyRequestApprovalSteps.RequestResults) {
    title = PolicyRequestApprovalStatuses.Accepted;
  }

  if (PolicyRequestApprovalStatuses.Rejected === status
    && item.title === PolicyRequestApprovalSteps.RequestResults) {
    title = PolicyRequestApprovalStatuses.Rejected;
  }

  return {
    title,
    duration,
    active,
  };
});

const handleEntityRequests = (data) => data.map((request) => ({
  entityType: request.type,
  remainingData: request.remainingData,
  requestType: RequestTypes.EntityEstablishment,
  nameArabic: request.nameArabic,
  nameEnglish: request.nameEnglish || request.nameArabic,
  createdAt: formatDate(request.createdAt),
  trackAction: true,
  unitId: request.id,
  isAdjustment: request?.entityMultiStatus.supervisoryChanges,
  loading: false,
  id: request.id,
  deleteAction: getDeleteStatus(request.status),
  viewAction: true,
  showInfo: false,
  status: request.status || RequestStatuses.Draft,
  membersReplacementType: request?.entityMultiStatus?.membersReplacementType
    || MembersReplacementTypes.None,
  secondApprovalType: request?.entityMultiStatus.secondApprovalType || SecondApprovalTypes.None,
  statuses: getStatuses(request.requestType, request.status),
}));

const handleBranchRequests = (data) => data.map((request) => ({
  entityType: request.type,
  requestType: request.requestType,
  parentId: request.entityId,
  nameArabic: request.nameArabic,
  nameEnglish: request.nameEnglish || request.nameArabic,
  parentNameArabic: request.parentNameArabic,
  parentNameEnglish: request.parentNameEnglish,
  createdAt: formatDate(request.createdAt),
  trackAction: true,
  unitId: request.id,
  isAdjustment: request.supervisoryChanges,
  loading: false,
  id: request.id,
  deleteAction: getDeleteStatus(request.status),
  viewAction: true,
  showInfo: false,
  status: request.status || RequestStatuses.Draft,
  membersReplacementType: request.membersReplacementType || MembersReplacementTypes.None,
  secondApprovalType: request.secondApprovalType || SecondApprovalTypes.None,
  statuses: getStatuses(request.requestType, request.status),
}));

const handleEmployeeRequests = (data) => data.map((request) => ({
  requestType: 'appointing_employee',
  employeeType: request.employeeType,
  rejectionReasonMessage: request.rejectionReasonMessage,
  entityType: request.entityType,
  id: request.id,
  entityNameAr: request.entityNameAr,
  entityNameEn: request.entityNameEn,
  status: request.status,
  showInfo: false,
  trackAction: true,
  loading: false,
  createdAt: formatDate(request.createdAt),
  statuses: getEmployeeStatuses(request.status),
}));

const handlePolicyRequests = (data) => data.map((request) => ({
  requestType: request.type,
  rejectedBy: request.status === RequestStatuses.Rejected ? 'admin' : '',
  rejectMsg: request.rejectReason,
  id: request.id,
  entityId: request.unitId,
  entityNameAr: request.unitArName,
  entityNameEn: request.unitEnName,
  status: request.status,
  showInfo: false,
  trackAction: true,
  loading: false,
  createdAt: formatDate(request.createdAt),
  statuses: getPolicyStatus(request.status, request.type),
}));

const handleChartData = (data) => {
  const sortedData = data.map((item, i) => {
    const handledItem = {
      ...item,
      index: i,
    };
    let color;
    switch (item.status) {
      case RequestStatuses.Draft:
        color = '#DFDFDF';
        break;
      case RequestStatuses.MemberApproval:
        color = '#B0D3CA';
        break;
      case RequestStatuses.MemberAdjustments:
        color = '#5A7F8B';
        break;
      case RequestStatuses.CloseBankAccount:
        color = '#B0D3CA';
        break;
      case RequestStatuses.RegistrationAndPermitApproval:
        color = '#36B79F';
        break;
      case RequestStatuses.Accepted:
        color = '#5A7F8B';
        break;
      case RequestStatuses.Rejected:
        color = '#7CC5B4';
        break;
      case RequestStatuses.AdminsApproval:
        color = '#005364';
        break;
      default:
        // Error color means that BE add new status that is not handled by app
        console.warn(`Unhandled request status: ${item.status}`);
        color = 'red';
    }
    handledItem.color = color;
    return handledItem;
  });

  const numbers = sortedData.map((item) => ({ count: item.count, color: item.color }));
  const titles = sortedData.map((item) => item.status);
  return { numbers, titles };
};

let cancelSource = null;

const mainState = {
  requests: [],
  loading: false,
  error: null,
  chartError: false,
  chartData: [],
  entityUpdates: [],
  sevenHundredErrorsLoading: false,
  sevenHundredErrors: [],
};

const mainGetters = {
  requests: (store) => store.requests,
  isLoading: (store) => store.loading,
  isError: (store) => store.error,
  isChartError: (store) => store.chartError,
  chartData: (store) => store.chartData,
  isSevenHundredErrorsLoading: (store) => store.sevenHundredErrorsLoading,
  getSevenHundredErrors: (store) => store.sevenHundredErrors,
};

const mutations = {
  setChartError: (store, value) => {
    store.chartError = value;
  },
  setChartData: (store, data) => {
    store.chartData = data;
  },
  setRequests: (store, requests) => {
    store.requests = requests;
  },
  setError: (store, value) => {
    store.error = value;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  setSevenHundredErrorsLoading: (store, value) => {
    store.sevenHundredErrorsLoading = value;
  },
  setSevenHundredErrors: (store, sevenHundredErrors) => {
    store.sevenHundredErrors = sevenHundredErrors;
  },
  setDeleteLoading: (store, { id, value }) => {
    store.requests = store.requests.map((r) => ({ ...r, loading: r.id === id ? value : false }));
  },
  setVisibleInfo: (store, id) => {
    store.requests = store.requests.map((m) => ({
      ...m,
      showInfo: m.id === id ? !m.showInfo : false,
    }));
  },
  changeMode: (store, params) => {
    const obj = store.requests.find((i) => i.id === params.id);
    obj.mode = params.mode;
  },
  setCopyLoading: (store, { id, value }) => {
    store.requests = store.requests.map((r) => ({ ...r, loading: r.id === id ? value : false }));
  },
};

const actions = {
  cancelAllRequests: () => {
    if (cancelSource) {
      cancelSource.cancel('abort all requests');
    }
  },
  checkRejectMsg: async (_, { unitId, unitType }) => {
    try {
      const res = await RequestApi.getRejectReason(unitId, unitType);
      const data = (await deserializer(res.data)) || [];
      const rejectMsg = data.map((item) => item.message);
      const rejectedBy = data[0]?.adminRole;
      return { rejectMsg, rejectedBy };
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchSevenHundredErrors: async ({ commit }, { unitId }) => {
    let sevenHundredErrors = [];
    commit('setSevenHundredErrorsLoading', true);
    try {
      const res = await RequestApi.getSevenHundredErrorsRequests(unitId);
      console.log(res.data.listUnifiedNumberErrors.errors);
      const list = res.data.listUnifiedNumberErrors.errors || [];
      sevenHundredErrors = list;
      commit('setSevenHundredErrors', sevenHundredErrors);
    } catch (e) {
      console.warn(e);
      sevenHundredErrors = { error: e };
    } finally {
      commit('setSevenHundredErrorsLoading', false);
    }
    return sevenHundredErrors;
  },
  getBranchRequests: async ({ commit, rootGetters }, { useLoading = true }) => {
    commit('setError', null);
    if (useLoading) commit('setLoading', true);
    if (cancelSource) {
      cancelSource.cancel('abort entity request');
    }
    try {
      cancelSource = axios.CancelToken.source();
      const res = await RequestApi.getBranchRequests(
        rootGetters['entityEstablishmentPagination/selectedPage'],
        rootGetters['entityEstablishmentPagination/selectedSize'],
        { cancelToken: cancelSource.token },
      );
      if (res.data.error) {
        commit('setRequests', []);
        return { error: res.data.error };
      }
      const data = await deserializer(res.data);
      commit('entityEstablishmentPagination/setTotalPages', res.data.meta.page_count, {
        root: true,
      });
      commit('entityEstablishmentPagination/setTotalRecords', res.data.meta.records_count, {
        root: true,
      });
      commit('setRequests', handleBranchRequests(data));
      commit('setLoading', false);
      return handleBranchRequests(data);
    } catch (e) {
      console.warn(e);
      if (!axios.isCancel(e)) {
        commit('setLoading', false);
        commit('setError', e.response.status);
      }
      return { error: e.message };
    }
  },
  getRequests: async ({ dispatch }, { useLoading = true, establishmentType }) => {
    if (establishmentType !== 'entity') {
      await dispatch('getBranchRequests', { useLoading });
    } else {
      await dispatch('getEntityRequests', { useLoading });
    }
  },
  getEntityRequests: async ({ commit, rootGetters }, { useLoading = true }) => {
    commit('setError', null);
    if (useLoading) commit('setLoading', true);
    if (cancelSource) {
      cancelSource.cancel('abort entity request');
    }
    try {
      cancelSource = axios.CancelToken.source();
      const res = await RequestApi.getEntityRequests({
        page: rootGetters['entityEstablishmentPagination/selectedPage'],
        size: rootGetters['entityEstablishmentPagination/selectedSize'],
        withAcceptedNamaEntities: true,
        config: { cancelToken: cancelSource.token, clearCacheEntry: true },
      });
      if (res.data.error) {
        commit('setRequests', []);
        return { error: res.data.error };
      }
      const entities = res?.data?.entities;
      const data = entities?.entities || [];
      commit('entityEstablishmentPagination/setTotalPages', entities.meta.pageCount, {
        root: true,
      });
      commit('entityEstablishmentPagination/setTotalRecords', entities.meta.recordsCount, {
        root: true,
      });
      commit('setRequests', handleEntityRequests(data));
      commit('setLoading', false);
      return handleEntityRequests(data);
    } catch (e) {
      console.warn(e);
      if (!axios.isCancel(e)) {
        commit('setLoading', false);
        commit('setError', e.response.status);
      }
      return { error: e.message };
    }
  },
  getEmployeeRequests: async ({ commit, rootGetters }) => {
    commit('setLoading', true);
    if (cancelSource) {
      cancelSource.cancel('abort employee request');
    }
    try {
      cancelSource = axios.CancelToken.source();
      const res = await RequestApi.getEmployeeRequests(
        rootGetters['employeePagination/selectedPage'],
        rootGetters['employeePagination/selectedSize'],
        { cancelToken: cancelSource.token },
      );
      if (res.data.error) {
        commit('setRequests', []);
        return { error: res.data.error };
      }
      const data = await deserializer(res.data);
      commit('employeePagination/setTotalPages', res.data.meta.page_count, { root: true });
      commit('employeePagination/setTotalRecords', res.data.meta.records_count, { root: true });
      commit('setRequests', handleEmployeeRequests(data));
      commit('setLoading', false);
      return handleEmployeeRequests(data);
    } catch (e) {
      console.warn(e);
      if (!axios.isCancel(e)) {
        commit('setLoading', false);
      }
      return { error: e.message };
    }
  },
  getPolicyRequests: async ({ commit, rootGetters }) => {
    commit('setLoading', true);
    if (cancelSource) {
      cancelSource.cancel('abort policy request');
    }
    cancelSource = axios.CancelToken.source();
    try {
      const res = await RequestApi.getPolicyRequests(
        rootGetters['editPolicyPagination/selectedPage'],
        rootGetters['editPolicyPagination/selectedSize'],
        { cancelToken: cancelSource.token },
      );
      const requests = await deserializer(res.data);
      const metaData = res.data?.meta;
      if (metaData) {
        commit('editPolicyPagination/setTotalPages', res.data.meta.page_count, { root: true });
        commit('editPolicyPagination/setTotalRecords', res.data.meta.records_count, { root: true });
      }
      commit('setRequests', handlePolicyRequests(requests));
      commit('setLoading', false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        commit('setLoading', false);
      }
      console.warn(err);
    }
  },
  getChartData: async ({ commit }, establishmentType) => {
    commit('setChartError', false);
    commit('setLoading', true);
    try {
      if (cancelSource) cancelSource.cancel('abort request');
      const res = await RequestApi.getChartData(establishmentType);
      if (res.data.error) {
        return { error: res.data.error };
      }
      const data = await deserializer(res.data);
      commit('setChartData', handleChartData(data));
      return handleChartData(data);
    } catch (e) {
      commit('setChartError', true);
      console.warn(e);
      return { error: e.message };
    } finally {
      commit('setLoading', false);
    }
  },

  deleteRequest: async ({ getters, dispatch, commit }, { requestId, establishmentType }) => {
    commit('setDeleteLoading', { id: requestId, value: true });
    try {
      const res = await RequestApi.deleteRequest(requestId);
      if (res.data.error) {
        return { error: res.data.error };
      }
      if (getters.requests.length <= getters.selectedSize) {
        commit('entityEstablishmentPagination/setPage', 1, { root: true });
      }
      await dispatch('getEntityRequests', {
        useLoading: false,
        establishmentType,
      });
      return res;
    } catch (e) {
      dispatch(
        'toast/showNotification',
        {
          message: ErrorHandler.parseGlobalErrors(e),
          duration: 3000,
          type: 'error',
        },
        { root: true },
      );
      return { error: e.message };
    } finally {
      commit('setDeleteLoading', { id: requestId, value: false });
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
