import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localize, localeChanged } from 'vee-validate';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/constants';
import { LocaleTypes } from '@/constants/enums';
import { setZELang } from '@/helpers/zendesk';
import ar_validations from './ar/validations.json';
import en_validations from './en/validations.json';

const gatherFiles = (local) => {
  const files = require.context('', true, /\.json$/);
  return files.keys().reduce((acc, key) => {
    if (key.includes(`${local}/`)) {
      const checkIfValidation = key.includes('validations') ? files(key).messages : files(key);
      acc = { ...acc, ...checkIfValidation };
    }
    return acc;
  }, {});
};

const ar = gatherFiles(LocaleTypes.Ar);
const en = gatherFiles(LocaleTypes.En);

Vue.use(VueI18n);

const messages = {
  ar,
  en,
};

localize({
  ar_validations,
  en_validations,
});

export function getLocale() {
  return localStorage.getItem('nawa/locale') || DEFAULT_LOCALE;
}

const i18n = new VueI18n({
  messages,
  locale: getLocale(),
  fallbackLocale: DEFAULT_LOCALE,
  silentTranslationWarn: process.env.NODE_ENV !== 'production',
});

export function setLocale(lang) {
  if (!SUPPORTED_LOCALES.includes(lang)) return;
  if (i18n.locale !== lang) {
    i18n.locale = lang;
    localeChanged();
  }
  window.document.title = i18n.t('general.nawa');
  setZELang(lang);
  document.dir = lang === LocaleTypes.Ar ? 'rtl' : 'ltr';
  document.documentElement.lang = lang;
  localStorage.setItem('nawa/locale', lang);
}

export default i18n;
