import { Gateway, GraphQLRequest } from '@/setup/axios';
import { UnitTypes, EditPolicyStatuses, EditRequestTypes } from '@/constants/enums';
import { LIST_ENTITY_REQUESTS } from '@/api/graphql/edit-request-gql';

class EditPolicyApi {
  resendEditRequest = (id) => Gateway.post(`edit_requests/${id}/resend`)

  saveEditedField = (data, id) => (
    Gateway.post(`edit_requests/${id}/edit_items`, data)
  )

  updateEditedField = ({ editRequestId, editItemId, data }) => (
    Gateway.patch(`edit_requests/${editRequestId}/edit_items/${editItemId}`, data)
  )

  deleteEditRequestItem = (id, editRequestId) => (
    Gateway.delete(`edit_requests/${editRequestId}/edit_items/${id}`)
  )

  fetchEditItems = (
    editRequestId,
    action = '',
  ) => {
    const actionParameter = action ? `?action=${action}` : '';
    return Gateway.get(`requests_view/edit_requests/${editRequestId}/edit_items${actionParameter}`);
  }

  getEditedFieldsByRequestId = (editRequestId) => (
    Gateway.get(`requests_view/edit_requests/${editRequestId}/edit_items`)
  );

  acceptAdminAdjustments = (editRequestId) => (
    Gateway.post(`edit_requests/${editRequestId}/accept`)
  );

  rejectAdminAdjustments = (editRequestId) => (
    Gateway.post(`edit_requests/${editRequestId}/reject`, {})
  );

  getAcceptedUpdatesOfEntity = ({
    adjustmentType = [
      EditRequestTypes.PrimaryInformation,
      EditRequestTypes.RegulationItemsAndGeographicInformation,
    ],
    status = EditPolicyStatuses.Accepted,
    unitId,
    unitType = UnitTypes.Entity,
  }) => GraphQLRequest({
    query: LIST_ENTITY_REQUESTS,
    variables: {
      unitId: Number(unitId),
      unitType,
      type: adjustmentType,
      status,
      page: 1,
      size: 50,
    },
  })

  fetchEditRequest = (id) => Gateway.get(`requests_view/edit_requests/${id}`)
}

export default new EditPolicyApi();
