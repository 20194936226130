export const GET_USER_PROFILE = `query GetUserProfile {
  profile {
    accountId
    email
    role
    phone
    title
    user {
      absherPhone
      birthplace
      birthDateHijri
      createdAt
      degrees
      homePhone
      educationSpecialization
      educationalLevel
      email
      employmentPlace
      fatherName
      firstName
      gender
      grandfatherName
      id
      lastName
      nationalId
      nationality {
        arTitle
        enTitle
        uid
      }
      nationalAddress {
        buildingNo
        streetNameAr
        streetNameEn
        districtAreaAr
        districtAreaEn
        cityAr
        cityEn
        zipCode
        additionalNo
        unitNo
      }
      nidExpireDate
      occupation
      previousExperience
      socialMedias {
        accountName
        type
      }
      updatedAt
    }
  }
}`;
