<template>
  <div
    v-if="getLastGlobal"
    class="notifications-container"
  >
    <global-notification
      :message="message"
      :notification="getLastGlobal"
      @globalNotificationClosed="close"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import { LocaleTypes, NotificationStatus } from '@/constants/enums';

export default {
  name: 'NotificationsContainer',
  computed: {
    ...mapGetters({
      getLastGlobal: 'notifications/getLastGlobal',
      userId: 'user/userId',
    }),
    message() {
      return this.$i18n.locale === LocaleTypes.Ar
        ? this.getLastGlobal.arMessage
        : this.getLastGlobal.enMessage;
    },
  },
  created() {
    this.getAllNotifications();
  },
  methods: {
    ...mapActions({
      getAllNotifications: 'notifications/getAllNotifications',
      updateNotificationStatus: 'notifications/updateNotificationStatus',
    }),
    ...mapMutations({
      markLastNotificationAsRead: 'notifications/markLastNotificationAsRead',
    }),
    close(notificationID) {
      const data = {
        user_id: this.userId,
        id: notificationID,
        status: NotificationStatus.Read,
      };

      this.markLastNotificationAsRead(notificationID);

      this.updateNotificationStatus(data);
    },
  },
};
</script>
