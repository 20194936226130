import { jsonParse, splitStringIntoArray } from '@/helpers/general';

export function createOriginalDublicateGoalsAndActivities(data) {
  const fields = [
    'activities',
    'goals',
    'classification',
    'firstClassification',
    'secondClassification',
  ];
  const store = {};
  if (data) {
    fields.forEach((fieldName) => {
      store[fieldName] = data[fieldName];
    });
  }
  return store;
}

export function compareOriginalAndCurrentGoalsAndActivities(originalData, currentData) {
  const newObject = {};
  // eslint-disable-next-line
  for (const prop in originalData) {
    if (
      Array.isArray(originalData[prop])
      && Array.isArray(currentData[prop])
      && originalData[prop].length === currentData[prop].length
      && originalData[prop].every((v, i) => v === currentData[prop][i])) {
      // eslint-disable-next-line
      continue;
    }
    if (originalData[prop] !== currentData[prop]) {
      newObject[prop] = currentData[prop];
    }
  }
  return newObject;
}

export function normalizeEntityPolicy(data = {}) {
  return {
    id: data.id,
    type: data.type,
    unitType: data.unitType,
    editRequestId: data.editRequestId,
    editItemId: data.editItemId,
    nameArabic: data.nameArabic,
    nameEnglish: data.nameEnglish,
    secondClassification: data.secondSubClassificationId,
    mainClassificationArTitle: data.mainClassificationArTitle,
    mainClassificationEnTitle: data.mainClassificationArTitle,
    firstSubClassificationArTitle: data.firstSubClassificationArTitle,
    firstSubClassificationEnTitle: data.firstSubClassificationEnTitle,
    secondSubClassificationArTitle: data.secondSubClassificationArTitle,
    secondSubClassificationEnTitle: data.secondSubClassificationEnTitle,
    activities: splitStringIntoArray(data.activityIds),
    activitiesArTitles: data.activitiesArTitles,
    activitiesEnTitles: data.activitiesEnTitles,
    goals: splitStringIntoArray(data.goalIds),
    goalsArTitles: data.goalsArTitles,
    goalsEnTitles: data.goalsEnTitles,
    numberOfBoardMembers: data.numberOfBoardMembers,
    minNumOfSharesPerMember: data.minNumOfSharesPerMember,
    numberOfShares: data.numberOfShares,
    shareValue: data.shareValue,
    cityUid: data.cityUid,
    cityArTitle: data.cityArTitle,
    cityEnTitle: data.cityEnTitle,
    regionCode: data.regionCode,
    regionArTitle: data.regionArTitle,
    regionEnTitle: data.regionEnTitle,
    typeOfBenefits: data.typeOfBenefits,
    membershipTypeAssociation: data.membershipType,
    ceoEducationalLevelCondition: data.ceoEducationalLevelCondition,
    ceoDegreesCondition: data.ceoDegreesCondition,
    ceoPreviousExperienceCondition: data.ceoPreviousExperienceCondition,
    ceoCustomCondition: splitStringIntoArray(
      jsonParse(data.ceoCustomCondition, []),
    ),
  };
}
