export const FETCH_LIST_OF_BRANCHES_BY_ENTITY_ID = `
  query fetchListOfBranchesByEntityId(
    $status: [String!],
    $entityId: Int!,
    $page: Int!,
    $size: Int!,
  ) {
    listBranches(
      status: $status,
      entityId: $entityId,
      page: $page,
      size: $size,
    ) {
      branches {
        id
        status
        nameArabic
        nameEnglish
        city {
          arTitle
          enTitle
          regionCode
        }
        region {
          arTitle
          enTitle
          code
        }
        secondSubClassificationId
        type
        registrationDateHijri
      }
      meta {
        page
        pageCount
        size
        recordsCount
      }
    }
  }
`;
export const FIND_BRANCH_BY_BRANCH_ID = `query FindBranchById($branchId: Int!) {
  findBranchById(id: $branchId) {
    approvalType
    acknowledgmentAgreed
    bankIds
    bankCertificate {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    city {
      arTitle
      enTitle
      regionCode
      uid
    }
    cityUid
    entityId
    goalIds
    goals
    createdAt
    updatedAt
    id
    entity {
     owner {
        firstName
        fatherName
        grandfatherName
        lastName
      }
    }
    nameArabic
    nameEnglish
    progressStep {
      step
    }
    region {
      arTitle
      enTitle
      code
    }
    regionCode
    registrationDateHijri
    secondSubClassificationId
    status
    type
  }
 }`;
