import { IS_PROD } from '@/constants';

export const loadGTAG = () => {
  if (!IS_PROD) {
    return;
  }
  const scriptTag = document.createElement('script');
  scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-KBD87DC6DV';
  scriptTag.async = true;
  document.head.appendChild(scriptTag);
  scriptTag.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-KBD87DC6DV');
  };
};
