export const FIND_FAMILY_FUND_ENTITY_BY_ID = `query findEntityById($id: Int!) {
  findEntityById(id: $id) {
    cityUid
    copiedFrom
    autocreateMembershipForOwner
    foundFor
    families{
      name
      relation
    }
    createdAt
    membershipRequirements
    entityUid
    entityLicenseNumber
    id
    typeOfBenefits
    membershipType
    nameArabic
    ceoCustomCondition
    ceoEducationalLevelCondition
    ceoPreviousExperienceCondition
    eligibilityStudyFiles {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    bankCertificates {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    type
    numberOfShares
    minNumOfSharesPerMember
    shareValue
    nameEnglish
    supportingDocuments {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    regionCode
    registrationDateHijri
    secondSubClassificationId
    status
    goalIds
    activityIds
    goals
    updatedAt
    city {
      arTitle
      enTitle
      regionCode
      uid
    }
    region {
      arTitle
      code
      enTitle
    }
    areasOfActivity {
      cities {
        arTitle
        enTitle
        regionCode
        uid
      }
      cityUids
      entityId
      region {
        arTitle
        cities {
          arTitle
          enTitle
          regionCode
          uid
        }
        code
        enTitle
      }
      regionCode
      type
    }
    progressStep {
      step
    }
    entityNationalAddress {
      additionalNumber
      buildingNumber
      postCode
      streetName
    }
    owner {
      id
      educationalCertificates {
        id: uid
        name: filename
        size: fileSize
        url: path
        type: mimeType
        attachedAt
      }
    }
  }
  findOwnerAsAMemberUserRequestByEntityId(entityId: $id) {
    createdAt
    status
    customPosition
    financeContributionAmount
    governmental
    email
    foundingMember
    familyRelation {
      uid
    }
    delegatorProof {
      entityName
      id
      userRequestId
      delegatorProofLetters {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      nonGovernmentalProof {
        createdAt
        delegatorProofId
        facilityRecordNumber
        gosiNumber
        id
        proofType
        proofValue
        updatedAt
        zakatCertificateNumber
      }
    }
    memberId
    memberType
    disclosureForm {
      acrm
      crmi
      crmo
      fffl
      lknw
      naff
      nmbd
      oman
      resi
      rman
      id
      userRequestId
    }
    numberOfShares
    position {
      arTitle
      enTitle
      uid
    }
    positionUid
    member {
      nationalId
    }
    role
    specialized
    unitId
    unitType
    id
  }
}`;

export const FIND_OWNER_FAMILY_FUND_ENTITY_BY_ID = `query findOwnerAsAMemberUserRequestByEntityId($id: Int!) {
  findOwnerAsAMemberUserRequestByEntityId(entityId: $id) {
    createdAt
    status
    customPosition
    financeContributionAmount
    governmental
    email
    foundingMember
    familyRelation {
      uid
    }
    delegatorProof {
      entityName
      id
      userRequestId
      delegatorProofLetters {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      nonGovernmentalProof {
        createdAt
        delegatorProofId
        facilityRecordNumber
        gosiNumber
        id
        proofType
        proofValue
        updatedAt
        zakatCertificateNumber
      }
    }
    memberId
    memberType
    disclosureForm {
      acrm
      crmi
      crmo
      fffl
      lknw
      naff
      nmbd
      oman
      resi
      rman
      id
      userRequestId
    }
    numberOfShares
    position {
      arTitle
      enTitle
      uid
    }
    positionUid
    member {
      nationalId
    }
    role
    specialized
    unitId
    unitType
    id
  }
}`;

export const FIND_FAMILY_FUND_SUBSCRIPTION = `query findFamilyFundSubscriptionByEntityIdRequest($entityId: Int!) {
    findFamilyFundSubscriptionByEntityIdRequest(entityId: $entityId) {
      amount
      createdAt
      entityId
      id
      updatedAt
    }
  }
`;

export const CREATE_FAMILY_FUND = `mutation createFamilyFundEntity($familyFundEntityCreateAttributes: CreateFamilyFundEntityInput!){
  createFamilyFundEntity(familyFundEntityCreateAttributes: $familyFundEntityCreateAttributes) {
    id
    foundForUserId
    foundFor
    families{
      name
      relation
    }
    autocreateMembershipForOwner
    owner {
      id
      fatherName
      firstName
      lastName
      educationalCertificates {
        attachedAt
        id: uid
        name: filename
        size: fileSize
        url: path
        type: mimeType
      }
    }
  }
}`;

export const UPDATE_FAMILY_FUND = `mutation updateFamilyFundEntity($familyFundEntityUpdateAttributes: UpdateFamilyFundEntityInput!, $progressStepUpdateAttributes: ProgressStepUpdateInput!){
  updateFamilyFundEntity(familyFundEntityUpdateAttributes: $familyFundEntityUpdateAttributes) {
    id
  }
  updateProgressStep(progressStepUpdateAttributes: $progressStepUpdateAttributes) {
    unitId
    unitType
    step
  }
}`;

export const UPDATE_PROGRESS_STEP = `mutation updateProgressStep($progressStepUpdateAttributes: ProgressStepUpdateInput!){
  updateProgressStep(progressStepUpdateAttributes: $progressStepUpdateAttributes) {
    unitId
  }
}`;

export const UPDATE_OWNER_EDUCATION = `mutation updateUser(
  $userUpdateAttributes: UserUpdateInput!,
  $progressStepUpdateAttributes: ProgressStepUpdateInput!
){
  updateUser(userUpdateAttributes: $userUpdateAttributes) {
    id
    educationalCertificates {
      attachedAt
      id: uid
      name: filename
      size: fileSize
      url: path
      type: mimeType
    }
  }
  updateProgressStep(progressStepUpdateAttributes: $progressStepUpdateAttributes) {
    unitId
  }
}`;

export const UPDATE_FAMILY_OWNER_REQUEST = `mutation UpdateUserRequest(
  $userRequestUpdateAttributes: UserRequestUpdateInput!,
) {
  updateUserRequest(
    userRequestUpdateAttributes: $userRequestUpdateAttributes,
  ) {
    id
  }
}`;

export const CREATE_FAMILY_FUND_SUBSCRIPTION = `mutation createFamilyFundSubscription($familyFundSubscriptionCreateAttributes: FamilyFundSubscriptionCreateInput!){
  createFamilyFundSubscription(familyFundSubscriptionCreateAttributes: $familyFundSubscriptionCreateAttributes) {
    amount
    createdAt
    entityId
    id
    updatedAt
  }
}`;

export const UPDATE_FAMILY_FUND_SUBSCRIPTION = `mutation updateFamilyFundSubscription($familyFundSubscriptionUpdateAttributes: FamilyFundSubscriptionUpdateInput!){
  updateFamilyFundSubscription(familyFundSubscriptionUpdateAttributes: $familyFundSubscriptionUpdateAttributes) {
    amount
    createdAt
    entityId
    id
    updatedAt
  }
}`;

export const DELETE_FAMILY_FUND_SUBSCRIPTION = `mutation deleteFamilyFundSubscription($id: Int!){
  deleteFamilyFundSubscription(id: $id) {
    id
  }
}`;

export const FINAL_SUBMIT_FAMILY_FUND = `mutation FinalSubmitFamilyFund(
  $familyFundSubmitAttributes: SubmitEntityInput!,
) {
  submitFamilyFundEntity(familyFundSubmitAttributes: $familyFundSubmitAttributes) {
    id
  }
}`;

export const GET_INFO = `query findOrCreateUser($nationalId: String!, $birthDateHijri: String!){
  findOrCreateUser(nationalId: $nationalId, birthDateHijri: $birthDateHijri) {
    absherPhone
    birthDateHijri
    birthplace
    createdAt
    degrees
    deletedAt
    educationSpecialization
    educationalCertificateUids
    educationalCertificates {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    educationalLevel
    email
    employmentPlace
    fatherName
    firstName
    gender
    grandfatherName
    homePhone
    id
    lastName
    nationalAddress {
      additionalNo
      buildingNo
      cityAr
      cityEn
      createdAt
      districtAreaAr
      districtAreaEn
      id
      latitude
      longitude
      streetNameAr
      streetNameEn
      unitNo
      updatedAt
      zipCode
    }
    nationalId
    nationality {
      arTitle
      enTitle
      uid
    }
    nidExpireDate
    occupation
    previousExperience
    socialMedias {
      accountName
      id
      type
      userId
    }
    updatedAt
  }
}`;
