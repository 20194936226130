import { GraphQLRequest } from '@/setup/axios';
import {
  CREATE_ENTITY_BANK, DELETE_ENTITY_BANK, UPDATE_ENTITY_BANK, LIST_ENTITY_BANKS,
} from '@/api/graphql/entity-relations-gql';

class EntityRelationsApi {
  listEntityBanks = (unitId, unitType) => (
    GraphQLRequest({
      query: LIST_ENTITY_BANKS,
      variables: {
        unitId: Number(unitId),
        unitType,
      },
    })
  )

  createEntityBank = (data) => (
    GraphQLRequest({
      query: CREATE_ENTITY_BANK,
      variables: {
        entitiesBankCreateAttributes: {
          unitId: Number(data.unitId),
          unitType: data.unitType,
          bankId: Number(data.bankId),
          bankBranchName: data.bankBranchName,
        },
      },
    })
  )

  updateEntityBank = (data) => (
    GraphQLRequest({
      query: UPDATE_ENTITY_BANK,
      variables: {
        entitiesBankUpdateAttributes: {
          id: Number(data.id),
          bankId: Number(data.bankId),
          bankBranchName: data.bankBranchName,
        },
      },
    })
  )

  deleteEntityBank = (id) => (
    GraphQLRequest({
      query: DELETE_ENTITY_BANK,
      variables: {
        id: Number(id),
      },
    })
  )
}

export default new EntityRelationsApi();
