import i18n from '@/i18n';
import router from '@/router';
import {
  LocaleTypes,
} from '@/constants/enums';
import RequestApi from '@/api/request.api';
import EditPolicyApi from '@/api/edit-policy.api';
import { deserializer } from '@/helpers/api';
import { sortAlphabetically } from '@/helpers/general';
import ErrorHandler from '@/helpers/errors';

import {
  handleEditedLocations,
  handleEditedFields,
} from '@/services/adjustments.service';

const mainState = {
  editedFields: [],
  editedLocations: [],
  secondSubClassification: null,
  adjustmentErrors: [],
};

const mainGetters = {
  getEditedFields: (store) => store.editedFields,
  getEditedLocations: (store) => {
    const isArabic = i18n.locale === LocaleTypes.Ar;
    const locations = store.editedLocations;
    return [{
      editRequestId: locations.editRequestId,
      fieldName: locations.fieldName,
      oldValue: locations.oldValue?.map((item) => ({
        region: isArabic ? item.regionAr : item.regionEn,
        city: isArabic ? item.cityAr : item.cityEn,
      })).sort((a, b) => sortAlphabetically(a.region, b.region)),
      newValue: locations.newValue?.map((item) => ({
        region: isArabic ? item.regionAr : item.regionEn,
        city: isArabic ? item.cityAr : item.cityEn,
      })).sort((a, b) => sortAlphabetically(a.region, b.region)),
    }];
  },
  getAdjustmentErrors: (store) => store.adjustmentErrors,
};

const mutations = {
  setAdjustmentErrors: (store, errors) => {
    store.adjustmentErrors = errors;
  },
  setEditedFields: (store, data) => {
    store.editedFields = data;
  },
  setEditedLocations: (store, data) => {
    store.editedLocations = data;
  },
  setSecondSubClassification: (store, data) => {
    store.secondSubClassification = data;
  },
};

const actions = {
  collectGoalsAndActivities: async ({ dispatch }, secondSubClassification) => {
    await dispatch('lookup/fetchActivities', { classificationId: secondSubClassification }, { root: true });
    await dispatch('lookup/fetchGoals', { classificationId: secondSubClassification }, { root: true });
  },
  getAdminAdjustments: async ({ commit, dispatch, rootGetters }, {
    adjustmentType,
    status,
    unitId,
    unitType,
  }) => {
    try {
      const getAdjustmentsListRequests = await RequestApi.getAdjustmentsListRequests(
        {
          adjustmentType,
          status,
          unitId,
          unitType,
        },
      );
      const adjustmentsList = await deserializer(getAdjustmentsListRequests.data) || [];
      if (adjustmentsList.length) {
        const secondSubClassification = adjustmentsList.find(
          (el) => el.secondSubClassificationId,
        ).secondSubClassificationId;
        const editRequestId = adjustmentsList.find((obj) => obj.id)?.id;
        if (editRequestId) {
          const getEditedFieldsByRequestId = await EditPolicyApi.getEditedFieldsByRequestId(
            editRequestId,
          );
          const editedFields = await deserializer(getEditedFieldsByRequestId.data);
          await dispatch('collectGoalsAndActivities', secondSubClassification);
          const goalsAndActivitiesObj = {
            activityIds: rootGetters['lookup/activitiesOptions'],
            goalIds: rootGetters['lookup/goalsOptions'],
          };
          commit('setEditedFields', handleEditedFields(editedFields, goalsAndActivitiesObj));
          commit('setEditedLocations', handleEditedLocations(editedFields));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  },

  sendAcceptOnAdjustment: async ({ dispatch, commit }, editRequestId) => {
    try {
      const res = await EditPolicyApi.acceptAdminAdjustments(editRequestId);
      dispatch('toast/showNotification', {
        message: i18n.t('requestView.adminChangesSubmitted'),
        duration: 2000,
        type: 'success',
      }, { root: true });
      if (!res?.error) {
        router.push({
          name: 'RequestsViewEntityTable',
        });
      }
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setAdjustmentErrors', error);
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    }
  },

  sendRejectOnAdjustment: async ({ dispatch, commit }, editRequestId) => {
    try {
      const res = await EditPolicyApi.rejectAdminAdjustments(editRequestId);
      dispatch('toast/showNotification', {
        message: i18n.t('requestView.adminChangesSubmitted'),
        duration: 2000,
        type: 'success',
      }, { root: true });
      if (!res?.error) {
        router.push({
          name: 'RequestsViewEntityTable',
        });
      }
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setAdjustmentErrors', error);
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
