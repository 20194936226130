import { getField, updateField } from 'vuex-map-fields';
import snakeCase from 'lodash.snakecase';
import uniqBy from 'lodash.uniqby';
import BankAccountApi from '@/api/bank-account.api';
import EmployeeApi from '@/api/employee.api';
import ErrorHandler from '@/helpers/errors';
import { deserializer, serializer } from '@/helpers/api';
import { getMemberships } from '@/services/membership.service';
import {
  EmployeeRoles, PermitPositions, UnitTypes,
} from '@/constants/enums';
import { findEntity, normalizeBranch } from '@/services/entity.service';
import { normalizeBankPermit } from '@/services/bank.service';
import { fetchListOfBranchesByEntityId } from '@/api/branch.api';

const mainState = () => ({
  form: {
    accountType: '',
    branchInEntity: '',
    bankId: '',
    otherBank: '',
  },
  entity: {},
  employeeRequests: [],
  memberships: {},
  bankPermit: {},
  prevBankPermit: {},
  branches: [],
});

const mainGetters = {
  getField,
  entity: (store) => store.entity,
  memberships: (store) => store.memberships,
  bankPermit: (store) => store.bankPermit,
  prevBankPermit: (store) => store.prevBankPermit,
  branches: (store) => store.branches,
  ceoMember(store) {
    const employee = store.employeeRequests?.find((e) => e.position === EmployeeRoles.Ceo);
    if (employee) {
      return {
        position: 'المسؤول التنفيذي',
        name: '-',
        nationalId: employee.nationalId,
      };
    }
    return null;
  },
  ownerEntity(store) {
    const owner = store.memberships
      ?.find((m) => m.nationalId === store.entity.entityEstablisherNid);
    return {
      position: owner?.positionTranslations?.ar || '-',
      name: store.entity.entityEstablisherName,
      nationalId: store.entity.entityEstablisherNid,
    };
  },
  members(store, getters) {
    const memberships = store.memberships
      .filter((m) => PermitPositions.includes(m.position))
      .map((m) => ({
        position: m?.positionTranslations?.ar || '-',
        name: m.fullName || '',
        nationalId: m?.nationalId,
      }));
    memberships.push(getters.ownerEntity);
    if (getters.ceoMember) {
      memberships.push(getters.ceoMember);
    }
    return uniqBy(memberships, (m) => m.nationalId);
  },
};

const mutations = {
  updateField,
  setEntity: (store, value) => {
    store.entity = value;
  },
  setBranches: (store, value) => {
    store.branches = value;
  },
  setBankPermit: (store, value) => {
    store.bankPermit = value;
  },
  setPrevBankPermit: (store, value) => {
    store.prevBankPermit = value;
  },
  setMemberships: (store, members) => {
    store.memberships = members;
  },
  setEmployeeRequests: (store, employeeRequests) => {
    store.employeeRequests = employeeRequests;
  },
};

const actions = {
  getBranches: async ({ commit }, entityId) => {
    try {
      const res = await fetchListOfBranchesByEntityId({ entityId, statuses: ['accepted'] });
      const data = res.data?.listBranches?.branches || [];
      const branches = data?.map((item) => normalizeBranch(item)) || [];
      commit('setBranches', branches);
    } catch (e) {
      console.warn(e);
    }
  },
  getPermitDocumentsList: async ({ getters, state }) => {
    try {
      const payload = {
        entityType: snakeCase(getters.entity.type),
        accountType: state.form.accountType,
      };
      const res = await BankAccountApi.getPermitDocumentsList(
        payload.entityType,
        payload.accountType,
      );
      return await deserializer(res.data);
    } catch (e) {
      return { error: e.message };
    }
  },
  createBankPermit: async ({ commit, state, getters }, {
    entityId, acceptStep, bankNames,
  }) => {
    try {
      let bankNameAr = '';
      if (!state.form.otherBank) {
        bankNameAr = bankNames.find((item) => item.key === state.form.bankId)?.label;
      }
      const payload = serializer(
        {
          unitId: state.form.branchInEntity || entityId,
          unitType: state.form.branchInEntity ? UnitTypes.Branch : UnitTypes.Entity,
          bankAccountType: state.form.accountType,
          bankName: state.form.otherBank || bankNameAr,
          bankId: state.form.otherBank ? null : state.form.bankId,
          acknowleged_permit_id: getters.prevBankPermit?.id || null,
        },
      );
      const res = await BankAccountApi.createBankPermit(payload);
      const bankPermit = await deserializer(res.data);
      if (bankPermit?.requireAcknowledge && !acceptStep) {
        commit('setPrevBankPermit', bankPermit);
      } else {
        commit('setBankPermit', normalizeBankPermit(bankPermit));
      }
      return bankPermit;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchMemberships: async ({ commit }, entityId) => {
    const res = await getMemberships(entityId);
    commit('setMemberships', res?.error ? [] : res);
  },
  fetchEntity: async ({ commit, rootGetters }, entityId) => {
    let entity = rootGetters['entities/getEntity'](entityId);
    if (!entity) {
      entity = await findEntity(entityId);
    }
    if (!entity?.error) {
      commit('setEntity', entity);
    }
  },
  getEmployeeRequestsByEntityId: async ({ commit }, entityId) => {
    try {
      const { data } = await EmployeeApi.getEmployeeRequestsByEntityId({ entityId });
      commit('setEmployeeRequests', data.listEmployeeRequests.employeeRequests);
    } catch (e) {
      console.warn(e);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
