import { GraphQLRequest } from '@/setup/axios';
import {
  CREATE_UNIFIED_NUMBER_ADMIN_REQUEST,
  CREATE_UNIFIED_NUMBER_ATTACHMENT,
  DELETE_UNIFIED_NUMBER_ADMIN_REQUEST,
  FETCh_UNIFIED_NUMBER_ADMIN_REQUEST,
  FETCh_UNIFIED_NUMBER_ADMIN_REQUEST_BY_ID,
  FETCH_UNIFIED_NUMBER_ATTACHMENTS,
  SUBMIT_UNIFIED_NUMBER_ADMIN_REQUEST,
  UPDATE_UNIFIED_NUMBER_ADMIN_REQUEST,
}
from './graphql/unified-national-number';

class UnifiedNationalNumbersApi {
  createUnifiedNumberAdminRequest = (payload) => GraphQLRequest({
    query: CREATE_UNIFIED_NUMBER_ADMIN_REQUEST,
    variables: {
      unifiedNumberAdminRequestCreateAttributes: {
        entityId: payload.entityId ? Number(payload.entityId) : undefined,
        unifiedNumbers: payload.unifiedNumbers ? payload.unifiedNumbers : undefined,
      },
    },
  });

  getUnifiedNumberAdminRequest = (payload) => (
    GraphQLRequest({
      query: FETCh_UNIFIED_NUMBER_ADMIN_REQUEST,
      variables: {
        page: Number(payload.page),
        size: Number(payload.size),
        entityId: payload.entityId ? Number(payload.entityId) : null,
      },
    })
  );

  deleteUnifiedNumberAdminRequest = (payload) => GraphQLRequest({
    query: DELETE_UNIFIED_NUMBER_ADMIN_REQUEST,
    variables: {
      unifiedNumberAdminRequestUpdateAttributes: {
        id: payload.id ? Number(payload.id) : undefined,
        unifiedNumbers: payload.unifiedNumbers ? payload.unifiedNumbers : undefined,
      },
    },
  });

  createUnifiedNumberAttachment = (payload) => GraphQLRequest({
    query: CREATE_UNIFIED_NUMBER_ATTACHMENT,
    variables: {
      unifiedNumberAttachmentCreateAttributes: {
        adminRequestId: payload.adminRequestId ? Number(payload.adminRequestId) : undefined,
        docType: payload.docType ? payload.docType : undefined,
        fileMetadataUid: payload.fileMetadataUid ? payload.fileMetadataUid : undefined,
        unifiedNumber: payload.unifiedNumber ? payload.unifiedNumber : undefined,
      },
    },
  });

  getUnifiedNumberAttachments = (payload) => GraphQLRequest({
    query: FETCH_UNIFIED_NUMBER_ATTACHMENTS,
    variables: {
      page: Number(payload.page),
      size: Number(payload.size),
      adminRequestId: payload.adminRequestId ? Number(payload.adminRequestId) : null,
    },
  });

  getUnifiedNumberAdminRequestById = (payload) => (
    GraphQLRequest({
      query: FETCh_UNIFIED_NUMBER_ADMIN_REQUEST_BY_ID,
      variables: {
        id: payload.id ? Number(payload.id) : null,
      },
    })
  );

  updateUnifiedNumberAdminRequest = (payload) => {
    const {
      id,
      entityPhoneNumber,
      entityTelephoneNumber,
      entityEmail,
      chairmanBoardFirstName,
      chairmanBoardFatherName,
      chairmanBoardGrandfatherName,
      chairmanBoardLastName,
      chairmanBoardNationalId,
      chairmanBoardBirthday,
      chairmanBoardPhoneNumber,
      chairmanBoardEmail,
      licenseExpiredAt,
      licenseInitiatedAt,
      unifiedNumbers,
    } = payload;

    return GraphQLRequest({
      query: UPDATE_UNIFIED_NUMBER_ADMIN_REQUEST,
      variables: {
        unifiedNumberAdminRequestUpdateAttributes: {
          id: id ? Number(id) : undefined,
          entityPhoneNumber: entityPhoneNumber || undefined,
          entityTelephoneNumber: entityTelephoneNumber || undefined,
          entityEmail: entityEmail || undefined,
          chairmanBoardFirstName: chairmanBoardFirstName || undefined,
          chairmanBoardFatherName: chairmanBoardFatherName || undefined,
          chairmanBoardGrandfatherName: chairmanBoardGrandfatherName || undefined,
          chairmanBoardLastName: chairmanBoardLastName || undefined,
          chairmanBoardNationalId: chairmanBoardNationalId || undefined,
          chairmanBoardBirthday: chairmanBoardBirthday || undefined,
          chairmanBoardPhoneNumber: chairmanBoardPhoneNumber || undefined,
          chairmanBoardEmail: chairmanBoardEmail || undefined,
          licenseExpiredAt: licenseExpiredAt || undefined,
          licenseInitiatedAt: licenseInitiatedAt || undefined,
          unifiedNumbers: unifiedNumbers || undefined,
        },
      },
    });
  };

  submitUnifiedNumberAdminRequest = (payload) => {
    const {
      id,
      ownerFirstName,
      ownerFatherName,
      ownerGrandfatherName,
      ownerLastName,
      ownerNationalId,
      ownerBirthday,
      ownerPhoneNumber,
      ownerEmail,
    } = payload;

    return GraphQLRequest({
      query: SUBMIT_UNIFIED_NUMBER_ADMIN_REQUEST,
      variables: {
        unifiedNumberAdminRequestSubmitAttributes: {
          id: id ? Number(id) : undefined,
          ownerFirstName: ownerFirstName || undefined,
          ownerFatherName: ownerFatherName || undefined,
          ownerGrandfatherName: ownerGrandfatherName || undefined,
          ownerLastName: ownerLastName || undefined,
          ownerNationalId: ownerNationalId || undefined,
          ownerBirthday: ownerBirthday || undefined,
          ownerPhoneNumber: ownerPhoneNumber || undefined,
          ownerEmail: ownerEmail || undefined,
        },
      },
    });
  };
}
export default new UnifiedNationalNumbersApi();
