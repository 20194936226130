import isEmpty from 'lodash.isempty';
import i18n from '@/i18n';

class ErrorHandler {
  parseFormErrors = (error, customErr) => {
    console.warn(error);
    const errorData = error?.response?.data?.error || error?.response?.data;
    if (Array.isArray(errorData) && !isEmpty(errorData)) {
      return { error: errorData };
    }
    const errorMessage = error?.message || customErr || i18n.t('general.internalError');
    return { error: [errorMessage] };
  }

  parseFetchErrors = (error) => {
    console.warn(error);
    const errorCode = error?.response?.status || 500;
    return { error: errorCode };
  }

  parseErrorCode = (err) => {
    console.warn(err);
    const error = err?.response?.data?.error || err;
    if (!isEmpty(error)) {
      return error[0].status || 500;
    }

    return 500;
  }

  parseGlobalErrors = (err) => {
    console.warn(err);
    const error = err?.response?.data?.error || err;

    if (!isEmpty(error)) {
      if (error[0]?.title) {
        return error[0].title[i18n.locale];
      }
      if (error[0]?.debug) {
        return error[0].debug.code;
      }
    }
    if (isEmpty(error)) {
      return i18n.t('general.internalError');
    }
    return error || i18n.t('general.internalError');
  }

  parseErrors = (err) => {
    console.warn(err);
    const error = err?.response?.data?.error || err;

    if (!isEmpty(error)) {
      if (error.length > 0) {
        return error.map((item) => item.title[i18n.locale]).join(', ');
      }
    }
    if (isEmpty(error)) {
      return i18n.t('general.internalError');
    }
    return error || i18n.t('general.internalError');
  }
}

export default new ErrorHandler();
