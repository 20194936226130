import { DialogTypes } from '@/constants/enums';

const mainState = {
  dialogs: Object.keys(DialogTypes).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {}),
  layoutLoader: false,
};

const mainGetters = {
  getDialog: (store) => (type) => store.dialogs[type],
  getLayoutLoader: ({ layoutLoader }) => Boolean(layoutLoader),
};

const mutations = {
  openDialog: (store, type) => {
    store.dialogs[type] = true;
  },
  closeDialog: (store, type) => {
    store.dialogs[type] = false;
  },
  setLayoutLoader: (store, value) => {
    store.layoutLoader = value;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
};
