import Hijri from 'hijrah-date';
import { LocaleTypes } from '@/constants/enums';

export const getEnvVar = (value) => {
  // eslint-disable-next-line
  const config = window._config || {};
  return config[value] || process.env[`VUE_APP_${value}`];
};

// env variables
export const API_URL = getEnvVar('GATEWAY_URL');
export const BANK_DELEGATIONS_SPA = getEnvVar('BANK_DELEGATIONS_SPA');
export const BENEFICIARY_SPA = getEnvVar('BENEFICIARY_SPA');
export const INSPECTION_VISITS_SPA = getEnvVar('INSPECTION_VISITS_SPA');
export const ESHOP_SPA = getEnvVar('ESHOP_SPA');
export const EDIT_POLICY_SPA = getEnvVar('EDIT_POLICY_SPA');
export const BRANCH_SPA = getEnvVar('BRANCH_SPA');
export const ACTIVITY_SPA = getEnvVar('ACTIVITY_SPA');
export const OFFICE_SPA = getEnvVar('OFFICE_SPA');
export const FAQ_URL = getEnvVar('FAQ_URL');
export const FINANCIAL_SPA = getEnvVar('FINANCIAL_SPA');
export const APPOINTING_EMPLOYEE_SPA = getEnvVar('APPOINTING_EMPLOYEE_SPA');
export const MANAGE_MEETINGS_SPA = getEnvVar('MANAGE_MEETINGS_SPA');
export const MIGRATION_SPA = getEnvVar('MIGRATION_SPA');
export const PROJECT_ENV = getEnvVar('PROJECT_ENV');
export const CI_COMMIT_SHORT_SHA = getEnvVar('CI_COMMIT_SHORT_SHA');
export const FRONTEND_URL = getEnvVar('FRONTEND_URL');
export const OAUTH_SERVER = getEnvVar('OAUTH_SERVER');
export const OAUTH_SERVER_EDIT_PROFILE = getEnvVar('OAUTH_SERVER_EDIT_PROFILE');
export const OAUTH_CLIENT_ID = getEnvVar('OAUTH_CLIENT_ID');
export const OAUTH_CLIENT_SECRET = getEnvVar('OAUTH_CLIENT_SECRET');
export const SENTRY_DNS = getEnvVar('SENTRY_DNS');
export const DASHBOARD_URL = getEnvVar('DASHBOARD_URL');

// general variables
export const SUPPORTED_LOCALES = [LocaleTypes.Ar, LocaleTypes.En];
export const DEFAULT_LOCALE = LocaleTypes.Ar;
export const IS_PROD = PROJECT_ENV === 'prod';
export const IS_DEMO = PROJECT_ENV === 'demo';
export const IS_STAGE = PROJECT_ENV === 'stage';

export const ENTITY_BANNED_WORDS = [
  'إقليمي',
  'إقليمية',
  'اميري',
  'اميرية',
  'أهلية',
  'بر',
  'تعاوني',
  'تعاونية',
  'جمعيه',
  'خليجي',
  'خليجية',
  'خيري',
  'خيرية',
  'دولي',
  'دولية',
  'سعودي',
  'سعودى',
  'السعوديه',
  'السعودية',
  'سعودية',
  'سعوديه',
  'صندوق',
  'عالمي',
  'عالمية',
  'عربي',
  'عربيه',
  'فرع',
  'مؤسسة',
  'مركز',
  'مشروع',
  'مكتب',
  'ملكي',
  'ملكية',
  'وطني',
  'وطنى',
  'وطنية',
  'الإقليميه',
  'الإقليمية',
  'الإقليمي',
  'إقليميه',
  'الاميري',
  'الأميري',
  'اميرية',
  'الاميرية',
  'الاميريه',
  'الأميرية',
  'الأميريه',
  'اميريه',
  'أميريه',
  'الأهليه',
  'أهليه',
  'الأهلية',
  'البر',
  'التعاوني',
  'تعاونيه',
  'التعاونيه',
  'التعاونية',
  'الجمعيه',
  'الجمعية',
  'جمعية',
  'الخليجي',
  'الخيري',
  'خيريه',
  'الخيرية',
  'الخيريه',
  'الدولي',
  'الدولية',
  'الدوليه',
  'السعودي',
  'السعوديه',
  'سعوديه',
  'الصندوق',
  'العالمي',
  'عالميه',
  'العالميه',
  'العالمية',
  'العربي',
  'العربية',
  'العربيه',
  'عربية',
  'الفرع',
  'المؤسسة',
  'المؤسسه',
  'مؤسسه',
  'المركز',
  'المشروع',
  'المكتب',
  'الملكي',
  'الملكيه',
  'الملكية',
  'ملكيه',
  'الوطني',
  'الوطنية',
  'الوطنيه',
  'وطنيه',
];

export const OFFICES_LIST = [
  {
    imgSrc: require('@/assets/images/offices/off1.png'),
    textKey: 'info.office1',
    slug: 'office1',
    name: 'Office',
  },
  {
    imgSrc: require('@/assets/images/offices/off2.png'),
    textKey: 'info.office2',
    slug: 'office2',
    name: 'Office',
  },
  {
    imgSrc: require('@/assets/images/offices/off3.png'),
    textKey: 'info.office3',
    slug: 'office3',
    name: 'Office',
  },
  {
    imgSrc: require('@/assets/images/offices/off1.png'),
    textKey: 'info.office4',
    slug: 'office4',
    name: 'Office',
  },
  {
    imgSrc: require('@/assets/images/offices/off2.png'),
    textKey: 'info.office5',
    slug: 'office5',
    name: 'Office',
  },
  {
    imgSrc: require('@/assets/images/offices/off3.png'),
    textKey: 'info.office6',
    slug: 'office6',
    name: 'Office',
  },
  {
    imgSrc: require('@/assets/images/offices/off1.png'),
    textKey: 'info.office7',
    slug: 'office7',
    name: 'Office',
  },
];

export const SUPERVISORY_LIST = [
  {
    textKey: 'info.supervisor1',
    slug: 'supervisory1',
    name: 'Supervisory',
  },
  {
    textKey: 'info.supervisor2',
    slug: 'supervisory2',
    name: 'Supervisory',
  },
  {
    textKey: 'info.supervisor3',
    slug: 'supervisory3',
    name: 'Supervisory',
  },
  {
    textKey: 'info.supervisor4',
    slug: 'supervisory4',
    name: 'Supervisory',
  },
  {
    textKey: 'info.supervisor5',
    slug: 'supervisory5',
    name: 'Supervisory',
  },
  {
    textKey: 'info.supervisor6',
    slug: 'supervisory6',
    name: 'Supervisory',
  },
  {
    textKey: 'info.supervisor7',
    slug: 'supervisory7',
    name: 'Supervisory',
  },
  {
    textKey: 'info.supervisor8',
    slug: 'supervisory8',
    name: 'Supervisory',
  },
];

const hijrahDate = new Hijri();
const minAllowableAge = 18;
const getOption = (i) => (i < 9 ? `0${i + 1}` : i + 1);
export const DAY_OPTIONS = Array.from(Array(30), (_, i) => `${getOption(i)}`);
export const MONTH_OPTIONS = Array.from(Array(12), (_, i) => `${getOption(i)}`);
export const YEAR_OPTIONS = Array.from(
  Array(101),
  (_, i) => `${hijrahDate.getFullYear() - minAllowableAge - i}`,
);

export const DISCLOSURE_LIST = [
  {
    title: 'join.joinReputation',
    list: [
      {
        condition: null,
        text: 'join.joinReputationText1',
        value: '',
        shortName: 'acrm',
      },
      {
        condition: null,
        text: 'join.joinReputationText2',
        value: '',
        shortName: 'crmo',
      },
      {
        condition: null,
        text: 'join.joinReputationText3',
        value: '',
        shortName: 'crmi',
      },
      {
        condition: null,
        text: 'join.joinReputationText4',
        value: '',
        shortName: 'resi',
      },
    ],
  },
  {
    title: 'join.joinCapacity',
    list: [
      {
        condition: null,
        text: 'join.joinCapacityText1',
        value: '',
        shortName: 'oman',
      },
      {
        condition: null,
        text: 'join.joinCapacityText2',
        value: '',
        shortName: 'rman',
      },
      {
        condition: null,
        text: 'join.joinCapacityText3',
        value: '',
        shortName: 'lknw',
      },
      {
        condition: null,
        text: 'join.joinCapacityText4',
        value: '',
        shortName: 'naff',
      },
    ],
  },
  {
    title: 'join.joinSolvency',
    list: [
      {
        condition: null,
        text: 'join.joinSolvencyText1',
        value: '',
        shortName: 'fffl',
      },
      {
        condition: null,
        text: 'join.joinSolvencyText2',
        value: '',
        shortName: 'nmbd',
      },
    ],
  },
];

export const MEMBERSHIP_REQUIREMENTS = [
  'item1',
  'item2',
  'item3',
  'item4',
  'item5',
  'item6',
  'item7',
];

export const MEMBERSHIP_REQUIREMENTS_PAE = [
  'item1',
  // "item2",
  'item3',
  'item4',
  // "item5",
  // "item6",
  // "item7",
  // "item8",
  'item9',
];

export const PARTNERS_LOGO_LIST = {
  en: [
    {
      src: require('@/assets/images/partners/hrsd-logo-en.png'),
      alt: 'hsrd logo',
      href: 'https://hrsd.gov.sa/',
    },
  ],
  ar: [
    {
      src: require('@/assets/images/partners/hrsd-logo-ar.png'),
      alt: 'hsrd logo',
      href: 'https://hrsd.gov.sa/',
    },
  ],
};

const getMustachePrivateAssociationUserManual = () => {
  const link = '/user-manual/_دليل المستخدم لخدمة تأسيس جمعية أهلية.pdf';
  return `<a  href="${link}" download>دليل المستخدم</a>`;
};

const getMustachePrivateCorporationUserManual = () => {
  const link = '/user-manual/_دليل المستخدم لخدمة تأسيس مؤسسة أهلية.pdf';
  return `<a href="${link}" download>دليل المستخدم</a>`;
};

const getMustachePrivateFamilyFundUserManual = () => {
  const link = '/user-manual/_دليل المستخدم لخدمة تأسيس صندوق عائلي.pdf';
  return `<a href="${link}" download>دليل المستخدم</a>`;
};
export const SERVICE_LIST = {
  registration: [
    {
      text: 'تتيح هذه الخدمة للمواطنين أو الجهات الاعتبارية تأسيس جمعية أهلية عن طريق تقديم الطلب إلكترونياً دون الحاجة إلى مراجعة المركز الوطني لتنمية القطاع غير الربحي.',
      // label: 'خدمة جديدة',
      tags: ['التسجيل والتراخيص'],
      title: 'تأسيس جمعية أهلية',
      labelColor: 'bold-orange',
      link: getMustachePrivateAssociationUserManual(),
    },
    {
      text: 'تتيح هذه الخدمة للمواطنين أو الجهات الاعتبارية تأسيس مؤسسة أهلية عن طريق تقديم الطلب إلكترونياً دون الحاجة إلى مراجعة المركز الوطني لتنمية القطاع غير الربحي.',
      // label: 'خدمة جديدة',
      tags: ['التسجيل والتراخيص'],
      title: 'تأسيس مؤسسة أهلية',
      labelColor: 'bold-orange',
      link: getMustachePrivateCorporationUserManual(),
    },
    {
      text: 'تتيح هذه الخدمه للمواطنين تأسيس الصناديق العائلية لتعزيز صلة الرحم ، وبث روح التكافل والتآلف والرحمة و تنظيم أوجه الإحسان بين أفراد العائلة عن طريق تقديم الطلب إلكترونياً دون الحاجة الى مراجعة المركز الوطني لتنمية القطاع غير الربحي.',
      // label: 'خدمة جديدة',
      tags: ['التسجيل والتراخيص'],
      title: 'تأسيس صندوق عائلي',
      labelColor: 'bold-orange',
      link: getMustachePrivateFamilyFundUserManual(),
    },
    {
      text: 'تتيح هذه الخدمة تحديث بيانات التراخيص الخاصة بالكيان بعد أي تحديث يطرأ  عليها ',
      // label: 'خدمة جديدة',
      tags: ['التسجيل والتراخيص'],
      title: 'تجديد التسجيل و التراخيص',
      labelColor: 'bold-orange',
      link: null,
    },
  ],
  supervision: [
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تقديم طلب تنفيذ نشاط سواء كان النشاط من ضمن الخطة السنوية أو خارجها',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'طلب تنفيذ نشاط',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تقديم طلب التسجيل في منصة نوى و تحديث بياناتها للاستفادة من كافة خدمات المنصة ',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'تحديث بيانات الكيانات المؤسسة مسبقاً ',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من إنشاء فروع تابعة لها بدءاً من تقديم الطلب حتي اصدار شهادة تسجيل الفرع',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'إنشاء الفرع',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من إنشاء فروع و مكاتب تابعة لها بدءاً من تقديم الطلب حتي اصدار شهادة تسجيل المكتب',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'إنشاء المكتب ',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات  من تحديث اللائحة الأساسية و التعديل على النطاق الجغرافي والتصانيف والأهداف',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'التعديل على اللائحة',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تعيين الموظفين للكيان إلكترونياً',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'تعيين الموظفين ',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من الحصول على الموافقة على فتح حساب مصرفي ',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'تصريح فتح حساب مصرفي ',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تسجيل بيانات منسوبيها إلكترونياً',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'إدارة بيانات منسوبي الكيان',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تقديم طلب تنفيذ نشاط خارج تخصص الكيان او نطاقه الجغرافي',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: 'ممارسة نشاط خارج تخصص او النطاق الجغرافي',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تعيين مفوضين للقيام بالتعاملات البنكية المختلفة بالنيابة عن الأشخاص المخولين نظامًا',
      // label: 'خدمة جديدة',
      tags: ['الإشراف والحوكمة'],
      title: ' تعيين مفوضين للتعامل مع المعاملات البنكية',
      labelColor: 'bold-orange',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تقديم طلب تعيين مكتب محاسب قانوني للكيان سواء كان المكتب المراد تعيينه هو من المكاتب الموفرة من قبل الوزارة أم مكتب سيتم التعاقد معه من قبل الكيان',
      // label: 'قريباً',
      tags: ['الإشراف والحوكمة'],
      title: 'تعيين مكتب محاسب قانوني',
      labelColor: 'blue',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من عقد و إدارة اجتماعات مجلس الإدارة  إلكترونياً',
      // label: 'قريباً',
      tags: ['الإشراف والحوكمة'],
      title: 'إدارة اجتماعات مجلس الإدارة ',
      labelColor: 'blue',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من إدارة انتخابات مجلس الإدارة إلكترونياً',
      // label: 'قريباً',
      tags: ['الإشراف والحوكمة'],
      title: 'انتخابات مجلس الإدارة',
      labelColor: 'blue',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من عقد و إدارة اجتماعات الجمعية العمومية إلكترونياً',
      // label: 'قريباً',
      tags: ['الإشراف والحوكمة'],
      title: 'إدارة اجتماعات الجمعية العمومية ',
      labelColor: 'blue',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى اتاحة الكيانات من الطلاع على بيانات الدعوم المقدمة للمستفيدين',
      // label: 'قريباً',
      tags: ['الإشراف والحوكمة'],
      title: 'إدارة دعوم المستفيدين',
      labelColor: 'blue',
      link: null,
    },
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تقديم طلب اصدار ترخيص متجر أو موقع إلكتروني',
      // label: 'قريباً',
      tags: ['الإشراف والحوكمة'],
      title: 'إصدار رخصة متجر او موقع الكتروني',
      labelColor: 'blue',
      link: null,
    },
  ],
  support: [
    {
      text: 'تهدف هذه الخدمة إلى تمكين الكيانات من تقديم طلب تنفيذ نشاط سواء كان النشاط من ضمن الخطة السنوية أو خارجها',
      // label: 'خدمة جديدة',
      tags: ['الدعم المالي والمؤسسي'],
      title: 'اعتماد الحساب البنكي',
      labelColor: 'bold-orange',
      link: null,
    },
  ],
};
