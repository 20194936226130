export const LIST_ENTITY_REQUESTS = `query listEditRequests($type: [EditRequestType!], $unitType: String, $status: [EditRequestStatus!], $unitId: Int, $page: Int!, $size: Int!) {
  listEditRequests(type: $type, unitType: $unitType, status: $status, unitId: $unitId, page: $page, size: $size) {
    editRequests {
      status
      id
      unitId
      type
      comments
      createdAt
      updatedAt
      version
      lockRequest {
        accountId
        requestId
        requestType
        status
      }
    }
  }
}`;

export const ACCEPT_EDIT_REQUEST = `mutation acceptEditRequest($editRequestAttributes: EditRequestAcceptInput!) {
  acceptEditRequest(editRequestAttributes: $editRequestAttributes) {
    accountId
  }
}`;

export const REJECT_EDIT_REQUEST = `mutation rejectEditRequest($editRequestAttributes: EditRequestRejectInput!) {
  rejectEditRequest(editRequestAttributes: $editRequestAttributes) {
    accountId
  }
}`;

export const LIST_EDIT_ITEMS = `query listEditItems($editRequestId: Int!, $action: [EditRequestAction!], $page: Int!, $size: Int!) {
  listEditItems(editRequestId: $editRequestId, action: $action, page: $page, size: $size) {
    editItems {
      action
      createdAt
      currentPayload {
        activityIds
        additionalNumber
        amount
        bankBranchName
        bankId
        buildingNumber
        ceoCustomCondition
        ceoDegreesCondition
        ceoEducationalLevelCondition
        ceoPreviousExperienceCondition
        cityUid
        description
        duties
        entityId
        fee
        goalIds
        minNumOfSharesPerMember
        nameArabic
        nameEnglish
        numberOfBoardMembers
        numberOfShares
        postCode
        regionCode
        rights
        secondSubClassificationId
        shareValue
        streetName
        type
        typeOfBenefits
        unitId
      }
      editRequestId
      id
      newPayload {
        activityIds
        additionalNumber
        amount
        bankBranchName
        bankId
        buildingNumber
        ceoCustomCondition
        ceoDegreesCondition
        ceoEducationalLevelCondition
        ceoPreviousExperienceCondition
        cityUid
        description
        duties
        entityId
        fee
        goalIds
        minNumOfSharesPerMember
        nameArabic
        nameEnglish
        numberOfBoardMembers
        numberOfShares
        postCode
        regionCode
        rights
        secondSubClassificationId
        shareValue
        streetName
        type
        typeOfBenefits
        unitId
      }
      unitId
      unitType
      updatedAt
    }
  }
}`;
