export const GET_INFO = `query findOrCreateUser($nationalId: String!, $birthDateHijri: String!){
  findOrCreateUser(nationalId: $nationalId, birthDateHijri: $birthDateHijri) {
    absherPhone
    birthDateHijri
    birthplace
    createdAt
    degrees
    deletedAt
    educationSpecialization
    educationalCertificateUids
    educationalCertificates {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    educationalLevel
    email
    employmentPlace
    fatherName
    firstName
    gender
    grandfatherName
    homePhone
    id
    lastName
    nationalAddress {
      additionalNo
      buildingNo
      cityAr
      cityEn
      createdAt
      districtAreaAr
      districtAreaEn
      id
      latitude
      longitude
      streetNameAr
      streetNameEn
      unitNo
      updatedAt
      zipCode
    }
    nationalId
    nationality {
      arTitle
      enTitle
      uid
    }
    nidExpireDate
    occupation
    previousExperience
    socialMedias {
      accountName
      id
      type
      userId
    }
    updatedAt
  }
}`;

export const GET_INFO_BY_ID = `query findByNationalId($nationalId: String!){
  findByNationalId(nationalId: $nationalId) {
    absherPhone
    birthDateHijri
    birthplace
    createdAt
    degrees
    deletedAt
    educationSpecialization
    educationalCertificateUids
    educationalCertificates {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    educationalLevel
    email
    employmentPlace
    fatherName
    firstName
    gender
    grandfatherName
    homePhone
    id
    lastName
    nationalAddress {
      additionalNo
      buildingNo
      cityAr
      cityEn
      createdAt
      districtAreaAr
      districtAreaEn
      id
      latitude
      longitude
      streetNameAr
      streetNameEn
      unitNo
      updatedAt
      zipCode
    }
    nationalId
    nationality {
      arTitle
      enTitle
      uid
    }
    nidExpireDate
    occupation
    previousExperience
    socialMedias {
      accountName
      id
      type
      userId
    }
    updatedAt
  }
}`;
