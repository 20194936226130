import Vue from 'vue';

import { OAuth2Client } from '@badgateway/oauth2-client';

import {
  OAUTH_SERVER,
  OAUTH_CLIENT_ID,
  OAUTH_CLIENT_SECRET,
} from '@/constants';

export const setupOAuth = () => {
  const client = new OAuth2Client({
    server: OAUTH_SERVER,
    clientId: OAUTH_CLIENT_ID,
    clientSecret: OAUTH_CLIENT_SECRET,
    tokenEndpoint: '/oauth/token',
    authorizationEndpoint: '/oauth/authorize',
  });

  Vue.prototype.OAuthClient = client;
};
