import { configure, extend } from 'vee-validate';
import { validations as generalValidations } from '@takamol/nawa-library/src/validations';
import i18n from '@/i18n';
import { RULES } from './validationRules';

export const setupValidation = () => {
  configure({
    defaultMessage: (field, values) => i18n.t(
      // eslint-disable-next-line
      `validations.${values._rule_}`, values,
    ),
  });
};

const validations = [
  ...generalValidations,
  {
    name: 'mimes',
    rule: RULES.mimes,
  },
  {
    name: 'minDimensions',
    rule: RULES.minDimensions,
  },
  {
    name: 'imageRequired',
    rule: RULES.required,
  },
  {
    name: 'image',
    rule: RULES.image,
  },
  {
    name: 'size',
    rule: RULES.size,
  },
  {
    name: 'goalsFieldRequired',
    rule: RULES.required,
  },
  {
    name: 'activitiesFieldRequired',
    rule: RULES.required,
  },
  {
    name: 'secondClassificationFieldRequired',
    rule: RULES.required,
  },
  {
    name: 'firstClassificationFieldRequired',
    rule: RULES.required,
  },
  {
    name: 'classificationFieldRequired',
    rule: RULES.required,
  },
  {
    name: 'cityFieldRequired',
    rule: RULES.required,
  },
  {
    name: 'positiveRequired',
    rule: RULES.required,
  },

  {
    name: 'entityType',
    rule: RULES.required,
  },

  {
    name: 'minimumRequiredYears',
    rule: RULES.required,
  },

  {
    name: 'minimumRequiredEducation',
    rule: RULES.required,
  },

  {
    name: 'governmentName',
    rule: RULES.required,
  },

  {
    name: 'endowmentLicense',
    rule: RULES.required,
  },

  {
    name: 'multipleInput',
    rule: RULES.validate,
  },

  {
    name: 'financialProof',
    rule: RULES.validate,
  },

  {
    name: 'branchApprovalFile',
    rule: RULES.validate,
  },

  {
    name: 'attachmentRequired',
    rule: RULES.validate,
  },

  {
    name: 'bankCertificate',
    rule: RULES.validate,
  },

  {
    name: 'bankStatement',
    rule: RULES.validate,
  },

  {
    name: 'governmentProof',
    rule: RULES.validate,
  },

  {
    name: 'bankVerificationUpload',
    rule: RULES.validate,
  },

  {
    name: 'approveUpload',
    rule: RULES.validate,
  },

  {
    name: 'approvalOfSupervisoryUpload',
    rule: RULES.validate,
  },

  {
    name: 'areaOfActivity',
    rule: RULES.validate,
  },
  {
    name: 'noEmptyCitiesInArea',
    rule: RULES.notTrue,
  },

  {
    name: 'bank',
    rule: RULES.validate,
  },

  {
    name: 'bankError',
    rule: RULES.validate,
  },

  {
    name: 'terms',
    rule: RULES.validate,
  },

  {
    name: 'dataMustBeVerified',
    rule: RULES.validate,
  },

  {
    name: 'termsPolicy',
    rule: RULES.validate,
  },

  {
    name: 'termsPledge',
    rule: RULES.validate,
  },

  {
    name: 'acknowledgement',
    rule: RULES.validate,
  },

  {
    name: 'termsAgreement',
    rule: RULES.validate,
  },

  {
    name: 'entityBranchName',
    rule: RULES.required,
  },

  {
    name: 'financialTerms',
    rule: RULES.validate,
  },

  {
    name: 'minValue',
    rule: RULES.min_value,
  },

  {
    name: 'positive',
    rule: RULES.min_value,
  },

  {
    name: 'positiveOne',
    rule: RULES.min_value,
  },

  {
    name: 'emailConfirmation',
    rule: RULES.values_match,
  },

  {
    name: 'arabic',
    rule: RULES.arabic,
  },

  {
    name: 'english',
    rule: RULES.english,
  },

  {
    name: 'nid',
    rule: RULES.nidOrCr,
  },
  {
    name: 'crNumber',
    rule: RULES.nidOrCr,
  },

  {
    name: 'double',
    rule: RULES.double,
  },

  {
    name: 'mustBeInteger',
    rule: RULES.integer,
  },

  {
    name: 'positiveNumber',
    rule: RULES.numeric,
  },
  {
    name: 'nidChairmanBoard',
    rule: RULES.nidChairmanBoard,
  },
  {
    name: 'nidOwner',
    rule: RULES.nidOwner,
  },
  {
    name: 'dateRequired',
    rule: RULES.dateRequired,
  },

  {
    name: 'iban',
    rule: RULES.iban,
  },

  {
    name: '700number',
    rule: RULES.bank700number,
  },

  {
    name: 'startWithDigits',
    rule: RULES.firstIsDigit,
  },

  {
    name: 'digitsOrFloat',
    rule: RULES.digitsOrFloat,
  },
  {
    name: 'startsWithOneOrFive',
    rule: RULES.startsWithOneOrFive,
  },
  {
    name: 'maxAssociateFee',
    rule: RULES.maxValue,
  },

  {
    name: 'positiveDouble',
    rule: RULES.positiveDouble,
  },

  {
    name: 'positiveDoubleMin',
    rule: RULES.positiveDoubleMin,
  },

  {
    name: 'maxLengthDigits',
    rule: RULES.maxLength,
  },
  {
    name: 'lessThan',
    rule: RULES.lessThan,
  },

  {
    name: 'moreThanZero',
    rule: RULES.moreThanZero,
  },

  {
    name: 'minLengthCustom',
    rule: RULES.minLengthCustom,
  },
  {
    name: 'entityBannedWords',
    rule: RULES.bannedWords,
  },

  {
    name: 'editEntityPolicy',
    rule: RULES.validate,
  },

  {
    name: 'shortMonth',
    rule: RULES.validate,
  },

  {
    name: 'editEntityPolicyApproval',
    rule: RULES.validate,
  },

  {
    name: 'uploadBankCertificate',
    rule: RULES.validate,
  },
  {
    name: 'familyFundDisclosureTerms',
    rule: RULES.validate,
  },
  {
    name: 'termsFamilyFund',
    rule: RULES.validate,
  },
  {
    name: 'familyGoalsRequired',
    rule: RULES.required,
  },
  {
    name: 'familyFundsRequired',
    rule: RULES.validate,
  },
  {
    name: 'familyFundsNameRequired',
    rule: RULES.validate,
  },
  {
    name: 'haveSubscriptionsRequired',
    rule: RULES.required,
  },
  {
    name: 'relationRequired',
    rule: RULES.required,
  },
  {
    name: 'nidNotOwner',
    rule: RULES.nidNotOwner,
  },
  {
    name: 'capitalValidation',
    rule: RULES.capitalValidation,
  },
  {
    name: 'entityMembers',
    rule: RULES.required,
  },
];

export const setupValidationRules = () => {
  validations.forEach((validation) => {
    extend(
      validation.name,
      validation.rule,
    );
  });
};
