import { getField, updateField } from 'vuex-map-fields';
import find from 'lodash.find';
import isEmpty from 'lodash.isempty';
import i18n from '@/i18n';
import {
  EditRequestUnitTypes,
  EditRequestUnitActions,
  LocaleTypes,
  EditPolicyStatuses,
  mediaAttachableTypes,
  mediaDocTypes,
  ClassificationsTypes,
} from '@/constants/enums';
import EditPolicyApi from '@/api/edit-policy.api';
import LookupApi from '@/api/lookup.api';
import { serializer, deserializer } from '@/helpers/api';
import ErrorHandler from '@/helpers/errors';
import LookupService from '@/services/lookup.service';
import {
  compareOriginalAndCurrentGoalsAndActivities,
  createOriginalDublicateGoalsAndActivities,
  normalizeEntityPolicy,
} from '@/services/entity-policy.service';
import { getDocumentsList, uploadDocuments } from '@/services/media.service';

export const editPolicyConfig = {
  classification: {
    fieldId: 'classification',
    resetIds: ['firstClassification', 'secondClassification', 'goals', 'activities'],
    fetch: ['fetchFirstClassifications'],
    needToDisable: ['secondClassification', 'goals', 'activities'],
  },
  firstClassification: {
    fieldId: 'firstClassification',
    resetIds: ['secondClassification', 'goals', 'activities'],
    fetch: ['fetchSecondClassifications'],
    needToDisable: ['goals', 'activities'],
  },
  secondClassification: {
    fieldId: 'secondClassification',
    resetIds: ['goals', 'activities'],
    fetch: ['fetchGoals', 'fetchActivities'],
    needToDisable: [],
  },
  goals: {
    fieldId: 'goals',
    resetIds: [],
    fetch: [],
    needToDisable: [],
  },
  activities: {
    fieldId: 'activities',
    resetIds: [],
    fetch: [],
    needToDisable: [],
  },
};

const mainState = {
  entityInfo: normalizeEntityPolicy(),
  entityLocations: [],
  isEntityRegulationItemsChanged: false,
  isEntityGoalsAndActivitiesChanged: false,
  fileLoading: false,
  editRequestInformation: null,
  originalGoalsAndActivities: {},
  editModes: {
    classification: {
      isEditable: false,
      isDisabled: false,
    },
    firstClassification: {
      isEditable: false,
      isDisabled: false,
    },
    secondClassification: {
      isEditable: false,
      isDisabled: false,
    },
    goals: {
      isEditable: false,
      isDisabled: false,
    },
    activities: {
      isEditable: false,
      isDisabled: false,
    },
  },
};
const mainGetters = {
  getField,
  getEntityInfo: (state) => state.entityInfo,
  isEntityRegulationItemsChanged: (state) => state.isEntityRegulationItemsChanged,
  getEntityLocations: (state) => state.entityLocations
    .map((item) => ({
      key: item.editItemId,
      id: item.unitId ? item.unitId : item.editItemId,
      editItemId: item.editItemId,
      cityUid: item.cityUid,
      regionCode: item.regionCode,
      city: i18n.locale === LocaleTypes.Ar ? item.cityArTitle : item.cityEnTitle,
      region: i18n.locale === LocaleTypes.Ar ? item.regionArTitle : item.regionEnTitle,
      loading: item.loading,
      isTemporary: !item.unitId,
    })),
  isEditRequestPending: (state) => (
      state.editRequestInformation?.status === EditPolicyStatuses.Pending
  ),
  isFileLoading: (state) => state.fileLoading,
  editRequestInformation: (state) => state.editRequestInformation,
  isEntityGoalsAndActivitiesChanged: (state) => state.isEntityGoalsAndActivitiesChanged,
  getEditModes: (state) => state.editModes,
};
const mutations = {
  updateField,
  setEntityLocation: (state, entityLocation) => {
    state.entityLocations = [...state.entityLocations, entityLocation];
  },
  setEntityRegulationItemsChanged: (state, value) => {
    state.isEntityRegulationItemsChanged = value;
  },
  setEntityLocationDeleteLoading: (state, { id, value }) => {
    const location = state.entityLocations.find((l) => l.editItemId === id);
    if (location) {
      location.loading = value;
    }
  },
  deleteEntityLocation: (state, id) => {
    state.entityLocations = state.entityLocations.filter((l) => l.editItemId !== id);
  },
  setFileLoading: (state, value) => {
    state.fileLoading = value;
  },
  saveRegulationItemsEditItems: (state, editItems) => {
    const editItemsWithMergedPayload = editItems
      .map(({
        unitType, currentPayload, newPayload, editRequestId, id, unitId,
      }) => ({
        unitType,
        editRequestId,
        editItemId: id,
        id: unitId,
        unitId,
        ...Object.assign(currentPayload, newPayload),
      }));

    state.entityInfo = normalizeEntityPolicy(find(editItemsWithMergedPayload, { unitType: 'Entity' }));
    state.entityLocations = editItemsWithMergedPayload
      .filter(({ unitType }) => unitType === 'EntityLocation')
      .map((item) => ({
        ...item,
        loading: false,
      }));
  },
  setEditRequestInformation: (state, payload) => {
    state.editRequestInformation = payload;
  },
  setEntityGoalsAndActivitiesChanged: (state, value) => {
    state.isEntityGoalsAndActivitiesChanged = value;
  },
  saveGoalsAndActivitiesEditItems: (state, editItems) => {
    const editItemsWithMergedPayload = editItems
      .map(({
        unitType, currentPayload, newPayload, editRequestId, id, unitId,
      }) => ({
        unitType,
        editRequestId,
        editItemId: id,
        id: unitId,
        ...Object.assign(currentPayload, newPayload),
      }));
    state.entityInfo = normalizeEntityPolicy(find(editItemsWithMergedPayload, { unitType: 'Entity' }));
  },
  setOriginalDublicateGoalsAndActivities: (state, data) => {
    state.originalGoalsAndActivities = createOriginalDublicateGoalsAndActivities(data);
  },
  setEditingMode: (state, { key, value }) => {
    state.editModes[key].isEditable = value;
  },
  setDisabledField: (state, { field, value }) => {
    state.editModes[field].isDisabled = value;
  },
  resetClassificationsIds: (state, array) => {
    array.forEach((field) => {
      state.entityInfo[field] = null;
    });
  },
};
const actions = {
  fetchEditRequest: async ({ commit }, editRequestId) => {
    try {
      const resp = await EditPolicyApi.fetchEditRequest(editRequestId);
      const editRequestInformation = await deserializer(resp.data);
      commit('setEditRequestInformation', editRequestInformation);
      return editRequestInformation;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchRegulationItemsEditItems: async ({ commit }, { editRequestId, action }) => {
    try {
      const response = await EditPolicyApi.fetchEditItems(editRequestId, action);
      const editItems = await deserializer(response.data);
      commit('saveRegulationItemsEditItems', editItems);
      return editItems;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchGoalsAndActivitiesEditItems: async ({ commit }, editRequestId) => {
    try {
      const response = await EditPolicyApi.fetchEditItems(editRequestId);
      const editItems = await deserializer(response.data);
      commit('saveGoalsAndActivitiesEditItems', editItems);
      return editItems;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  createEntityLocationField: async ({ commit, state }, data) => {
    const session = state.editRequestInformation;
    const unitId = state.entityInfo.id;
    if (session.id && unitId) {
      try {
        const payload = {
          action: EditRequestUnitActions.Create,
          unitType: EditRequestUnitTypes.EntityLocation,
          editRequestId: session.id,
          ...data,
        };
        const serializedData = serializer(payload, 'edit_item');
        const editRequestItem = await EditPolicyApi.saveEditedField(serializedData, session.id);
        const deserializedEditRequestItem = await deserializer(editRequestItem.data);
        const region = LookupService.getRegions()
          .find((r) => r.code === data.newPayload.regionCode);
        const city = LookupService.getCities(data.newPayload.regionCode)
          .find((c) => c.uid === data.newPayload.cityUid);

        commit('setEntityLocation', {
          editItemId: deserializedEditRequestItem.id,
          cityUid: data.newPayload.cityUid,
          regionCode: data.newPayload.regionCode,
          cityArTitle: city.arTitle,
          cityEnTitle: city.enTitle,
          regionArTitle: region.arTitle,
          regionEnTitle: region.enTitle,
          unitType: data.unitType,
          loading: false,
          unitId: deserializedEditRequestItem.unitId,
        });
        commit('setEntityRegulationItemsChanged', true);
      } catch (e) {
        const { error } = ErrorHandler.parseFormErrors(e);
        throw error;
      }
    }
  },
  deleteExistingEntityLocationField: async ({ commit, state }, { data, id, editItemId }) => {
    const session = state.editRequestInformation;
    if (session.id) {
      commit('setEntityLocationDeleteLoading', { value: true, id: editItemId });
      try {
        const payload = await serializer({
          unitId: id,
          unitType: EditRequestUnitTypes.EntityLocation,
          editRequestId: session.id,
          ...data,
        }, 'edit_item');
        await EditPolicyApi.updateEditedField({
          editRequestId: session.id,
          editItemId,
          data: payload,
        });
        commit('deleteEntityLocation', editItemId);
        commit('setEntityRegulationItemsChanged', true);
      } catch (e) {
        const { error } = ErrorHandler.parseFormErrors(e);
        throw error;
      } finally {
        commit('setEntityLocationDeleteLoading', { value: false, id: editItemId });
      }
    }
  },
  deleteTemporaryEntityLocationField: async ({ commit, state }, id) => {
    const session = state.editRequestInformation;
    if (session.id) {
      commit('setEntityLocationDeleteLoading', { value: true, id });
      try {
        await EditPolicyApi.deleteEditRequestItem(id, session.id);
        commit('deleteEntityLocation', id);
        commit('setEntityRegulationItemsChanged', true);
      } catch (e) {
        const { error } = ErrorHandler.parseFormErrors(e);
        throw error;
      } finally {
        commit('setEntityLocationDeleteLoading', { value: false, id });
      }
    }
  },
  deleteEntityLocationGeneral: async ({ dispatch }, {
    data, id, editItemId, isTemporary,
  }) => {
    if (isTemporary) {
      await dispatch('deleteTemporaryEntityLocationField', id);
      return;
    }
    await dispatch('deleteExistingEntityLocationField', { data, id, editItemId });
  },
  updateEntityPolicy: async ({ commit, state }, data) => {
    try {
      const {
        editRequestId, editItemId, unitType, id,
      } = state.entityInfo;
      const payload = {
        action: EditRequestUnitActions.Update,
        editRequestId,
        unitType,
        unitId: id,
        newPayload: { ...data },
      };
      const serializedData = serializer(payload, 'edit_item');
      await EditPolicyApi.updateEditedField({ editRequestId, editItemId, data: serializedData });
      commit('setEntityRegulationItemsChanged', true);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      throw error;
    }
  },
  uploadGeneralAssemblyFile: async ({ commit }, { files, attachableId }) => {
    commit('setFileLoading', true);
    const res = await uploadDocuments(
      files,
      attachableId,
      mediaAttachableTypes.EntityPolicyRequest,
      mediaDocTypes.GeneralAssemblyFile,
    );
    commit('setFileLoading', false);
    return res;
  },
  getGeneralAssemblyFile: async ({ commit }, { attachableId }) => {
    commit('setFileLoading', true);
    const res = await getDocumentsList(
      attachableId,
      mediaAttachableTypes.EntityPolicyRequest,
      mediaDocTypes.GeneralAssemblyFile,
      null,
    );
    commit('setFileLoading', false);
    return res;
  },
  resendEditRequest: async (_, editRequestId) => {
    try {
      const resp = await EditPolicyApi.resendEditRequest(editRequestId);
      return await deserializer(resp.data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchGoalsAndActivitiesLookup: async ({ dispatch }) => {
    await dispatch('findClassification');
    const mainClassification = dispatch('fetchMainClassifications');
    const firstClassification = dispatch('fetchFirstClassifications');
    const secondClassification = dispatch('fetchSecondClassifications');
    const goalsClassification = dispatch('fetchGoals');
    const activitiesClassification = dispatch('fetchActivities');

    await Promise.allSettled([
      mainClassification,
      firstClassification,
      secondClassification,
      goalsClassification,
      activitiesClassification,
    ]);
  },
  findClassification: async ({ commit, getters }) => {
    const { getEntityInfo } = getters;
    if (getEntityInfo.secondClassification) {
      const classificationJson = await LookupApi
        .findClassification(getEntityInfo.secondClassification);
      const classificationData = await deserializer(classificationJson.data);
      commit('updateField', {
        path: 'entityInfo.firstClassification',
        value: Number(classificationData?.parentClassification.id),
      });
      commit('updateField', {
        path: 'entityInfo.classification',
        value: Number(classificationData?.parentClassification.classificationId),
      });
    }
  },
  fetchMainClassifications: ({ dispatch, getters }) => dispatch('lookup/fetchClassifications', {
    type: ClassificationsTypes.MainClassification,
    id: getters.getEntityInfo.secondClassification,
  }, { root: true }),
  fetchFirstClassifications: ({ dispatch, getters }) => dispatch('lookup/fetchClassifications', {
    type: ClassificationsTypes.FirstSubClassification,
    id: getters.getEntityInfo.classification,
  }, { root: true }),
  fetchSecondClassifications: ({ dispatch, getters }) => dispatch('lookup/fetchClassifications', {
    type: ClassificationsTypes.SecondSubClassification,
    id: getters.getEntityInfo.firstClassification,
  }, { root: true }),
  fetchGoals: ({ dispatch, getters }) => dispatch('lookup/fetchGoals', {
    classificationId: getters.getEntityInfo.secondClassification,
  }, { root: true }),
  fetchActivities: ({ dispatch, getters }) => dispatch('lookup/fetchActivities', {
    classificationId: getters.getEntityInfo.secondClassification,
  }, { root: true }),
  saveGoalsAndActivitiesTab: async ({
    state, dispatch,
  }) => {
    const session = state.editRequestInformation;
    const changedData = {
      activity_ids: state.entityInfo.activities?.map((i) => Number(i)),
      goal_ids: state.entityInfo.goals?.map((i) => Number(i)),
      classification: state.entityInfo.classification,
      firstClassification: state.entityInfo.firstClassification,
      secondSubClassificationId: state.entityInfo.secondClassification,
    };
    const originalData = {
      activity_ids: state.originalGoalsAndActivities.activities?.map((i) => Number(i)),
      goal_ids: state.originalGoalsAndActivities.goals?.map((i) => Number(i)),
      classification: state.originalGoalsAndActivities.classification,
      firstClassification: state.originalGoalsAndActivities.firstClassification,
      secondSubClassificationId: state.originalGoalsAndActivities.secondClassification,
    };
    const updatedData = compareOriginalAndCurrentGoalsAndActivities(originalData, changedData);

    if (session.id) {
      const payload = serializer({
        action: 'update',
        unitId: session.unitId,
        unitType: session.unitType,
        editRequestId: session.id,
        newPayload: updatedData,
      }, 'edit_item');
      try {
        const res = await EditPolicyApi.updateEditedField({
          editRequestId: session.id,
          editItemId: state.entityInfo.editItemId,
          data: payload,
        });
        await dispatch('resendEditRequest', session.id);
        dispatch('toast/showNotification', {
          message: i18n.t('general.dataSaved'),
          duration: 4000,
          type: 'success',
        }, { root: true });
        return res;
      } catch (e) {
        return ErrorHandler.parseFormErrors(e);
      }
    }
    return false;
  },
  changeDisabledMode: ({ state, commit }, array) => {
    if (!isEmpty(array)) {
      Object.keys(state.editModes).forEach((field) => {
        if (array.includes(field)) {
          if (!state.editModes[field].isDisabled) {
            commit('setDisabledField', { field, value: true });
          }
        } else if (state.editModes[field].isDisabled) {
          commit('setDisabledField', { field, value: false });
        }
      });
    } else {
      Object.keys(state.editModes).forEach((field) => {
        if (state.editModes[field].isDisabled) {
          commit('setDisabledField', { field, value: false });
        }
      });
    }
  },
  updateEntityInfo: ({ commit, dispatch }, {
    fieldObj,
    fieldValue,
  }) => {
    commit('updateField', {
      path: `entityInfo.${fieldObj.fieldId}`,
      value: fieldValue,
    });
    commit('setEntityGoalsAndActivitiesChanged', true);
    commit('resetClassificationsIds', fieldObj.resetIds);
    dispatch('changeDisabledMode', fieldObj.needToDisable);
    const fetchData = fieldObj.fetch;
    fetchData.forEach((i) => dispatch(i));
  },
  changeEditMode: ({ state, commit }, { key, value }) => {
    if (Object.hasOwnProperty.call(state.editModes, key)) {
      Object.keys(state.editModes).forEach((field) => {
        if (key === field) {
          commit('setEditingMode', {
            key: field,
            value,
          });
        } else if (state.editModes[field].isEditable) {
          commit('setEditingMode', {
            key: field,
            value: false,
          });
        }
      });
    } else {
      console.warn(`Something went wrong. Field ${key} is undefined in Store Edit Modes.`);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
