import Vue from 'vue';

// Components
import BaseButton from '@takamol/nawa-library/src/components/base-button/BaseButton.vue';
import Breadcrumbs from '@takamol/nawa-library/src/components/breadcrumbs/Breadcrumbs.vue';
import CollapseBox from '@takamol/nawa-library/src/components/collapse-box/CollapseBox.vue';
import ComboSelect from '@takamol/nawa-library/src/components/combo-select/ComboSelect.vue';
import FocusHidden from '@takamol/nawa-library/src/components/focus-hidden/FocusHidden.vue';
import FormValidator from '@takamol/nawa-library/src/components/form-validator/FormValidator.vue';
import FormCheckbox from '@takamol/nawa-library/src/components/form-checkbox/FormCheckbox.vue';
import FormErrors from '@takamol/nawa-library/src/components/form-errors/FormErrors.vue';
import FormFile from '@takamol/nawa-library/src/components/form-file/FormFile.vue';
import FormInput from '@takamol/nawa-library/src/components/form-input/FormInput.vue';
import FormRadio from '@takamol/nawa-library/src/components/form-radio/FormRadio.vue';
import FormRadioGroup from '@takamol/nawa-library/src/components/form-radio-group/FormRadioGroup.vue';
import FormSearch from '@takamol/nawa-library/src/components/form-search/FormSearch.vue';
import FormTextarea from '@takamol/nawa-library/src/components/form-textarea/FormTextarea.vue';
import GlobalNotification from '@takamol/nawa-library/src/components/notifications/GlobalNotification.vue';
import HijriDatePicker from '@takamol/nawa-library/src/components/date-picker-hijri/HijriDatePicker.vue';
import InfoCard from '@takamol/nawa-library/src/components/info-card/InfoCard.vue';
import LayoutLoading from '@takamol/nawa-library/src/components/layout-loading/LayoutLoading.vue';
import Loader from '@takamol/nawa-library/src/components/loader/Loader.vue';
import Logo from '@takamol/nawa-library/src/components/logo/Logo.vue';
import MainPanel from '@takamol/nawa-library/src/components/main-panel/MainPanel.vue';
import MainPanelFooter from '@takamol/nawa-library/src/components/main-panel-footer/MainPanelFooter.vue';
import Modal from '@takamol/nawa-library/src/components/modal/Modal.vue';
import MqLayout from '@takamol/nawa-library/src/components/mq-layout/MqLayout.vue';
import MultipleSelection from '@takamol/nawa-library/src/components/multiple-selection/MultipleSelection.vue';
import Pagination from '@takamol/nawa-library/src/components/pagination/Pagination.vue';
import Redirect from '@takamol/nawa-library/src/components/redirect/Redirect';
import RowAligner from '@takamol/nawa-library/src/components/row-aligner/RowAligner.vue';
import ServiceButtons from '@takamol/nawa-library/src/components/service-buttons/ServiceButtons.vue';
import ServiceHeader from '@takamol/nawa-library/src/components/service-header/ServiceHeader.vue';
import ServiceTableWrapper from '@takamol/nawa-library/src/components/service-table-wrapper/ServiceTableWrapper.vue';
import ServiceTabs from '@takamol/nawa-library/src/components/service-tabs/ServiceTabs.vue';
import TableView from '@takamol/nawa-library/src/components/table/TableView.vue';
import TabSet from '@takamol/nawa-library/src/components/tabset/TabSet.vue';
import Toast from '@takamol/nawa-library/src/components/toast/Toast.vue';
import Tooltip from '@takamol/nawa-library/src/components/tooltip/Tooltip.vue';
import StepsSwitcher from '@takamol/nawa-library/src/components/steps-switcher/StepsSwitcher.vue';
import RequestActions from '@takamol/nawa-library/src/components/request-actions/RequestActions.vue';
import EntityTitle from '@takamol/nawa-library/src/components/entity-title/EntityTitle.vue';
import PdfDocument from '@takamol/nawa-library/src/components/pdf-document/PdfDocument.vue';
import UploadDocument from '@takamol/nawa-library/src/components/upload-document/UploadDocument.vue';
import BulletItem from '@takamol/nawa-library/src/components/bullet-item/BulletItem.vue';

// Containers
import ContentGuard from '@takamol/nawa-library/src/containers/content-guard/ContentGuard.vue';
import QuestionContainer from '@takamol/nawa-library/src/containers/question-container/QuestionContainer.vue';
import QuestionPart from '@takamol/nawa-library/src/containers/question-part/QuestionPart.vue';
import OwnerQuestionPart from '@takamol/nawa-library/src/containers/owner-question-part/OwnerQuestionPart.vue';
import QuestionForm from '@takamol/nawa-library/src/containers/question-form/QuestionForm.vue';
import RequestsTrackerContainer from '@takamol/nawa-library/src/containers/requests-tracker-container/RequestsTrackerContainer.vue';
import AreasContainer from '@takamol/nawa-library/src/containers/areas-container/AreasContainer.vue';

// Icons
import IconArrowLocation from '@takamol/nawa-library/src/components/icons/IconArrowLocation.vue';
import IconAvatar from '@takamol/nawa-library/src/components/icons/IconAvatar.vue';
import IconCalendar from '@takamol/nawa-library/src/components/icons/IconCalendar.vue';
import IconChart from '@takamol/nawa-library/src/components/icons/IconChart.vue';
import IconChevron from '@takamol/nawa-library/src/components/icons/IconChevron.vue';
import IconContinue from '@takamol/nawa-library/src/components/icons/IconContinue.vue';
import IconActionCopy from '@takamol/nawa-library/src/components/icons/IconActionCopy.vue';
import IconDelete from '@takamol/nawa-library/src/components/icons/IconDelete.vue';
import IconDoc from '@takamol/nawa-library/src/components/icons/IconDoc.vue';
import IconDownload from '@takamol/nawa-library/src/components/icons/IconDownload.vue';
import IconEdit from '@takamol/nawa-library/src/components/icons/IconEdit.vue';
import IconEyeSmall from '@takamol/nawa-library/src/components/icons/IconEyeSmall.vue';
import IconEyeSmallClose from '@takamol/nawa-library/src/components/icons/IconEyeSmallClose.vue';
import IconFacebook from '@takamol/nawa-library/src/components/icons/IconFacebook.vue';
import IconHistory from '@takamol/nawa-library/src/components/icons/IconHistory.vue';
import IconList from '@takamol/nawa-library/src/components/icons/IconList.vue';
import IconOk from '@takamol/nawa-library/src/components/icons/IconOk.vue';
import IconPdf from '@takamol/nawa-library/src/components/icons/IconPdf.vue';
import IconPencil from '@takamol/nawa-library/src/components/icons/IconPencil.vue';
import IconPlus from '@takamol/nawa-library/src/components/icons/IconPlus.vue';
import IconProfile from '@takamol/nawa-library/src/components/icons/IconProfile.vue';
import IconReject from '@takamol/nawa-library/src/components/icons/IconReject.vue';
import IconRequestHasNotBeenSent from '@takamol/nawa-library/src/components/icons/IconRequestHasNotBeenSent.vue';
import IconSaveDraft from '@takamol/nawa-library/src/components/icons/IconSaveDraft.vue';
import IconSearch from '@takamol/nawa-library/src/components/icons/IconSearch.vue';
import IconTrack from '@takamol/nawa-library/src/components/icons/IconTrack.vue';
import IconTwitter from '@takamol/nawa-library/src/components/icons/IconTwitter.vue';
import IconUpdate from '@takamol/nawa-library/src/components/icons/IconUpdate.vue';
import IconYoutube from '@takamol/nawa-library/src/components/icons/IconYoutube.vue';
import IconWarning from '@takamol/nawa-library/src/components/icons/IconWarning.vue';
import IconQuestion from '@takamol/nawa-library/src/components/icons/IconQuestion.vue';
import IconViewMembers from '@takamol/nawa-library/src/components/icons/IconViewMembers.vue';
import IconCeoAdmin from '@takamol/nawa-library/src/components/icons/IconCeoAdmin.vue';
import IconCenterAdmin from '@takamol/nawa-library/src/components/icons/IconCenterAdmin.vue';
import IconCenterEmployeeAdmin from '@takamol/nawa-library/src/components/icons/IconCenterEmployeeAdmin.vue';
import IconTrash from '@takamol/nawa-library/src/components/icons/IconTrash.vue';
import IconUpload from '@takamol/nawa-library/src/components/icons/IconUpload.vue';
import IconPlusBold from '@takamol/nawa-library/src/components/icons/IconPlusBold.vue';
import IconInfo from '@takamol/nawa-library/src/components/icons/IconInfo.vue';
import IconNawa from '@takamol/nawa-library/src/components/icons/IconNawa.vue';
import IconMenu from '@takamol/nawa-library/src/components/icons/IconMenu.vue';
import IconComplited from '@takamol/nawa-library/src/components/icons/IconComplited.vue';
import IconComsLeft from '@takamol/nawa-library/src/components/icons/IconComsLeft.vue';
import IconComsRight from '@takamol/nawa-library/src/components/icons/IconComsRight.vue';
import IconBell from '@takamol/nawa-library/src/components/icons/IconBell.vue';
import IconAttention from '@takamol/nawa-library/src/components/icons/IconAttention.vue';
import IconActiveDelegate from '@takamol/nawa-library/src/components/icons/IconActiveDelegate.vue';

export const setupGlobalComponents = () => {
  Vue.component('BaseButton', BaseButton);
  Vue.component('Breadcrumbs', Breadcrumbs);
  Vue.component('CollapseBox', CollapseBox);
  Vue.component('ComboSelect', ComboSelect);
  Vue.component('FocusHidden', FocusHidden);
  Vue.component('FormValidator', FormValidator);
  Vue.component('FormCheckbox', FormCheckbox);
  Vue.component('FormErrors', FormErrors);
  Vue.component('FormFile', FormFile);
  Vue.component('FormInput', FormInput);
  Vue.component('FormRadio', FormRadio);
  Vue.component('FormRadioGroup', FormRadioGroup);
  Vue.component('FormSearch', FormSearch);
  Vue.component('FormTextarea', FormTextarea);
  Vue.component('GlobalNotification', GlobalNotification);
  Vue.component('HijriDatePicker', HijriDatePicker);
  Vue.component('InfoCard', InfoCard);
  Vue.component('LayoutLoading', LayoutLoading);
  Vue.component('Loader', Loader);
  Vue.component('Logo', Logo);
  Vue.component('MainPanel', MainPanel);
  Vue.component('MainPanelFooter', MainPanelFooter);
  Vue.component('Modal', Modal);
  Vue.component('MqLayout', MqLayout);
  Vue.component('MultipleSelection', MultipleSelection);
  Vue.component('Pagination', Pagination);
  Vue.component('Redirect', Redirect);
  Vue.component('RowAligner', RowAligner);
  Vue.component('ServiceButtons', ServiceButtons);
  Vue.component('ServiceHeader', ServiceHeader);
  Vue.component('ServiceTableWrapper', ServiceTableWrapper);
  Vue.component('ServiceTabs', ServiceTabs);
  Vue.component('TableView', TableView);
  Vue.component('TabSet', TabSet);
  Vue.component('Toast', Toast);
  Vue.component('Tooltip', Tooltip);
  Vue.component('PdfDocument', PdfDocument);
  Vue.component('UploadDocument', UploadDocument);
  Vue.component('BulletItem', BulletItem);
  Vue.component('StepsSwitcher', StepsSwitcher);
  Vue.component('RequestActions', RequestActions);
  Vue.component('EntityTitle', EntityTitle);
  Vue.component('QuestionContainer', QuestionContainer);
  Vue.component('QuestionPart', QuestionPart);
  Vue.component('OwnerQuestionPart', OwnerQuestionPart);
  Vue.component('QuestionForm', QuestionForm);
};

export const setupGlobalContainers = () => {
  Vue.component('ContentGuard', ContentGuard);
  Vue.component('RequestsTrackerContainer', RequestsTrackerContainer);
  Vue.component('AreasContainer', AreasContainer);
};

export const setupGlobalIcons = () => {
  Vue.component('IconArrowLocation', IconArrowLocation);
  Vue.component('IconAvatar', IconAvatar);
  Vue.component('IconCalendar', IconCalendar);
  Vue.component('IconChart', IconChart);
  Vue.component('IconChevron', IconChevron);
  Vue.component('IconContinue', IconContinue);
  Vue.component('IconActionCopy', IconActionCopy);
  Vue.component('IconDelete', IconDelete);
  Vue.component('IconDownload', IconDownload);
  Vue.component('IconEdit', IconEdit);
  Vue.component('IconEyeSmall', IconEyeSmall);
  Vue.component('IconEyeSmallClose', IconEyeSmallClose);
  Vue.component('IconFacebook', IconFacebook);
  Vue.component('IconHistory', IconHistory);
  Vue.component('IconList', IconList);
  Vue.component('IconOk', IconOk);
  Vue.component('IconPdf', IconPdf);
  Vue.component('IconPencil', IconPencil);
  Vue.component('IconPlus', IconPlus);
  Vue.component('IconProfile', IconProfile);
  Vue.component('IconReject', IconReject);
  Vue.component('IconRequestHasNotBeenSent', IconRequestHasNotBeenSent);
  Vue.component('IconSaveDraft', IconSaveDraft);
  Vue.component('IconSearch', IconSearch);
  Vue.component('IconTrack', IconTrack);
  Vue.component('IconTwitter', IconTwitter);
  Vue.component('IconUpdate', IconUpdate);
  Vue.component('IconYoutube', IconYoutube);
  Vue.component('IconWarning', IconWarning);
  Vue.component('IconQuestion', IconQuestion);
  Vue.component('IconViewMembers', IconViewMembers);
  Vue.component('IconCenterAdmin', IconCenterAdmin);
  Vue.component('IconCenterEmployeeAdmin', IconCenterEmployeeAdmin);
  Vue.component('IconCeoAdmin', IconCeoAdmin);
  Vue.component('IconTrash', IconTrash);
  Vue.component('IconUpload', IconUpload);
  Vue.component('IconPlusBold', IconPlusBold);
  Vue.component('IconInfo', IconInfo);
  Vue.component('IconNawa', IconNawa);
  Vue.component('IconMenu', IconMenu);
  Vue.component('IconComplited', IconComplited);
  Vue.component('IconComsLeft', IconComsLeft);
  Vue.component('IconComsRight', IconComsRight);
  Vue.component('IconBell', IconBell);
  Vue.component('IconAttention', IconAttention);
  Vue.component('IconActiveDelegate', IconActiveDelegate);
  Vue.component('IconDoc', IconDoc);
};
