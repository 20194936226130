export const LIST_MEMBERSHIP_CONDITIONS = `query ListMembershipConditions($page: Int, $size: Int, $entityId: Int!) {
  membershipConditions(page: $page, size: $size, entityId: $entityId) {
    membershipConditions {
      description
      duties
      fee
      id
      rights
      type
    }
  }
}`;
