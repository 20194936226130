import { Gateway, GraphQLRequest } from '@/setup/axios';
import { INIT_OTP, UPDATE_PHONE_EMAIL, UPDATE_USER } from '@/api/graphql/update-user-profile-gql';
import { GET_USER_PROFILE } from '@/api/graphql/auth-gql';
import { GET_INFO, GET_INFO_BY_ID } from '@/api/graphql/user-gql';

class UserApi {
  initOtp = (initPhoneOtpAttributes) => (
    GraphQLRequest({
      query: INIT_OTP,
      variables: {
        initPhoneOtpAttributes,
      },
    })
  )

  updateIndividual = (data) => (
    GraphQLRequest({
      query: UPDATE_USER,
      variables: {
        userUpdateAttributes: {
          id: Number(data.id),
          email: data.email ? String(data.email) : undefined,
          absherPhone: data.absherPhone ? String(data.absherPhone) : undefined,
          homePhone: data.homePhone ? String(data.homePhone) : undefined,
          educationalLevel: data.educationalLevel ? String(data.educationalLevel) : undefined,
          educationSpecialization: data.educationSpecialization
            ? String(data.educationSpecialization)
            : undefined,
          degrees: data.degrees ? String(data.degrees) : undefined,
          previousExperience: data.previousExperience ? String(data.previousExperience) : undefined,
          birthplace: data.birthplace ? String(data.birthplace) : undefined,
          occupation: data.occupation ? String(data.occupation) : undefined,
          employmentPlace: data.employmentPlace ? String(data.employmentPlace) : undefined,
          attachments: data.attachments,
          socialMedias: data.socialMedias,
        },
      },
    })
  )

  updateUser = (data) => (
    GraphQLRequest({
      query: UPDATE_USER,
      variables: {
        userUpdateAttributes: {
          id: Number(data.id),
          email: data.email,
          absherPhone: data.absherPhone,
          homePhone: data.homePhone,
          educationalLevel: data.educationalLevel,
          degrees: data.degrees,
          previousExperience: data.previousExperience,
          birthplace: data.birthplace,
          occupation: data.occupation,
          employmentPlace: data.employmentPlace,
          attachments: data.attachments,
          socialMedias: data.socialMedias,
          gender: data.gender,
        },
      },
    })
  )

  sendUpdatedProfile = (accountEmailPhoneAttributes) => (
    GraphQLRequest({
      query: UPDATE_PHONE_EMAIL,
      variables: {
        accountEmailPhoneAttributes,
      },
    })
  )

  removeSocialMedia = (userId, type) => {
    const typeParam = type ? `&type=${type}` : '';
    return Gateway.delete(`individual/social-media?user_id=${userId}${typeParam}`);
  }

  getUserProfile = () => (
    GraphQLRequest({
      query: GET_USER_PROFILE,
    })
  )

  getInfo = (data) => (
    GraphQLRequest({
      query: GET_INFO,
      variables: {
        nationalId: data.nationalId,
        birthDateHijri: data.birthDateHijri,
      },
    })
  )

  getInfoByNationalId = (data) => (
    GraphQLRequest({
      query: GET_INFO_BY_ID,
      variables: {
        nationalId: data.nationalId,
      },
    })
  )
}

export default new UserApi();
