import isString from 'lodash.isstring';

import i18n from '@/i18n';
import {
  LocaleTypes, RemainingType, TranslationsForTitles, UserGender,
} from '@/constants/enums';

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fileSizeFormat = (bytes, decimals = 2) => {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))}${sizes[i]}`;
};

export const randomEmail = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let string = '';
  for (let i = 0; i < 15; i += 1) {
    string += chars[Math.floor(Math.random() * chars.length)];
  }
  return `${string}@domain.com`;
};

export const remainingTimeTranslations = (date) => {
  if (date < 1) {
    return 'space.remainingTimeSmallThanOne';
  }
  return `space.remainingTime${(date < 2) ? 'One' : ''}`;
};

export const handleNationalAddress = (address) => {
  if (!address) return ['—'];
  const { buildingNo } = address;
  const streetName = address[`streetName${capitalizeFirstLetter(i18n.locale)}`];
  const districtArea = address[`districtArea${capitalizeFirstLetter(i18n.locale)}`];
  const city = address[`city${capitalizeFirstLetter(i18n.locale)}`];
  const code = address.zipCode;
  const additional = address.additionalNo;
  const unit = address.unitNo;
  return [`${buildingNo || ''} ${streetName || ''}`, `${districtArea || ''}`, `${city || ''} ${code || ''} ${additional || ''} ${unit || ''}`];
};

export const getUrlParam = (query) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(query);
};

export const setUrlParams = (params) => {
  let queryString = '?';
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.forEach((v, k) => {
    if (!Object.prototype.hasOwnProperty.call(params, k)) {
      params[k] = v;
    }
  });

  Object.entries(params).forEach(([k, v]) => {
    queryString += `${k}=${v}&`;
  });

  window.history.replaceState(null, null, queryString.slice(0, -1));
};

export const handleDOB = (dobStr) => {
  if (dobStr && typeof dobStr === 'string') {
    return {
      day: dobStr.slice(6, 8),
      month: dobStr.slice(4, 6),
      year: dobStr.slice(0, 4),
    };
  }
  return {
    day: '',
    month: '',
    year: '',
  };
};

export const getHijriWeekDate = (date) => {
  if (!date) return null;
  if (typeof date === 'string' && date.includes('UTC')) { // safari fix
    date = date.replace(/-/g, '/');
  }
  return new Intl.DateTimeFormat('ar-FR-u-ca-islamic', { weekday: 'long' }).format(new Date(date));
};

export const getDateOfBirthAsString = (obj) => {
  if (typeof obj !== 'object') return '';
  if (!obj && !obj.day && !obj.month && !obj.year) return '';
  return `${obj.day}/${obj.month}/${obj.year}`;
};

export const splitStringIntoArray = (value, separator = ',') => {
  if (Array.isArray(value)) {
    return value.map((v) => v.toString());
  }
  if (value && typeof value === 'string') {
    return Object.freeze(value.split(separator));
  }
  return undefined;
};

export const jsonParse = (value, defaultValue = []) => {
  try {
    if (value) {
      return JSON.parse(value) || defaultValue;
    }
    return undefined;
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

export const sortAlphabetically = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const makeDateFromString = (date, separator = '/') => {
  if (typeof date === 'string') {
    const day = date.slice(6, 8);
    const month = date.slice(4, 6);
    const year = date.slice(0, 4);

    return `${year}${separator}${month}${separator}${day}`;
  }
  return '';
};

export const getGender = (data) => {
  let gender = i18n.t('general.noData');
  if (!data) return gender;
  const useTranslation = (str) => {
    if (str === UserGender.Male) gender = i18n.t('general.gender.m');
    if (str === UserGender.Female) gender = i18n.t('general.gender.f');
  };
  if (isString(data)) useTranslation(data);
  if (data.gender) useTranslation(data.gender);
  return gender;
};

export function makeNationalAddress(address) {
  const {
    buildingNo, streetName, districtArea, city, zipCode, additionalNo, unitNo,
  } = address;
  return `${buildingNo || ''} ${streetName || ''} ${districtArea || ''} ${city || ''} ${zipCode || ''} ${additionalNo || ''} ${unitNo || ''}`;
}

export function getRemainingTypeTranslation(remainingType) {
  switch (remainingType) {
    case RemainingType.Day:
      return i18n.t('general.days');
    case RemainingType.Min:
      return i18n.t('general.minutes');
    default:
      return '';
  }
}

export function getDOB(o) {
  if (!o?.year || !o?.month || !o?.day) {
    return '';
  }
  return `${o.year}${o.month}${o.day}`;
}

export const replaceEmptyValuesInArray = (v) => ((v === 0 || v) ? v : i18n.t('general.noData'));

export const getLocationTranslation = (obj) => {
  if (!obj) return i18n.t('general.noData');
  const locationTitle = i18n.locale === LocaleTypes.Ar
    ? TranslationsForTitles.Ar
    : TranslationsForTitles.En;
  return obj[locationTitle] || i18n.t('general.noData');
};
