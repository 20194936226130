export const LIST_ENTITY_BANKS = `query ListEntitiesBanks($unitType: UnitType!, $unitId: Int!) {
  entitiesBanks(unitType: $unitType, unitId: $unitId) {
    bankBranchName
    bankId
    id
    unitId
    unitType
  }
}`;

export const CREATE_ENTITY_BANK = `mutation CreateEntityBank($entitiesBankCreateAttributes: CreateEntitiesBankInput!) {
  createEntitiesBank(entitiesBankCreateAttributes: $entitiesBankCreateAttributes) {
    bankBranchName
    bankId
    id
    unitId
  }
}`;

export const UPDATE_ENTITY_BANK = `mutation UpdateEntityBank($entitiesBankUpdateAttributes: UpdateEntitiesBankInput!) {
  updateEntitiesBank(entitiesBankUpdateAttributes: $entitiesBankUpdateAttributes) {
    bankBranchName
    bankId
    id
    unitId
  }
}`;

export const DELETE_ENTITY_BANK = `mutation DeleteEntityBank($id: Int!) {
  deleteEntitiesBank(id: $id) {
    uid
    id
  }
}`;
