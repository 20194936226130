import { GraphQLRequest } from '@/setup/axios';
import {
  CREATE_DISCLOSURE_FORM,
  GET_USER_REQUESTS,
  UPDATE_DISCLOSURE_FORM,
  ACCEPT_USER_REQUEST,
  REJECT_USER_REQUEST,
  CANCEL_USER_REQUEST,
  MEMBERS_COUNT,
  FIND_DELEGATE_PROOF_LETTERS,
} from '@/api/graphql/user-requests-gql';
import { UserRequestStatuses } from '@/constants/enums';

class UserRequestsApi {
  cancelRequest = (userRequestId) => (
    GraphQLRequest({
      query: CANCEL_USER_REQUEST,
      variables: {
        id: Number(userRequestId),
      },
    })
  )

  createDisclosureFormGql = (data) => (
    GraphQLRequest({
      query: CREATE_DISCLOSURE_FORM,
      variables: {
        userRequestId: +data.id,
        acrm: data.acrm,
        crmo: data.crmo,
        crmi: data.crmi,
        resi: data.resi,
        oman: data.oman,
        rman: data.rman,
        lknw: data.lknw,
        naff: data.naff,
        fffl: data.fffl,
        nmbd: data.nmbd,
      },
    })
  )

  updateDisclosureFormGql = (data) => (
    GraphQLRequest({
      query: UPDATE_DISCLOSURE_FORM,
      variables: {
        id: +data.id,
        acrm: data.acrm,
        crmo: data.crmo,
        crmi: data.crmi,
        resi: data.resi,
        oman: data.oman,
        rman: data.rman,
        lknw: data.lknw,
        naff: data.naff,
        fffl: data.fffl,
        nmbd: data.nmbd,
      },
    })
  )

  getUserRequestsGql = ({
    page = 1, size = 10, unitId, unitType, status = [UserRequestStatuses.Pending],
  }, config) => (
    GraphQLRequest({
      query: GET_USER_REQUESTS,
      variables: {
        page: Number(page),
        size: Number(size),
        unitId: Number(unitId) || undefined,
        status,
        unitType,
      },
    }, config)
  )

  acceptUserRequest = (id) => (
    GraphQLRequest({
      query: ACCEPT_USER_REQUEST,
      variables: {
        userRequestAcceptAttributes: { id: +id },
      },
    })
  )

  rejectUserRequest = (id, rejectReason) => (
    GraphQLRequest({
      query: REJECT_USER_REQUEST,
      variables: {
        userRequestRejectByMemberAttributes: {
          id: +id,
          message: rejectReason,
        },
      },
    })
  )

  bulkMembersCount = (entityUnits) => GraphQLRequest({
    query: MEMBERS_COUNT,
    variables: {
      units: entityUnits,
    },
  })

  findDelegateProofLetter = (userRequestId) => GraphQLRequest({
    query: FIND_DELEGATE_PROOF_LETTERS,
    variables: {
      id: userRequestId,
    },
  })
}

export default new UserRequestsApi();
