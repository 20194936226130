import * as editRequestApi from '@/api/edit-request.api';
import ErrorHandler from '@/helpers/errors';

export const fetchEditItems = async (editId, action, page = 1, size = 10) => {
  try {
    const res = await editRequestApi.listEditItems(editId, action, page, size);
    return res?.data?.listEditItems?.editItems;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const listEditRequests = async (type, unitId, status, unitType = 'Entity', page = 1, size = 10) => {
  try {
    const res = await editRequestApi.listEditRequests(type, unitId, status, unitType, page, size);
    return res?.data?.listEditRequests?.editRequests;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const acceptEditRequest = async (editId) => {
  try {
    const res = await editRequestApi.acceptEditRequest(editId);
    return res?.data?.acceptEditRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const rejectEditRequest = async (editId, reason) => {
  try {
    const res = await editRequestApi.rejectEditRequest(editId, reason);
    return res?.data?.rejectEditRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};
