import { GraphQLRequest } from '@/setup/axios';
import {
  GET_ENTITY_EMPLOYEES,
  GET_EMPLOYEE_INFO_PUBLIC,
  ACCEPT_EMPLOYEE_INFO_PUBLIC,
  REJECT_EMPLOYEE_INFO_PUBLIC,
  ACCEPT_BY_MEMBER_EMPLOYEE_REQUEST,
  REJECT_BY_MEMBER_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_REQUESTS_COUNT,
  LIST_EMPLOYEE_REQUESTS,
  FIND_EMPLOYEE_REQUEST,
} from '@/api/graphql/employee-requests-gql';
import { UnitTypes, EmployeeRequestStatuses } from '@/constants/enums';

class EmployeeApi {
  getEmployeeRequests = ({
    page = 1,
    size = 10,
    unitType = UnitTypes.Entity,
    status = EmployeeRequestStatuses.Pending,
  }) => (
    GraphQLRequest({
      query: LIST_EMPLOYEE_REQUESTS,
      variables: {
        page,
        size,
        unitType,
        status,
      },
    })
  )

  getEmployeeRequestsByEntityId = ({
    page = 1,
    size = 10,
    entityId,
    unitType = UnitTypes.Entity,
    status,
  }) => (
    GraphQLRequest({
      query: LIST_EMPLOYEE_REQUESTS,
      variables: {
        page,
        size,
        unitType,
        unitId: Number(entityId),
        status,
      },
    })
  )

  getEmployeeRequestInfo = (id) => (
    GraphQLRequest({
      query: FIND_EMPLOYEE_REQUEST,
      variables: {
        id: Number(id),
      },
    })
  )

  decideOnRequest = (data, isAccepted) => (
    GraphQLRequest({
      query: isAccepted ? ACCEPT_BY_MEMBER_EMPLOYEE_REQUEST : REJECT_BY_MEMBER_EMPLOYEE_REQUEST,
      variables: {
        employeeRequestAttributes: {
          id: Number(data.id),
          message: (!isAccepted && data.rejectMessage) ? String(data.rejectMessage) : undefined,
        },
      },
    })
  )

  getEntityEmployeesGql = (unitId, page = 1, size = 30) => (
    GraphQLRequest({
      query: GET_ENTITY_EMPLOYEES,
      variables: {
        page,
        size,
        unitId,
      },
    })
  )

  getEmployeeInfoGql = (token) => (
    GraphQLRequest({
      query: GET_EMPLOYEE_INFO_PUBLIC,
      variables: {
        token,
      },
    })
  )

  sendConfirmationEmployeeInfo = (value, token) => (
    GraphQLRequest({
      query: value ? ACCEPT_EMPLOYEE_INFO_PUBLIC : REJECT_EMPLOYEE_INFO_PUBLIC,
      variables: {
        token,
      },
    })
  )

  getEmployeeRequestsCount = () => (
    GraphQLRequest({
      query: GET_EMPLOYEE_REQUESTS_COUNT,
      variables: {
        page: 1,
        size: 10,
        status: 'pending',
        asOwner: false,
        unitType: UnitTypes.Entity,
      },
    })
  )
}

export default new EmployeeApi();
