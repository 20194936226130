import { GraphQLRequest } from '@/setup/axios';
import {
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  GET_USER_REQUEST_INFO,
  GET_USER_REQUESTS,
  UPDATE_USER_REQUEST,
  GET_OWNER_AS_MEMBER,
  CREATE_DELEGATE_PROOF,
  UPDATE_DELEGATE_PROOF,
} from '@/api/graphql/user-requests-gql';
import { UnitTypes } from '@/constants/enums';

class MembershipApi {
  getSummaryMembers = ({
    page = 1, size = 100, entityId, isBranch,
  }, config) => (
    GraphQLRequest({
      query: GET_USER_REQUESTS,
      variables: {
        page: Number(page),
        size: Number(size),
        unitId: Number(entityId),
        unitType: isBranch ? UnitTypes.Branch : UnitTypes.Entity,
      },
    }, config)
  )

  getMembershipInfo = ({ id }) => (
    GraphQLRequest({
      query: GET_USER_REQUEST_INFO,
      variables: {
        id: Number(id),
      },
    })
  )

  findOwnerAsAMemberUserRequestByEntityId = (entityId) => (
    GraphQLRequest({
      query: GET_OWNER_AS_MEMBER,
      variables: {
        entityId: Number(entityId),
      },
    })
  )

  createDelegateProof = (id, data) => {
    const delegateProofCreateAttributes = {
      entityName: data.entityName,
      userRequestId: Number(id),
      organizationName: data.organizationName,
      organizationNumber: data.organizationNumber,
      nonGovernmentalProof: data.nonGovernmentalProof,
    };
    if (data.attachments && data.attachments?.length) {
      return GraphQLRequest({
        query: CREATE_DELEGATE_PROOF,
        variables: {
          delegatorProofCreateAttributes: {
            ...delegateProofCreateAttributes,
            attachments: [
              ...data.attachments,
            ],
          },
        },
      });
    }
    return GraphQLRequest({
      query: CREATE_DELEGATE_PROOF,
      variables: {
        delegatorProofCreateAttributes: {
          ...delegateProofCreateAttributes,
        },
      },
    });
  }

  updateDelegateProof = (delegateId, data) => {
    const delegatorProofUpdateAttributes = {
      entityName: data.entityName,
      id: Number(delegateId),
      organizationName: data.organizationName,
      organizationNumber: data.organizationNumber,
      nonGovernmentalProof: data.nonGovernmentalProof,
    };
    if (data.attachments && data.attachments?.length) {
      return GraphQLRequest({
        query: UPDATE_DELEGATE_PROOF,
        variables: {
          delegatorProofUpdateAttributes: {
            ...delegatorProofUpdateAttributes,
            attachments: [
              ...data.attachments,
            ],
          },
        },
      });
    }
    return GraphQLRequest({
      query: UPDATE_DELEGATE_PROOF,
      variables: {
        delegatorProofUpdateAttributes: {
          ...delegatorProofUpdateAttributes,
        },
      },
    });
  }

  deleteMembership = (requestId) => (
    GraphQLRequest({
      query: DELETE_USER_REQUEST,
      variables: {
        id: Number(requestId),
      },
    })
  )

  saveMembership = (data) => GraphQLRequest({
    query: CREATE_USER_REQUEST,
    variables: {
      userRequestCreateAttributes: {
        unitId: data.unitId,
        unitType: data.unitType,
        memberType: data.memberType,
        memberMode: data.memberMode,
        nationalId: data.nationalId,
        familyRelationUid: data.familyRelationUid,
        email: data.email,
        birthDateHijri: data.birthDateHijri,
        absherPhone: data.absherPhone,
        positionUid: data.positionUid,
        customPosition: data.customPosition,
        role: data.role,
        foundingMember: data.foundingMember,
        governmental: data.governmental,
        specialized: data.specialized,
        contributeFinances: data.contributeFinances,
        financeContributionAmount: Number(data.financeContributionAmount),
        numberOfShares: data.numberOfShares,
        attachments: data.attachments,
      },
    },
  });

  updateMembership = (data, requestId) => GraphQLRequest({
    query: UPDATE_USER_REQUEST,
    variables: {
      userRequestUpdateAttributes: {
        id: Number(requestId),
        positionUid: data.positionUid || null,
        email: data.email,
        customPosition: data.customPosition,
        specialized: data.specialized,
        governmental: data.governmental,
        numberOfShares: data.numberOfShares,
        financeContributionAmount: Number(data.financeContributionAmount),
        role: data.role,
        foundingMember: data.foundingMember,
        contributeFinances: data.contributeFinances,
        familyRelationUid: data.familyRelationUid,
        attachments: data.attachments,
      },
    },
  })
}

export default new MembershipApi();
