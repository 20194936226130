<template>
  <div class="pending-layout">
    <pending-header />
    <div class="pending-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import PendingHeader from '@/views/pending/pending-header/PendingHeader.vue';

export default {
  name: 'PendingLayout',
  components: {
    PendingHeader,
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
