<template>
  <header class="pending-header">
    <div class="pending-header-left">
      <logo route-name="Home" />
    </div>
    <div class="pending-header-right">
      <lang-switcher />
    </div>
  </header>
</template>

<script>
import LangSwitcher from '@/containers/lang-switcher/LangSwitcher.vue';

export default {
  name: 'PendingHeader',
  components: {
    LangSwitcher,
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
