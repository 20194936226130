import Vue from 'vue';
import VueScrollActive from 'vue-scrollactive';
import VueScreen from 'vue-screen';
import VueConstants from '@/plugins/constants.plugin';
import VueScreenConfig from '@/plugins/screen.plugin';
import focus from '@/directives/focus';
import clickOutside from '@/directives/click-outside';

export const setupVueGlobal = () => {
  Vue.config.productionTip = false;

  Vue.use(VueScrollActive);
  Vue.use(VueConstants);
  Vue.use(VueScreen, VueScreenConfig);

  Vue.directive('click-outside', clickOutside);
  Vue.directive('focus', focus);
};
