import {
  acceptEditRequest,
  fetchEditItems,
  rejectEditRequest,
} from '@/services/edit-request.service';
import {
  EditRequestUnitTypes,
} from '@/constants/enums';
import { sleep } from '@/helpers/api';

const findEditItemByUnitType = (uType, list) => list
  ?.find(({ unitType }) => unitType === EditRequestUnitTypes[uType]);

const findEditItemsByUnitType = (uType, list) => list
  ?.filter(({ unitType }) => unitType === EditRequestUnitTypes[uType]);

const mainState = {
  editItems: {},
  editRequestId: null,
};

const mainGetters = {
  getEntityEditId: (store) => store.editRequestId,
  getEntityEditItem: (store) => findEditItemByUnitType(
    EditRequestUnitTypes.Entity, store.editItems,
  ),
  getEntitySubscriptionEditItem: (store) => findEditItemByUnitType(
    EditRequestUnitTypes.FamilyFundSubscription, store.editItems,
  ),
  getEntitiesBankEditItems: (store) => findEditItemsByUnitType(
    EditRequestUnitTypes.EntitiesBank, store.editItems,
  ),
  getEntityAddressEditItem: (store) => findEditItemByUnitType(
    EditRequestUnitTypes.EntityNationalAddress, store.editItems,
  ),
};

const mutations = {
  setEditItems(store, items) {
    store.editItems = items;
  },
  setEditRequestId(store, id) {
    store.editRequestId = id;
  },
};
const actions = {
  fetchEditItems: async ({ commit }, id) => {
    commit('setEditRequestId', id);
    const data = await fetchEditItems(id);
    commit('setEditItems', data);
  },
  submitEditRequest: async ({ getters }, { decision, reason }) => {
    const method = decision ? acceptEditRequest : rejectEditRequest;
    const data = await method(getters.getEntityEditId, reason);
    await sleep({}, 2000);
    return data;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
