import { getField, updateField } from 'vuex-map-fields';
import isEmpty from 'lodash.isempty';
import EntityApi from '@/api/entity.api';
import MembershipApi from '@/api/membership.api';
import LookupApi from '@/api/lookup.api';
import {
  EntitySteps,
  EntityTypes,
  MemberTypes,
  UnitTypes,
  MembershipEntityTypes,
  UserRequestPayloadType, mediaDocTypes, MembershipTypesOptions, MembershipTypeAssociation,
} from '@/constants/enums';
import { serializer, deserializer } from '@/helpers/api';
import router from '@/router';
import {
  getEntityClassifications,
  normalizeEntity,
  normalizeOwnerMembership,
  listContributionSources,
  findEntity,
} from '@/services/entity.service';
import { capitalizeFirstLetter, sortAlphabetically } from '@/helpers/general';
import ErrorHandler from '@/helpers/errors';
import UserRequestsApi from '@/api/user-requests.api';
import { prepareDisclosureForm } from '@/services/user-requests.service';
import {
  getUserRequestPayload,
  updateMembership,
} from '@/services/membership.service';
import { getDocumentsList } from '@/services/media.service';

const createContributionSourceForm = () => ({
  amount: '',
  entityId: '',
  id: '',
  kind: '',
  title: '',
});

const handleConditionsData = (data) => ({
  entity_id: data.entity_id,
  type: data.type,
  id: data.id,
  fee: data.fee,
  duties: '',
  rights: '',
  description: data.description,
});

export function makeEntityLocationsPayload(entityLocations) {
  return entityLocations.map((entityLocation) => ({
    cityUids: entityLocation.selectedCities,
    regionCode: entityLocation.regionCode,
    type: 'activity',
  }));
}

const mainState = {
  form: normalizeEntity(),
  agreements: {
    pledgeTerms: false,
    policyTerms: false,
    agreementTerms: false,
    agreementToDelegate: false,
  },
  contributionSourceForm: createContributionSourceForm(),
  showOutsideSupportersForm: false,
  outsideSupporters: false,
  innerSupporters: false,
  contributionSourcesList: [],
  loading: false,
  entityLoading: false,
  errors: [],
  fileUploading: false,
  membershipConditions: [],
  membershipConditionsLoading: false,
  entityType: null,
  entitySummary: null,
  entityClassifications: null,
  ownerMembership: normalizeOwnerMembership(),
};

const mainGetters = {
  getField,
  getErrors: (store) => store.errors,
  getEntityClassifications: (store) => store.entityClassifications,
  getEntitySummary: (store) => store.entitySummary,
  ownerMembership: (store) => store.ownerMembership,
  disclosureForm: (store) => store.ownerMembership.disclosureForm,
  areasOfActivity: (store) => store.form.areasOfActivity,
  isLoading: (store) => store.loading,
  entityFormType: (store) => store.form.type,
  membershipConditions: (store) => store.membershipConditions,
  isMembershipConditionsLoading: (store) => store.membershipConditionsLoading,
  fileUploading: (store) => store.fileUploading,
  form: (store) => store.form,
  contributionSourcesList: (store) => store.contributionSourcesList,
  contributionSourceForm: (store) => store.contributionSourceForm,
  getMembershipObject: (store) => {
    const data = store.membershipConditions;
    if (isEmpty(data)) return null;
    const membershipObject = { membershipTypes: [] };
    data.forEach((item) => {
      switch (item.type) {
        case MembershipTypesOptions.Worker:
          membershipObject.workerMembershipFee = item.fee || '';
          membershipObject.conditionsWorker = [item.description] || [];
          if (membershipObject.conditionsWorker.some((el) => el.includes('\n'))) {
            membershipObject.conditionsWorker = membershipObject.conditionsWorker.map((cond) => {
              if (cond.includes('\n')) {
                return cond.split('\n');
              }
              return cond;
            });
          }
          break;
        case MembershipTypesOptions.Associate:
          if (
            store.entitySummary.membershipTypeAssociation === MembershipTypeAssociation.Closed
          ) break;
          membershipObject.associateMembershipFee = item.fee || '';
          membershipObject.membershipTypes = [
            ...membershipObject.membershipTypes,
            MembershipTypesOptions.Associate,
          ];
          break;
        case MembershipTypesOptions.Honorary1:
          membershipObject.membershipTypes = [
            ...membershipObject.membershipTypes,
            MembershipTypesOptions.Honorary1,
          ];
          break;
        case MembershipTypesOptions.Honorary2:
          membershipObject.membershipTypes = [
            ...membershipObject.membershipTypes,
            MembershipTypesOptions.Honorary2,
          ];
          break;
        default:
          break;
      }
    });
    membershipObject.conditionsWorker = membershipObject.conditionsWorker.flat(Infinity);
    membershipObject.membershipTypes = membershipObject.membershipTypes.sort(
      (a, b) => sortAlphabetically(a, b),
    );
    return membershipObject;
  },
};

const mutations = {
  updateField,
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  setEntityLoading: (store, value) => {
    store.entityLoading = value;
  },
  resetForm: (store) => {
    store.form = normalizeEntity();
    store.ownerMembership = normalizeOwnerMembership();
    store.contributionSourceForm = createContributionSourceForm();
  },
  resetContributionSourceForm: (store) => {
    store.contributionSourceForm = createContributionSourceForm();
  },
  setForm: (store, entity) => {
    store.form = { ...store.form, ...entity };
  },
  setOwnerMembership: (store, data) => {
    store.ownerMembership = normalizeOwnerMembership(data);
  },
  setDisclosureForm: (store, list) => {
    store.ownerMembership.disclosureForm = list;
  },
  setMembershipConditions: (store, data) => {
    store.membershipConditions = data;
  },
  setMembershipConditionsLoading: (store, value) => {
    store.membershipConditionsLoading = value;
  },
  setEntityType: (store, type) => {
    store.entityType = type;
  },
  setContributionSourceForm: (store, data) => {
    store.contributionSourceForm = data;
  },
  setShowOutsideSupportersForm: (store, value) => {
    store.showOutsideSupportersForm = value;
  },
  openContributionInfo: (store, id) => {
    store.contributionSourcesList = store.contributionSourcesList.map((source) => {
      source.showInfo = source.id === id;
      source.showEdit = false;
      return source;
    });
  },
  openContributionEdit: (store, id) => {
    store.contributionSourcesList = store.contributionSourcesList.map((source) => {
      source.showEdit = source.id === id;
      source.showInfo = false;
      return source;
    });
  },
  closeContribution: (store) => {
    store.contributionSourcesList = store.contributionSourcesList.map((source) => {
      source.showEdit = false;
      source.showInfo = false;
      return source;
    });
  },
  setContributionSources: (store, contributionSourcesList) => {
    if (contributionSourcesList?.length) {
      store.outsideSupporters = true;
    }
    store.contributionSourcesList = contributionSourcesList?.map((source) => ({
      ...source,
      showEdit: false,
      showInfo: false,
    }));
  },
  updateEntityForm: (store, data) => {
    store.form.type = data.type;
    store.form.becomeMember = data.becomeMember;
  },
  setEntitySummary: (store, entity) => {
    store.entitySummary = entity;
  },
  setEntityClassifications: (store, classification) => {
    store.entityClassifications = classification;
  },
};

const actions = {
  createOrUpdateEntityLocations: async ({ state, dispatch }, entityLocations) => {
    try {
      await EntityApi
        .createOrUpdateEntityLocations(
          state.form.id,
          makeEntityLocationsPayload(entityLocations),
        );
    } catch (e) {
      dispatch('toast/showNotification', {
        message: ErrorHandler.parseGlobalErrors(e),
        duration: 3000,
        type: 'error',
      }, { root: true });
    }
  },
  submitEntity: async ({ state, commit, dispatch }, { payload, step }) => {
    commit('setLoading', true);
    try {
      const stepData = {
        unitId: +state.form.id,
        unitType: UnitTypes.Entity,
        step,
      };

      const { createEntity: create, updateEntity: update } = EntityApi;
      const entityId = state.form.id;
      let payloadData = payload;
      if (entityId) {
        payloadData = { ...payload, id: +entityId };
      }

      const method = entityId ? update : create;
      const res = await method(
        state.form.type,
        payloadData,
        stepData,
      );
      const type = capitalizeFirstLetter(state.form.type);
      const action = entityId ? 'update' : 'create';
      const { [`${action}${type}Entity`]: data } = res?.data;
      if (data?.id) {
        commit('updateField', {
          path: 'form.id',
          value: data.id,
        });
      }
      if (data?.autocreateMembershipForOwner && !entityId) {
        const ownerUserRequest = await dispatch('getOwnerUserRequest', data?.id);
        commit('setOwnerMembership', ownerUserRequest);
        dispatch('membership/fetchMemberships', {
          entityId: data?.id,
          type: MembershipEntityTypes.Entity,
        }, { root: true });
      }
      if (!data?.error && router.currentRoute.name !== 'EditEntity') {
        router.replace({
          name: 'EditEntity',
          params: { entityId: data?.id, skipScroll: true },
        });
      }
      return data;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  finalSubmitEntity: async ({ state, commit, getters }) => {
    commit('setLoading', true);
    try {
      const entityId = state.form.id;
      const res = await EntityApi.finalSubmitEntity(
        state.form.type,
        entityId,
        state.innerSupporters,
        state.outsideSupporters,
      );
      const type = capitalizeFirstLetter(state.form.type);
      const { [`submit${type}Entity`]: data } = res?.data;
      if (state.form.becomeMember) {
        UserRequestsApi.acceptUserRequest(getters.ownerMembership.id);
      }
      return data;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  filterEntitySteps: ({ commit }, { type, member, role }) => {
    const membershipSteps = [
      EntitySteps.Disclosure,
      EntitySteps.Security,
      EntitySteps.Individual,
      EntitySteps.OwnerMember,
    ];
    switch (type) {
      case EntityTypes.CooperativeAssociation:
        commit(
          'entityStepper/filterStepsByKeys',
          [
            EntitySteps.Resources,
            EntitySteps.MembershipTypes,
            EntitySteps.Ceo,
          ]
            .concat(member ? [EntitySteps.Security] : membershipSteps)
            .concat(role === MemberTypes.Delegate ? [] : [EntitySteps.Representation]),
          { root: true },
        );
        break;
      case EntityTypes.PrivateAssociation:
        commit(
          'entityStepper/filterStepsByKeys',
          [
            EntitySteps.Financial,
            EntitySteps.Eligibility,
          ]
            .concat(member ? [EntitySteps.Individual] : membershipSteps)
            .concat(role === MemberTypes.Delegate ? [] : [EntitySteps.Representation]),
          { root: true },
        );
        break;
      case EntityTypes.PrivateCorporation:
        commit(
          'entityStepper/filterStepsByKeys',
          [
            EntitySteps.Resources,
            EntitySteps.MembershipTypes,
            EntitySteps.Eligibility,
            EntitySteps.MembershipRequirements,
          ]
            .concat(member ? [EntitySteps.Individual] : membershipSteps)
            .concat(role === MemberTypes.Delegate ? [] : [EntitySteps.Representation]),
          { root: true },
        );
        break;
      default:
        commit(
          'entityStepper/filterStepsByKeys',
          member ? [] : membershipSteps,
          { root: true },
        );
    }
  },
  getEntityInfo: async ({ commit, state }, entityId) => {
    if (state.entityInfo && state.entityInfo.id === entityId) {
      return state.entityInfo;
    }
    commit('setEntityLoading', true);
    try {
      const res = await EntityApi.findEntity(entityId);
      commit('setEntityType', res.data?.findEntityById?.type);
      return res.data?.findEntityById;
    } catch (e) {
      return { error: e.message };
    } finally {
      commit('setEntityLoading', false);
    }
  },
  getEntityDepartment: async ({ commit }, secondClassification) => {
    if (secondClassification) {
      const classifications = await getEntityClassifications(secondClassification);
      commit('updateField', {
        path: 'form.department',
        value: classifications[0]?.department,
      });
      commit('updateField', {
        path: 'form.description',
        value: classifications[0]?.description,
      });
    }
  },
  getOwnerUserRequest: async (_, entityId) => {
    try {
      const res = await MembershipApi.findOwnerAsAMemberUserRequestByEntityId(entityId);
      return res?.data?.findOwnerAsAMemberUserRequestByEntityId || {};
    } catch (e) {
      console.warn(e);
      return ErrorHandler.parseFormErrors(e);
    }
  },
  updateOwner: async ({ commit, state, dispatch }, entityId) => {
    commit('setLoading', true);
    try {
      const payloadType = UserRequestPayloadType[`Entity${capitalizeFirstLetter(state.form.type)}Update`];
      const data = {
        form: state.ownerMembership,
        unitId: entityId,
        unitType: UnitTypes.Entity,
        memberMode: MemberTypes.Individual,
      };
      const submitPayload = getUserRequestPayload(payloadType, data);
      const res = await updateMembership(submitPayload, state.ownerMembership?.id);
      dispatch('membership/fetchMemberships', {
        entityId,
        type: MembershipEntityTypes.Entity,
      }, { root: true });
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  submitEntityStep: async ({ commit, state }, { step }) => {
    commit('setLoading', true);
    let res = null;
    const stepData = {
      unitId: +state.form.id,
      unitType: UnitTypes.Entity,
      step,
    };
    try {
      res = await EntityApi.updateStepProgress(stepData);
    } catch (e) {
      res = ErrorHandler.parseFormErrors(e);
    }
    commit('setLoading', false);
    return res;
  },
  submitDisclosureForm: async ({ commit, dispatch, getters }, { step, data }) => {
    commit('setLoading', true);
    try {
      commit('setDisclosureForm', data);
      const { updateDisclosureFormGql: update, createDisclosureFormGql: create } = UserRequestsApi;
      const { disclosureFormId, id } = getters.ownerMembership;
      const method = disclosureFormId ? update : create;
      const res = await method({ ...prepareDisclosureForm(data), id: disclosureFormId || id });
      await dispatch('submitEntityStep', step);
      if (!disclosureFormId && res?.data?.createDisclosureForm?.id) {
        commit('updateField', {
          path: 'ownerMembership.disclosureFormId',
          value: res.data.createDisclosureForm.id,
        });
      }
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  listContributionSources: async ({ commit }, entityId) => {
    const res = await listContributionSources(entityId);
    if (!res?.error) commit('setContributionSources', res);
    return res;
  },
  getEntity: async ({ commit, dispatch, rootGetters }, entityId) => {
    try {
      const entityRes = await EntityApi.findEntity(entityId);
      const data = entityRes?.data?.findEntityById;
      const ownerUserRequestData = entityRes?.data?.findOwnerAsAMemberUserRequestByEntityId;
      const entity = normalizeEntity(data);
      commit('setForm', entity);
      commit('setOwnerMembership', ownerUserRequestData);
      commit('membership/setDelegateForm', ownerUserRequestData?.delegatorProof, { root: true });
      await dispatch('filterEntitySteps', {
        type: entity.type,
        member: entity.becomeMember,
        role: ownerUserRequestData?.role,
      });
      if (entity.step) {
        if (entity.copiedFrom && entity.step === 'initial') {
          dispatch('entityStepper/goStep', { index: 0 }, { root: true });
        } else {
          const step = Object.values(rootGetters['entityStepper/allSteps']).findIndex((s) => entity.step === s.name);
          dispatch('entityStepper/goStep', { index: step < 0 ? 1 : step + 1 }, { root: true });
        }
      }
      if (entity.secondClassification) {
        const classificationJson = await LookupApi.findClassification(
          entity.secondClassification,
        );
        const classificationData = await deserializer(classificationJson.data);
        commit('updateField', {
          path: 'form.firstClassification',
          value: Number(classificationData?.parentClassification.id),
        });
        commit('updateField', {
          path: 'form.classification',
          value: Number(classificationData?.parentClassification.classificationId),
        });
      }
      await dispatch('membership/fetchMemberships', {
        entityId,
        type: MembershipEntityTypes.Entity,
      }, { root: true });
      await dispatch('listContributionSources', entityId);
      if (entity?.supportingDocuments) {
        await dispatch('supportDocumentsMedia/initFiles', entity.supportingDocuments, { root: true });
      }
      if (entity?.eligibilityDocument) {
        await dispatch('eligibilityDocumentMedia/initFiles', entity.eligibilityDocument, { root: true });
      }
      if (entity?.bankCertificate) {
        await dispatch('bankCertificateDocumentMedia/initFiles', entity.bankCertificate, { root: true });
      }
      return entity;
    } catch (e) {
      console.warn(e);
      return {
        error: e,
      };
    }
  },
  loadMembershipConditions: async ({ commit }, id) => {
    try {
      const { data } = await EntityApi.getMembershipConditionsList(id);
      commit('setMembershipConditions', data.membershipConditions.membershipConditions);
      return data.membershipConditions.membershipConditions;
    } catch (e) {
      console.warn(e);
      return { error: e.message };
    }
  },
  submitMembershipConditions: async ({ getters, dispatch, commit }, membershipData = []) => {
    const worker = getters.membershipConditions.find((c) => c.type === 'worker');
    if (!worker) {
      await dispatch('createMembershipCondition', membershipData.find((c) => c.type === 'worker'));
    }
    const membershipPromises = membershipData.reduce((acc, condition) => {
      if (!worker && condition.type === 'worker') {
        return acc;
      }
      if (condition.deleteMode) {
        acc.push(
          dispatch('deleteMembershipCondition', handleConditionsData(condition)),
        );
      } else if (condition.editMode) {
        acc.push(
          dispatch('updateMembershipCondition', handleConditionsData(condition)),
        );
      } else {
        acc.push(dispatch('createMembershipCondition', condition));
      }
      return acc;
    }, []);
    commit('setMembershipConditionsLoading', true);
    const res = await Promise.allSettled(membershipPromises);
    commit('setMembershipConditionsLoading', false);
    return res;
  },
  updateMembershipCondition: ({ commit, dispatch, state }, data) => {
    try {
      const payload = serializer(data, 'membership_condition');
      return EntityApi.updateMembershipConditions(data.id, payload);
    } catch (e) {
      dispatch('loadMembershipConditions', state.form.id);
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return { error: e.message };
    }
  },
  deleteMembershipCondition: ({ commit, dispatch, state }, data) => {
    try {
      const payload = serializer(data, 'membership_condition');
      return EntityApi.deleteMembershipConditions(data.id, payload);
    } catch (e) {
      dispatch('loadMembershipConditions', state.form.id);
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return { error: e.message };
    }
  },
  createMembershipCondition: ({ commit, dispatch, state }, data) => {
    try {
      const payload = serializer(data, 'membership_condition');
      return EntityApi.createMembershipConditions(payload);
    } catch (e) {
      dispatch('loadMembershipConditions', state.form.id);
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      return { error: e.message };
    }
  },
  validateMembers: async ({ state, commit }) => {
    commit('setLoading', true);
    try {
      const entityId = state.form.id;
      const res = await EntityApi.validateMembers(entityId, UnitTypes.Entity);
      return res?.data?.validatePotentialMembersByUnit;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  getMainBankCertificate: async (_, id) => getDocumentsList(
    id,
    'Entity',
    mediaDocTypes.BankCertificate,
    null,
  ),
  fetchEntityDetails: async ({ commit, dispatch }, entityId) => {
    let requestDetails = null;
    try {
      const entityPromise = findEntity(entityId);
      const ownerUserRequestPromise = dispatch('getOwnerUserRequest', entityId);
      const [entityData, ownerUserRequestData, contributionSources] = await Promise.all(
        [entityPromise, ownerUserRequestPromise, listContributionSources(entityId)],
      );
      commit('setEntitySummary', { ...entityData, contributionSources });
      const entityBanks = dispatch('entityRelations/listEntityBanks', entityId, { root: true });
      const membershipConditions = dispatch('loadMembershipConditions', entityId);
      let arrayOfPromises = [
        entityBanks,
        membershipConditions,
      ];
      if (entityData.secondClassification) {
        const fetchEntityClassifications = await getEntityClassifications(
          entityData.secondClassification,
        ) || [];
        const entityClassifications = fetchEntityClassifications[0];
        commit('setEntityClassifications', entityClassifications);

        const listGoals = dispatch(
          'lookup/fetchGoals',
          {
            classificationId: entityData.secondClassification,
          },
          { root: true },
        );
        const listActivities = dispatch(
          'lookup/fetchActivities',
          {
            classificationId: entityData.secondClassification,
          },
          { root: true },
        );
        arrayOfPromises = [...arrayOfPromises, ...[listGoals, listActivities]];
      }
      if (entityData.becomeMember) {
        commit('updateField', {
          path: 'entitySummary.ownerMembership',
          value: normalizeOwnerMembership(ownerUserRequestData),
        });
      }
      requestDetails = await Promise.all(arrayOfPromises);
    } catch (e) {
      requestDetails = ErrorHandler.parseFetchErrors(e);
    }
    return requestDetails;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
