export const LIST_SEVEN_HUNDRED_ERRORS = `query ListUnifiedNumberErrors($unitId: Int!) { 
  listUnifiedNumberErrors(unitId: $unitId) { 
    errors {
              id 
              unitId
              errorEn
              errorAr
              code
            }
        }
      }`;
