import { Gateway, GraphQLRequest } from '@/setup/axios';
import { EmployeeRequestStatuses, EditRequestTypes, UnitTypes } from '@/constants/enums';
import {
  FETCH_REQUEST_TRACKER,
  GET_ENTITIES,
  DELETE_ENTITY_REQUEST,
} from '@/api/graphql/entities-gql';
import { LIST_SEVEN_HUNDRED_ERRORS } from './graphql/seven-hundred-errors-gql';

class RequestApi {
  employeeRequestStatuses = [
    EmployeeRequestStatuses.Pending,
    EmployeeRequestStatuses.AcceptedByUser,
    EmployeeRequestStatuses.RejectedByUser,
    EmployeeRequestStatuses.AcceptedBySpecializedAdmin,
    EmployeeRequestStatuses.RejectedBySpecializedAdmin,
    EmployeeRequestStatuses.AcceptedByHrsdEmployee,
    EmployeeRequestStatuses.RejectedByHrsdEmployee,
    EmployeeRequestStatuses.AcceptedByRegistrationAndPermitAdmin,
    EmployeeRequestStatuses.RejectedByRegistrationAndPermitAdmin,
    EmployeeRequestStatuses.Accepted,
  ];

  getRejectReason = (unitId, unitType = 'Entity') => Gateway.get(
    `establishment_admin_requests?unit_id=${unitId}&unit_type=${unitType}&request_type=approval&status=rejected`,
  );

  getBranchRequests = (page = 1, size = 50, config = {}) => Gateway.get(`entities/search?establishment_type=branch&page=${page}&size=${size}`, config);

  getEntityRequests = ({
    page = 1, size = 50, withAcceptedNamaEntities, config = {},
  }) => GraphQLRequest(
    {
      query: GET_ENTITIES,
      variables: {
        page: Number(page),
        size: Number(size),
        withAcceptedNamaEntities: Boolean(withAcceptedNamaEntities),
      },
    },
    config,
  );

  fetchRequestTracker = (id, type) => GraphQLRequest(
    {
      query: FETCH_REQUEST_TRACKER,
      variables: {
        requestId: Number(id),
        requestType: type,
      },
    },
    null,
    true,
  );

  getEmployeeRequests = (page = 1, size = 10, config = {}) => Gateway.get(
    `employee_requests?by=owner&unit_type=Entity&page=${page}&size=${size}&status=${this.employeeRequestStatuses.join(
      ',',
    )}`,
    config,
  );

  getPolicyRequests = (page = 1, size = 10, config = {}) => Gateway.get(
    `requests_view/edit_requests?type=${EditRequestTypes.RegulationItemsAndGeographicInformation},${EditRequestTypes.GoalsAndActivities}&page=${page}&size=${size}`,
    config,
  );

  getAdjustmentsListRequests = ({
    adjustmentType,
    status = 'pending',
    unitId,
    unitType = UnitTypes.Entity,
  }) => Gateway.get(
    `edit_requests?type=${adjustmentType}&status=${status}&unit_id=${unitId}&unit_type=${unitType}`,
  );

  getChartData = (establishmentType) => Gateway.get(`entities/request_logs/chart?establishment_type=${establishmentType}`);

  getSevenHundredErrorsRequests = (unitId) => GraphQLRequest({
    query: LIST_SEVEN_HUNDRED_ERRORS,
    variables: {
      unitId: Number(unitId),
    },
  });

  deleteRequest = (id) => GraphQLRequest({
    query: DELETE_ENTITY_REQUEST,
    variables: {
      id: Number(id),
    },
  });
}

export default new RequestApi();
