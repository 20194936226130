export const LIST_ENTITY_MEMBERS = `query Memberships($unitId: Int, $role: [String!], $page: Int!, $size: Int!, $active: Boolean) {
  memberships(unitId: $unitId, role: $role, page: $page, size: $size, active: $active) {
    memberships {
      id
      position {
        arTitle
        enTitle
      }
      positionUid
      ownerEndDate
      ownerStartDate
      memberMembership {
        position {
          arTitle
          enTitle
        }
      }
      member {
        absherPhone
        nationalId
        birthDateHijri
        homePhone
        fatherName
        firstName
        grandfatherName
        lastName
        email
      }
      memberId
      memberType
      role
      unitId
      unitType
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const GET_CURRENT_DELEGATE = `query GetCurrentDelegate($id: Int, $role: [String!], $active: Boolean) {
  memberships(unitId: $id, role: $role, active: $active) {
    memberships {
      id
      member {
        email
        nationalId
        birthDateHijri
        fatherName
        firstName
        grandfatherName
        lastName
      }
    }
  }
}`;

export const GET_DELEGATE_DETAILS = `query GetDelegateDetails($id: Int!) {
  findMembershipRequest(id: $id) {
    memberMembership {
      position {
        arTitle
        enTitle
      }
      ownerStartDate
      ownerEndDate
    }
    rejectReason
    status
    unitId
    unit {
      ...on Entity {
        entityLicenseNumber
        type
        city {
          arTitle
          enTitle
        }
        region {
          arTitle
          enTitle
        }
        classificationDetails {
          firstSubClassificationArTitle
          mainClassificationArTitle
          secondSubClassificationArTitle
        }
        goals {
          ... on CommonGoal {
            arTitle
            enTitle
          }
        }
        familyGoals: goals {
          ... on FamilyTrustGoal {
            arTitle
            enTitle
          }
        }
        nameArabic
        nameEnglish
      }
    }
    email
    memberId
    member {
      birthDateHijri
      nationalId
      email
      firstName
      fatherName
      grandfatherName
      lastName
      account {
        email
      }
    }
  }
}`;

export const GET_MEMBERSHIP_DETAILS = `query GetMembershipDetails($id: Int!) {
  findMembership(id: $id) {
    position {
      arTitle
      enTitle
    }
    positionUid
    memberMembership {
      position {
        arTitle
        enTitle
      }
      member {
        birthDateHijri
        nationalId
        email
        firstName
        fatherName
        grandfatherName
        lastName
      }
    }
    member {
      birthDateHijri
      nationalId
      email
      firstName
      fatherName
      grandfatherName
      lastName
      account {
        email
      }
    }
    ownerEndDate
    ownerStartDate
  }
}`;

export const CREATE_MEMBERSHIP_REQUEST = `mutation createMembershipRequest($membershipRequestCreateAttributes: MembershipRequestCreateInput!) {
  createMembershipRequest (membershipRequestCreateAttributes: $membershipRequestCreateAttributes) {
    id
  }
}`;

export const GET_REQUESTS = `query GetRequests($unitId: Int, $page: Int!, $size: Int!, $unitType: MembershipRequestUnitType, $status: [MembershipRequestStatus!], $asChairman: Boolean) {
  listMembershipRequests(unitId: $unitId, page: $page, size: $size, unitType: $unitType, status: $status, asChairman: $asChairman) {
    membershipRequests {
      id
      status
      createdAt
      unitType
      unit {
        ...on Entity {
          type
          owner {
            firstName
            fatherName
            grandfatherName
            lastName
          }
          nameArabic
          nameEnglish
        }
      }
      member {
        absherPhone
        nationalId
        birthDateHijri
        homePhone
        fatherName
        firstName
        grandfatherName
        lastName
        email
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const REJECT_MEMBERSHIP_REQUEST = `mutation rejectMembershipRequest($membershipRequestRejectAttributes: MembershipRequestsRejectInput!) {
  rejectMembershipRequest(membershipRequestRejectAttributes: $membershipRequestRejectAttributes) {
    id
    unitId
  }
}`;

export const ACCEPT_MEMBERSHIP_REQUEST = `mutation acceptMembershipRequest($membershipRequestAcceptAttributes: MembershipRequestsAcceptInput!) {
  acceptMembershipRequest(membershipRequestAcceptAttributes: $membershipRequestAcceptAttributes) {
    id
    unitId
  }
}`;
