import { LocaleTypes } from '@/constants/enums';
import router from './router';
import enMessages from './translations/en.json';
import arMessages from './translations/ar.json';

export default {
  router,
  i18n: {
    [LocaleTypes.En]: enMessages,
    [LocaleTypes.Ar]: arMessages,
  },
};
