import DateTime from 'luxon/src/datetime';
import i18n from '@/i18n';
import { DatePattern, TimePart } from '@/constants/enums';

const getHours = (minutes) => Math.floor(minutes / 60);

const getDays = (minutes) => Math.floor((minutes / 60) / 24);

const minutesWithTranslation = (minutes) => `${minutes} ${i18n.t('general.minutes')}`;

const hoursWithTranslation = (hours) => `${hours} ${i18n.t('general.hours')}`;

const daysWithTranslation = (days) => `${days} ${i18n.t('general.days')}`;

export const formatDate = (str, format = DatePattern.General) => {
  if (!str) return 'general.noDate';
  if (!format) format = DatePattern.General;
  if (typeof str === 'string' && str.includes('UTC')) { // safari fix
    str = str.replace(/-/g, '/');
  }
  return DateTime.fromJSDate(new Date(str)).toFormat(format);
};

export const getRemainingTime = (time, timeUnits = TimePart.Minutes) => {
  if (!time) return 0;
  if (!timeUnits) timeUnits = TimePart.Minutes;
  if (typeof time === 'string' && time.includes('UTC')) { // safari fix
    time = time.replace(/-/g, '/');
  }
  const diffObj = DateTime.fromJSDate(new Date(time)).diff(DateTime.now(), timeUnits);
  return diffObj.toObject()[timeUnits];
};

export const timeConvert = (isoTime, timeUnits = DateTime.Minutes) => {
  const minutes = Math.round(getRemainingTime(isoTime, timeUnits));
  if (minutes < 0) {
    return minutesWithTranslation(0);
  }

  let time = 0;

  switch (true) {
    case minutes > 60 && minutes < 1440:
      time = hoursWithTranslation(getHours(minutes));
      break;
    case minutes >= 1440:
      time = daysWithTranslation(getDays(minutes));
      break;
    default:
      time = minutesWithTranslation(minutes);
  }
  return time;
};

export const dateFormatter = (str) => {
  if (!str) return i18n.t('general.noDate');
  str = String(str);
  const year = str.slice(0, 4);
  const month = str.slice(4, 6);
  const day = str.slice(6, 8);
  return `${year}/${month}/${day}`;
};
