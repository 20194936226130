<template>
  <div class="bank-permit-module dashboard-page">
    <router-view />
  </div>
</template>

<script>
import bankPermit from '@/modules/bank-permit/store/bank-permit';

export default {
  name: 'BankPermitModule',
  beforeCreate() {
    this.$store.registerModule('bankPermit', bankPermit);
  },
  destroyed() {
    this.$store.unregisterModule('bankPermit');
  },
};
</script>
