import i18n from '@/i18n';
import DocumentsApi from '@/api/documents.api';
import { deserializer } from '@/helpers/api';
import { generatePDF } from '@/helpers/document';
import ErrorHandler from '@/helpers/errors';
import { isFeatureAvailable } from '@/helpers/features-config';
import { featureNames } from '@/constants/enums';

const mainState = {
  entityDocInfo: null,
  bankAccount: null,
  loading: false,
  generating: false,
  error: false,
  currentBankAccountId: null,
  banks: [],
};

const mainGetters = {
  bankAccount: (store) => store.bankAccount,
  banks: (store) => store.banks,
  isLoading: (store) => store.loading,
  isError: (store) => store.error,
};

const mutations = {
  setBankAccount: (store, value) => {
    store.bankAccount = value;
  },
  setError: (store, value) => {
    store.error = value;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  setGenerating: (store, value) => {
    store.generating = value;
  },
};

const actions = {
  getDocument: async ({ commit, dispatch }, {
    id, itemType, docType, bankId, fileName, download, uid,
  }) => {
    commit('setGenerating', true);
    try {
      let res;
      if (uid) {
        res = await DocumentsApi.getDocumentV2(uid);
      } else {
        if (isFeatureAvailable(featureNames.hotkeys)) {
          dispatch('toast/showNotification', {
            message: 'Deprecated API method used for document downloading',
            duration: 2000,
            type: 'warning',
          }, { root: true });
        }
        // Method is deprecated due to security reason
        res = await DocumentsApi.getDocument(id, itemType, docType, bankId);
      }
      generatePDF(
        res.data,
        fileName || `${itemType}_${docType}`,
        download,
      );
    } catch (e) {
      dispatch('toast/showNotification', {
        message: i18n.t('documents.pdf.error'),
        duration: 2000,
        type: 'error',
      }, { root: true });
    } finally {
      commit('setGenerating', false);
    }
  },
  getBankAccount: async ({ commit, state }, id) => {
    if (state.bankAccount && state.bankAccount.id === id) {
      return state.bankAccount;
    }
    commit('setError', false);
    commit('setLoading', true);
    try {
      const res = await DocumentsApi.getBranchDocuments(id);
      const data = await deserializer(res.data);
      commit('setBankAccount', data);
      return data;
    } catch (e) {
      commit('setError', true);
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  mainState,
  mainGetters,
  mutations,
  actions,
};
