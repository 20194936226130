import UserRequestsApi from '@/api/user-requests.api';
import {
  EmployeeHistoryStatuses,
  EmployeeRequestStatuses,
  MembershipRequestStatus,
  RequestMode,
  UnitTypes,
  UserRequestStatuses,
} from '@/constants/enums';
import EmployeeApi from '@/api/employee.api';
import { handleUserRequestList } from '@/services/user-requests.service';
import ErrorHandler from '@/helpers/errors';
import DelegateManagementApi from '@/api/delegate-management.api';

const mainState = {
  userRequestsList: [],
  userRequestsCount: {
    entity: 0,
    branch: 0,
    employee: 0,
    delegate: 0,
  },
  userRequestsCountLoading: false,
};

const mainGetters = {
  getUserRequestsList(store) {
    return store.userRequestsList;
  },
  getEntityRequestCount(store) {
    return store.userRequestsCount.entity;
  },
  getBranchRequestCount(store) {
    return store.userRequestsCount.branch;
  },
  getEmployeeRequestCount(store) {
    return store.userRequestsCount.employee;
  },
  getDelegateRequestCount(store) {
    return store.userRequestsCount.delegate;
  },
  getUserRequestsCountLoading(store) {
    return store.userRequestsCountLoading;
  },
  getTotalRequestsCount(store) {
    return store.userRequestsCount.entity
      + store.userRequestsCount.branch
      + store.userRequestsCount.delegate
      + store.userRequestsCount.employee;
  },
};

const mutations = {
  setUserRequestsCount: (store, data) => {
    const [entity, branch, employee, delegate] = data;
    store.userRequestsCount.entity = entity?.recordsCount || 0;
    store.userRequestsCount.branch = branch?.recordsCount || 0;
    store.userRequestsCount.employee = employee?.recordsCount || 0;
    store.userRequestsCount.delegate = delegate?.recordsCount || 0;
  },
  setDelegateRequestsCount: (store, count) => {
    store.userRequestsCount.delegate = count || 0;
  },
  setBranchRequestsCount: (store, count) => {
    store.userRequestsCount.branch = count || 0;
  },
  setEmployeeRequestsCount: (store, count) => {
    store.userRequestsCount.employee = count || 0;
  },
  setEntityRequestsCount: (store, count) => {
    store.userRequestsCount.entity = count || 0;
  },
  setUserRequestsList: (store, data) => {
    store.userRequestsList = data;
  },
  setUserRequestsCountLoading: (store, value) => {
    store.userRequestsCountLoading = value;
  },
  resetUserRequestsCount: (store) => {
    store.userRequestsCount = {
      entity: 0,
      branch: 0,
      employee: 0,
    };
  },
};

const actions = {
  fetchEmployeeUserRequestsList: async ({ commit, rootGetters }, mode) => {
    const isHistoryMode = mode === RequestMode.History;
    const payload = {
      page: rootGetters['userRequestsPagination/selectedPage'] || 1,
      status: isHistoryMode ? EmployeeHistoryStatuses : EmployeeRequestStatuses.Pending,
      size: 8,
    };
    try {
      const res = await EmployeeApi.getEmployeeRequests(payload);
      const data = res?.data?.listEmployeeRequests;
      commit('setUserRequestsList', data?.employeeRequests);
      if (!isHistoryMode) {
        commit('setEmployeeRequestsCount', data?.meta?.recordsCount);
      }
      commit('userRequestsPagination/setTotalPages', data?.meta.pageCount, { root: true });
      commit('userRequestsPagination/setTotalRecords', data?.meta.recordsCount, { root: true });
      return data;
    } catch (e) {
      console.warn(e);
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchEntityOrBranchUserRequestsList: async ({ commit, rootGetters }, unitType) => {
    const payload = {
      page: rootGetters['userRequestsPagination/selectedPage'] || 1,
      size: 10,
      unitType: unitType || UnitTypes.Entity,
    };
    try {
      const res = await UserRequestsApi.getUserRequestsGql(payload);
      const { meta, userRequests } = res?.data?.userRequests;
      commit('setUserRequestsList', handleUserRequestList(userRequests));
      if (unitType === UnitTypes.Branch) {
        commit('setBranchRequestsCount', meta?.recordsCount);
      } else {
        commit('setEntityRequestsCount', meta?.recordsCount);
      }
      commit('userRequestsPagination/setTotalPages', meta.pageCount, { root: true });
      commit('userRequestsPagination/setTotalRecords', meta.recordsCount, { root: true });
      return res;
    } catch (e) {
      console.warn(e);
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchDelegateUserRequestsList: async ({ commit, rootGetters }) => {
    const payload = {
      page: rootGetters['userRequestsPagination/selectedPage'] || 1,
      status: [EmployeeRequestStatuses.Pending],
      size: 10,
      asChairman: false,
    };
    try {
      const res = await DelegateManagementApi.listEntityRequests(payload);
      const data = res?.data?.listMembershipRequests;
      commit('setUserRequestsList', handleUserRequestList(data?.membershipRequests));
      commit('setDelegateRequestsCount', data?.meta?.recordsCount);
      commit('userRequestsPagination/setTotalPages', data?.meta.pageCount, { root: true });
      commit('userRequestsPagination/setTotalRecords', data?.meta.recordsCount, { root: true });
      return data;
    } catch (e) {
      console.warn(e);
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchUserRequestsCounts: async ({ dispatch, commit }) => {
    commit('setUserRequestsCountLoading', true);
    const promises = [
      dispatch('fetchUserRequestCount', UnitTypes.Entity),
      dispatch('fetchUserRequestCount', UnitTypes.Branch),
      dispatch('fetchEmployeeRequestCount'),
      dispatch('fetchDelegateRequestCount'),
    ];
    const data = await Promise.all(promises);
    commit('setUserRequestsCount', data);
    commit('setUserRequestsCountLoading', false);
  },
  fetchEmployeeRequestCount: async () => {
    const payload = {
      page: 1,
      size: 1,
      status: UserRequestStatuses.Pending,
    };
    try {
      const res = await EmployeeApi.getEmployeeRequests(payload);
      const { meta } = res?.data?.listEmployeeRequests;
      return meta;
    } catch (e) {
      console.warn(e);
      return {};
    }
  },
  fetchDelegateRequestCount: async () => {
    try {
      const res = await DelegateManagementApi.listEntityRequests({
        page: 1,
        size: 1,
        status: [MembershipRequestStatus.Pending],
        asChairman: false,
      });
      const { meta } = res?.data?.listMembershipRequests;
      return meta;
    } catch (e) {
      console.warn(e);
      return {};
    }
  },
  fetchUserRequestCount: async (_, unitType) => {
    const payload = {
      page: 1,
      size: 1,
      unitType,
      status: UserRequestStatuses.Pending,
    };
    try {
      const res = await UserRequestsApi.getUserRequestsGql(payload);
      const { meta } = res?.data?.userRequests;
      return meta;
    } catch (e) {
      console.warn(e);
      return {};
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
