import { GraphQLRequest } from '@/setup/axios';
import {
  CREATE_FAMILY_FUND,
  UPDATE_FAMILY_FUND,
  UPDATE_PROGRESS_STEP,
  UPDATE_OWNER_EDUCATION,
  CREATE_FAMILY_FUND_SUBSCRIPTION,
  UPDATE_FAMILY_FUND_SUBSCRIPTION,
  DELETE_FAMILY_FUND_SUBSCRIPTION,
  FIND_FAMILY_FUND_ENTITY_BY_ID,
  FIND_FAMILY_FUND_SUBSCRIPTION,
  UPDATE_FAMILY_OWNER_REQUEST,
  FINAL_SUBMIT_FAMILY_FUND,
  FIND_OWNER_FAMILY_FUND_ENTITY_BY_ID,
  GET_INFO,
} from '@/api/graphql/family-fund-gql';

class FamilyFundApi {
  createFamilyFund = (data) => (
    GraphQLRequest({
      query: CREATE_FAMILY_FUND,
      variables: {
        familyFundEntityCreateAttributes: {
          nameArabic: data.nameArabic,
          autocreateMembershipForOwner: data.autocreateMembershipForOwner,
          foundFor: data.foundFor,
          foundForUserId: data.foundForUserId,
          membershipData: data.membershipData,
          families: data.families,
        },
      },
    })
  )

  updateFamilyFund = (data, stepData) => (
    GraphQLRequest({
      query: UPDATE_FAMILY_FUND,
      variables: {
        familyFundEntityUpdateAttributes: data,
        progressStepUpdateAttributes: stepData,
      },
    })
  )

  updateStepProgress = (data) => (
    GraphQLRequest({
      query: UPDATE_PROGRESS_STEP,
      variables: {
        progressStepUpdateAttributes: {
          step: data.step,
          unitId: +data.unitId,
          unitType: data.unitType,
        },
      },
    })
  )

  updateOwnerEducation = (data, stepData, familyRelationId, requestId) => (
    GraphQLRequest({
      query: UPDATE_OWNER_EDUCATION,
      variables: {
        userUpdateAttributes: data,
        id: +requestId,
        familyRelationUid: familyRelationId,
        progressStepUpdateAttributes: stepData,
      },
    })
  )

  updateFamilyOwnerRequest = (ownerMembership) => (
    GraphQLRequest({
      query: UPDATE_FAMILY_OWNER_REQUEST,
      variables: {
        userRequestUpdateAttributes: {
          id: +ownerMembership?.id,
          familyRelationUid: ownerMembership.familyRelationId,
          email: ownerMembership.email,
          positionUid: ownerMembership?.positionUid,
          foundingMember: !!ownerMembership?.foundingMember,
        },
      },
    })
  )

  findFamilyFundEntity = (id) => (
    GraphQLRequest({
      query: FIND_FAMILY_FUND_ENTITY_BY_ID,
      variables: {
        id: Number(id),
      },
    })
  )

  findOwnerFamilyFundEntity = (id) => (
    GraphQLRequest({
      query: FIND_OWNER_FAMILY_FUND_ENTITY_BY_ID,
      variables: {
        id: Number(id),
      },
    })
  )

  findFamilyFundSubscription = (id) => (
    GraphQLRequest({
      query: FIND_FAMILY_FUND_SUBSCRIPTION,
      variables: {
        entityId: Number(id),
      },
    })
  )

  createFamilyFundSubscription = (data, stepData) => (
    GraphQLRequest({
      query: CREATE_FAMILY_FUND_SUBSCRIPTION,
      variables: {
        familyFundSubscriptionCreateAttributes: data,
        progressStepUpdateAttributes: stepData,
      },
    })
  )

  updateFamilyFundSubscription = (data, stepData) => (
    GraphQLRequest({
      query: UPDATE_FAMILY_FUND_SUBSCRIPTION,
      variables: {
        familyFundSubscriptionUpdateAttributes: data,
        progressStepUpdateAttributes: stepData,
      },
    })
  )

  deleteFamilyFundSubscription = (id, stepData) => (
    GraphQLRequest({
      query: DELETE_FAMILY_FUND_SUBSCRIPTION,
      variables: {
        id,
        progressStepUpdateAttributes: stepData,
      },
    })
  )

  finalSubmitFamilyFund = (id) => (
    GraphQLRequest({
      query: FINAL_SUBMIT_FAMILY_FUND,
      variables: {
        familyFundSubmitAttributes: { id: +id },
      },
    })
  )

  getInfo = (data) => (
    GraphQLRequest({
      query: GET_INFO,
      variables: {
        nationalId: data.nationalId,
        birthDateHijri: data.birthDateHijri,
      },
    })
  )
}

export default new FamilyFundApi();
