export const EDUCATION_OPTIONS = [
  {
    key: 'k1',
    label: 'educationOptions.key1',
  },
  {
    key: 'k2',
    label: 'educationOptions.key2',
  },
  {
    key: 'k3',
    label: 'educationOptions.key3',
  },
  {
    key: 'k4',
    label: 'educationOptions.key4',
  },
  {
    key: 'k5',
    label: 'educationOptions.key5',
  },
];

export const MEMBERSHIP_TYPES_OPTIONS = [
  {
    key: 'worker',
    label: 'membershipTypesDrop1',
  },
  {
    key: 'associate',
    label: 'membershipTypesDrop2',
  },
  {
    key: 'honorary1',
    label: 'membershipTypesDrop3',
  },
  {
    key: 'honorary2',
    label: 'membershipTypesDrop4',
  },
];

export const KIND_OF_EXTERNAL = [
  {
    key: 'financial',
    label: 'kindOfExternal.k1',
  },
  {
    key: 'logistics',
    label: 'kindOfExternal.k2',
  },
  {
    key: 'assets',
    label: 'kindOfExternal.k3',
  },
];

export const CEO_YEARS_OPTIONS = Array.from(Array(40), (_, i) => i + 1);

export const MEMBERSHIP_TYPE_ASSOCIATION_OPTIONS = [
  {
    key: 'open',
    label: 'membershipTypeAssociationOpen',
  },
  {
    key: 'closed',
    label: 'membershipTypeAssociationClosed',
  },
];

export const TYPE_OF_BENEFIT_OPTIONS = [
  {
    key: 'open',
    label: 'entity.benefitOpen',
  },
  {
    key: 'closed',
    label: 'entity.benefitClose',
  },
];

export const ENTITIES_TYPE_OPTIONS = [
  {
    key: 'privateAssociation',
    label: 'entitiesList.filterOptions.privateAssociation',
  },
  {
    key: 'privateCorporation',
    label: 'entitiesList.filterOptions.privateCorporation',
  },
  {
    key: 'familyFund',
    label: 'entitiesList.filterOptions.familyFund',
  },
];

export const DURATION_OPTIONS = [
  {
    key: 'h',
    label: 'hours',
  },
  {
    key: 'd',
    label: 'days',
  },
  {
    key: 'm',
    label: 'months',
  },
];

export const ACTIVITIES_TYPES_OPTIONS = [
  {
    key: 'programs',
    label: 'programs',
  },
  {
    key: 'projects',
    label: 'projects',
  },
  {
    key: 'activities',
    label: 'activities',
  },
  {
    key: 'participations',
    label: 'participations',
  },
];

export const ACCOUNT_TYPES_OPTIONS = [
  {
    key: 'main_account',
    label: 'createPermit.main_account',
  },
  {
    key: 'branch_account',
    label: 'createPermit.branch_account',
  },
  {
    key: 'branch_expenses_account',
    label: 'createPermit.branch_expenses_account',
  },
];

export const NON_GOVERNMENTAL_ENTITY_TYPES = [
  {
    key: 'NonProfitOrganizationLicense',
    label: 'nonGovernmentalTypes.license',
  },
  {
    key: 'CommercialRegistrationNumber',
    label: 'nonGovernmentalTypes.commercial',
  },
  {
    key: 'EndowmentDeedNumber',
    label: 'nonGovernmentalTypes.endowment',
  },
  {
    key: 'PrivateAssociation',
    label: 'nonGovernmentalTypes.privateAssociation',
  },
  {
    key: 'PrivateCorporation',
    label: 'nonGovernmentalTypes.privateCorporation',
  },
];
export const NON_GOVERNMENTAL_COOPERATIVE_ENTITY_TYPES = [
  {
    key: 'NonProfitOrganizationLicense',
    label: 'nonGovernmentalTypes.license',
  },
  {
    key: 'CommercialRegistrationNumber',
    label: 'nonGovernmentalTypes.commercial',
  },
  {
    key: 'EndowmentDeedNumber',
    label: 'nonGovernmentalTypes.endowment',
  },
];

export const FAMILY_RELATIONS = [
  {
    key: '1',
    label: 'familyFund.relation.firstClass',
  },
  {
    key: '2',
    label: 'familyFund.relation.secondClass',
  },
  {
    key: '3',
    label: 'familyFund.relation.thirdClass',
  },
  {
    key: '4',
    label: 'familyFund.relation.forthClass',
  },
];

export const USER_GENDER = [
  {
    key: 'ذكر',
    label: 'general.gender.m',
  },
  {
    key: 'أنثى',
    label: 'general.gender.f',
  },
];
