import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import isEmpty from 'lodash.isempty';
import {
  API_URL,
  IS_DEMO,
  IS_PROD,
  CI_COMMIT_SHORT_SHA,
} from '@/constants';
// import * as Sentry from '@sentry/vue';

export const cache = setupCache({
  exclude: {
    methods: ['put', 'patch', 'delete', 'post', 'get'],
  },
  debug: false,
  invalidate: async (config, request) => {
    if (request.clearCacheEntry) {
      await config.store?.clear();
    }
  },
});

export const Gateway = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  adapter: cache.adapter,
  headers: {
    'spa-version': CI_COMMIT_SHORT_SHA,
  },
});

export const GraphQL = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  adapter: cache.adapter,
  headers: {
    'spa-version': CI_COMMIT_SHORT_SHA,
  },
});

export const GraphQLRequest = (data, config = {}, useCache) => {
  if (useCache) {
    config = {
      ...config,
      cache: {
        maxAge: 2 * 60 * 1000,
        exclude: {
          methods: [],
        },
      },
    };
  }
  return GraphQL.post('graphql', data || {}, config);
};

export const setupInterceptors = (store) => {
  Gateway.interceptors.response.use(
    (response) => response,
    async (error) => {
      if ([401, 403].includes(error?.response?.status)) {
        store.commit('user/setProfile', null, { root: true });
      }
      if (IS_DEMO) {
        if ([501].includes(error?.response.status)) {
          const endPoint = error?.response.config.baseURL + error?.response.config.url;
          store.dispatch('toast/showNotification', {
            message: `Unrealized APIs were used on this page. Please open network and check all of them. ${endPoint}`,
            duration: 60000,
            type: 'error',
          });
        }
      }
      return Promise.reject(error);
    },
  );

  GraphQL.interceptors.response.use(
    (response) => {
      const errors = response.data?.errors || [];
      if (!isEmpty(errors)) {
        if (!IS_PROD) {
          const messages = errors.map((e) => e.message);
          // Sentry.captureMessage('GraphQL', {
          //   level: 'error',
          //   extra: {
          //     errors: messages.join('\n'),
          //     GqlQuery: response.config.data,
          //   },
          // });
          store.dispatch('toast/showNotification', {
            message: messages.join(', '),
            duration: 5000,
            type: 'error',
          });
        }
        return Promise.reject(errors);
      }
      return response.data;
    },
    (error) => {
      if ([401, 403].includes(error?.response?.status)) {
        store.commit('user/setProfile', null, { root: true });
      }
      return Promise.reject(error);
    },
  );
};
