import NotificationsAPI from '@/api/notifications.api';
import { deserializer, serializer } from '@/helpers/api';
import { NotificationStatus } from '@/constants/enums';

const mainState = {
  global: [],
};

const mainGetters = {
  isLoading: (store) => store.loading,
  getLastGlobal: (store) => store.global.filter(
    (notification) => notification.status !== NotificationStatus.Read,
  )[0],
};

const mutations = {
  setNotificationsList: (store, list) => {
    store.global = list;
  },
  markLastNotificationAsRead: (store, notificationID) => {
    const notification = store.global.find((n) => n.id === notificationID);
    notification.status = NotificationStatus.Read;
  },
};

const actions = {
  getAllNotifications: async ({ commit }) => {
    try {
      const res = await NotificationsAPI.getNotifications();
      const notifications = await deserializer(res.data);
      commit('setNotificationsList', notifications);
    } catch (e) {
      console.warn(e);
    }
  },
  updateNotificationStatus: async ({ dispatch }, data) => {
    try {
      const rq_body = await serializer(data, 'notification');
      await NotificationsAPI.updateNotificationStatus(data.id, rq_body);
      dispatch('getAllNotifications');
    } catch (e) {
      console.warn(e);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
