<template>
  <div class="auth-layout">
    <Header>
      <router-link
        v-if="isSignIn"
        slot="header-left"
        :to="{ name: 'Registration' }"
        tag="button"
        class="base-button transparent auth-btn"
        v-text="$t('login.registration')"
      />
      <router-link
        v-if="!isSignIn"
        slot="header-left"
        :to="{ name: 'Login' }"
        tag="button"
        class="base-button transparent auth-btn"
        v-text="$t('login.login')"
      />
    </Header>
    <div class="auth-main">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from '@/containers/header/Header.vue';

export default {
  name: 'AuthLayout',
  components: {
    Header,
  },
  computed: {
    isSignIn() {
      return this.$route.name === 'Login';
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
