import { getField, updateField } from 'vuex-map-fields';
import Hijri from 'hijrah-date';

import i18n from '@/i18n';
import EditPolicyApi from '@/api/edit-policy.api';
import { serializer, deserializer } from '@/helpers/api';
import ErrorHandler from '@/helpers/errors';
import { MEMBERSHIP_TYPES_OPTIONS } from '@/constants/dropdowns';
import {
  EditRequestUnitTypes,
  mediaDocTypes,
  mediaAttachableTypes,
  LocaleTypes,
  ClassificationsTypes,
  EditEntityPolicyTabset,
  EditPolicyStatuses,
  EditRequestUnitActions,
  DatePattern,
} from '@/constants/enums';

import {
  createOriginalDublicateGoalsAndActivities,
  compareOriginalAndCurrentGoalsAndActivities,
  normalizeEntityPolicy,
} from '@/services/entity-policy.service';
import { getDocumentsList, uploadDocuments } from '@/services/media.service';
import LookupService from '@/services/lookup.service';

const mainState = {
  entityInfo: normalizeEntityPolicy(),
  entityPrimaryInformationChanged: false,
  entityRegulationItemsChanged: false,
  entityGoalsAndActivitiesChanged: false,
  loading: false,
  fileLoading: false,
  membershipConditions: [],
  activeMembershipType: MEMBERSHIP_TYPES_OPTIONS[0]?.key,
  entityLocations: [],
  temporaryEntityLocations: [],
  editModes: {
    classification: {
      isEditable: false,
      isDisabled: false,
    },
    firstClassification: {
      isEditable: false,
      isDisabled: false,
    },
    secondClassification: {
      isEditable: false,
      isDisabled: false,
    },
    goals: {
      isEditable: false,
      isDisabled: false,
    },
    activities: {
      isEditable: false,
      isDisabled: false,
    },
  },
  citiesLoading: false,
  entityCities: [],
  activeTabsetItem: EditEntityPolicyTabset.PrimaryInformationTab,
  editRequestPrimaryInformation: null,
  editRequestRegulationItems: null,
  editRequestGoalsAndActivities: null,
  sessionLoading: false,
  newDataSaving: false,
  errors: [],
  originalGoalsAndActivities: {},
  entityType: null,
  entityUpdates: [],
};

const handleEntityUpdates = (updateList) => (
  updateList.map((item) => ({
    createdAt: new Hijri(item.createdAt).format(DatePattern.Rtl),
    status: item.status,
    type: item.type,
    updatedAt: new Hijri(item.updatedAt).format(DatePattern.Rtl),
    version: item.version,
  }))
);

const mainGetters = {
  getField,
  getEntityInfo: (state) => state.entityInfo,
  isLoading: (state) => state.loading,
  isFileLoading: (state) => state.fileLoading,
  entityType: (state) => state.entityType,
  getEntityLocations: (state) => state.entityLocations
    .map((item) => ({
      key: item.editItemId,
      id: item.unitId ? item.unitId : item.editItemId,
      editItemId: item.editItemId,
      cityUid: item.cityUid,
      regionCode: item.regionCode,
      city: i18n.locale === LocaleTypes.Ar ? item.cityArTitle : item.cityEnTitle,
      region: i18n.locale === LocaleTypes.Ar ? item.regionArTitle : item.regionEnTitle,
      loading: item.loading,
      isTemporary: !item.unitId,
    })),
  editRequestGoalsAndActivities: (state) => state.editRequestGoalsAndActivities,
  editRequestRegulationItems: (state) => state.editRequestRegulationItems,
  getErrors: (store) => store.errors,
  getLastEntityUpdates: (store) => {
    const latestUpdate = store.entityUpdates[0]?.updatedAt;
    let arr = [];
    try {
      arr = [
        ...new Set((store.entityUpdates.filter(
          (u) => u?.updatedAt === latestUpdate,
        )).map((obj) => JSON.stringify(obj))),
      ].map((str) => JSON.parse(str));
    } catch (error) {
      console.warn(error);
    }
    return arr;
  },
};

const mutations = {
  updateField,
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setLoading: (state, value) => {
    state.loading = value;
  },
  setOriginalDublicateGoalsAndActivities: (state, data) => {
    state.originalGoalsAndActivities = createOriginalDublicateGoalsAndActivities(data);
  },
  setEntityUpdates: (store, list) => {
    store.entityUpdates = list;
  },
};

const actions = {
  fetchRegulationItemsEditItems: async ({ commit }, { editRequestId, action }) => {
    try {
      const response = await EditPolicyApi.fetchEditItems(editRequestId, action);
      const editItems = await deserializer(response.data);
      commit('saveRegulationItemsEditItems', editItems);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      throw error;
    }
  },
  fetchGoalsAndActivitiesEditItems: async ({ commit }, editRequestId) => {
    try {
      const response = await EditPolicyApi.fetchEditItems(editRequestId);
      const editItems = await deserializer(response.data);
      commit('saveGoalsAndActivitiesEditItems', editItems);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      throw error;
    }
  },
  changeEditMode: ({ state, commit }, { key, value }) => {
    if (Object.hasOwnProperty.call(state.editModes, key)) {
      Object.keys(state.editModes).forEach((field) => {
        if (key === field) {
          commit('setEditingMode', {
            key: field,
            value,
          });
        } else if (state.editModes[field].isEditable) {
          commit('setEditingMode', {
            key: field,
            value: false,
          });
        }
      });
    } else {
      console.warn(`Something went wrong. Field ${key} is undefined in Store Edit Modes.`);
    }
  },
  saveGoalsAndActivitiesTab: async ({
    commit, state, getters, dispatch,
  }) => {
    const session = getters.editRequestGoalsAndActivities;
    const changedData = {
      activity_ids: state.entityInfo.activities?.map((i) => Number(i)),
      goal_ids: state.entityInfo.goals?.map((i) => Number(i)),
      classification: state.entityInfo.classification,
      firstClassification: state.entityInfo.firstClassification,
      secondSubClassificationId: state.entityInfo.secondClassification,
    };
    const originalData = {
      activity_ids: state.originalGoalsAndActivities.activities?.map((i) => Number(i)),
      goal_ids: state.originalGoalsAndActivities.goals?.map((i) => Number(i)),
      classification: state.originalGoalsAndActivities.classification,
      firstClassification: state.originalGoalsAndActivities.firstClassification,
      secondSubClassificationId: state.originalGoalsAndActivities.secondClassification,
    };
    const updatedData = compareOriginalAndCurrentGoalsAndActivities(originalData, changedData);

    if (session.id) {
      const payload = serializer({
        action: 'update',
        unitId: session.unitId,
        unitType: session.unitType,
        editRequestId: session.id,
        newPayload: updatedData,
      }, 'edit_item');
      commit('setNewDataSaving', true);
      try {
        const res = await EditPolicyApi.updateEditedField({
          editRequestId: session.id,
          editItemId: state.entityInfo.editItemId,
          data: payload,
        });
        await dispatch('submitRequestStatus',
          {
            id: session.id,
            status: EditPolicyStatuses.Pending,
          });
        dispatch('toast/showNotification', {
          message: i18n.t('general.dataSaved'),
          duration: 4000,
          type: 'success',
        }, { root: true });
        return res;
      } catch (e) {
        const { error } = ErrorHandler.parseFormErrors(e);
        commit('setErrors', error);
        return ErrorHandler.parseFormErrors(e);
      } finally {
        commit('setNewDataSaving', false);
      }
    }
    return false;
  },
  fetchFirstClassifications: ({ dispatch, getters }) => dispatch('lookup/fetchClassifications', {
    type: ClassificationsTypes.FirstSubClassification,
    id: getters.getEntityInfo.classification,
  }, { root: true }),
  fetchSecondClassifications: ({ dispatch, getters }) => dispatch('lookup/fetchClassifications', {
    type: ClassificationsTypes.SecondSubClassification,
    id: getters.getEntityInfo.firstClassification,
  }, { root: true }),
  fetchGoals: ({ dispatch, getters }) => dispatch('lookup/fetchGoals', {
    classificationId: getters.getEntityInfo.secondClassification,
  }, { root: true }),
  fetchActivities: ({ dispatch, getters }) => dispatch('lookup/fetchActivities', {
    classificationId: getters.getEntityInfo.secondClassification,
  }, { root: true }),
  updateEntityInfo: ({ commit, dispatch }, {
    fieldObj,
    fieldValue,
  }) => {
    commit('updateField', {
      path: `entityInfo.${fieldObj.fieldId}`,
      value: fieldValue,
    });
    commit('setEntityGoalsAndActivitiesChanged', true);
    commit('resetClassificationsIds', fieldObj.resetIds);
    dispatch('changeDisabledMode', fieldObj.needToDisable);
    const fetchData = fieldObj.fetch;
    fetchData.forEach((i) => dispatch(i));
  },
  updateEntityPolicy: async ({ commit, state }, data) => {
    try {
      const {
        editRequestId, editItemId, unitType, id,
      } = state.entityInfo;
      const payload = {
        action: EditRequestUnitActions.Update,
        editRequestId,
        unitType,
        unitId: id,
        newPayload: { ...data },
      };
      const serializedData = serializer(payload, 'edit_item');
      await EditPolicyApi.updateEditedField({ editRequestId, editItemId, data: serializedData });
      if (
        state.activeTabsetItem === EditEntityPolicyTabset.PrimaryInformationTab
      ) {
        commit('setEntityPrimaryInformationChanged', true);
      }
      if (
        state.activeTabsetItem === EditEntityPolicyTabset.RegulationItemsAndGeographicInformationTab
      ) {
        commit('setEntityRegulationItemsChanged', true);
      }
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      throw error;
    }
  },
  uploadGeneralAssemblyFile: async ({ commit }, { files, attachableId }) => {
    commit('setFileLoading', true);
    const res = await uploadDocuments(
      files,
      attachableId,
      mediaAttachableTypes.EntityPolicyRequest,
      mediaDocTypes.GeneralAssemblyFile,
    );
    commit('setFileLoading', false);
    return res;
  },
  getGeneralAssemblyFile: async ({ commit }, { attachableId }) => {
    commit('setFileLoading', true);
    const res = await getDocumentsList(
      attachableId,
      mediaAttachableTypes.EntityPolicyRequest,
      mediaDocTypes.GeneralAssemblyFile,
      null,
    );
    commit('setFileLoading', false);
    return res;
  },
  createEntityLocationField: async ({ commit, getters, state }, data) => {
    const session = getters.editRequestRegulationItems;
    const unitId = state.entityInfo.id;
    if (session.id && unitId) {
      try {
        const payload = {
          action: EditRequestUnitActions.Create,
          unitType: EditRequestUnitTypes.EntityLocation,
          editRequestId: session.id,
          ...data,
        };
        const serializedData = serializer(payload, 'edit_item');
        const editRequestItem = await EditPolicyApi.saveEditedField(serializedData, session.id);
        const deserializedEditRequestItem = await deserializer(editRequestItem.data);
        const region = LookupService.getRegions()
          .find((r) => r.code === data.newPayload.regionCode);
        const city = LookupService.getCities(data.newPayload.regionCode)
          .find((c) => c.uid === data.newPayload.cityUid);

        commit('setEntityLocation', {
          editItemId: deserializedEditRequestItem.id,
          cityUid: data.newPayload.cityUid,
          regionCode: data.newPayload.regionCode,
          cityArTitle: city.arTitle,
          cityEnTitle: city.enTitle,
          regionArTitle: region.arTitle,
          regionEnTitle: region.enTitle,
          unitType: data.unitType,
          loading: false,
          unitId: deserializedEditRequestItem.unitId,
        });
        commit('setEntityRegulationItemsChanged', true);
      } catch (e) {
        const { error } = ErrorHandler.parseFormErrors(e);
        throw error;
      }
    }
  },
  deleteEntityLocationGeneral: async ({ dispatch }, {
    data, id, editItemId, isTemporary,
  }) => {
    if (isTemporary) {
      await dispatch('deleteTemporaryEntityLocationField', id);
      return;
    }
    await dispatch('deleteExistingEntityLocationField', { data, id, editItemId });
  },
  getAcceptedUpdatesOfEntity: async ({ commit }, {
    adjustmentType,
    status,
    unitId,
    unitType,
  }) => {
    let updatesList = [];
    try {
      const res = await EditPolicyApi.getAcceptedUpdatesOfEntity({
        adjustmentType,
        status,
        unitId,
        unitType,
      });
      const updates = res.data?.listEditRequests?.editRequests || [];
      updatesList = handleEntityUpdates(updates).sort(
        (a, b) => a.updatedAt - b.updatedAt,
      );
      commit('setEntityUpdates', updatesList);
    } catch (e) {
      updatesList = ErrorHandler.parseFormErrors(e);
    }
    return updatesList;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
