import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';

import bankPermit from '@/modules/bank-permit';

const modules = [
  bankPermit,
];

export const setupModules = () => {
  modules.forEach((item) => {
    if (item.router) {
      router.addRoute(item.router.parent, item.router.route);
    }
    if (item.store) {
      store.registerModule(item.store.name, item.store.module);
    }
    if (item.i18n) {
      Object.entries(item.i18n).forEach(([locale, messages]) => {
        i18n.mergeLocaleMessage(locale, messages);
      });
    }
  });
};
