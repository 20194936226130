<template>
  <ul class="nav-list">
    <li
      v-for="(link, index) in linksData"
      :key="index"
      class="nav-list-item"
      :class="[itemClassName]"
      @click="itemClick"
    >
      <router-link
        class="nav-list-item-link"
        tag="a"
        :to="getRoute(link)"
        v-text="$t(link.label)"
      />
    </li>
    <!-- <li
      class="nav-list-item"
      :class="[itemClassName]"
      @click="itemClick"
    >
      <a
        class="nav-list-item-link"
        :href="openMigrationSpa"
        v-text="$t('migration.start')"
      />
    </li> -->
  </ul>
</template>

<script>
import { MIGRATION_SPA } from '@/constants';

export default {
  name: 'NavList',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    itemClassName: {
      type: String,
    },
  },
  computed: {
    linksData() {
      if (this.data.length) return this.data;
      return [
        {
          label: 'الخدمات الالكترونية',
          route: 'Services',
          href: '',
        },
        // {
        //   label: 'header.nav_check_bank_account_permit',
        //   route: 'CheckBankPermit',
        //   href: '',
        // },
        // {
        //   label: 'header.nav_verify_entity_license',
        //   route: 'VerifyEntityLicense',
        //   href: '',
        // },
        // {
        //   label: 'header.nav_verify_bank_delegations',
        //   route: 'VerifyBankDelegations',
        //   href: '',
        // },
        {
          label: 'header.nav_established_entities',
          route: 'EntitiesList',
          href: '',
        },
        {
          label: 'home.info.faq',
          route: 'FAQ',
          href: '',
        },
      ].filter((i) => !i.notAvailable);
    },
    openMigrationSpa() {
      return `${MIGRATION_SPA}/${this.$i18n.locale}`;
    },
  },
  methods: {
    getRoute(link) {
      if (link.route) {
        return { name: link.route };
      }
      return { patch: link.href };
    },
    itemClick() {
      this.$emit('itemClick', true);
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
